import { Api } from 'api';
import { ChartData } from 'chart.js';
import { downloadBlobAsFile, getRandomColorForChartPie } from 'core';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { Pie } from 'react-chartjs-2';
import { useMutation, useQuery } from 'react-query';
import { AdminPackageSaleReportGraphData, ExpertisePoint, ReportingQueryCacheKey } from 'types';
import { Box, Button, Center, Flex, Icon, Text, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { hideLoading, showLoading } from 'features';
import { DownloadIcon } from '@chakra-ui/icons';

export interface AdminReportExpertisePackagesProps {
  beginDate?: Date;
  endDate?: Date;
  expertisePoint?: ExpertisePoint;
}

type PackageSaleReportChartData = ChartData<'pie', number[], string>;

export const AdminReportExpertisePackages: React.FC<AdminReportExpertisePackagesProps> = ({
  beginDate,
  endDate,
  expertisePoint,
}) => {
  const authUserInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const toast = useToast();
  const dispatch = useDispatch();
  const { data: reportData, isLoading } = useQuery(
    [ReportingQueryCacheKey.GetPackageSalesReport, beginDate?.getTime(), endDate?.getTime(), expertisePoint?.id],
    async () => {
      if (beginDate && endDate) {
        const response =
          authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin
            ? await Api.Reporting.getSalesReportByExpertisePackageAdmin(
                beginDate.getTime(),
                endDate.getTime(),
                expertisePoint?.id,
              )
            : await Api.Reporting.getSalesReportByExpertisePackage(beginDate.getTime(), endDate.getTime());
        const packageSaleData = response.data;
        const graphData: AdminPackageSaleReportGraphData = {
          packageLabels: [],
          packageSales: [],
        };
        packageSaleData.forEach((packageSale) => {
          graphData.packageLabels.push(packageSale.expertisePackage.name);
          graphData.packageSales.push(packageSale.count);
        });
        return {
          isEmpty: packageSaleData.length === 0,
          graphData: {
            labels: graphData.packageLabels,
            datasets: [
              {
                label: 'Paket',
                data: graphData.packageSales,
                backgroundColor: getRandomColorForChartPie(packageSaleData.length),
              },
            ],
          } as PackageSaleReportChartData,
        };
      }
      return null;
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!(beginDate && endDate),
    },
  );

  const getExportPackagesReport = useMutation<any, any>(
    async () => {
      dispatch(showLoading());
      if (beginDate && endDate) {
        const response =
          authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin
            ? await Api.Reporting.exportSalesReportByExpertisePackageAdmin(
                beginDate.getTime(),
                endDate.getTime(),
                expertisePoint?.id,
              )
            : await Api.Reporting.exportSalesReportByExpertisePackage(beginDate.getTime(), endDate.getTime());

        return response;
      }
      return null;
    },
    {
      onSuccess(data) {
        if (data) {
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const fileName = `Paketler${formattedDate}.xlsx`;
          downloadBlobAsFile(data, fileName);
        } else {
          toast({
            status: 'info',
            title: 'Rapor Bulunamadı',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Rapor İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  if (!reportData || isLoading) {
    return null;
  }

  if (reportData.isEmpty) {
    return (
      <Center>
        <Text fontSize="2xl" mt={2} mb={6}>
          Seçtiğiniz Tarih Aralığında Veri Bulunmuyor!
        </Text>
      </Center>
    );
  }
  return (
    <Flex direction="column" gap="4">
      <Center>
        <Box width="50%">
          <Pie data={reportData.graphData} />
        </Box>
      </Center>
      <Button variant="solid" leftIcon={<Icon as={DownloadIcon} />} onClick={() => getExportPackagesReport.mutate()}>
        Excel İndir
      </Button>
    </Flex>
  );
};
