import { ViewIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from 'components';
import React from 'react';
import { InquiryKmData } from 'types';

export interface AdminExpertisePointKmInquiriesHistoryTableProps {
  inquiriesData: InquiryKmData[];
}

export const AdminExpertisePointKmInquiriesHistoryTable: React.FC<AdminExpertisePointKmInquiriesHistoryTableProps> = ({
  inquiriesData,
}) => {
  const columnHelper = createColumnHelper<InquiryKmData>();

  const columns = [
    columnHelper.accessor('chassis', {
      header: 'Şase no',
    }),

    columnHelper.accessor('createdAt', {
      header: 'Tarih',
      cell: (info) => new Date(info.getValue()).toLocaleString('tr-TR'),
    }),

    columnHelper.accessor('imageUrl', {
      header: 'URL',
      cell: (info) => {
        return (
          <Flex gap={4}>
            <Tooltip label="View">
              <IconButton
                aria-label="View"
                icon={<ViewIcon />}
                onClick={() => window.open(info.getValue(), '_blank')}
              />
            </Tooltip>
          </Flex>
        );
      },
    }),
  ];

  return (
    <DataTable
      data={inquiriesData}
      columns={columns}
      usePagination
      tableProps={{
        variant: 'striped',
        colorScheme: 'teal',
      }}
      title="Km Sorgulama Geçmişi"
    />
  );
};
