import React, { useRef, useState } from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { DataTable, LoadingIndicator } from 'components';
import {
  Button,
  Flex,
  IconButton,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ButtonGroup,
  Tooltip,
  useToast,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBoolean,
  Center,
} from '@chakra-ui/react';
import { AddIcon, CheckIcon, ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { GenericMessages, getErrorMessage } from 'core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { ExpertisePoint, ExpertisePointQueryCacheKey, RegisterUserRequest } from 'types';
import { Api } from 'api';
import {
  AdminExpertisePointForm,
  AdminExpertisePointManagementBalanceHistory,
  AdminExpertisePointUserManagementDataTable,
  AdminExpertisePointManagementSpendingHistory,
  AdminPageTitle,
} from '../components';

const userRegisterFormValidation = yup.object({
  name: yup.string().required(GenericMessages.CannotBeEmpty),
  surname: yup.string().required(GenericMessages.CannotBeEmpty),
  email: yup.string().email(GenericMessages.InvalidEmail).required(GenericMessages.CannotBeEmpty),
  password: yup.string().required(GenericMessages.CannotBeEmpty),
  isAdmin: yup.bool(),
});

type RowActionProps = {
  row: Row<ExpertisePoint>;
  onExpertisePointSpendingHistoryModalOpen: () => void;
  onExpertisePointBalanceHistoryModalOpen: () => void;
  setFocusedExpertisePoint: (expertisePoint: ExpertisePoint) => void;
  onChangeExpertisePointDialogOpen: () => void;
  onExpertisePointDeleteConfirmDialogOpen: () => void;
  onExpertisePointActivateConfirmDialogOpen: () => void;
  onUserManagementModalOpen: () => void;
};

const RowAction: React.FC<RowActionProps> = ({
  row,
  onExpertisePointSpendingHistoryModalOpen,
  setFocusedExpertisePoint,
  onExpertisePointBalanceHistoryModalOpen,
  onChangeExpertisePointDialogOpen,
  onExpertisePointDeleteConfirmDialogOpen,
  onExpertisePointActivateConfirmDialogOpen,
  onUserManagementModalOpen,
}) => {
  const openCallback = (callback: any) => () => {
    setFocusedExpertisePoint(row.original);
    callback();
  };
  return (
    <Flex gap={4}>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          İşlemler
        </MenuButton>
        <MenuList>
          <MenuItem onClick={openCallback(onChangeExpertisePointDialogOpen)}>Düzenle</MenuItem>
          {row.original.active && (
            <MenuItem onClick={openCallback(onExpertisePointDeleteConfirmDialogOpen)}>Deaktive et</MenuItem>
          )}
          {!row.original.active && (
            <MenuItem onClick={openCallback(onExpertisePointActivateConfirmDialogOpen)}>Aktifleştir</MenuItem>
          )}
          <MenuItem onClick={openCallback(onExpertisePointSpendingHistoryModalOpen)}>Harcama Geçmişi</MenuItem>
          <MenuItem onClick={openCallback(onExpertisePointBalanceHistoryModalOpen)}>Bakiye Geçmişi</MenuItem>
          <MenuItem onClick={openCallback(onUserManagementModalOpen)}>Kullanıcılar</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export const AdminExpertisePointsManagementPage: React.FC = () => {
  const toast = useToast();
  const [focusedExpertisePoint, setFocusedExpertisePoint] = useState<ExpertisePoint>();
  const {
    data: expertisePointsData,
    isLoading,
    refetch: refetchExpertisePoints,
  } = useQuery(
    'asdasdasd',
    async () => {
      const response = await Api.ExpertisePoint.getAllExpertisePoints();
      return response.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
  const {
    onOpen: onExpertisePointSpendingHistoryModalOpen,
    onClose: onExpertisePointSpendingHistoryModalClose,
    isOpen: isExpertisePointSpendingHistoryModalOpen,
  } = useDisclosure();

  const {
    onOpen: onChangeExpertisePointDialogOpen,
    onClose: onChangeExpertisePointDialogClose,
    isOpen: isChangeExpertisePointDialogOpen,
  } = useDisclosure({
    onClose: () => {
      // eslint-disable-next-line no-use-before-define
      resetForm();
    },
  });

  const {
    onOpen: onExpertisePointBalanceHistoryModalOpen,
    onClose: onExpertisePointBalanceHistoryModalClose,
    isOpen: isExpertisePointBalanceHistoryModalOpen,
  } = useDisclosure();

  const {
    onOpen: onExpertisePointDeleteConfirmDialogOpen,
    onClose: onExpertisePointDeleteConfirmDialogClose,
    isOpen: isExpertisePointDeleteConfirmDialogOpen,
  } = useDisclosure();

  const {
    onOpen: onExpertisePointActivateConfirmDialogOpen,
    onClose: onExpertisePointActivateConfirmDialogClose,
    isOpen: isExpertisePointActivateConfirmDialogOpen,
  } = useDisclosure();

  const {
    onOpen: onUserManagementModalOpen,
    onClose: onUserManagementModalClose,
    isOpen: isUserManagementModalOpen,
  } = useDisclosure();

  const registerUserMutation = useMutation<void, any, RegisterUserRequest>(
    async (request: RegisterUserRequest) => {
      await Api.User.registerUser(request);
    },
    {
      onSuccess() {
        // eslint-disable-next-line no-use-before-define
        onCreateExpertisePointDialogClose();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Kullanıcı başarı ile oluşturuldu',
        });
        // refetchUsers();
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcı kayıt sırasında hata oluştu'),
        });
      },
    },
  );

  const columnHelper = createColumnHelper<ExpertisePoint>();

  const deleteExpertisePointCancelButtonRef = useRef<HTMLButtonElement>(null);
  const activateExpertisePointCancelButtonRef = useRef<HTMLButtonElement>(null);

  const deleteExpertisePointMutation = useMutation<any, any, number>(
    async (expertisePointId: number) => {
      await Api.ExpertisePoint.deleteExpertisePoint(expertisePointId);
    },
    {
      onSuccess() {
        // eslint-disable-next-line no-use-before-define
        onExpertisePointDeleteConfirmDialogClose();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Bayi başarı ile deaktive edildi',
        });
        // refetchUsers();
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Bayi silerken hata olustu'),
        });
      },
    },
  );

  const activateExpertisePointMutation = useMutation<any, any, number>(
    async (expertisePointId: number) => {
      await Api.ExpertisePoint.activateExpertisePoint(expertisePointId);
    },
    {
      onSuccess() {
        // eslint-disable-next-line no-use-before-define
        onExpertisePointActivateConfirmDialogClose();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Bayi başarı ile aktifleştirildi.',
        });
        // refetchUsers();
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Bayi aktifleştirirken hata olustu'),
        });
      },
    },
  );

  const columns = [
    columnHelper.accessor('id', {
      header: 'Id',
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor((row) => `${row.name}`, {
      id: 'Ad',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor('active', {
      header: 'Aktif Mi?',
      cell: (info) => {
        return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Aksiyonlar',
      cell: (cellProps) => {
        const { row } = cellProps;
        // const { active } = row.original;

        // if (!active) {
        //   return null;
        // }

        return (
          <RowAction
            row={row}
            onExpertisePointDeleteConfirmDialogOpen={onExpertisePointDeleteConfirmDialogOpen}
            onExpertisePointActivateConfirmDialogOpen={onExpertisePointActivateConfirmDialogOpen}
            onExpertisePointSpendingHistoryModalOpen={onExpertisePointSpendingHistoryModalOpen}
            setFocusedExpertisePoint={setFocusedExpertisePoint}
            onExpertisePointBalanceHistoryModalOpen={onExpertisePointBalanceHistoryModalOpen}
            onChangeExpertisePointDialogOpen={onChangeExpertisePointDialogOpen}
            onUserManagementModalOpen={onUserManagementModalOpen}
          />
        );
      },
    }),
  ];

  const { resetForm } = useFormik({
    validationSchema: userRegisterFormValidation,
    initialValues: {
      name: '',
      surname: '',
      email: '',
      isAdmin: false,
      password: '',
    },
    validateOnChange: false,
    onSubmit: (userFormData: any) => {
      registerUserMutation.mutate({
        name: userFormData.name,
        surname: userFormData.surname,
        email: userFormData.email,
        isAdmin: userFormData.isAdmin,
        password: userFormData.password,
      });
    },
  });

  const {
    onOpen: onCreateUserDialogOpen,
    onClose: onCreateExpertisePointDialogClose,
    isOpen: isCreateUserDialogOpen,
  } = useDisclosure({
    onClose: () => {
      resetForm();
    },
  });

  const [
    isExpertisePointSaveFormLoading,
    { on: setExpertisePointSaveFormLoadingOn, off: setIsExpertisePointSaveFormLoadingOff },
  ] = useBoolean();

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch: refetchUsers,
  } = useQuery(
    [ExpertisePointQueryCacheKey.GetUsers, focusedExpertisePoint?.id],
    async () => {
      if (focusedExpertisePoint) {
        const response = await Api.ExpertisePoint.getUsersForExpertisePoint(focusedExpertisePoint?.id);
        return response.data;
      }
      return [];
    },
    {
      onError(error: Error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcıları çekilirken hata oluştu'),
        });
      },
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: focusedExpertisePoint != null,
    },
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AdminPageTitle title="Bayi Yönetimi" />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DataTable
            data={expertisePointsData || []}
            columns={columns}
            usePagination
            tableProps={{
              variant: 'striped',
              colorScheme: 'teal',
            }}
            title="Bayiler"
            actions={
              <ButtonGroup>
                <Tooltip label="Bayi Ekle">
                  <IconButton aria-label="Bayi Ekle" icon={<AddIcon />} onClick={onCreateUserDialogOpen} />
                </Tooltip>
              </ButtonGroup>
            }
          />

          {/** #region Bayi Olustur */}
          <Modal isOpen={isCreateUserDialogOpen} onClose={onCreateExpertisePointDialogClose} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Bayı Oluştur</ModalHeader>
              <ModalCloseButton disabled={isExpertisePointSaveFormLoading} />
              <ModalBody>
                <AdminExpertisePointForm
                  formId="createExpertisePointForm"
                  onSubmitStart={setExpertisePointSaveFormLoadingOn}
                  onSubmitComplete={() => {
                    setIsExpertisePointSaveFormLoadingOff();
                    onCreateExpertisePointDialogClose();
                    refetchExpertisePoints();
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button mr={3} onClick={onCreateExpertisePointDialogClose} disabled={isExpertisePointSaveFormLoading}>
                  İptal
                </Button>
                <Button
                  colorScheme="blue"
                  type="submit"
                  form="createExpertisePointForm"
                  isLoading={isExpertisePointSaveFormLoading}
                >
                  Kaydet
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/** #endregion */}
          {/** #region Bayi Duzenle */}
          <Modal isOpen={isChangeExpertisePointDialogOpen} onClose={onChangeExpertisePointDialogClose} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Bayı Düzenle</ModalHeader>
              <ModalCloseButton disabled={isExpertisePointSaveFormLoading} />
              <ModalBody>
                <AdminExpertisePointForm
                  expertisePoint={focusedExpertisePoint}
                  formId="changeExpertisePointForm"
                  onSubmitStart={setExpertisePointSaveFormLoadingOn}
                  onSubmitComplete={() => {
                    setIsExpertisePointSaveFormLoadingOff();
                    onChangeExpertisePointDialogClose();
                    refetchExpertisePoints();
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button mr={3} onClick={onChangeExpertisePointDialogClose} disabled={isExpertisePointSaveFormLoading}>
                  İptal
                </Button>
                <Button
                  colorScheme="blue"
                  type="submit"
                  form="changeExpertisePointForm"
                  isLoading={isExpertisePointSaveFormLoading}
                >
                  Kaydet
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/** #endregion */}
          <Modal
            onClose={onExpertisePointSpendingHistoryModalClose}
            isOpen={isExpertisePointSpendingHistoryModalOpen}
            size="full"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Harcama Geçmişi</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {focusedExpertisePoint && (
                  <AdminExpertisePointManagementSpendingHistory expertisePoint={focusedExpertisePoint} />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal
            onClose={onExpertisePointBalanceHistoryModalClose}
            isOpen={isExpertisePointBalanceHistoryModalOpen}
            size="full"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Bakiye Geçmişi</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {focusedExpertisePoint && (
                  <AdminExpertisePointManagementBalanceHistory expertisePoint={focusedExpertisePoint} />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          <AlertDialog
            onClose={onExpertisePointDeleteConfirmDialogClose}
            isOpen={isExpertisePointDeleteConfirmDialogOpen}
            leastDestructiveRef={deleteExpertisePointCancelButtonRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Bayi Deaktive Et
                </AlertDialogHeader>

                <AlertDialogBody>Bayi Deaktive Etmek İstediğinize Emin Misiniz?</AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={deleteExpertisePointCancelButtonRef} onClick={onExpertisePointDeleteConfirmDialogClose}>
                    İptal
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      if (focusedExpertisePoint) {
                        deleteExpertisePointMutation.mutate(focusedExpertisePoint.id);
                      }
                    }}
                    ml={3}
                  >
                    Deaktive Et
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <AlertDialog
            onClose={onExpertisePointActivateConfirmDialogClose}
            isOpen={isExpertisePointActivateConfirmDialogOpen}
            leastDestructiveRef={activateExpertisePointCancelButtonRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Bayi Aktifleştir
                </AlertDialogHeader>

                <AlertDialogBody>Bayi Aktifleştirmek İstediğinize Emin Misiniz?</AlertDialogBody>

                <AlertDialogFooter>
                  <Button
                    ref={activateExpertisePointCancelButtonRef}
                    onClick={onExpertisePointActivateConfirmDialogClose}
                  >
                    İptal
                  </Button>
                  <Button
                    colorScheme="teal"
                    onClick={() => {
                      if (focusedExpertisePoint) {
                        activateExpertisePointMutation.mutate(focusedExpertisePoint.id);
                      }
                    }}
                    ml={3}
                  >
                    Aktifleştir
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <Modal onClose={onUserManagementModalClose} isOpen={isUserManagementModalOpen} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Kullanıcı Yönetimi</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {isUsersLoading ? (
                  <LoadingIndicator />
                ) : (
                  <AdminExpertisePointUserManagementDataTable
                    users={users || []}
                    refetchUsers={refetchUsers}
                    expertisePointId={focusedExpertisePoint?.id}
                  />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};
