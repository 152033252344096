import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Checkbox,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackItem,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Api } from 'api';
import { ExpertisePointSelect, LoadingText } from 'components';
import { FormControlFormInput, FormControlFormTextarea, GenericMessages, getErrorMessage } from 'core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Announcement, ExpertisePoint, SendAnnouncementRequest, UtilQueryCacheKey } from 'types';
import * as yup from 'yup';
import { AdminAnnouncementsTable, AdminPageTitle } from '../components';

const validationSchema = yup.object({
  title: yup.string().required(GenericMessages.CannotBeEmpty),
  description: yup.string().required(GenericMessages.CannotBeEmpty),
  selectedExpertisePoints: yup.array().when('sendToEveryone', {
    is: false,
    then: yup.array().min(1, 'En az bir bayi eklemek zorundasınız').required('En az bir bayi eklemek zorundasınız'),
  }),
});

export const AdminAnnouncementsPage: React.FC = () => {
  const [focusedAnnouncement, setFocusedAnnouncement] = useState<Announcement>();
  const authUserInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const toast = useToast();
  const {
    isOpen: isAddAnnouncementDialogOpen,
    onOpen: onAddAnnouncementDialogOpen,
    onClose: onAddAnnouncementDialogClose,
  } = useDisclosure();

  const {
    data: allAnnouncemenets,
    refetch: refetchAllAnnouncements,
    isLoading,
  } = useQuery(
    [UtilQueryCacheKey.GetAnnouncements],
    async () => {
      const response = authUserInfo?.isSuperAdmin
        ? await Api.Util.getAllAnnouncements()
        : await Api.Util.getUserAnnouncements();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError(error: any) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Duyurulari çekerken hata oluştu'),
        });
      },
    },
  );

  const saveAnnouncementMutation = useMutation<any, any, SendAnnouncementRequest>(
    async (sendAnnouncementRequest: SendAnnouncementRequest) => {
      const response = await Api.Util.sendAnnouncement(sendAnnouncementRequest);
      return response.data;
    },
    {
      onSuccess: () => {
        toast({
          isClosable: true,
          status: 'success',
          title: 'Duyuru oluşturuldu',
        });
        onAddAnnouncementDialogClose();
        refetchAllAnnouncements();
        // eslint-disable-next-line no-use-before-define
        resetForm();
      },
      onError: () => {
        toast({
          isClosable: true,
          status: 'error',
          title: 'Duyuru oluşturulurken hata oluştu',
        });
      },
    },
  );

  const { setFieldValue, values, handleChange, errors, handleSubmit, resetForm, setFieldError } = useFormik({
    initialValues: {
      selectedExpertisePoint: null,
      selectedExpertisePoints: [] as ExpertisePoint[],
      title: '',
      description: '',
      sendToEveryone: false,
    },
    onSubmit: (formData) => {
      saveAnnouncementMutation.mutateAsync({
        title: formData.title,
        description: formData.description,
        toExpertisePointIds: formData.sendToEveryone
          ? undefined
          : formData.selectedExpertisePoints.map((expertisePoint) => expertisePoint.id),
      });
    },
    validationSchema,
    validateOnChange: false,
  });

  const onExpertisePointAddClicked = () => {
    const expertisePoint = values.selectedExpertisePoint as ExpertisePoint | null;
    if (expertisePoint) {
      if (values.selectedExpertisePoints.findIndex((elem) => elem.id === expertisePoint.id) === -1) {
        setFieldError('selectedExpertisePoint', undefined);
        setFieldValue('selectedExpertisePoints', [...values.selectedExpertisePoints, values.selectedExpertisePoint]);
      } else {
        setFieldError('selectedExpertisePoint', 'Ayni Bayi Eklenemez');
      }
    } else {
      setFieldError('selectedExpertisePoint', 'Bayi Seçmeniz Gerekiyor');
    }
  };

  const onDeleteExpertisePoint = (index: number) => () => {
    const newExpertisePointValues = [...values.selectedExpertisePoints];
    newExpertisePointValues.splice(index, 1);
    setFieldValue('selectedExpertisePoints', newExpertisePointValues);
  };

  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();

  const deleteMutation = useMutation<any, any, number>(
    async (announcementId: number) => {
      await Api.Util.deleteAnnouncement(announcementId);
    },
    {
      onSuccess: () => {
        toast({
          title: 'Duyuru başarı ile silindi',
          isClosable: true,
          status: 'success',
        });
        refetchAllAnnouncements();
        onDeleteModalClose();
      },
      onError: () => {
        toast({
          title: 'Duyuru silinirken hata oluştu',
          isClosable: true,
          status: 'error',
        });
      },
    },
  );

  if (isLoading) {
    return <LoadingText />;
  }

  const showNoAnnouncementLabel = allAnnouncemenets?.length === 0 && !authUserInfo?.isSuperAdmin;

  return (
    <>
      <AdminPageTitle title="Duyurular" />
      {showNoAnnouncementLabel ? (
        <Center>
          <Text>Şu an için gösterilebilecek bir duyuru yok</Text>
        </Center>
      ) : (
        <AdminAnnouncementsTable
          announcements={allAnnouncemenets || []}
          isAdmin={authUserInfo?.isSuperAdmin || false}
          onAddAnnouncementClicked={onAddAnnouncementDialogOpen}
          refetch={refetchAllAnnouncements}
          onDeleteClicked={(announcement: Announcement) => {
            setFocusedAnnouncement(announcement);
            onDeleteModalOpen();
          }}
        />
      )}

      <Modal isOpen={isAddAnnouncementDialogOpen} onClose={onAddAnnouncementDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Duyuru Ekle</ModalHeader>
          <form id="createAnnouncementDialog" noValidate onSubmit={handleSubmit}>
            <ModalBody>
              <VStack align="stretch" gap={2}>
                <StackItem>
                  <VStack align="stretch">
                    <HStack align="start">
                      <ExpertisePointSelect
                        onExpertisePointSelected={(expertisePoint?: ExpertisePoint) => {
                          setFieldValue('selectedExpertisePoint', expertisePoint);
                        }}
                        error={errors.selectedExpertisePoint != null}
                        helperText={
                          errors.selectedExpertisePoint != null ? (errors.selectedExpertisePoint as string) : ''
                        }
                        filterActive
                      />
                      <StackItem alignSelf="end">
                        <IconButton
                          icon={<AddIcon />}
                          aria-label="Ekle"
                          type="button"
                          onClick={onExpertisePointAddClicked}
                          disabled={values.sendToEveryone}
                        >
                          Ekle
                        </IconButton>
                      </StackItem>
                    </HStack>
                    <Checkbox
                      name="sendToEveryone"
                      isChecked={values.sendToEveryone}
                      onChange={(event) => {
                        setFieldValue(`sendToEveryone`, event.target.checked);
                      }}
                    >
                      Herkese Gönder
                    </Checkbox>
                  </VStack>
                </StackItem>
                <StackItem>
                  {!values.sendToEveryone && (
                    <VStack align="start" gap={1}>
                      <Text fontSize="md">Bayiler</Text>
                      {values.selectedExpertisePoints.length === 0 && <Text fontSize="xs"> Lütfen Bayi Seçin</Text>}
                      <HStack gap={1}>
                        {values.selectedExpertisePoints.map((selectedExpertisePoint, index) => (
                          <Tag
                            key={`ausdt${selectedExpertisePoint.id}`}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="green"
                          >
                            <TagLabel>{selectedExpertisePoint.name}</TagLabel>
                            <TagCloseButton onClick={onDeleteExpertisePoint(index)} />
                          </Tag>
                        ))}
                      </HStack>
                    </VStack>
                  )}
                </StackItem>
                <StackItem>
                  <FormControlFormInput
                    label="Başlık"
                    placeholder="Başlık"
                    value={values.title}
                    onChange={handleChange}
                    name="title"
                    error={errors.title != null}
                    helperText={errors.title}
                  />
                </StackItem>
                <StackItem>
                  <FormControlFormTextarea
                    label="Duyuru"
                    placeholder="Duyuru"
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                    name="description"
                    error={errors.description != null}
                    helperText={errors.description}
                  />
                </StackItem>
              </VStack>
            </ModalBody>
          </form>
          <ModalFooter>
            <Button mr={3} onClick={onAddAnnouncementDialogClose} disabled={saveAnnouncementMutation.isLoading}>
              İptal
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              form="createAnnouncementDialog"
              isLoading={saveAnnouncementMutation.isLoading}
            >
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Duyuru Sil</ModalHeader>
          <ModalBody>
            <Text>Bu duyuruyu silmek istediğinize emin misniz?</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onDeleteModalClose} isLoading={deleteMutation.isLoading}>
              Hayır
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                if (focusedAnnouncement) {
                  deleteMutation.mutate(focusedAnnouncement.id);
                }
              }}
              isLoading={deleteMutation.isLoading}
            >
              Evet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
