import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { VehicleQueryCacheKeys } from 'types';

export type VehicleYearSelectProps = FormControlFormSelectProps;
export const VehicleYearSelect: React.FC<VehicleYearSelectProps> = ({ ...formSelectProps }) => {
  const query = useQuery(
    VehicleQueryCacheKeys.GetYears,
    async () => {
      const years = [];
      for (let i = new Date().getFullYear(); i >= 1985; i -= 1) {
        years.push(i);
      }
      return years;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <FormControlFormSelect label="Model Yılı" placeholder="Model Yılı Seç" {...formSelectProps}>
      {query.data?.map((year) => (
        <option value={year} key={`vhsy${year}`}>
          {year}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
