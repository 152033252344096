import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  useBoolean,
  useColorModeValue,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteProps,
  AutoCompleteCreatable,
} from '@choc-ui/chakra-autocomplete';
import { Api } from 'api';
import { createCustomerSocket } from 'core';
import { Socket } from 'socket.io-client';
import { hideLoading, showLoading } from 'features';
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  DamageNoteSocket,
  ExpertiseDamageNoteType,
  ExpertiseQueryCacheKey,
  PictureNoteElem,
  SaveDamageNoteRequest,
  SaveDamageNoteResponse,
  SearchExpertiseDamageNoteDictItem,
} from 'types';
import { AutoCompletePictureNoteTag } from './AutoCompleteNoteTag';

export interface NoteAutoCompleteSelectProps extends FormControlProps {
  customerId: number;
  noteType: ExpertiseDamageNoteType;
  autoCompleteProps?: Omit<AutoCompleteProps, 'children'>;
}

interface AddNoteItem {
  note: string;
  damageNoteDictId?: number;
}

export const NoteAutoCompleteSelect: React.FC<NoteAutoCompleteSelectProps> = ({
  customerId,
  noteType,
  autoCompleteProps,
  ...formControlProps
}) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [tags, setTags] = useState<PictureNoteElem[]>([]);
  const [hasFocus, { on: onHasFocus, off: offHasFocus }] = useBoolean(false);

  const createTagBackgroundColorBg = useColorModeValue('#fff', '#232934');
  const [autoCompleteInputText, setAutoCompleteInputText] = useState<string>('');
  const [searchResult, setSearchResults] = useState<SearchExpertiseDamageNoteDictItem[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);

  const findDictItems = (note: string) => searchResult.find((elem) => elem.note === note);

  const search = async (query: string) => {
    const { data } = await Api.Expertise.searchDamageNotes(query, noteType);
    setSearchResults(data);
  };

  useQuery(
    ExpertiseQueryCacheKey.GetDamageNotes,
    async () => {
      const response = await Api.Expertise.getDamageNotes(customerId, noteType);
      return response.data.map((note) => ({
        id: note.id,
        label: note.note,
        image: note.photoUrl,
      }));
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (data) => {
        setTags(data);
      },
    },
  );
  const mutation = useMutation<SaveDamageNoteResponse, any, SaveDamageNoteRequest>(
    async (saveExpertiseNoteRequest: SaveDamageNoteRequest) => {
      const response = await Api.Expertise.saveDamageNote(saveExpertiseNoteRequest);
      return response.data;
    },
  );

  const deleteMutation = useMutation<void, any, number>(async (damageNoteId: number) => {
    await Api.Expertise.deleteDamageNote(damageNoteId);
  });

  const clearImageMutation = useMutation<number, any, number>(
    async (index) => {
      dispatch(showLoading());
      await Api.Expertise.clearDamageNoteImage(tags[index].id);
      return index;
    },
    {
      onSuccess: (index: number) => {
        const newTags = [...tags];
        newTags[index].image = undefined;
        setTags(newTags);
        toast({
          status: 'success',
          isClosable: true,
          title: 'Resim temizlendi',
        });
      },
      onError: (err) => {
        console.error(err);
        toast({
          status: 'error',
          isClosable: true,
          title: 'Resim temizlenirken hata oluştu',
        });
      },
      onSettled: () => {
        dispatch(hideLoading());
      },
    },
  );

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
    // can not delete first letter of input
    // if (!e.target.value || e.target.value === '') return false;
    setAutoCompleteInputText(e.target.value);
    search(e.target.value);
    return false;
  };

  const onImageChange = (index: number, file?: File) => {
    dispatch(showLoading());

    mutation
      .mutateAsync({
        id: tags[index].id,
        customerId,
        noteType,
        note: tags[index].label,
        file,
      })
      .then((response) => {
        const newTags = [...tags];
        newTags[index].image = response.photoUrl;
        setTags(newTags);
      })
      .catch((err) => {
        console.error(err);
        toast({
          status: 'error',
          title: 'Resim Yüklenirken Hata Oluştu',
          isClosable: true,
        });
      })
      .then(() => dispatch(hideLoading()));
  };

  const deleteTag = (index: number) => {
    dispatch(showLoading());
    deleteMutation
      .mutateAsync(tags[index].id)
      .then(() => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
      })
      .catch((error) => {
        console.error(error);
        toast({
          status: 'error',
          title: 'Silinirken hata oluştu',
          isClosable: true,
        });
      })
      .then(() => dispatch(hideLoading()));
  };

  const addNewNote = (item: AddNoteItem) => {
    dispatch(showLoading());
    if (tags.findIndex((elem: PictureNoteElem) => elem.label === item.note) >= 0) {
      toast({
        status: 'error',
        title: 'Bu not zaten var',
        isClosable: true,
      });
      dispatch(hideLoading());
      return;
    }
    mutation
      .mutateAsync({
        customerId,
        noteType,
        note: item.note,
        damageNoteDictId: item.damageNoteDictId,
      })
      .then((response) => {
        const newTags = [...tags];
        newTags.push({
          id: response.id,
          label: response.note,
        });
        setTags(newTags);
        search('');
        setAutoCompleteInputText('');
        // offHasFocus();
      })
      .catch((err) => {
        console.error(err);
        toast({
          status: 'error',
          title: 'Not Kayıt Edilirken Hata oluştu',
          isClosable: true,
        });
      })
      .then(() => dispatch(hideLoading()));
  };

  useEffect(() => {
    if (customerId) {
      const newCustomerSocket = createCustomerSocket(customerId.toString());
      setCustomerSocket(newCustomerSocket);

      const handleExpertiseBodyDamageNoteChanged = (data: DamageNoteSocket) => {
        const formatData = data.notes.map((note) => ({
          id: note.id,
          label: note.note,
          image: note.photoUrl,
          noteType: note.noteType,
          customerId: note.customerId,
        }));
        if (
          formatData.length &&
          formatData.some((tag) => tag.customerId === customerId) &&
          formatData.some((tag) => tag.noteType === noteType)
        ) {
          setTags(formatData);
        } else if (Number(data.type) === noteType) {
          setTags([]);
        }
      };

      newCustomerSocket.on('expertiseBodyDamageNoteChanged', handleExpertiseBodyDamageNoteChanged);

      return () => {
        newCustomerSocket.off('expertiseBodyDamageNoteChanged', handleExpertiseBodyDamageNoteChanged);
        newCustomerSocket.disconnect();
      };
    }
    return undefined;
  }, [customerId, noteType]);

  return (
    <FormControl {...formControlProps}>
      <FormLabel>Fotoğraflı Notlar</FormLabel>
      {/** Yeni girdi eklemeyi kapatmak icin freeSolo ve createable flaglari silinmeli
       * Buna ek olarak satir 301 de olan icinde <AutoCompleteCreatable> olan
       * blogun silinmesi gerekiyor
       */}
      <AutoComplete
        freeSolo
        creatable
        onSelectOption={({ item }) => {
          if (!item.creatable) {
            const dictItem = findDictItems(item.value);
            if (dictItem) {
              addNewNote({
                note: item.value,
                damageNoteDictId: dictItem?.id,
              });
              return;
            }
          }
          addNewNote({ note: item.value });
        }}
        {...autoCompleteProps}
      >
        <Wrap mb={2}>
          {tags.map((tag, tid) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <WrapItem key={tid}>
                <AutoCompletePictureNoteTag
                  label={tag.label}
                  onRemove={() => deleteTag(tid)}
                  onImageChange={(file?: File) => onImageChange(tid, file)}
                  image={tag.image}
                  onClearImage={() => clearImageMutation.mutate(tid)}
                  isActionDisabled={formControlProps.isDisabled}
                />
              </WrapItem>
            );
          })}
        </Wrap>

        <AutoCompleteInput
          variant="filled"
          onChange={onInputChange}
          value={autoCompleteInputText}
          onFocus={() => onHasFocus()}
          onBlur={() => {
            setTimeout(() => {
              offHasFocus();
            }, 100);
          }}
        />
        {hasFocus && (
          <Box
            mt={2}
            mb={1}
            boxShadow="base"
            borderColor="var(--chakra-colors-chakra-border-color)"
            bg={createTagBackgroundColorBg}
            borderRadius="var(--chakra-radii-md)"
            py={4}
          >
            <AutoCompleteCreatable>{({ value }) => <span>{value} Ekle...</span>}</AutoCompleteCreatable>
          </Box>
        )}

        <AutoCompleteList>
          {searchResult.map((country) => (
            <AutoCompleteItem
              // eslint-disable-next-line react/no-array-index-key
              key={`option-${country.id}`}
              value={country.note}
              textTransform="capitalize"
              _selected={{ bg: 'whiteAlpha.50' }}
              _focus={{ bg: 'whiteAlpha.100' }}
              fixed
            >
              {country.note}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
    </FormControl>
  );
};
