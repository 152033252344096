import { Spinner, SpinnerProps, Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export interface LoadingTextProps extends SpinnerProps {
  textProps?: TextProps;
}
export const LoadingText: React.FC<LoadingTextProps> = ({ textProps, ...spinnerProps }) => {
  return (
    <>
      <Spinner size="md" mr={2} {...spinnerProps} /> <Text {...textProps}>Yükleniyor...</Text>
    </>
  );
};
