// #region Enums
export const enum AdminPages {
  REPORTS = 'reports',
  BALANCE = 'balance',
  USERS = 'users',
  PRICE_LIST = 'price-list',
  PACKAGE_MANAGEMENT = 'package-management',
  EXPERTISE_POINT_MANAGEMENT = 'expertise_point_management',
  ANNOUNCEMENT = 'announcement',
  PANEL = 'panel',
  DICTIONARY = 'not-sozluk',
  INQUIRIES = 'inquiries',
  INHOUSE_CODE = 'inhouse-code',
}

export const enum AdminReportPages {
  PACKAGE = 'package',
  SALES = 'sales',
  QUERIES = 'queries',
}

// #endregion
export interface AdminUserManagementUser {
  id: number;
  name: string;
  surname: string;
  email: string;
  isAdmin: boolean;
  active: boolean;
}
