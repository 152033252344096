import { useToast } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { VehicleQueryCacheKeys } from 'types';

export type VehicleModelSelectProps = FormControlFormSelectProps & { brandId?: number | string };

export const VehicleModelSelect: React.FC<VehicleModelSelectProps> = ({ brandId, ...formSelectProps }) => {
  const toast = useToast();

  const { refetch, data } = useQuery(
    [VehicleQueryCacheKeys.GetModels, brandId],
    async () => {
      if (brandId) {
        const response = await Api.Vehicle.getModels(brandId);
        return response.data;
      }
      return [];
    },
    {
      initialData: [],
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        toast({
          title: 'Markalar cekilirken hata olustu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
  useEffect(() => {
    if (brandId) {
      refetch();
    }
  }, [brandId]);
  return (
    <FormControlFormSelect
      label="Model"
      placeholder="Model Seç"
      {...formSelectProps}
      isDisabled={!brandId || !data || data.length === 0 || formSelectProps.isDisabled}
    >
      {data &&
        data.map((brand) => (
          <option value={brand.id} key={`vhbsb${brand.id}`}>
            {brand.name}
          </option>
        ))}
    </FormControlFormSelect>
  );
};
