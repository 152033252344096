import { ExpertiseCarBodyTypes } from './vehicle.types';

// #region Enums
export const enum CustomerQueryCacheKey {
  GetDailyCustomer = 'GetDailyCustomers',
  GetExpertisePackages = 'GetExpertisePackages',
  GetExpertisePackagesByGroup = 'GetExpertisePackagesByGroup',
  GetCustomerVehicle = 'GetCustomerVehicle',
  GetCustomerVehiclePhotos = 'GetCustomerVehiclePhotos',
  GetCustomerAbilities = 'GetCustomerAbilities',
  GetCustomerFileFaultDetection = 'GetCustomerFileFaultDetection',
  GetCustomerFile = 'GetCustomerFile',
  HasExpertiseResultFromMachine = 'HasExpertiseResultFromMachine',
  GetGasketLeakTestResult = 'GetGasketLeakTestResult',
  CheckAbilityCompleted = 'CheckAbilityCompleted',
  CheckCustomerEditable = 'CheckCustomerEditable',
}

export enum CustomerExpertiseFileType {
  FaultDetectionResult = 10,
  KMDetectionResult = 20,
  GasketLeakResult = 30,
  KMPhoto = 40,
  ChassisPhoto = 50,
}

export enum ExpertisePackageAbilityDefinitionType {
  BoyaDegisenFormu = 1,
  MikronKaydiFormu = 2,
  KaportaKontrolNotlari = 3,
  MotorMekanikKontrolNotlari = 4,
  EkspertizCihaziTestSonuclari = 5,
  AracFotografiTekli = 6,
  AracFotografiCoklu = 7,
  HasarKaydiSorgulama = 8,
  KMKaydiSorgulama = 9,
  ArizaTespitSonuclari = 10,
  KMTespitSonuclari = 11,
  ContaKacakTespitSonuclari = 12,
  SasiKontrol = 13,
  MotorKontrolFormu = 14,
  IcKontrolFormu = 15,
  YolYardimSertifikasi = 16,
  AracKorumaSertifikasi = 17,
  AltTakimKontrolFormu = 18,
  DisKontrolFormu = 19,
}

export const enum CustomerStatus {
  Started = 10,
  Completed = 20,
  Cancelled = 30,
}

export const enum CustomerGasketLeakResultType {
  VeryHigh = 10,
  High = 20,
  Medium = 30,
  Slight = 40,
  NoLeak = 50,
  CouldnotControlled = 60,
}

// #endregion

export interface CustomerVehicle {
  customerId: number;
  kilometer: number;
  fuelTypeId: number;
  gearTypeId: number;
  engineHp: number;
  engineVolume: number;
  colorId: number;
  vehicleTypeId: number;
  modelYear: number;
  modelId: number;
  brandId: number;
  chassisNumber: string;
  engineNumber: string;
  id: number;
  preExpertiseNotes?: string;
  customer?: any;
  brand?: any;
  model?: any;
  vehicleType?: {
    id: number;
    name: ExpertiseCarBodyTypes;
  };
}

export interface Customer {
  id: number;

  plate: string;

  displayPlate: string;

  name?: string;

  firmName?: string;

  mersisNo?: string;

  authorizationNo?: string;

  phoneNumber?: string;

  cityId?: number;

  countyId?: number;

  governmentId?: string;

  isIndividual: boolean;

  carLicenceName?: string;

  carLicencePhoneNumber?: string;

  expertisePackageId?: number;

  expertisePackageGroupId?: number;

  userId: number;

  canContinue: boolean;

  customerVehicle?: CustomerVehicle;

  status: CustomerStatus;

  hasKvkkApproval: boolean;

  email?: string;

  address?: string;

  createdAt?: Date;

  expertisePackage?: {
    id: number;
    name: string;
  };
}

export interface CustomersResponse {
  customers: Customer[];
}

export interface CustomerHistories {
  customerId: number;
  expertiseDate: Date;
  expertisePointId: number;
  expertisePointName: string;
  expertiseReportUrl?: string;
}

export interface CustomerInitiateResponse {
  hasHistoryByPlate: boolean;
  savedCustomerId: number;
  histories: CustomerHistories[];
}
export interface CustomerInitiateRequest {
  license: string;
  expertisePackage: string;
  expertisePackageGroup: string;
  packageCode?: string;
  discountApplied?: boolean;
  discountedPrice?: string;
}
export interface CustomerResponse {
  customer: Customer;
  canContinue: boolean;
  displayPlate: string;
  hasKvkkApproval: boolean;
}

export interface SaveCustomerRequest {
  id: number;

  name: string;

  firmName?: string;

  mersisNo: string;

  authorizationNo: string;

  phoneNumber: string;

  cityId: number;

  countyId: number;

  governmentId: string;

  isIndividual: boolean;

  carLicenceName?: string;

  carLicencePhoneNumber?: string;

  // expertisePackageId: number;

  // expertisePackageGroupId: number;

  email: string;

  address?: string;
}

export interface SaveCustomerVehicleRequest {
  customerId: number;
  engineNumber: string;
  chassisNumber: string;
  brandId: number;
  modelId: number;
  modelYear: number;
  vehicleTypeId: number;
  colorId: number;
  fuelTypeId: number;
  gearTypeId: number;
  engineHp: number;
  engineVolume: number;
  kilometer: number;
  preExpertiseNotes?: string;
}

export interface GetCustomerVehicleResponse {
  customerVehicle: CustomerVehicle;
}

export interface SaveCustomerVehiclePhotoRequest {
  customerId: number;
  file: File;
  id?: number;
}

export interface OcrLicencePhotoRequest {
  files: File;
}

export interface CustomerVehiclePhoto {
  id: number;
  photoUrl: string;
}

export interface CustomerVehiclePhotosResponse {
  customerVehiclePhotos: CustomerVehiclePhoto[];
}

export interface CustomerAbilityDefinition {
  abilityDefinitionType: ExpertisePackageAbilityDefinitionType;
  abilityDefinitionTypeValue: any;
  hasAbility: boolean;
}

export interface CustomerAbilityDefinitionAllResponse {
  customerId: number;
  abilityDefinitions: CustomerAbilityDefinition[];
}

export type CustomerAbilityDefinitionTable = {
  [key in ExpertisePackageAbilityDefinitionType]: boolean;
};

export interface CustomerTestResultFile {
  id: number;
  url: string;
  customerId: number;
}
export interface CustomerTestResultFileUploadResponse {
  id: number;
  url: string;
  customerId: number;
}

export interface SendKvKKRequestResponse {
  alreadyApproved: boolean;
  smsSent: boolean;
  customerData: Customer;
}

export interface SearchCustomerParams {
  plate?: string;
  chassisNumber?: string;
  startDate?: string;
  endDate?: string;
  brandId?: any;
  modelId?: any;
  vehicleTypeId?: any;
  customerName?: string;
}

export interface GasLeakInfo {
  customerId: number;
  resultType: CustomerGasketLeakResultType;
}
