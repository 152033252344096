import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  StackItem,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { DataTable, DateRangePreSelector } from 'components';
import { useMutation, useQuery } from 'react-query';
import {
  ExpertisePoint,
  ExpertisePointQueryCacheKey,
  ExpertisePointSpendingTransaction,
  GetExpertisePointSpendingsData,
} from 'types';
import { DownloadIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'features';
import { downloadBlobAsFile } from 'core';

export interface AdminExpertisePointManagementSpendingHistoryProps {
  expertisePoint: ExpertisePoint;
}
export const AdminExpertisePointManagementSpendingHistory: React.FC<
  AdminExpertisePointManagementSpendingHistoryProps
> = ({ expertisePoint }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);

  const query = useQuery(
    [
      ExpertisePointQueryCacheKey.GetExpertisePointSpendingHistory,
      expertisePoint.id,
      selectedDates[0],
      selectedDates[1],
    ],
    async () => {
      if (selectedDates.length === 2) {
        const response = await Api.ExpertisePoint.getSpendingTransactions(
          expertisePoint.id,
          selectedDates[0].toISOString(),
          selectedDates[1].toISOString(),
        );
        const responseData = response.data;
        const convertedData: GetExpertisePointSpendingsData = {
          totalTransactionAmount: responseData.totalTransactionAmount,
          totalBalance: responseData.totalBalance,
          transactions: responseData.transactions.map((transaction) => {
            return {
              id: transaction.id,
              amount: transaction.amount,
              isCompleted: transaction.isCompleted,
              transactionDate: new Date(transaction.transactionDate),
              createdAt: new Date(transaction.createdAt),
              transactionTypeDesc: transaction.transactionTypeDesc,
              expertisePointCityName: transaction.expertisePointCityName,
              expertisePointCountyName: transaction.expertisePointCountyName,
              expertisePointName: transaction.expertisePointName,
              transactionSource: transaction.transactionSource,
              balanceAfterTransaction: transaction.balanceAfterTransaction,
            };
          }),
        };

        return convertedData;
      }
      return null;
    },
    {
      cacheTime: 0,
    },
  );

  const getExportSpendingTransaction = useMutation<Blob, Error>(
    async () => {
      dispatch(showLoading());
      const response = await Api.ExpertisePoint.exportSpendingTransactions(
        expertisePoint.id,
        selectedDates[0].toISOString(),
        selectedDates[1].toISOString(),
      );
      return response;
    },
    {
      onSuccess(data) {
        if (data) {
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const fileName = `HarcamaGecmisi_${formattedDate}.xlsx`;
          downloadBlobAsFile(data, fileName);
        } else {
          toast({
            status: 'info',
            title: 'Rapor Bulunamadı',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Raport İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  const columnHelper = createColumnHelper<ExpertisePointSpendingTransaction>();
  const columns = [
    columnHelper.accessor('id', {
      header: 'Id',
    }),
    columnHelper.accessor('transactionTypeDesc', {
      header: 'Tür',
    }),
    columnHelper.accessor('amount', {
      header: 'Fiyat',
      cell: (info) => `₺${info.getValue()}`,
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor('transactionDate', {
      cell: (info) => info.getValue()?.toLocaleDateString() || '-',
      header: 'Tarih',
    }),
  ];
  return (
    <Box p={2}>
      <Button
        variant="solid"
        leftIcon={<Icon as={DownloadIcon} />}
        onClick={() => getExportSpendingTransaction.mutate()}
      >
        Excel İndir
      </Button>
      <HStack align="center" gap={10}>
        <StackItem>
          <DateRangePreSelector
            onDateRangeSelected={(startDate: Date, endDate: Date) => {
              setSelectedDates([startDate, endDate]);
            }}
          />
        </StackItem>
      </HStack>
      <Flex gap={4} flexDirection="column">
        <FormControl>
          <FormLabel>Tarih Aralığı</FormLabel>

          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            configs={{
              dateFormat: 'dd MMMM yyyy',
            }}
          />
        </FormControl>

        {query.data && selectedDates.length === 2 && (
          <Stat>
            <StatLabel>Harcanan Toplam</StatLabel>
            <StatNumber>{query.data.totalTransactionAmount} ₺</StatNumber>
            <StatHelpText>
              {selectedDates[0].toLocaleDateString()} - {selectedDates[1].toLocaleDateString()}
            </StatHelpText>
          </Stat>
        )}

        <Box my={4} width="100%">
          <DataTable usePagination title="Harcamalar" columns={columns} data={query.data?.transactions || []} />
        </Box>
      </Flex>
    </Box>
  );
};
