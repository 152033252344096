import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { VehicleQueryCacheKeys } from 'types';

export type VehicleTypeSelectProps = FormControlFormSelectProps;
export const VehicleTypeSelect: React.FC<VehicleTypeSelectProps> = ({ ...formSelectProps }) => {
  const query = useQuery(
    VehicleQueryCacheKeys.GetVehicleTypes,
    async () => {
      const response = await Api.Vehicle.getVehicleTypes();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <FormControlFormSelect label="Arac Türü" placeholder="Arac Türü" {...formSelectProps}>
      {query.data?.map((type) => (
        <option value={type.key} key={`vts${type.value}`}>
          {type.value}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
