import { ApiBase, ApiResponse, Brand, VehicleFuelType, Model, VehicleColor, VehicleType } from 'types';

const BASE_URL = 'vehicle';

export class VehicleApi extends ApiBase {
  public async getBrands(): Promise<ApiResponse<Brand[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-brands`);

    return response.data;
  }

  public async getModels(brandId: number | string): Promise<ApiResponse<Model[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-models/${brandId}`);

    return response.data;
  }

  public async getColors(): Promise<ApiResponse<VehicleColor[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-colors`);

    return response.data;
  }

  public async getVehicleTypes(): Promise<ApiResponse<VehicleType[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-vehicle-types`);

    return response.data;
  }

  public async getVehicleFuelTypes(): Promise<ApiResponse<VehicleFuelType[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-fuel-types`);

    return response.data;
  }
}
