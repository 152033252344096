/* eslint-disable @typescript-eslint/no-unused-vars */
import { AddIcon, ArrowDownIcon, ArrowUpIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper, CellContext, ColumnDef } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable, ExpertisePointSelect } from 'components';
import { FormControlFormInput, FormControlFormSelect, GenericMessages, useFetchExpertisePackages } from 'core';
import { FormikErrors, useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  ExpertisePackage,
  ExpertisePackageGroup,
  ExpertisePackagePriceListItem,
  ExpertisePoint,
  ExpertisePointQueryCacheKey,
  ExternalPackageGroupSourceType,
  SaveExpertisePackageRequest,
} from 'types';
import * as yup from 'yup';

const validationSchema = yup.object({
  priceListName: yup.string().required(GenericMessages.CannotBeEmpty),
  priceCampaingOverrides: yup
    .array(
      yup.object({
        price: yup
          .number()
          .required(GenericMessages.CannotBeEmpty)
          .min(1, () => GenericMessages.CantBeLessThanNumber(1)),
        quantity: yup.number().min(1, () => GenericMessages.CantBeLessThanNumber(1)),
      }),
    )
    .min(1, 'En az 1 tane paket eklemeniz gerekli'),
  selectedExpertisePoints: yup
    .array()
    .min(1, 'En az bir bayi eklemek zorundasınız')
    .required('En az bir bayi eklemek zorundasınız'),
  discountAvailable: yup.boolean(),
  maxDiscountRate: yup.string().when('discountAvailable', {
    is: true,
    then: yup.string().required(GenericMessages.CannotBeEmpty),
  }),
});

export interface AdminPriceListFormProps {
  formId: string;
  onSubmitStart: () => void;
  onSubmitComplete: () => void;
  group?: ExpertisePackageGroup;
}

export const AdminPriceListForm: React.FC<AdminPriceListFormProps> = ({
  formId,
  onSubmitComplete,
  onSubmitStart,
  group,
}) => {
  const toast = useToast();
  const [isChecked, setIsChecked] = useState(false);

  const { data: allExpertisePoints } = useQuery(
    ExpertisePointQueryCacheKey.GetAllExpertisePoints,
    async () => {
      const response = await Api.ExpertisePoint.getAllExpertisePoints();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const deleteMutate = useMutation<any, any, number>(
    (id: number) => {
      return Api.Expertise.deletePackageGroupItem(id);
    },
    {
      onSuccess: () => {
        toast({
          title: 'Kampanya paketi başarı ile silindi',
          isClosable: false,
          status: 'success',
        });
      },
      onError: () => {
        toast({
          title: 'Kampanya paketi silinirken hata oluştu',
          isClosable: false,
          status: 'error',
        });
      },
    },
  );
  const mutate = useMutation<any, any, SaveExpertisePackageRequest>(
    (request: SaveExpertisePackageRequest) => {
      return Api.Expertise.savePackageGroup(request);
    },
    {
      onSuccess: () => {
        toast({
          title: 'Kampanya başarı ile eklendi',
          isClosable: false,
          status: 'success',
        });
      },
      onSettled: () => {
        onSubmitComplete();
      },
      onError: () => {
        toast({
          title: 'Kampanya eklenirken hata oluştu',
          isClosable: false,
          status: 'error',
        });
      },
    },
  );

  const { values, errors, handleChange, setFieldError, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      selectedCampaing: 0,
      selectedExpertisePoint: null,
      selectedExpertisePoints: (group?.expertisePoints || []) as ExpertisePoint[],
      priceListName: group?.name || '',
      isOccoCar: group?.externalPackageGroupSource === ExternalPackageGroupSourceType.OccoCar,
      isOccoCoupon: group?.externalPackageGroupSource === ExternalPackageGroupSourceType.OccoCoupon,
      discountAvailable: group?.discountAvailable || false,
      maxDiscountRate: group?.maxDiscountRate || '',
      priceCampaingOverrides: (group?.items || []).map((item) => ({
        active: item.active,
        price: item.price,
        displayOrder: 0,
        durationInMinute: 0,
        name: item.expertisePackageName,
        id: item.expertisePackageId,
        itemId: item.id,
        quantity: item.quantity || 1,
      })) as ExpertisePackagePriceListItem[],
    },
    onSubmit: (formData) => {
      onSubmitStart();

      let externalPackageGroupSourceResult: ExternalPackageGroupSourceType | undefined;
      if (formData.isOccoCar) {
        externalPackageGroupSourceResult = ExternalPackageGroupSourceType.OccoCar;
      } else if (formData.isOccoCoupon) {
        externalPackageGroupSourceResult = ExternalPackageGroupSourceType.OccoCoupon;
      } else {
        externalPackageGroupSourceResult = undefined;
      }

      mutate.mutate({
        name: formData.priceListName,
        id: group?.id,
        packages: formData.priceCampaingOverrides.map((priceCampaingOverride) => ({
          expertisePackageId: priceCampaingOverride.id,
          price: parseInt(priceCampaingOverride.price, 10),
          quantity: formData.isOccoCar || formData.isOccoCoupon ? priceCampaingOverride.quantity : 1,
        })),
        expertisePoints: formData.selectedExpertisePoints.map((expertisePoint) => expertisePoint.id),
        externalPackageGroupSource: externalPackageGroupSourceResult,
        discountAvailable: formData.discountAvailable,
        maxDiscountRate: formData.discountAvailable ? parseInt(formData.maxDiscountRate, 10) : undefined,
      });
    },
    validationSchema,
    validateOnChange: false,
  });

  const onExpertisePointAddClicked = () => {
    const expertisePoint = values.selectedExpertisePoint as ExpertisePoint | null;
    if (expertisePoint) {
      if (values.selectedExpertisePoints.findIndex((elem) => elem.id === expertisePoint.id) === -1) {
        setFieldError('selectedExpertisePoint', undefined);
        setFieldValue('selectedExpertisePoints', [...values.selectedExpertisePoints, values.selectedExpertisePoint]);
      } else {
        setFieldError('selectedExpertisePoint', 'Ayni Bayi Eklenemez');
      }
    } else {
      setFieldError('selectedExpertisePoint', 'Bayi Seçmeniz Gerekiyor');
    }
  };

  const onDeleteExpertisePoint = (index: number) => () => {
    const newExpertisePointValues = [...values.selectedExpertisePoints];
    newExpertisePointValues.splice(index, 1);
    setFieldValue('selectedExpertisePoints', newExpertisePointValues);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked && allExpertisePoints) {
      const mergeAllExpertisePointsArray = [
        ...values.selectedExpertisePoints,
        ...allExpertisePoints.filter(
          (expertisePoint) =>
            !values.selectedExpertisePoints.some(
              (selectedExpertisePoint) => selectedExpertisePoint.id === expertisePoint.id,
            ),
        ),
      ];
      setFieldValue('selectedExpertisePoints', mergeAllExpertisePointsArray);
    } else {
      setFieldValue('selectedExpertisePoints', group?.expertisePoints || []);
    }
  };

  const columnHelper = createColumnHelper<ExpertisePackage>();
  const expertisePackagesQuery = useFetchExpertisePackages();

  // Su an icin daah fazla editlenebilir hucereye ihtiyac yok
  // fakat ileride olursa daha generic bir yapi olusturulmali
  // ornegin: https://github.com/TanStack/table/blob/5145a632c944d135863d8a2f14a160a2f9395f61/examples/editable-data/src/App.js
  const priceInputCell = (info: CellContext<ExpertisePackage, string>) => {
    const { id, price } = info.row.original;
    const { index } = info.row;
    const [cellData, setCellData] = useState<string>(price.toString());
    return (
      <FormControlFormInput
        type="number"
        min="1"
        step="1"
        error={
          errors.priceCampaingOverrides != null &&
          (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).price != null
        }
        helperText={
          errors.priceCampaingOverrides != null
            ? (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).price
            : undefined
        }
        key={`aplfpui${id}`}
        value={cellData}
        onChange={(event) => {
          setCellData(event.target.value);
        }}
        onBlur={() => {
          const value = parseInt(cellData, 10);
          if (!Number.isNaN(value)) {
            setFieldValue(`priceCampaingOverrides.${index}.price`, value);
          }
        }}
      />
    );
  };

  type ExpertisePackageQuantityCellProps = CellContext<ExpertisePackage, number>;

  const quantityInputCell: React.FC<ExpertisePackageQuantityCellProps> = (info) => {
    const { id, quantity } = info.row.original;
    const { index } = info.row;
    const [quantityCellData, setQuantityCellData] = useState<number>(quantity || 1);

    const handleBlur = () => {
      const value = quantityCellData;
      if (!Number.isNaN(value)) {
        setFieldValue(`priceCampaingOverrides.${index}.quantity`, value);
      }
    };

    return (
      <FormControlFormInput
        type="number"
        min="1"
        step="1"
        error={
          errors.priceCampaingOverrides != null &&
          (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).quantity != null
        }
        helperText={
          errors.priceCampaingOverrides != null
            ? (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).quantity
            : undefined
        }
        key={`aplfpui${id}`}
        value={quantityCellData}
        onChange={(event) => setQuantityCellData(Number(event.target.value))}
        onBlur={handleBlur}
        isDisabled={!values.isOccoCar && !values.isOccoCoupon}
      />
    );
  };

  const { isOpen: isDeleteDialogOpen, onClose: onDeleteDialogClose, onOpen: onDeleteDialogOpen } = useDisclosure();
  const [focusedDeletePackageGroupItem, setFocusedDeletePackageGroupItem] = useState<ExpertisePackagePriceListItem>();
  const rowActionCell = (info: CellContext<ExpertisePackagePriceListItem, number>) => {
    const moveItem = (from: number, to: number) => {
      const newList = [...values.priceCampaingOverrides];
      // remove `from` item and store it
      const tmp = newList.splice(from, 1)[0];
      // insert stored item into position `to`
      newList.splice(to, 0, tmp);
      setFieldValue('priceCampaingOverrides', newList);
    };
    return (
      <Flex gap={4} justify="center">
        <Tooltip label="Sil">
          <IconButton
            aria-label="Sil"
            icon={<DeleteIcon />}
            onClick={() => {
              const deletedPriceInfo = values.priceCampaingOverrides[info.row.index];
              if (deletedPriceInfo.itemId) {
                setFocusedDeletePackageGroupItem(deletedPriceInfo);
                onDeleteDialogOpen();
              } else {
                const newData = [...values.priceCampaingOverrides];
                newData.splice(info.row.index, 1);
                setFieldValue('priceCampaingOverrides', newData);
              }
            }}
            colorScheme="red"
          />
        </Tooltip>
        <IconButton
          icon={<ArrowDownIcon />}
          aria-label="Assagi"
          onClick={() => {
            moveItem(info.row.index, info.row.index + 1);
          }}
          disabled={info.row.index === values.priceCampaingOverrides.length - 1}
        />
        <IconButton
          icon={<ArrowUpIcon />}
          aria-label="Yukari"
          disabled={info.row.index === 0}
          onClick={() => {
            moveItem(info.row.index, info.row.index - 1);
          }}
        />
      </Flex>
    );
  };

  const columns: ColumnDef<ExpertisePackagePriceListItem, any>[] = [
    columnHelper.accessor('name', {
      header: 'Ad',
    }),
    columnHelper.accessor('price', {
      header: 'Fiyat',
      cell: priceInputCell,
    }),
    columnHelper.accessor('quantity', {
      header: 'Adet',
      cell: quantityInputCell,
    }),
    {
      header: 'Aksiyonlar',
      cell: rowActionCell,
    },
  ];

  const cancelRef = useRef(null);

  return (
    <>
      <AlertDialog isOpen={isDeleteDialogOpen} leastDestructiveRef={cancelRef} onClose={onDeleteDialogClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Sil
            </AlertDialogHeader>

            <AlertDialogBody>Silmek istediğinize emin misiniz?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteDialogClose} disabled={deleteMutate.isLoading}>
                İptal
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (focusedDeletePackageGroupItem && focusedDeletePackageGroupItem.itemId) {
                    try {
                      await deleteMutate.mutateAsync(focusedDeletePackageGroupItem.itemId);
                      const index = values.priceCampaingOverrides.findIndex(
                        (elem) => elem.itemId === focusedDeletePackageGroupItem.itemId,
                      );
                      if (index !== -1) {
                        const newData = [...values.priceCampaingOverrides];
                        newData.splice(index, 1);
                        setFieldValue('priceCampaingOverrides', newData);
                        onDeleteDialogClose();
                      }
                    } catch {
                      // Do nothing
                    }
                  }
                }}
                ml={3}
                disabled={deleteMutate.isLoading}
                isLoading={deleteMutate.isLoading}
              >
                Sil
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <form id={formId} noValidate onSubmit={handleSubmit} />
      <Grid templateRows="auto 1fr auto" templateColumns="repeat(1, 1fr)" gap={4}>
        <GridItem rowSpan={1} colSpan={1}>
          <HStack align="center">
            <FormControlFormInput
              label="Ad"
              name="priceListName"
              value={values.priceListName}
              error={errors.priceListName != null}
              helperText={errors.priceListName}
              onChange={handleChange}
            />
            <HStack align="center">
              <FormControlFormSelect
                name="selectedCampaing"
                label="Paket"
                value={values.selectedCampaing}
                error={errors.selectedCampaing != null}
                helperText={errors.selectedCampaing}
                onChange={handleChange}
                placeholder="Paket Seç"
              >
                {expertisePackagesQuery &&
                  expertisePackagesQuery.data &&
                  expertisePackagesQuery.data.map((campaing) => (
                    <option value={campaing.id} key={`aplcs${campaing.id}`}>
                      {campaing.name}
                    </option>
                  ))}
              </FormControlFormSelect>

              <IconButton
                icon={<AddIcon />}
                colorScheme="green"
                aria-label="ekle"
                alignSelf="flex-end"
                onClick={() => {
                  if (expertisePackagesQuery.data && values.selectedCampaing) {
                    // eslint-disable-next-line
                    const campaing = expertisePackagesQuery.data.find((elem) => elem.id == values.selectedCampaing);

                    const existingCampaing = values.priceCampaingOverrides.find(
                      // eslint-disable-next-line
                      (elem) => elem.id == values.selectedCampaing,
                    );

                    if (existingCampaing) {
                      setFieldError('selectedCampaing', 'Aynı paket bir daha eklenemez');
                      return;
                    }
                    if (campaing) {
                      const overrides = [...values.priceCampaingOverrides];
                      overrides.push(campaing);
                      setFieldValue('priceCampaingOverrides', overrides);
                    }
                  } else {
                    setFieldError('selectedCampaing', 'Paket Seçmeniz Gerekli');
                  }
                }}
              />
            </HStack>
          </HStack>
          <Flex justify="space-between" alignItems="start" mt="2">
            <Flex direction="column">
              <Checkbox
                size="sm"
                colorScheme="green"
                name="discountAvailable"
                onChange={(event) => {
                  setFieldValue(event.target.name, event.target.checked);
                }}
                isChecked={values.discountAvailable}
                isDisabled={values.isOccoCar || values.isOccoCoupon}
              >
                İndirim uygulanabilsin mi?
              </Checkbox>

              {values.discountAvailable && (!values.isOccoCar || !values.isOccoCoupon) && (
                <FormControlFormInput
                  name="maxDiscountRate"
                  value={values.maxDiscountRate}
                  error={errors.maxDiscountRate != null}
                  helperText={errors.maxDiscountRate}
                  onChange={handleChange}
                  type="number"
                  size="xs"
                  placeholder="Maksimum İndirim Oranı(%)"
                />
              )}
            </Flex>

            <Checkbox
              size="sm"
              colorScheme="green"
              name="isOccoCoupon"
              onChange={(event) => {
                setFieldValue(event.target.name, event.target.checked);
                if (!event.target.checked) {
                  setFieldValue('maxDiscountRate', '');
                  setFieldValue('discountAvailable', false);
                  setFieldValue('isOccoCar', false);
                }
              }}
              isChecked={values.isOccoCoupon}
              isDisabled={values.isOccoCar}
            >
              Koçan
            </Checkbox>

            <Checkbox
              size="sm"
              colorScheme="green"
              name="isOccoCar"
              onChange={(event) => {
                setFieldValue(event.target.name, event.target.checked);
                if (!event.target.checked) {
                  setFieldValue('maxDiscountRate', '');
                  setFieldValue('discountAvailable', false);
                  setFieldValue('isOccoCoupon', false);
                }
              }}
              isChecked={values.isOccoCar}
              isDisabled={values.isOccoCoupon}
            >
              Occo Car
            </Checkbox>
          </Flex>
          <HStack align="center" mt="2">
            <HStack>
              <ExpertisePointSelect
                onExpertisePointSelected={(expertisePoint?: ExpertisePoint) => {
                  setFieldValue('selectedExpertisePoint', expertisePoint);
                }}
                error={errors.selectedExpertisePoint != null}
                helperText={errors.selectedExpertisePoint != null ? (errors.selectedExpertisePoint as string) : ''}
                filterActive
              />
              <IconButton
                icon={<AddIcon />}
                colorScheme="green"
                aria-label="ekle"
                alignSelf="flex-end"
                onClick={onExpertisePointAddClicked}
              />
              <Checkbox
                colorScheme="green"
                alignSelf="flex-end"
                name="selectAllPoints"
                isChecked={isChecked}
                onChange={handleCheckboxChange}
              >
                <Text>Hepsi</Text>
              </Checkbox>
            </HStack>
          </HStack>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <FormLabel>Erişebilen Bayiler</FormLabel>
          {values.selectedExpertisePoints.length ? (
            <Box maxW="full" borderWidth="1px" borderRadius="lg" overflow="hidden" p="2">
              <Flex flexWrap="wrap">
                {values.selectedExpertisePoints.map((selectedExpertisePoint, index) => (
                  <ButtonGroup size="sm" isAttached variant="solid" m="2" key={`ausdt${selectedExpertisePoint.id}`}>
                    <Button>{selectedExpertisePoint.name}</Button>
                    <IconButton onClick={onDeleteExpertisePoint(index)} aria-label="Bayi Sil" icon={<CloseIcon />} />
                  </ButtonGroup>
                ))}
              </Flex>
            </Box>
          ) : (
            <Text fontSize="xs">Bayi seçiniz.</Text>
          )}
        </GridItem>
        <GridItem colSpan={1} rowSpan={1}>
          <DataTable data={values.priceCampaingOverrides} columns={columns} usePagination={false} />
          {errors.priceCampaingOverrides && typeof errors.priceCampaingOverrides === 'string' && (
            <Alert status="error" mt="2">
              <AlertIcon />
              <AlertTitle>{errors.priceCampaingOverrides}</AlertTitle>
            </Alert>
          )}
          {errors.selectedExpertisePoints && typeof errors.selectedExpertisePoints === 'string' && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>{errors.selectedExpertisePoints}</AlertTitle>
            </Alert>
          )}
        </GridItem>
      </Grid>
    </>
  );
};
