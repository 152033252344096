import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Button,
  ModalFooter,
  VStack,
  Radio,
  useToast,
} from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormRadioGroup, useGetUploadedClientFile, useUploadClientFile } from 'core';
import { hideLoading, showLoading } from 'features';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { CustomerExpertiseFileType, CustomerGasketLeakResultType, CustomerQueryCacheKey, GasLeakInfo } from 'types';

export interface GasketLeakResultsProps extends Omit<ModalProps, 'children'> {
  customerId: number;
}

export const GasketLeakResults: React.FC<GasketLeakResultsProps> = ({ customerId, ...modalProps }) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const gasketLeakTestResultFileInputRef = useRef<HTMLInputElement>(null);
  const [isValid, setIsValid] = useState(false);

  const uploadTestResultFileMutation = useUploadClientFile(customerId, CustomerExpertiseFileType.GasketLeakResult, {
    onMutate: () => {
      dispatch(showLoading());
    },
    onSettled: () => {
      dispatch(hideLoading());
      if (gasketLeakTestResultFileInputRef.current) {
        gasketLeakTestResultFileInputRef.current.value = '';
      }
      // eslint-disable-next-line no-use-before-define
      refetchGasketLeakTestDetectionResultFileResponse();
    },
    onError: (error: any) => {
      console.error(error);
      toast({
        status: 'error',
        title: 'Dosya yüklenirken hata oluştu',
        isClosable: true,
      });
    },
  });

  const { data: gasketLeakTestResult } = useQuery(
    CustomerQueryCacheKey.GetGasketLeakTestResult,
    async () => {
      const response = await Api.Customer.getGasketLeakTestResult(customerId);
      return response.data;
    },
    {
      onError: () => {
        toast({
          title: 'Conta kaçak test sonuçları kontrolü sırasında hata oluştu',
          status: 'error',
        });
      },
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  const { data: gasketLeakTestResultFileResponse, refetch: refetchGasketLeakTestDetectionResultFileResponse } =
    useGetUploadedClientFile(customerId, CustomerExpertiseFileType.GasketLeakResult, {
      onError: (err: any) => {
        console.error(err);
        toast({
          title: 'Conta Kaçak Test sonuçları çekilirken hata oluştu',
          status: 'error',
        });
      },
    });

  const mutation = useMutation<GasLeakInfo, any, { resultType: CustomerGasketLeakResultType; customerId: number }>(
    async ({ resultType }) => {
      const response = await Api.Customer.sendGasketLeakTestResult(resultType, customerId);
      return response.data;
    },
    {
      onSuccess: () => {
        modalProps.onClose();
        toast({
          title: 'Conta Kaçak Test Sonucu Güncellendi',
          status: 'success',
        });
      },
    },
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      resultType: gasketLeakTestResult ? gasketLeakTestResult.resultType.toString() : '',
      customerId,
    },
    onSubmit: (formValues) => {
      mutation.mutateAsync({
        resultType: +formValues.resultType,
        customerId: formValues.customerId,
      });
    },
  });

  const validate = () => {
    if (values.resultType === '60') {
      return true;
    }
    if (gasketLeakTestResultFileResponse) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const checkValid = validate();
    setIsValid(checkValid);
  }, [values.resultType, gasketLeakTestResultFileResponse]);
  return (
    <>
      <input
        type="file"
        accept="image/*"
        hidden
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files && e.target.files.length === 1) {
            uploadTestResultFileMutation.mutate(e.target.files[0]);
          }
        }}
        ref={gasketLeakTestResultFileInputRef}
      />
      <Modal {...modalProps} isCentered size="lg">
        <ModalOverlay />
        <form noValidate onSubmit={handleSubmit}>
          <ModalContent>
            <ModalHeader>Conta Kaçak Tespiti</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControlFormRadioGroup
                name="resultType"
                label="Kaçak Derecesi"
                onChange={(value) => {
                  setFieldValue('resultType', value);
                }}
                value={values.resultType}
              >
                <VStack spacing="24px" align="start" mt="4">
                  <Radio value="10">Çok Yüksek Kaçak</Radio>
                  <Radio value="20">Yüksek Kaçak</Radio>
                  <Radio value="30">Orta Kaçak</Radio>
                  <Radio value="40">Hafif Kaçak</Radio>
                  <Radio value="50">Kaçak Yok</Radio>
                  <Radio value="60">Kontrol Sağlanamadı</Radio>
                </VStack>
              </FormControlFormRadioGroup>
            </ModalBody>
            <ModalFooter justifyContent="space-between">
              <Button onClick={() => gasketLeakTestResultFileInputRef.current?.click()} colorScheme="blue">
                Fotoğraf Çek
              </Button>
              <Button isDisabled={!isValid} type="submit" colorScheme="teal" onClick={modalProps.onClose}>
                Onayla
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};
