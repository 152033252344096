import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Box,
  DrawerFooter,
  Button,
  VStack,
  Image,
  HStack,
  Radio,
  Stack,
  Text,
  IconButton,
  Portal,
  useToast,
  Center,
} from '@chakra-ui/react';
import { Api } from 'api';
import { LoadingText } from 'components';
import {
  createCustomerSocket,
  FormControlFormInput,
  FormControlFormRadioGroup,
  getExpertiseBackroundImageUrl,
} from 'core';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  BodyPartAnswerInfo,
  BodyPartExpertiseInfo,
  ExpertiseBodyDamagePartType,
  ExpertiseCarBodyTypes,
  ExpertiseTemplate,
  VehicleQueryCacheKeys,
} from 'types';
import { Socket } from 'socket.io-client';
import { ExpertiseBodyworkExpertiseMicronSelector } from './ExpertiseBodyworkExpertiseMicronSelector';

export interface ExpertiseBodyworkMicronRecorderProps {
  isOpen: boolean;
  onClose: () => void;
  carBodyType: ExpertiseCarBodyTypes;
  customerId: number;
  vehicleTypeId: number;
  isDisabled?: boolean;
}

type MicronQuestionData = {
  [key: number]: string;
};

type MicronPartData = {
  [key: number]: number;
};

type ExpertiseSaveData = { microns: MicronPartData; answers: MicronQuestionData };
type PositionLookupTableRow = {
  left?: string;
  bottom?: string;
  top?: string;
  right?: string;
};

type PositionLookupTable = {
  [key: number]: PositionLookupTableRow;
};

const mikronPositions: PositionLookupTable = {
  // Sol
  3: {
    left: '10%',
    bottom: '15%',
  },
  4: {
    left: '22%',
    bottom: '17%',
  },
  6: {
    left: '35%',
    bottom: '27%',
  },
  5: {
    left: '30%',
    bottom: '8%',
  },
  8: {
    left: '43%',
    bottom: '15%',
  },
  9: {
    left: '53%',
    bottom: '8%',
  },
  10: {
    left: '65%',
    bottom: '6%',
  },
  11: {
    left: '64%',
    bottom: '17%',
  },
  14: {
    left: '83%',
    bottom: '18%',
  },
  13: {
    left: '75%',
    bottom: '27%',
  },
  // Orta
  15: {
    right: '6%',
    bottom: '38%',
  },
  18: {
    right: '6%',
    top: '38%',
  },
  // sag
  16: {
    right: '-3%',
    bottom: '35%',
  },
  17: {
    right: '-3%',
    top: '35%',
  },
  20: {
    left: '83%',
    top: '18%',
  },
  19: {
    left: '75%',
    top: '27%',
  },
  22: {
    left: '65%',
    top: '16%',
  },
  21: {
    left: '65%',
    top: '6%',
  },
  26: {
    left: '53%',
    top: '8%',
  },
  27: {
    left: '43%',
    top: '15%',
  },
  28: {
    left: '30%',
    top: '8%',
  },
  25: {
    left: '35%',
    top: '27%',
  },
  29: {
    left: '22%',
    top: '17%',
  },
  30: {
    left: '10%',
    top: '15%',
  },
  // orta
  31: {
    left: '15%',
    top: '30%',
  },
  32: {
    left: '2%',
    top: '38%',
  },
  1: {
    left: '2%',
    bottom: '38%',
  },
  2: {
    left: '15%',
    bottom: '30%',
  },
  7: {
    left: '42%',
    bottom: '37%',
  },
  12: {
    right: '26%',
    bottom: '37%',
  },
  23: {
    right: '26%',
    top: '37%',
  },
  24: {
    left: '42%',
    top: '37%',
  },
};

const MicronInputPositionTables: {
  [key in ExpertiseCarBodyTypes]: PositionLookupTable;
} = {
  [ExpertiseCarBodyTypes.Sedan]: {
    ...mikronPositions,
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
  [ExpertiseCarBodyTypes.Hatchback5]: {
    ...mikronPositions,
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
  [ExpertiseCarBodyTypes.Hatchback2]: {
    ...mikronPositions,
    8: {
      left: '36%',
      bottom: '27%',
    },
    9: {
      left: '42%',
      bottom: '37%',
    },
    10: {
      left: '57%',
      bottom: '6%',
    },
    15: {
      right: '-3%',
      bottom: '35%',
    },
    18: {
      right: '6%',
      bottom: '38%',
    },
    19: {
      right: '6%',
      top: '38%',
    },
    20: {
      right: '-3%',
      top: '35%',
    },
    21: {
      left: '83%',
      top: '18%',
    },
    22: {
      left: '75%',
      top: '27%',
    },
    24: {
      left: '65%',
      top: '16%',
    },
    25: {
      left: '54%',
      top: '7%',
    },
    26: {
      left: '42%',
      top: '37%',
    },
    27: {
      left: '35%',
      top: '27%',
    },
  },
  [ExpertiseCarBodyTypes.Pickup]: {
    ...mikronPositions,
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
  [ExpertiseCarBodyTypes.Pickup2]: {
    ...mikronPositions,
    8: {
      left: '36%',
      bottom: '27%',
    },
    9: {
      left: '42%',
      bottom: '37%',
    },
    10: {
      left: '57%',
      bottom: '37%',
    },
    11: {
      left: '50%',
      bottom: '17%',
    },
    12: {
      left: '42%',
      bottom: '12%',
    },
    13: {
      left: '50%',
      bottom: '7%',
    },
    14: {
      left: '60%',
      bottom: '7%',
    },
    15: {
      right: '22%',
      bottom: '18%',
    },
    18: {
      right: '5%',
      bottom: '18%',
    },
    19: {
      bottom: '34%',
      right: '-2%',
    },
    20: {
      top: '34%',
      right: '-2%',
    },
    21: {
      top: '20%',
      right: '5%',
    },
    22: {
      top: '19%',
      left: '72%',
    },
    23: {
      top: '9%',
      left: '62%',
    },
    24: {
      top: '8%',
      left: '49%',
    },
    25: {
      top: '16%',
      left: '43%',
    },
    26: {
      top: '24%',
      left: '49%',
    },
    27: {
      top: '35%',
      left: '57%',
    },
    28: {
      top: '35%',
      left: '42%',
    },
    29: {
      top: '7%',
      left: '29%',
    },
    30: {
      top: '14%',
      left: '20%',
    },
    31: {
      left: '23%',
      top: '18%',
    },
    32: {
      top: '15%',
      left: '10%',
    },
    33: {
      left: '14%',
      top: '31%',
    },
    34: {
      left: '2%',
      top: '37%',
    },
  },
  [ExpertiseCarBodyTypes.Minibus]: {
    ...mikronPositions,
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
  [ExpertiseCarBodyTypes.Panelvan]: {
    ...mikronPositions,
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
  [ExpertiseCarBodyTypes.CanliPanelvan]: {
    ...mikronPositions,
    7: {
      left: '42%',
      bottom: '37%',
    },
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
  [ExpertiseCarBodyTypes.TekSurguPanelvan]: {
    ...mikronPositions,
    10: {
      left: '81%',
      bottom: '8%',
    },
    11: {
      left: '67%',
      bottom: '20%',
    },
    12: {
      right: '29%',
      bottom: '31%',
    },
    13: {
      left: '68%',
      bottom: '38%',
    },
    14: {
      left: '80%',
      bottom: '30%',
    },
    19: {
      right: '26%',
      top: '37%',
    },
    20: {
      left: '75%',
      top: '27%',
    },
    21: {
      left: '78%',
      top: '9%',
    },
    23: {
      left: '53%',
      top: '8%',
    },
    24: {
      left: '43%',
      top: '15%',
    },
    25: {
      left: '43%',
      top: '37%',
    },
    26: {
      left: '35%',
      top: '27%',
    },
    27: {
      left: '30%',
      top: '8%',
    },
    28: {
      left: '22%',
      top: '17%',
    },
    29: {
      left: '10%',
      top: '15%',
    },
    30: {
      left: '15%',
      top: '30%',
    },
    31: {
      left: '2%',
      top: '36%',
    },
  },
  [ExpertiseCarBodyTypes.SUV]: {
    ...mikronPositions,
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
  [ExpertiseCarBodyTypes.Station]: {
    ...mikronPositions,
    26: {
      left: '43%',
      top: '15%',
    },
    27: {
      left: '53%',
      top: '8%',
    },
  },
};

const canGoToNextMicronPosition = (expertiseTemplates: ExpertiseTemplate[], micronPartNo: number): boolean => {
  return micronPartNo < expertiseTemplates.length;
};

const canGoToPrevMicronPosition = (micronPartNo: number): boolean => {
  return micronPartNo > 1;
};

export const ExpertiseBodyworkMicronRecorder: React.FC<ExpertiseBodyworkMicronRecorderProps> = ({
  isOpen,
  onClose,
  carBodyType,
  customerId,
  vehicleTypeId,
  isDisabled,
}) => {
  const toast = useToast();
  const micronValueInputRef = useRef<HTMLInputElement>(null);
  const inputPositionTable = useMemo(() => MicronInputPositionTables[carBodyType], [carBodyType]);
  const [focusedMikronPart, setFocusedMikronPart] = useState<number | null>(null);
  const expertiseImageUrl = getExpertiseBackroundImageUrl(carBodyType);
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);

  const {
    data: expertises,
    isLoading: isExpertisesLoading,
    refetch,
  } = useQuery(
    VehicleQueryCacheKeys.GetBodyWorkMicrons,
    async () => {
      const { data: expertiseResponse } = await Api.Expertise.getCustomerBodyPartsExpertise(
        customerId,
        ExpertiseBodyDamagePartType.Micron,
      );
      const { expertiseBodyDamages, expertiseBodyAnswers } = expertiseResponse;
      return {
        microns: expertiseBodyDamages.reduce((accum: any, nextValue: BodyPartExpertiseInfo) => {
          // eslint-disable-next-line no-param-reassign
          accum[nextValue.expertiseBodyDamagePartId] = nextValue.expertiseBodyDamageValue;
          return accum;
        }, {} as MicronPartData),
        answers: expertiseBodyAnswers.reduce((accum: any, nextValue: BodyPartAnswerInfo) => {
          // eslint-disable-next-line no-param-reassign
          accum[nextValue.expertiseBodyQuestionId] = nextValue.answer;
          return accum;
        }, {} as MicronPartData),
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: isOpen,
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Boyalı Değişen verileri çekilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const { data: expertiseTemplates, isLoading: isExpertisesTemplateLoading } = useQuery(
    VehicleQueryCacheKeys.GetBodyWorkMicronTemplates,
    async () => {
      const response = await Api.Expertise.getExpertiseTemplate(vehicleTypeId, ExpertiseBodyDamagePartType.Micron);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: isOpen,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Boyalı değişen işlemleri için gereken verileri çekerken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      answers: (expertises?.answers || {}) as MicronQuestionData,
      microns: (expertises?.microns || {}) as MicronPartData,
    },
    onSubmit: (data) => {
      if (expertiseTemplates) {
        const filledAnswers = expertiseTemplates.expertiseBodyQuestions.reduce((accum, next) => {
          // eslint-disable-next-line no-param-reassign
          accum[next.id] = data.answers[next.id] || '0';
          return accum;
        }, {} as any);
        // eslint-disable-next-line no-use-before-define
        saveExpertiseMutate.mutate({ answers: filledAnswers, microns: data.microns });
      }
    },
  });

  const saveExpertiseMutate = useMutation<void, any, ExpertiseSaveData>(
    async (data: ExpertiseSaveData) => {
      await Api.Expertise.saveExpertiseBodyParts({
        customerId,
        expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType.Micron,
        bodyDamageAnswers: Object.entries(data.answers).map(([key, value]) => {
          return {
            answer: value,
            expertiseBodyQuestionId: parseInt(key, 10),
          };
        }),
        bodyDamageParts: Object.entries(data.microns).map(([key, value]) => {
          return {
            bodyDamageValue: value.toString(),
            expertiseBodyDamagePartId: parseInt(key, 10),
          };
        }),
      });
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: 'Başarı ile kayıt edildi',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Kayıt sırasında hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const goToNextMicronPosition = () => {
    if (focusedMikronPart) {
      if (canGoToNextMicronPosition(expertiseTemplates?.expertiseBodyDamageParts || [], focusedMikronPart)) {
        if (micronValueInputRef.current) {
          const micronTempalte = expertiseTemplates?.expertiseBodyDamageParts[focusedMikronPart];
          if (micronTempalte) {
            const micron = values.microns[micronTempalte.id];
            micronValueInputRef.current.value = micron ? micron.toString() : '';
          }
        }
        setFocusedMikronPart(focusedMikronPart + 1);
        micronValueInputRef.current?.focus();
      }
    }
  };

  const gotToPrevMicronPosition = () => {
    if (focusedMikronPart && expertiseTemplates?.expertiseBodyDamageParts) {
      if (canGoToPrevMicronPosition(focusedMikronPart)) {
        if (micronValueInputRef.current) {
          const micronTempalte = expertiseTemplates?.expertiseBodyDamageParts[focusedMikronPart - 2];
          if (micronTempalte) {
            const micron = values.microns[micronTempalte.id];
            micronValueInputRef.current.value = micron ? micron.toString() : '';
          }
        }
        setFocusedMikronPart(focusedMikronPart - 1);
        micronValueInputRef.current?.focus();
      }
    }
  };

  useEffect(() => {
    if (customerId && isOpen) {
      const newCustomerSocket = createCustomerSocket(customerId.toString());
      setCustomerSocket(newCustomerSocket);

      const handleExpertiseBodyDamageChanged = (data: any) => {
        if (data) {
          refetch(); // Refetch expertises data when the socket event is triggered
        }
      };

      newCustomerSocket.on('expertiseBodyDamageChanged', handleExpertiseBodyDamageChanged);

      return () => {
        newCustomerSocket.off('expertiseBodyDamageChanged', handleExpertiseBodyDamageChanged);
        newCustomerSocket.disconnect();
      };
    }
    return () => {
      if (customerSocket) {
        customerSocket.disconnect();
      }
    };
  }, [customerId, isOpen, refetch]);

  const showLoading = !expertiseTemplates || isExpertisesTemplateLoading || isExpertisesLoading || !isOpen;

  return (
    <Portal>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent overflow="auto">
          <form noValidate onSubmit={handleSubmit}>
            <DrawerCloseButton />
            <DrawerHeader>Mikron Kaydı Al</DrawerHeader>

            <DrawerBody>
              {showLoading ? (
                <Center>
                  <LoadingText />
                </Center>
              ) : (
                <VStack gap={24} align="stretch">
                  <VStack>
                    <Box width="100%" position="relative" maxWidth="450px">
                      <Image src={expertiseImageUrl} fit="fill" width="100%" />
                      <Box position="absolute" top="0" left="0" right="0" bottom="0">
                        {expertiseTemplates.expertiseBodyDamageParts.map((expertiseTemplate, index) => {
                          const mikronPart = inputPositionTable[expertiseTemplate.displayId];
                          if (!mikronPart) {
                            return null;
                          }
                          return (
                            <ExpertiseBodyworkExpertiseMicronSelector
                              key={`mp${expertiseTemplate.displayId}`}
                              left={mikronPart.left}
                              bottom={mikronPart.bottom}
                              top={mikronPart.top}
                              right={mikronPart.right}
                              partNo={expertiseTemplate.name}
                              isFocused={focusedMikronPart === index + 1}
                              onClick={() => setFocusedMikronPart(index + 1)}
                              value={values.microns[expertiseTemplate.id]}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </VStack>
                  <Stack direction={['column', 'row-reverse']} width="100%" justify="center" spacing="24px">
                    {!isDisabled && (
                      <VStack>
                        {focusedMikronPart ? (
                          <>
                            <FormControlFormInput
                              label={`Mikron Değeri(Parça No ${
                                expertiseTemplates.expertiseBodyDamageParts[focusedMikronPart - 1].name
                              })`}
                              onChange={(event) =>
                                setFieldValue(
                                  `microns.${expertiseTemplates.expertiseBodyDamageParts[focusedMikronPart - 1].id}`,
                                  event.target.value,
                                )
                              }
                              ref={micronValueInputRef}
                              type="number"
                            />
                            <HStack justify="space-between" width="100%">
                              <IconButton
                                aria-label=""
                                icon={<ArrowLeftIcon />}
                                onClick={() => gotToPrevMicronPosition()}
                                disabled={!canGoToPrevMicronPosition(focusedMikronPart)}
                              />
                              <IconButton
                                aria-label=""
                                icon={<ArrowRightIcon />}
                                onClick={() => goToNextMicronPosition()}
                                disabled={
                                  !canGoToNextMicronPosition(
                                    expertiseTemplates.expertiseBodyDamageParts,
                                    focusedMikronPart,
                                  )
                                }
                              />
                            </HStack>
                          </>
                        ) : (
                          <Text>Mikron Değeri Girmek için Lütfen Üst Taraftan Parça Seçin</Text>
                        )}
                      </VStack>
                    )}

                    <Box>
                      {expertiseTemplates.expertiseBodyQuestions.map((expertiseBodyQuestion) => (
                        <FormControlFormRadioGroup
                          key={`bweq${expertiseBodyQuestion.id}`}
                          label={expertiseBodyQuestion.question}
                          onChange={(value) => {
                            setFieldValue(`answers.${expertiseBodyQuestion.id}`, value);
                          }}
                          value={values.answers[expertiseBodyQuestion.id] || '0'}
                          isDisabled={isDisabled}
                        >
                          <HStack spacing="24px">
                            <Radio value="1">Evet</Radio>
                            <Radio value="0">Hayır</Radio>
                          </HStack>
                        </FormControlFormRadioGroup>
                      ))}
                    </Box>
                  </Stack>
                </VStack>
              )}
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                İptal
              </Button>
              {!isDisabled && <Button type="submit">Kaydet</Button>}
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
    </Portal>
  );
};
