import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ExpertisePoint, ExpertisePointQueryCacheKey } from 'types';

export interface ExpertisePointSelectProps extends FormControlFormSelectProps {
  enableAll?: boolean;
  onExpertisePointSelected: (expertisePoint?: ExpertisePoint) => void;
  filterActive?: boolean;
}

export const ExpertisePointSelect: React.FC<ExpertisePointSelectProps> = ({
  enableAll,
  onExpertisePointSelected,
  filterActive,
  ...selectedProps
}) => {
  const query = useQuery(
    ExpertisePointQueryCacheKey.GetAllExpertisePoints,
    async () => {
      const response = await Api.ExpertisePoint.getAllExpertisePoints();
      if (filterActive) {
        return response.data.filter((expertisePoint) => expertisePoint.active);
      }
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const [expertisePointId, setExpertisePointId] = useState<string>();

  useEffect(() => {
    if (enableAll && expertisePointId === '-1') {
      onExpertisePointSelected();
    } else if (query.data && expertisePointId && expertisePointId !== '-1') {
      const selectedExpertisePoint = query.data.find(
        (expertisePoint) => expertisePoint.id === parseInt(expertisePointId, 10),
      );

      if (selectedExpertisePoint) {
        onExpertisePointSelected(selectedExpertisePoint);
      }
    }
  }, [expertisePointId]);

  return (
    <FormControlFormSelect
      onChange={(event) => {
        setExpertisePointId(event.target.value);
      }}
      value={expertisePointId}
      defaultValue={enableAll ? -1 : undefined}
      placeholder={enableAll ? undefined : 'Bayi'}
      label="Bayi"
      {...selectedProps}
    >
      {query.data && (
        <>
          {enableAll && <option value="-1">Hepsi</option>}
          {query.data.map((expertisePoint) => (
            <option key={`eps${expertisePoint.id}`} value={expertisePoint.id}>
              {expertisePoint.name}
            </option>
          ))}
        </>
      )}
    </FormControlFormSelect>
  );
};
