import { ViewIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from 'components';
import React from 'react';
import { InquiryTramerData } from 'types';

export interface AdminExpertisePointTramerInquiriesHistoryTableProps {
  inquiriesData: InquiryTramerData[];
}

export const AdminExpertisePointTramerInquiriesHistoryTable: React.FC<
  AdminExpertisePointTramerInquiriesHistoryTableProps
> = ({ inquiriesData }) => {
  const columnHelper = createColumnHelper<InquiryTramerData>();

  const columns = [
    columnHelper.accessor('chassisNumber', {
      header: 'Şase no',
    }),

    columnHelper.accessor('createdAt', {
      header: 'Tarih',
      cell: (info) => new Date(info.getValue()).toLocaleString('tr-TR'),
    }),

    columnHelper.accessor('url', {
      header: 'URL',
      cell: (info) => {
        return (
          <Flex gap={4}>
            <Tooltip label="View">
              <IconButton
                aria-label="View"
                icon={<ViewIcon />}
                onClick={() => window.open(info.getValue(), '_blank')}
              />
            </Tooltip>
          </Flex>
        );
      },
    }),
  ];

  return (
    <DataTable
      data={inquiriesData}
      columns={columns}
      usePagination
      tableProps={{
        variant: 'striped',
        colorScheme: 'teal',
      }}
      title="Tramer Sorgulama Geçmişi"
    />
  );
};
