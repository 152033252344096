import React, { useRef } from 'react';
import { useFormik } from 'formik';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Center,
  useBoolean,
  Button,
} from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormTextarea, getErrorMessage } from 'core';

interface EditTestProps {
  isOpen: boolean;
  onClose: () => void;
  customerId: number;
  onEditTestComplete: () => void;
}

export const EditTest: React.FC<EditTestProps> = ({ isOpen, onClose, customerId, onEditTestComplete }) => {
  const initialRef = useRef(null);
  const [isLoading, { on, off }] = useBoolean();
  const toast = useToast();

  const { handleSubmit, handleChange, values, errors, resetForm } = useFormik({
    initialValues: {
      description: '',
    },
    onSubmit: (data) => {
      if (data) {
        on();
        Api.Customer.editCustomer(values.description, customerId)
          .then((result) => {
            if (result.data) {
              onClose();
              onEditTestComplete();
              toast({
                status: 'success',
                title: 'Düzenleme sebebi başarıyla gönderilmiştir.',
                isClosable: true,
              });
            } else {
              toast({
                status: 'warning',
                title: 'Düzenleme sebebi gönderilirken bir hata oluştu.',
                isClosable: true,
              });
            }
          })
          .catch((err) => {
            toast({
              isClosable: true,
              status: 'error',
              title: getErrorMessage(err, 'Düzenleme sırasında hata oluştu'),
            });
          })
          .then(() => off());
      }
    },
  });

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      initialFocusRef={initialRef}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
      size="md"
    >
      <ModalOverlay />
      <form id="editTestForm" noValidate onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Yeniden düzenleme yapabilmek için düzenleme sebebini giriniz.</ModalHeader>
          <ModalCloseButton disabled={isLoading} />
          <ModalBody>
            <Center>
              <FormControlFormTextarea
                name="description"
                onChange={handleChange}
                value={values.description}
                label="Açıklama"
                helperText={errors.description}
                error={errors.description != null}
              />
            </Center>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button onClick={closeModal} disabled={isLoading}>
              Kapat
            </Button>
            <Button form="editTestForm" type="submit" disabled={isLoading || !values.description}>
              Gönder
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
