import { AddIcon, DeleteIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Tag,
  HStack,
  Box,
} from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable } from 'components';
import React, { useState } from 'react';
import { Announcement, ExpertisePoint } from 'types';

export interface AdminAnnouncementsTableProps {
  announcements: Announcement[];
  isAdmin: boolean;
  onDeleteClicked?: (announcement: Announcement) => void;
  onAddAnnouncementClicked?: () => void;
  refetch: () => void;
}

const ExpertisePointTags: React.FC<{
  isAllSend?: boolean;
  expertisePoints?: ExpertisePoint[];
}> = ({ isAllSend, expertisePoints }) => {
  if (!expertisePoints) {
    return null;
  }

  if (isAllSend) {
    return (
      <Box my={2}>
        <Tag colorScheme="teal">Tüm Bayiler</Tag>
      </Box>
    );
  }
  return (
    <Box my={2}>
      <Text mb={2}>Bayiler</Text>
      <HStack spacing={4}>
        {expertisePoints.map((expertisePoint) => (
          <Tag colorScheme="teal" key={`aept${expertisePoint.name}`}>
            {expertisePoint.name}
          </Tag>
        ))}
      </HStack>
    </Box>
  );
};
export const AdminAnnouncementsTable: React.FC<AdminAnnouncementsTableProps> = ({
  announcements,
  onAddAnnouncementClicked,
  isAdmin,
  onDeleteClicked,
  refetch,
}) => {
  const [focusedAnnouncement, setFocusedAnnouncement] = useState<Announcement>();
  const columnHelper = createColumnHelper<Announcement>();
  const {
    isOpen: isDetailModalOpen,
    onOpen: onDetailModalOpen,
    onClose: onDetailModalClose,
  } = useDisclosure({
    onClose: () => {
      if (focusedAnnouncement?.isRead === false) {
        refetch();
      }
    },
  });

  const markAsRead = (announcementId: number) => {
    if (isAdmin) {
      return;
    }

    Api.Util.markAnnouncementAsRead(announcementId);
  };

  const columns: ColumnDef<Announcement, string>[] = [
    columnHelper.accessor('title', {
      header: 'Duyuru',
      cell: (info) => {
        const { original } = info.row;
        if (isAdmin) {
          return original.title;
        }

        return <Text fontWeight={original.isRead ? 'light' : 'bold'}>{original.title}</Text>;
      },
    }),
    columnHelper.accessor('title', {
      header: 'Tarih',
      cell: (info) => {
        const { original } = info.row;
        return original.createdAt.toLocaleDateString();
      },
    }),
    {
      header: 'Aksiyonlar',
      cell: (info) => {
        const { original } = info.row;
        return (
          <ButtonGroup>
            <IconButton
              icon={<InfoIcon />}
              aria-label="Detay"
              onClick={() => {
                setFocusedAnnouncement(original);
                markAsRead(original.id);
                onDetailModalOpen();
              }}
            />
            {isAdmin && (
              <IconButton
                icon={<DeleteIcon />}
                aria-label="Fil"
                onClick={() => {
                  if (onDeleteClicked) {
                    onDeleteClicked(original);
                  }
                }}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const actionButtons = (
    <Button
      leftIcon={<AddIcon />}
      aria-label="Ekle"
      onClick={() => {
        if (onAddAnnouncementClicked) {
          onAddAnnouncementClicked();
        }
      }}
    >
      Ekle
    </Button>
  );

  return (
    <>
      <DataTable
        columns={columns}
        data={announcements || []}
        tableProps={{
          variant: 'striped',
          colorScheme: 'teal',
        }}
        title="Duyurular"
        actions={isAdmin ? actionButtons : undefined}
        usePagination
      />
      <Modal isOpen={isDetailModalOpen} onClose={onDetailModalClose}>
        <ModalOverlay />
        <ModalContent>
          {focusedAnnouncement && (
            <>
              <ModalHeader>{focusedAnnouncement.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {isAdmin && (
                  <ExpertisePointTags
                    isAllSend={focusedAnnouncement.isAllSend}
                    expertisePoints={focusedAnnouncement.expertisePoints}
                  />
                )}
                {focusedAnnouncement.description}
              </ModalBody>
            </>
          )}
          <ModalFooter>
            <Button mr={3} onClick={onDetailModalClose}>
              Kapat
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
