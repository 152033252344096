import { useToast } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { LocationQueryCacheKeys } from 'types';

export type CitySelectProps = FormControlFormSelectProps;
export const CitySelect: React.FC<CitySelectProps> = ({ ...formSelectProps }) => {
  const toast = useToast();
  const query = useQuery(
    LocationQueryCacheKeys.GetCities,
    async () => {
      const response = await Api.Location.getCities();
      return response.data;
    },
    {
      onError: () => {
        toast({
          status: 'error',
          title: 'İller çekilirken hata oluştu',
          isClosable: true,
        });
      },
      refetchOnWindowFocus: false,
    },
  );
  return (
    <FormControlFormSelect label="İl Seç" placeholder="İl Seç" {...formSelectProps}>
      {query.data?.map((city) => (
        <option value={city.id} key={`cs${city.name}`}>
          {city.name}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
