/* eslint-disable no-unreachable */
import {
  ApiBase,
  ApiResponse,
  BodyPartExpertiseInfoResponse,
  ExpertiseBodyDamagePartType,
  ExpertisePackagesResponse,
  ExpertiseTemplateResponse,
  SaveExpertiseBodyPartsRequest,
  SaveDamageNoteRequest,
  SaveDamageNoteResponse,
  ExpertiseDamageNoteType,
  DamageNote,
  ExpertisePackageWithAbilitiesResponse,
  ExpertisePackageAbility,
  SavePackageWithAbilitiesRequest,
  ExpertisePackageGroupResponse,
  ExpertisePackageGroup,
  SaveExpertisePackageRequest,
  ExpertiseReportFileInfo,
  SearchExpertiseDamageNoteDictItem,
  SaveExpertiseNoteDictItemRequest,
  ExpertiseDamageNoteDictItem,
  CreateInhouseCodeRequest,
  InhouseCoudeExpertisePackagesResponse,
  InhouseCodes,
  ExternalPackageGroupSourceType,
  ExpertiseBodyQuestionResponse,
  SaveExpertiseBodyDamageAnswersRequest,
} from 'types';
import { SaveExpertiseReportResultRequest } from 'types/save-expertise.types';

const BASE_URL = 'expertise';
const BODY_DAMAGE_BASE_URL = 'expertise-body-damage';
const EXPERTISE_REPORT_BASE_URL = 'expertise-report';
export class ExpertiseApi extends ApiBase {
  public async getExpertisePackages(): Promise<ApiResponse<ExpertisePackagesResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-packages`);
    return response.data;
  }

  public async getExpertiseTemplate(
    vehicleTypeId: number,
    expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType,
  ): Promise<ApiResponse<ExpertiseTemplateResponse>> {
    const response = await this.apiClient.get(`${BODY_DAMAGE_BASE_URL}/get-body-damage-part-template`, {
      params: {
        vehicleTypeId,
        expertiseBodyDamagePartTypeId,
      },
    });
    return response.data;
  }

  public async getExpertiseBodyQuestions(
    expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType,
  ): Promise<ApiResponse<ExpertiseBodyQuestionResponse>> {
    const response = await this.apiClient.get(`${BODY_DAMAGE_BASE_URL}/get-body-questions`, {
      params: {
        expertiseBodyDamagePartTypeId,
      },
    });
    return response.data;
  }

  public async saveExpertiseBodyParts(saveExpertiseBodyPartsRequest: SaveExpertiseBodyPartsRequest) {
    await this.apiClient.post(`${BODY_DAMAGE_BASE_URL}/save-body-damages`, saveExpertiseBodyPartsRequest);
  }

  public async saveBodyQuestionAnswers(request: SaveExpertiseBodyDamageAnswersRequest) {
    await this.apiClient.post(`${BODY_DAMAGE_BASE_URL}/save-body-question-answers`, request);
  }

  public async getCustomerBodyPartsExpertise(
    customerId: number,
    partType: ExpertiseBodyDamagePartType,
  ): Promise<ApiResponse<BodyPartExpertiseInfoResponse>> {
    const response = await this.apiClient.get(`${BODY_DAMAGE_BASE_URL}/get-body-damages/${customerId}`, {
      params: {
        expertiseBodyDamagePartTypeId: partType,
      },
    });
    return response.data;
  }

  public async getDamageNotes(
    customerId: number,
    noteType: ExpertiseDamageNoteType,
  ): Promise<ApiResponse<DamageNote[]>> {
    const response = await this.apiClient.get(`${BODY_DAMAGE_BASE_URL}/get-damage-notes`, {
      params: {
        damageNoteTypeId: noteType,
        customerId,
      },
    });
    return response.data;
  }

  public async saveDamageNote(
    saveExpertiseNoteRequest: SaveDamageNoteRequest,
  ): Promise<ApiResponse<SaveDamageNoteResponse>> {
    const formData = new FormData();
    if (saveExpertiseNoteRequest.id) {
      formData.append('id', saveExpertiseNoteRequest.id.toString());
    }

    formData.append('customerId', saveExpertiseNoteRequest.customerId.toString());
    formData.append('note', saveExpertiseNoteRequest.note);
    formData.append('noteType', saveExpertiseNoteRequest.noteType.toString());
    if (saveExpertiseNoteRequest.damageNoteDictId) {
      formData.append('damageNoteDictId', saveExpertiseNoteRequest.damageNoteDictId.toString());
    }

    if (saveExpertiseNoteRequest.file) {
      formData.append('files', saveExpertiseNoteRequest.file);
    }
    const response = await this.apiClient.post(`${BODY_DAMAGE_BASE_URL}/save-damage-note`, formData);
    return response.data;
  }

  public async deleteDamageNote(damageNoteId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.delete(`${BODY_DAMAGE_BASE_URL}/delete-damage-note/${damageNoteId}`);
    return response.data;
  }

  public async clearDamageNoteImage(damageNoteId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.delete(`${BODY_DAMAGE_BASE_URL}/delete-damage-note-photo/${damageNoteId}`);
    return response.data;
  }

  public async searchDamageNotes(
    query: string,
    noteType: ExpertiseDamageNoteType,
  ): Promise<ApiResponse<SearchExpertiseDamageNoteDictItem[]>> {
    const response = await this.apiClient.get(`${BODY_DAMAGE_BASE_URL}/search-damage-notes`, {
      params: {
        text: query,
        damageNoteTypeId: noteType,
      },
    });
    return response.data;
  }

  public async getExpertiseReportPdfLink(customerId: number): Promise<ApiResponse<ExpertiseReportFileInfo[]>> {
    const response = await this.apiClient.get(`${EXPERTISE_REPORT_BASE_URL}/export-report/${customerId}`);
    return response.data;
  }

  public async sendExpertiseReportLinkBySMS(customerId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${EXPERTISE_REPORT_BASE_URL}/send-report-via-sms/${customerId}`);
    return response.data;
  }

  public async sendExpertiseReportLinkByEmail(customerId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${EXPERTISE_REPORT_BASE_URL}/send-report-via-email/${customerId}`);
    return response.data;
  }

  public async getExpertisePackagesWithAbilities(): Promise<ApiResponse<ExpertisePackageWithAbilitiesResponse[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-all-packages-with-ability`);
    return response.data;
  }

  public async getAllAbilities(): Promise<ApiResponse<ExpertisePackageAbility[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-ability-definitions`);
    return response.data;
  }

  public async savePackageWithAbilities(request: SavePackageWithAbilitiesRequest): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${BASE_URL}/save-package-with-abilities`, request);
    return response.data;
  }

  public async deletePackage(packageId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.delete(`${BASE_URL}/remove-package/${packageId}`);
    return response.data;
  }

  public async getAllPackageGroups(): Promise<ApiResponse<ExpertisePackageGroup[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-all-package-groups`);
    const { data }: { data: ApiResponse<ExpertisePackageGroupResponse[]> } = response;
    if (!data.isSuccess) {
      return {
        isSuccess: data.isSuccess,
        message: data.message,
        data: [],
        code: data.code,
      };
    }
    const backendData = data.data;
    const normalizedData = backendData.map((elem) => {
      const {
        id,
        name,
        items,
        active,
        expertisePoints,
        externalPackageGroupSource,
        discountAvailable,
        maxDiscountRate,
      } = elem.expertisePackageGroup;

      return {
        id,
        name,
        items,
        active,
        expertisePoints,
        externalPackageGroupSource,
        discountAvailable,
        maxDiscountRate,
      };
    });

    return {
      isSuccess: data.isSuccess,
      message: data.message,
      data: normalizedData,
      code: data.code,
    };
  }

  public async savePackageGroup(request: SaveExpertisePackageRequest): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${BASE_URL}/save-package-group`, request);
    return response.data;
  }

  public async deletePackageGroup(expertisePackageGroupId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.delete(`${BASE_URL}/remove-package-group/${expertisePackageGroupId}`);
    return response.data;
  }

  public async deletePackageGroupItem(expertisePackageGroupItemId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.delete(
      `${BASE_URL}/remove-package-group-item/${expertisePackageGroupItemId}`,
    );
    return response.data;
  }

  public async getExpertisePackagesByGroup(
    expertisePackageGroupId: string,
  ): Promise<ApiResponse<ExpertisePackageGroupResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-package-group/${expertisePackageGroupId}`);
    return response.data;
  }

  public async checkOldBodyDamages(saveExpertiseBodyPartsRequest: SaveExpertiseBodyPartsRequest) {
    const response = await this.apiClient.post(
      `${BODY_DAMAGE_BASE_URL}/check-old-body-damages`,
      saveExpertiseBodyPartsRequest,
    );
    return response.data;
  }

  public async saveExpertiseNoteDictItem(
    saveExpertiseNoteDictItemRequest: SaveExpertiseNoteDictItemRequest,
  ): Promise<ApiResponse<ExpertiseDamageNoteDictItem>> {
    const response = await this.apiClient.post(
      `${BODY_DAMAGE_BASE_URL}/save-expertise-note-dict-item`,
      saveExpertiseNoteDictItemRequest,
    );

    return response.data;
  }

  public async getDamageNoteDictItems(
    noteType: ExpertiseDamageNoteType,
  ): Promise<ApiResponse<ExpertiseDamageNoteDictItem[]>> {
    const response = await this.apiClient.get(`${BODY_DAMAGE_BASE_URL}/expertise-note-dict-items/${noteType}`);
    return response.data;
  }

  public async getInhouseCodeExpertisePackages(): Promise<ApiResponse<InhouseCoudeExpertisePackagesResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-external-packages-inhouse/${20}`);
    return response.data;
  }

  public async getInhouseCodes(
    packageCodeStatus?: number | null,
    expertisePointId?: number | null,
    expertisePackageGroupItemId?: number | null,
  ): Promise<ApiResponse<InhouseCodes>> {
    const result = await this.apiClient.get(`${BASE_URL}/search-external-packages`, {
      params: {
        packageCodeStatus,
        expertisePointId,
        expertisePackageGroupItemId: Number(expertisePackageGroupItemId) || undefined,
        externalPackageGroupSource: ExternalPackageGroupSourceType.OccoCoupon,
      },
    });
    return result.data;
  }

  public async createInhouseCode(request: CreateInhouseCodeRequest): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${BASE_URL}/generate-external-package-codes-inhouse`, request);

    return response.data;
  }

  public async exportInhouseReport(
    packageCodeStatus?: number | null,
    expertisePointId?: number | null,
    expertisePackageGroupItemId?: number | null,
  ): Promise<Blob> {
    const response = await this.apiClient.get(`${BASE_URL}/export-external-packages`, {
      params: {
        packageCodeStatus,
        expertisePointId,
        expertisePackageGroupItemId: Number(expertisePackageGroupItemId) || undefined,
        externalPackageGroupSource: ExternalPackageGroupSourceType.OccoCoupon,
      },
      responseType: 'blob',
    });

    return response.data;
  }

  public async saveExpertiseReportResult(request: SaveExpertiseReportResultRequest): Promise<ApiResponse<any>> {
    const response = await this.apiClient.post(`${EXPERTISE_REPORT_BASE_URL}/save-result`, request);
    return response.data;
  }

  public async getExpertiseReportResult(customerId: number): Promise<ApiResponse<any>> {
    const response = await this.apiClient.get(`${EXPERTISE_REPORT_BASE_URL}/get-report/${customerId}`);
    return response.data;
  }
}
