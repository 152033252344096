import {
  Button,
  ButtonGroup,
  ButtonProps,
  Checkbox,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { CustomerInitiateRequest, CustomerInitiateResponse, ExpertisePackageGroup, FormBaseProps } from 'types';
import React, { useRef } from 'react';
import * as yup from 'yup';
import { FocusableElement } from '@chakra-ui/utils';
import { useFormik } from 'formik';
import { AddIcon } from '@chakra-ui/icons';
import { CarEntryRow } from 'core/types/Types';
import { FormControlFormInput, GenericMessages, NoSpecialCharacterRegex } from 'core';
import { useMutation } from 'react-query';
import { Api } from 'api';
import { ExpertisePackageGroupSelect, ExpertisePackageSelect } from 'components';

export interface AddCarWithPlateFormValues {
  license: string;
  expertisePackage?: string;
  expertisePackageGroup?: string;
  packageCode?: string;
  discountApplied?: boolean;
  discountedPrice?: string;
}
export type AddCarWithPlateFormProps = FormBaseProps<AddCarWithPlateFormValues, CarEntryRow>;

const addCarWithPlateFormValidationSchema = yup.object({
  license: yup
    .string()
    .required(GenericMessages.CannotBeEmpty)
    .matches(NoSpecialCharacterRegex, GenericMessages.NoSpecialCharater),
  expertisePackageGroup: yup.number().required(GenericMessages.CannotBeEmpty),
  expertisePackage: yup.number().when('isPackageCodeShown', {
    is: false,
    then: yup.number().required(GenericMessages.CannotBeEmpty),
    otherwise: yup.number(),
  }),
  packageCode: yup.string().when('isPackageCodeShown', {
    is: true,
    then: yup
      .string()
      .required(GenericMessages.CannotBeEmpty)
      .matches(NoSpecialCharacterRegex, GenericMessages.NoSpecialCharater),
    otherwise: yup.string(),
  }),
  discountApplied: yup.boolean(),
  discountedPrice: yup.string().when('discountApplied', {
    is: true,
    then: yup.string().required(GenericMessages.CannotBeEmpty),
    otherwise: yup.string(),
  }),
});

export const AddCarWithPlateForm: React.FC<AddCarWithPlateFormProps> = ({
  defaultValues,
  formId,
  initialFocusRef,
  onSubmitBegin,
  onSubmitComplete,
  onSubmitFailed,
}) => {
  const [showPackageCodeInput, setShowPackageCodeInput] = React.useState(false);
  const [showDiscountCheckbox, setShowDiscountCheckbox] = React.useState(false);

  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      license: defaultValues?.license || '',
      expertisePackage: defaultValues?.expertisePackage || '',
      expertisePackageGroup: defaultValues?.expertisePackageGroup || '',
      packageCode: defaultValues?.packageCode || '',
      isPackageCodeShown: showPackageCodeInput,
      discountApplied: defaultValues?.discountApplied || false,
      discountedPrice: defaultValues?.discountedPrice || '',
    },
    validationSchema: addCarWithPlateFormValidationSchema,
    onSubmit: (values) => {
      if (onSubmitBegin) {
        onSubmitBegin();
      }

      // eslint-disable-next-line no-use-before-define
      mutation.mutateAsync(values);
    },
  });

  const mutation = useMutation<CustomerInitiateResponse, any, CustomerInitiateRequest>(
    async (customerInıtiateRequest: CustomerInitiateRequest) => {
      const response = await Api.Customer.initiateCustomer(customerInıtiateRequest);
      return response.data;
    },
    {
      onSuccess: (customerData) => {
        toast({
          title: 'Araç Başarılı Bir Şekilde Eklendi',
          status: 'success',
          isClosable: true,
        });
        if (onSubmitComplete) {
          onSubmitComplete({
            id: customerData.savedCustomerId,
            hasHistoryByPlate: customerData.hasHistoryByPlate,
            histories: customerData.histories,
            license: formik.values.license,
          });
          formik.resetForm();
        }
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: err.response.data.message,
          status: 'error',
          isClosable: true,
        });
        if (onSubmitFailed) {
          onSubmitFailed();
        }
      },
    },
  );

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.toUpperCase().replace(' ', '');
    formik.handleChange(e);
  };

  const handlePackageGroupChange = (selectedGroup: ExpertisePackageGroup) => {
    if (selectedGroup.externalPackageGroupSource !== null) {
      setShowPackageCodeInput(true);
      formik.setFieldValue('isPackageCodeShown', true);
      formik.setFieldValue('expertisePackage', '');
      formik.setFieldValue('discountApplied', false);
      formik.setFieldValue('discountedPrice', '');
    } else {
      setShowPackageCodeInput(false);
      formik.setFieldValue('isPackageCodeShown', false);
      formik.setFieldValue('packageCode', '');
    }

    if (selectedGroup?.discountAvailable) {
      setShowDiscountCheckbox(true);
    } else {
      setShowDiscountCheckbox(false);
      formik.setFieldValue('discountApplied', false);
      formik.setFieldValue('discountedPrice', '');
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} id={formId}>
      <Flex direction="column" gap="4">
        <FormControlFormInput
          label="Plaka"
          placeholder="Aracın Plakası"
          name="license"
          onChange={onChange}
          value={formik.values.license}
          error={formik.errors.license != null}
          helperText={formik.errors.license}
          ref={initialFocusRef}
        />
        <ExpertisePackageGroupSelect
          name="expertisePackageGroup"
          onChange={formik.handleChange}
          value={formik.values.expertisePackageGroup}
          error={formik.errors.expertisePackageGroup != null}
          helperText={formik.errors.expertisePackageGroup}
          onPackageGroupChange={handlePackageGroupChange}
        />

        {!showPackageCodeInput && (
          <ExpertisePackageSelect
            name="expertisePackage"
            onChange={formik.handleChange}
            value={formik.values.expertisePackage}
            error={formik.errors.expertisePackage != null}
            helperText={formik.errors.expertisePackage}
            isDisabled={!formik.values.expertisePackageGroup}
            expertisePackageGroupId={formik.values.expertisePackageGroup}
          />
        )}
        {showPackageCodeInput && (
          <FormControlFormInput
            label="Kupon Kodu"
            placeholder="Kupon Kodu"
            name="packageCode"
            onChange={formik.handleChange}
            value={formik.values.packageCode}
            error={formik.errors.packageCode != null}
            helperText={formik.errors.packageCode}
          />
        )}

        {/* DISCOUNT */}
        {showDiscountCheckbox && !showPackageCodeInput && (
          <Checkbox
            size="sm"
            colorScheme="green"
            name="discountApplied"
            onChange={(event) => {
              formik.setFieldValue(event.target.name, event.target.checked);
            }}
            isChecked={formik.values.discountApplied}
          >
            İndirim uygula
          </Checkbox>
        )}

        {formik.values.discountApplied && (
          <FormControlFormInput
            size="xs"
            label=""
            placeholder="İndirimli Paket Fiyatı"
            name="discountedPrice"
            onChange={onChange}
            value={formik.values.discountedPrice}
            error={formik.errors.discountedPrice != null}
            helperText={formik.errors.discountedPrice}
            width="auto"
          />
        )}
      </Flex>
    </form>
  );
};
export interface AddCarWithPlatePopoverButtonProps extends AddCarWithPlateFormProps {
  popoverProps?: Omit<PopoverProps, 'children'>;
  popoverButtonProps?: ButtonProps;
}

export const AddCarWithPlatePopoverButton: React.FC<AddCarWithPlatePopoverButtonProps> = ({
  popoverProps,
  popoverButtonProps,
  ...addCarWithPlateFormProps
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialFocusRef = useRef<FocusableElement>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const onFormSubmitBeing = () => {
    setIsLoading(true);
  };

  const onSubmitComplete = (value: CarEntryRow) => {
    setIsLoading(false);
    onClose();
    if (addCarWithPlateFormProps.onSubmitComplete) {
      addCarWithPlateFormProps.onSubmitComplete(value);
    }
  };

  const onSubmitFailed = () => {
    setIsLoading(false);
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      {...popoverProps}
      initialFocusRef={initialFocusRef}
      placement="bottom"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button width="100%" leftIcon={<AddIcon />} flexShrink="0" {...popoverButtonProps}>
          Ekle
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Araç Ekle
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton disabled={isLoading} />
        <PopoverBody>
          <AddCarWithPlateForm
            {...addCarWithPlateFormProps}
            formId="addCarWithPlateForm"
            initialFocusRef={initialFocusRef}
            onSubmitBegin={onFormSubmitBeing}
            onSubmitComplete={onSubmitComplete}
            onSubmitFailed={onSubmitFailed}
          />
        </PopoverBody>
        <PopoverFooter border="0" display="flex" alignItems="center" justifyContent="flex-end" pb={4}>
          <ButtonGroup size="sm">
            <Button disabled={isLoading} onClick={onClose}>
              İptal
            </Button>
            <Button colorScheme="green" isLoading={isLoading} type="submit" form="addCarWithPlateForm">
              Ekle
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
