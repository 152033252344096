import {
  useDisclosure,
  useToast,
  Portal,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { Api } from 'api';
import {
  FormControlBaseProps,
  GenericMessages,
  FormControlFormInput,
  createFormControlInput,
  FormPhoneInput,
  FormPhoneInputProps,
} from 'core';
import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Customer, SendKvKKRequestResponse } from 'types';
import * as yup from 'yup';

// #region Form Phone KvKK Input
export type FormPhoneKvKKInputProps = FormPhoneInputProps & {
  onKvKKConfirmation: (kvKKCustomerResponse?: Customer) => void;
  showKvKKButton: boolean;
  customerName: string;
};
export type FormControlFormPhoneKvKKInputProps = FormPhoneKvKKInputProps & FormControlBaseProps;
const FormPhoneKvKKInputValidationSchema = yup.object({
  confirmationCode: yup.number().required(GenericMessages.CannotBeEmpty),
});

type FormPhoneKvKKInputFormData = {
  confirmationCode: string;
};

export const FormPhoneKvKKInput = React.forwardRef<HTMLInputElement, FormPhoneKvKKInputProps>(
  ({ onKvKKConfirmation, showKvKKButton, customerName, ...inputProps }, ref) => {
    const customer = useSelector((state: RootState) => state.customer.focusedCustomer);
    const formId = useMemo(() => {
      return `formid-kvkk-phone-${Math.random()}`;
    }, []);
    const { onOpen, onClose, isOpen } = useDisclosure();
    const toast = useToast();

    const { handleSubmit, handleChange, values, errors, setFieldError } = useFormik({
      validateOnChange: false,
      validationSchema: FormPhoneKvKKInputValidationSchema,
      initialValues: {
        confirmationCode: '',
      },
      onSubmit: (data: FormPhoneKvKKInputFormData) => {
        // eslint-disable-next-line no-use-before-define
        confirmMutation.mutate(data);
      },
    });

    const confirmMutation = useMutation<boolean, any, FormPhoneKvKKInputFormData>(
      async (data: FormPhoneKvKKInputFormData) => {
        if (inputProps.value) {
          const response = await Api.Customer.approveKvKK(
            customer!.id,
            String(inputProps.value),
            parseInt(data.confirmationCode, 10),
          );
          return response.data;
        }
        return false;
      },
      {
        onError: (err: any) => {
          console.error(err);
          toast({
            title: 'İşlem sırasında hata oluştu',
            isClosable: true,
            status: 'error',
          });
        },
        onSuccess: (data: boolean) => {
          console.error(data);
          if (data) {
            toast({
              title: 'Başarı ile onaylandı',
              isClosable: true,
              status: 'success',
            });
            onKvKKConfirmation();
            onClose();
          } else {
            toast({
              title: 'Onaylanamadı',
              isClosable: true,
              status: 'warning',
            });
            setFieldError('confirmationCode', 'Lütfen kodun doğruluğunu kontrol edin');
          }
        },
      },
    );

    const sendSmsMutation = useMutation<SendKvKKRequestResponse, any, void>(
      async () => {
        const response = await Api.Customer.sendKVKKSms(customer!.id, customerName, String(inputProps.value));
        return response.data;
      },
      {
        onError: (err: any) => {
          console.error(err);
          toast({
            title: 'SMS Gönderirken Hata oluştu',
            isClosable: true,
            status: 'error',
          });
        },
        onSuccess: (response: SendKvKKRequestResponse) => {
          if (response.customerData) {
            onKvKKConfirmation(response!.customerData);
          }
          if (response.alreadyApproved) {
            toast({
              title: 'Zaten Onay Verilmiş!',
              isClosable: true,
              status: 'success',
            });
            onClose();
          } else {
            toast({
              title: 'SMS Başarı İle Gönderildi',
              isClosable: true,
              status: 'success',
            });
          }
        },
      },
    );

    useEffect(() => {
      if (isOpen) {
        sendSmsMutation.mutate();
      }
    }, [isOpen]);

    const isLoading = sendSmsMutation.isLoading || confirmMutation.isLoading;

    return (
      <>
        <Portal>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>KvKK Onay</ModalHeader>
              <ModalCloseButton disabled={isLoading} />
              <ModalBody>
                <form noValidate onSubmit={handleSubmit} id={formId}>
                  <FormControlFormInput
                    type="number"
                    name="confirmationCode"
                    error={errors.confirmationCode != null}
                    helperText={errors.confirmationCode}
                    value={values.confirmationCode}
                    onChange={handleChange}
                    label="KvKK Onay Kodu"
                  />
                </form>
              </ModalBody>
              <ModalFooter>
                <Button mr={3} onClick={onClose} disabled={isLoading}>
                  Kapat
                </Button>
                <Button
                  mr={3}
                  colorScheme="teal"
                  disabled={isLoading}
                  onClick={() => sendSmsMutation.mutate()}
                  isLoading={sendSmsMutation.isLoading}
                >
                  Tekrar SMS Gönder
                </Button>
                <Button
                  colorScheme="blue"
                  type="submit"
                  form={formId}
                  disabled={isLoading}
                  isLoading={confirmMutation.isLoading}
                >
                  Onayla
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
        <FormPhoneInput {...inputProps} ref={ref} />
        {showKvKKButton && (
          <Button mt="1" width="100%" colorScheme="blue" onClick={onOpen}>
            KvKK ve İş Emri Formu
          </Button>
        )}
      </>
    );
  },
);

export const FormControlFormPhoneKvKKInput = createFormControlInput<FormPhoneKvKKInputProps>(FormPhoneKvKKInput);
// #endregion Form Phone KvKK Input
