import { Api } from 'api';
import { ChartData } from 'chart.js';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { Chart } from 'react-chartjs-2';
import { useMutation, useQuery } from 'react-query';
import {
  ApiResponse,
  ExpertisePoint,
  ExtraPackagesReportAdminResponse,
  ExtraPackagesReportResponse,
  ReportingQueryCacheKey,
} from 'types';
import {
  Button,
  Center,
  Icon,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useToast,
} from '@chakra-ui/react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'features';
import { downloadBlobAsFile } from 'core';
import { DownloadIcon } from '@chakra-ui/icons';

export interface AdminReportExtraPackagesProps {
  beginDate?: Date;
  endDate?: Date;
  expertisePoint?: ExpertisePoint;
}

interface GraphData {
  dateLabels: string[];
  roadAssistanceData: number[];
  usedCarProtectionData: number[];
  totalCountLineData: number[];
}

type ExtraPackageReportChartData = ChartData<'line' | 'bar', number[], string>;
type ExtraPackageReportData = {
  graphData: ExtraPackageReportChartData;
  isEmpty: boolean;
  totalCount?: number;
  totalAmount?: number;
  totalRoadAssistanceCount?: number;
  totalRoadAssistanceAmount?: number;
  totalUsedCarProtectionCount?: number;
  totalUsedCarProtectionAmount?: number;
};

export const AdminReportExtraPackages: React.FC<AdminReportExtraPackagesProps> = ({
  beginDate,
  endDate,
  expertisePoint,
}) => {
  const authUserInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const toast = useToast();
  const dispatch = useDispatch();

  const { data: reportData, isLoading } = useQuery(
    [ReportingQueryCacheKey.GetExtraPackageReport, beginDate?.getTime(), endDate?.getTime(), expertisePoint?.id],
    async (): Promise<ExtraPackageReportData | null> => {
      if (beginDate && endDate) {
        const response: ApiResponse<ExtraPackagesReportResponse | ExtraPackagesReportAdminResponse> =
          authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin
            ? await Api.Reporting.getExtraPackageReportAdmin(beginDate.getTime(), endDate.getTime(), expertisePoint?.id)
            : await Api.Reporting.getExtraPackageReport(beginDate.getTime(), endDate.getTime());

        const queryData = response.data;
        const graphData: GraphData = {
          dateLabels: [],
          roadAssistanceData: [],
          usedCarProtectionData: [],
          totalCountLineData: [],
        };

        for (let i = 0; i < queryData.roadAssistanceQueries.length; i += 1) {
          const roadAssist = queryData.roadAssistanceQueries[i];
          const carProtection = queryData.usedCarProtectionQueries[i];
          const roadAssistCount = Number(roadAssist.count);
          const carProtectionCount = Number(carProtection.count);
          const total = roadAssistCount + carProtectionCount;

          if (total !== 0) {
            const date = new Date(roadAssist.date);
            graphData.dateLabels.push(
              `${date.getDate()} ${date.toLocaleString(window.navigator.language, { month: 'long' })}`,
            );
            graphData.roadAssistanceData.push(roadAssistCount);
            graphData.usedCarProtectionData.push(carProtectionCount);
            graphData.totalCountLineData.push(total);
          }
        }

        return {
          isEmpty: graphData.dateLabels.length === 0,
          graphData: {
            labels: graphData.dateLabels,
            datasets: [
              {
                type: 'line' as const,
                label: 'Toplam',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: graphData.totalCountLineData,
                yAxisID: 'totalYAxis',
                xAxisID: 'xAxis',
              },
              {
                type: 'bar' as const,
                label: 'Yol Yardım Sertifikası',
                backgroundColor: 'rgb(75, 192, 192)',
                data: graphData.roadAssistanceData,
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'yAxis',
                xAxisID: 'xAxis',
              },
              {
                type: 'bar' as const,
                label: 'Araç Garanti Sertifikası',
                backgroundColor: 'rgb(53, 162, 235)',
                data: graphData.usedCarProtectionData,
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'yAxis',
                xAxisID: 'xAxis',
              },
            ],
          },
          totalCount: 'totalCount' in queryData ? queryData.totalCount : undefined,
          totalAmount: 'totalAmount' in queryData ? queryData.totalAmount : undefined,
          totalRoadAssistanceCount:
            'totalRoadAssistanceCount' in queryData ? queryData.totalRoadAssistanceCount : undefined,
          totalRoadAssistanceAmount:
            'totalRoadAssistanceAmount' in queryData ? queryData.totalRoadAssistanceAmount : undefined,
          totalUsedCarProtectionCount:
            'totalUsedCarProtectionCount' in queryData ? queryData.totalUsedCarProtectionCount : undefined,
          totalUsedCarProtectionAmount:
            'totalUsedCarProtectionAmount' in queryData ? queryData.totalUsedCarProtectionAmount : undefined,
        };
      }
      return null;
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!(beginDate && endDate),
    },
  );
  const getExportExtraPackagesReport = useMutation<any, any>(
    async () => {
      dispatch(showLoading());
      if (beginDate && endDate) {
        const response = await Api.Reporting.exportExtraPackageReport(beginDate.getTime(), endDate.getTime());
        if (!response) throw new Error('No response');
        return response;
      }
      return null;
    },
    {
      onSuccess(data) {
        if (data) {
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const fileName = `EkHizmetPaketleri${formattedDate}.xlsx`;
          downloadBlobAsFile(data, fileName);
        } else {
          toast({
            status: 'info',
            title: 'Rapor Bulunamadı',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Rapor İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  if (!reportData || isLoading) {
    return null;
  }

  if (reportData.isEmpty) {
    return (
      <Center>
        <Text fontSize="2xl" mt={2} mb={6}>
          Seçtiğiniz Tarih Aralığında Veri Bulunmuyor!
        </Text>
      </Center>
    );
  }

  return (
    <>
      <Chart
        type="bar"
        data={reportData.graphData}
        options={{
          responsive: true,
          scales: {
            xAxis: {
              stacked: true,
              axis: 'x',
            },
            yAxis: {
              stacked: true,
              position: 'left',
            },
            totalYAxis: {
              stacked: true,
              ticks: {
                precision: 0,
              },
              beginAtZero: true,
              position: 'right',
            },
          },
        }}
      />
      {beginDate && endDate && reportData && (
        <>
          <StatGroup p={4}>
            <Stat>
              <StatLabel>Yol Yardım Sertifikası</StatLabel>
              <StatNumber>{reportData.totalRoadAssistanceCount}</StatNumber>
              <StatHelpText>
                {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Araç Garanti Sertifikası</StatLabel>
              <StatNumber>{reportData.totalUsedCarProtectionCount}</StatNumber>
              <StatHelpText>
                {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Toplam Satış</StatLabel>
              <StatNumber>{reportData.totalCount}</StatNumber>
              <StatHelpText>
                {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
              </StatHelpText>
            </Stat>
          </StatGroup>
          <StatGroup p={4}>
            <Stat>
              <StatLabel>Yol Yardım Sertifikası Tutarı</StatLabel>
              <StatNumber>{reportData.totalRoadAssistanceAmount} ₺</StatNumber>
              <StatHelpText>
                {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Araç Garanti Sertifikası Tutarı</StatLabel>
              <StatNumber>{reportData.totalUsedCarProtectionAmount} ₺</StatNumber>
              <StatHelpText>
                {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Toplam Satış Tutarı</StatLabel>
              <StatNumber>{reportData.totalAmount} ₺</StatNumber>
              <StatHelpText>
                {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
              </StatHelpText>
            </Stat>
          </StatGroup>
        </>
      )}
      <Button
        variant="solid"
        leftIcon={<Icon as={DownloadIcon} />}
        onClick={() => getExportExtraPackagesReport.mutate()}
      >
        Excel İndir
      </Button>
    </>
  );
};
