import React from 'react';
import { Api } from 'api';
import { LoadingText } from 'components';
import { useQuery } from 'react-query';
import { ExpertisePoint, ExpertisePointQueryCacheKey, TotalBalanceSheetData } from 'types';
import { AdminExpertisePointBalanceHistoryTable } from './AdminExpertisePointBalanceHistoryTable';

export interface AdminExpertisePointManagementBalanceHistoryProps {
  expertisePoint: ExpertisePoint;
}
export const AdminExpertisePointManagementBalanceHistory: React.FC<
  AdminExpertisePointManagementBalanceHistoryProps
> = ({ expertisePoint }) => {
  const { data: transactionData } = useQuery(
    [ExpertisePointQueryCacheKey.GetPreviousBalanceTransactions, expertisePoint.id],
    async () => {
      const response = await Api.ExpertisePoint.getTransactions(expertisePoint.id);
      const responseData = response.data;
      const convertedTransactionData: TotalBalanceSheetData = {
        totalBalance: responseData.totalBalance,
        transactions: responseData.transactions.map((transaction) => {
          return {
            id: transaction.id,
            amount: transaction.amount,
            isCompleted: transaction.isCompleted,
            transactionDate: transaction.transactionDate ? new Date(transaction.transactionDate) : undefined,
          };
        }),
      };
      return convertedTransactionData;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!transactionData?.transactions) {
    return <LoadingText />;
  }

  return <AdminExpertisePointBalanceHistoryTable transactions={transactionData.transactions} />;
};
