export const enum IntegrationQueryCacheKey {
  GetExpertiseTramerDamage = 'GetExpertiseTramerDamage',
  GetTramerOldQueries = 'GetTramerOldQueries',
  GetKmResults = 'GetKmResults',
  getExternalTramerQueries = 'getExternalTramerQueries',
  getExternalKilometerQueries = 'getExternalKilometerQueries',
}

export const enum TramerQueryType {
  Damage = 2,
  ChangedPart = 4,
  DetailDamage = 6,
}

export const TramerQueryTypeLabel = new Map<number, string>([
  [TramerQueryType.Damage, 'Hasar Sorgulama'],
  [TramerQueryType.ChangedPart, 'Değişen Parça Sorgulama'],
  [TramerQueryType.DetailDamage, 'Araç Detay Sorgulama'],
]);

export interface TramerDamageResponseData {
  damageDateKeyValue: string;
  queryTypeId: number;
  url: string;
  customerId: number;
  id: number;
  customer?: any;
  damageDateKeys: string;
}

export interface TramerDamageDateKeyResponse {
  OrderId: number;
  Value: string;
}

export interface TramerDamageDateKey {
  orderId: number;
  value: string;
}

export interface TramerDamageData {
  url: string;
  damageDateKeys: TramerDamageDateKey[];
}

export interface TramerDamageResponse {
  tramerResult: TramerDamageResponseData;
}

export interface KmResultResponseData {
  chassis: string;
  imageUrl: string;
}

export interface InquiryTramerData {
  id: number;
  url: string;
  queryTypeId: number;
  createdAt: string;
  chassisNumber?: string;
}

export interface InquiryKmData {
  chassis: string;
  createdAt: string;
  imageUrl: string;
}
