import { Api } from 'api';
import { ChartData } from 'chart.js';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { Chart } from 'react-chartjs-2';
import { useMutation, useQuery } from 'react-query';
import {
  AdminSaleReportGraphData,
  ApiResponse,
  ExpertisePoint,
  ReportingQueryCacheKey,
  SaleReportAdminResponse,
  SaleReportResponse,
} from 'types';
import {
  StatGroup,
  Center,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useToast,
  Button,
  Icon,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { hideLoading, showLoading } from 'features';
import { downloadBlobAsFile } from 'core';
import { DownloadIcon } from '@chakra-ui/icons';

export interface AdminReportSalesProps {
  beginDate?: Date;
  endDate?: Date;
  expertisePoint?: ExpertisePoint;
  externalPackageGroupSource?: number | null;
  onlyDiscountedSales?: boolean;
}

type SaleReportChartData = ChartData<'line' | 'bar', number[], string>;
type SaleReportQueryData = {
  graphData: SaleReportChartData;
  isEmpty: boolean;
  totalSalesAmount: number;
  totalRoyaltyAmount?: number;
  totalSalesCount: number;
};
export const AdminReportSales: React.FC<AdminReportSalesProps> = ({
  beginDate,
  endDate,
  expertisePoint,
  externalPackageGroupSource,
  onlyDiscountedSales,
}) => {
  const authUserInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const toast = useToast();
  const dispatch = useDispatch();

  const { data: reportData, isLoading } = useQuery(
    [
      ReportingQueryCacheKey.GetSalesReport,
      beginDate?.getTime(),
      endDate?.getTime(),
      expertisePoint?.id,
      externalPackageGroupSource,
      onlyDiscountedSales,
    ],
    async (): Promise<SaleReportQueryData | null> => {
      if (beginDate && endDate) {
        const response: ApiResponse<SaleReportAdminResponse | SaleReportResponse> = authUserInfo?.isSuperAdmin
          ? await Api.Reporting.getSalesReportAdmin(
              beginDate.getTime(),
              endDate.getTime(),
              expertisePoint?.id,
              externalPackageGroupSource,
              onlyDiscountedSales,
            )
          : await Api.Reporting.getSalesReport(
              beginDate.getTime(),
              endDate.getTime(),
              externalPackageGroupSource,
              onlyDiscountedSales,
            );
        const saleData = response.data;
        const graphData: AdminSaleReportGraphData = {
          dateLabels: [],
          saleReportLineData: [],
          countReportBarData: [],
        };
        saleData.sales.forEach((sale) => {
          if (sale.count !== '0') {
            const date = new Date(sale.date);
            graphData.dateLabels.push(
              `${date.getDate()} ${date.toLocaleString(window.navigator.language, { month: 'long' })}`,
            );
            const saleAmount = parseInt(sale.amount, 10);
            graphData.saleReportLineData.push(saleAmount);
            graphData.countReportBarData.push(parseInt(sale.count, 10));
          }
        });
        return {
          isEmpty: graphData.dateLabels.length === 0,
          graphData: {
            labels: graphData.dateLabels,
            datasets: [
              {
                type: 'line' as const,
                label: 'Satış Adeti',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: graphData.countReportBarData,
                yAxisID: 'CountYAxis',
              },
              {
                type: 'bar' as const,
                label: 'Ciro',
                backgroundColor: 'rgb(75, 192, 192)',
                data: graphData.saleReportLineData,
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'SalexYAxis',
              },
            ],
          },
          totalSalesAmount: saleData.totalSalesAmount,
          totalSalesCount: saleData.totalSalesCount,
          totalRoyaltyAmount: 'royaltiAmount' in saleData ? saleData.royaltiAmount : undefined,
        };
      }
      return null;
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!(beginDate && endDate),
    },
  );

  const getExportSalesReport = useMutation<any, any>(
    async () => {
      dispatch(showLoading());
      if (beginDate && endDate) {
        const response = authUserInfo?.isSuperAdmin
          ? await Api.Reporting.exportSalesReportAdmin(
              beginDate?.getTime(),
              endDate.getTime(),
              expertisePoint?.id,
              externalPackageGroupSource,
              onlyDiscountedSales,
            )
          : await Api.Reporting.exportSalesReport(beginDate?.getTime(), endDate.getTime(), externalPackageGroupSource);

        return response;
      }
      return null;
    },
    {
      onSuccess(data) {
        if (data) {
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const fileName = `Satıslar${formattedDate}.xlsx`;
          downloadBlobAsFile(data, fileName);
        } else {
          toast({
            status: 'info',
            title: 'Rapor Bulunamadı',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Rapor İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  if (!reportData || isLoading) {
    return null;
  }

  if (reportData.isEmpty) {
    return (
      <Center>
        <Text fontSize="2xl" mt={2} mb={6}>
          Seçtiğiniz Tarih Aralığında Veri Bulunmuyor!
        </Text>
      </Center>
    );
  }

  return (
    <>
      <Chart
        type="bar"
        data={reportData.graphData}
        options={{
          scales: {
            SalexYAxis: {
              type: 'linear',
              position: 'left',
            },
            CountYAxis: {
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
              },
              beginAtZero: true,
            },
          },
        }}
      />
      {beginDate && endDate && reportData && reportData.totalSalesAmount && (
        <StatGroup p={4}>
          <Stat>
            <StatLabel>Total Ciro</StatLabel>
            <StatNumber>{reportData.totalSalesAmount} ₺</StatNumber>
            <StatHelpText>
              {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
            </StatHelpText>
          </Stat>
          {authUserInfo?.isSuperAdmin && !!reportData.totalRoyaltyAmount && (
            <Stat>
              <StatLabel>Royalti Bedeli</StatLabel>
              <StatNumber>{Math.floor(reportData.totalRoyaltyAmount)} ₺</StatNumber>
              <StatHelpText>
                {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
              </StatHelpText>
            </Stat>
          )}
          <Stat>
            <StatLabel>Toplam Satış Adeti</StatLabel>
            <StatNumber>{reportData.totalSalesCount} Adet</StatNumber>
            <StatHelpText>
              {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
            </StatHelpText>
          </Stat>

          <Button variant="solid" leftIcon={<Icon as={DownloadIcon} />} onClick={() => getExportSalesReport.mutate()}>
            Excel İndir
          </Button>
        </StatGroup>
      )}
    </>
  );
};
