/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  cssVar,
  Stack,
  Image,
  Text,
  useColorModeValue,
  Heading,
  ResponsiveValue,
  BoxProps,
} from '@chakra-ui/react';
import React from 'react';

export interface CardImageProps {
  src: string;
  imageObjectFixMode?: ResponsiveValue<any>;
}

export const CardImage: React.FC<CardImageProps> = ({ src, imageObjectFixMode }) => {
  return (
    <Box h="210px" bg="gray.100" mt={-6} mx={-6} pos="relative">
      <Image src={src} width="100%" height="100%" objectFit={imageObjectFixMode} />
    </Box>
  );
};

export interface CardHeaderProps {
  title: string;
  subtitle?: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ title, subtitle }) => {
  return (
    <Stack>
      <Heading color={useColorModeValue('gray.700', 'white')} fontSize="2xl" fontFamily="body">
        {title}
      </Heading>
      {subtitle && (
        <Text color="green.500" textTransform="uppercase" fontWeight={800} fontSize="sm" letterSpacing={1.1}>
          {subtitle}
        </Text>
      )}
    </Stack>
  );
};

export interface CardTextProps {
  children: React.ReactNode;
}

export const CardText: React.FC<CardTextProps> = ({ children }) => {
  return <Text color="gray.500">{children}</Text>;
};

export interface CardContentProps extends BoxProps {
  children?: React.ReactNode;
}

export const CardContent: React.FC<CardContentProps> = ({ children, ...boxProps }) => {
  return (
    <Box p={6} {...boxProps}>
      {children}
    </Box>
  );
};

CardContent.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  p: 6,
};

export type CardProps = BoxProps;

export const Card: React.FC<CardProps> = ({ children, ...boxProps }) => {
  const boxShadowCssVar = cssVar('chakra-shadow-base');
  return (
    <Box
      maxW="445px"
      w="full"
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={boxShadowCssVar}
      rounded="md"
      overflow="hidden"
      {...boxProps}
    >
      {children}
    </Box>
  );
};
