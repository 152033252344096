import React from 'react';
import { AdminInhouseCodeManagementDataTable, AdminPageTitle } from '../components';

export const AdminInhouseCodeManagementPage: React.FC = () => {
  return (
    <>
      <AdminPageTitle title="Koçan Yönetimi" />
      <AdminInhouseCodeManagementDataTable />
    </>
  );
};
