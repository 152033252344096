import { SimpleGrid, useToast } from '@chakra-ui/react';
import { Api } from 'api';
import { CitySelect, CountySelect } from 'components';
import { FormControlFormInput, FormControlFormPhoneInput, FORM_SELECT_SIMPLE_GRID_LAYOUT, GenericMessages } from 'core';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { ExpertisePoint, SaveExpertisePointRequest } from 'types';
import * as yup from 'yup';

const validationSchema = yup.object({
  email: yup.string().required(GenericMessages.CannotBeEmpty).email(GenericMessages.InvalidEmail),
  name: yup.string().required(GenericMessages.CannotBeEmpty),
  officialName: yup.string().required(GenericMessages.CannotBeEmpty),
  address: yup.string().required(GenericMessages.CannotBeEmpty),
  phoneNumber: yup.number().required(GenericMessages.CannotBeEmpty),
  cityId: yup.number().required(GenericMessages.CannotBeEmpty),
  countyId: yup.number().required(GenericMessages.CannotBeEmpty),
  tseCode: yup.string().required(GenericMessages.CannotBeEmpty),
  taxNo: yup.string().required(GenericMessages.CannotBeEmpty),
  taxOfficeName: yup.string().required(GenericMessages.CannotBeEmpty),
  iban: yup.string().required(GenericMessages.CannotBeEmpty),
  commissionRate: yup.number().required(GenericMessages.CannotBeEmpty),
  mersisNo: yup.string().required(GenericMessages.CannotBeEmpty),
  companyTitle: yup.string().required(GenericMessages.CannotBeEmpty),
});

export interface AdminExpertisePointFormProps {
  formId: string;
  expertisePoint?: ExpertisePoint;
  onSubmitStart: () => void;
  onSubmitComplete: () => void;
}
export const AdminExpertisePointForm: React.FC<AdminExpertisePointFormProps> = ({
  formId,
  expertisePoint,
  onSubmitStart,
  onSubmitComplete,
}) => {
  const toast = useToast();
  const mutation = useMutation<any, any, SaveExpertisePointRequest>(
    async (data: SaveExpertisePointRequest) => {
      onSubmitStart();
      await Api.ExpertisePoint.saveExpertisePoint(data);
    },
    {
      onSuccess: () => {
        toast({
          title: 'Bayi başarı ile kayıt edildi',
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Bayi kayıt edilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: () => {
        onSubmitComplete();
      },
    },
  );
  const { values, handleChange, handleSubmit, errors } = useFormik({
    validationSchema,
    initialValues: {
      email: expertisePoint?.email || '',
      name: expertisePoint?.name || '',
      officialName: expertisePoint?.officialName || '',
      address: expertisePoint?.address || '',
      phoneNumber: expertisePoint?.mobilePhoneNumber ? expertisePoint?.mobilePhoneNumber.toString() : '',
      cityId: expertisePoint?.cityId ? expertisePoint?.cityId.toString() : '',
      countyId: expertisePoint?.countyId ? expertisePoint?.countyId.toString() : '',
      tseCode: expertisePoint?.tseCode || '',
      taxNo: expertisePoint?.taxNo || '',
      taxOfficeName: expertisePoint?.taxOfficeName || '',
      iban: expertisePoint?.iban || '',
      commissionRate: expertisePoint?.commissionRate || '',
      mersisNo: expertisePoint?.mersisNo || '',
      companyTitle: expertisePoint?.companyTitle || '',
    },
    onSubmit: (formData) => {
      mutation.mutate({
        id: expertisePoint?.id,
        email: formData.email,
        name: formData.name,
        officialName: formData.officialName,
        address: formData.address,
        phoneNumber: parseInt(formData.phoneNumber, 10),
        cityId: parseInt(formData.cityId, 10),
        countyId: parseInt(formData.countyId, 10),
        tseCode: formData.tseCode,
        taxNo: formData.taxNo,
        taxOfficeName: formData.taxOfficeName,
        iban: formData.iban,
        commissionRate:
          typeof formData.commissionRate === 'number' ? formData.commissionRate : parseInt(formData.commissionRate, 10),
        mersisNo: formData.mersisNo,
        companyTitle: formData.companyTitle,
      });
      // empty
    },
  });

  // const officeRecordDocumentRef = useRef<HTMLInputElement>(null);
  // const tradeOfficeDocumentRef = useRef<HTMLInputElement>(null);
  return (
    <form noValidate onSubmit={handleSubmit} id={formId}>
      <SimpleGrid spacing="30px" columns={FORM_SELECT_SIMPLE_GRID_LAYOUT}>
        <FormControlFormInput
          name="name"
          onChange={handleChange}
          value={values.name}
          label="Bayi Adı"
          helperText={errors.name}
          error={errors.name != null}
        />
        <FormControlFormInput
          name="email"
          type="email"
          onChange={handleChange}
          value={values.email}
          label="Email"
          helperText={errors.email}
          error={errors.email != null}
        />
        <FormControlFormInput
          name="officialName"
          onChange={handleChange}
          value={values.officialName}
          label="Yetkili Adı"
          helperText={errors.officialName}
          error={errors.officialName != null}
        />
        <FormControlFormInput
          name="address"
          onChange={handleChange}
          value={values.address}
          label="Adres"
          helperText={errors.address}
          error={errors.address != null}
        />
        <FormControlFormPhoneInput
          name="phoneNumber"
          onChange={handleChange}
          value={values.phoneNumber}
          label="Telefon"
          helperText={errors.phoneNumber}
          error={errors.phoneNumber != null}
        />
        <CitySelect
          onChange={handleChange}
          value={values.cityId}
          name="cityId"
          helperText={errors.cityId}
          error={errors.cityId != null}
        />
        <CountySelect
          onChange={handleChange}
          value={values.countyId}
          name="countyId"
          cityId={values.cityId ? parseInt(values.cityId, 10) : undefined}
          helperText={errors.countyId}
          error={errors.countyId != null}
        />
        <FormControlFormInput
          name="tseCode"
          onChange={handleChange}
          value={values.tseCode}
          label="TSE Kodu"
          helperText={errors.tseCode}
          error={errors.tseCode != null}
        />
        <FormControlFormInput
          name="taxNo"
          onChange={handleChange}
          value={values.taxNo}
          label="Vergi No"
          helperText={errors.taxNo}
          error={errors.taxNo != null}
        />
        <FormControlFormInput
          name="taxOfficeName"
          onChange={handleChange}
          value={values.taxOfficeName}
          label="Vergi Dairesi"
          helperText={errors.taxOfficeName}
          error={errors.taxOfficeName != null}
        />
        <FormControlFormInput
          name="iban"
          onChange={handleChange}
          value={values.iban}
          label="IBAN"
          helperText={errors.iban}
          error={errors.iban != null}
        />
        <FormControlFormInput
          type="number"
          name="commissionRate"
          onChange={handleChange}
          value={values.commissionRate}
          label="Komisyon Oranı"
          helperText={errors.commissionRate}
          error={errors.commissionRate != null}
        />
        <FormControlFormInput
          type="number"
          name="mersisNo"
          onChange={handleChange}
          value={values.mersisNo}
          label="Mersis No"
          helperText={errors.mersisNo}
          error={errors.mersisNo != null}
        />
        <FormControlFormInput
          name="companyTitle"
          onChange={handleChange}
          value={values.companyTitle}
          label="Firma Ünvanı"
          helperText={errors.companyTitle}
          error={errors.companyTitle != null}
        />

        {/* <input
          name="officeRecordDocument"
          type="file"
          accept="application/pdf"
          hidden
          onChange={(e) => {
            console.warn(e.target.files);

            if (e.target.files && e.target.files.length === 1) {
              setFieldValue('officeRecordDocument', e.target.files[0]);
            }
          }}
          ref={officeRecordDocumentRef}
        />
        <input
          name="tradeOfficeDocument"
          type="file"
          accept="application/pdf"
          hidden
          onChange={(e) => {
            console.warn(e.target.files);
            if (e.target.files && e.target.files.length === 1) {
              setFieldValue('tradeOfficeDocument', e.target.files[0]);
            }
          }}
          ref={tradeOfficeDocumentRef}
        />
        <Box />
        <FormControl isInvalid={errors.tradeOfficeDocument != null}>
          <Button
            colorScheme="blue"
            variant="solid"
            leftIcon={<AddIcon />}
            onClick={() => {
              if (officeRecordDocumentRef.current) {
                officeRecordDocumentRef.current.click();
              }
            }}
          >
            Oda Kayıt Belgesi(Meslek / Ticaret) Yükle
          </Button>
          {errors.officeRecordDocument && <FormErrorMessage>{errors.officeRecordDocument}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={errors.tradeOfficeDocument != null}>
          <Button
            w="100%"
            colorScheme="blue"
            variant="solid"
            leftIcon={<AddIcon />}
            onClick={() => {
              if (tradeOfficeDocumentRef.current) {
                tradeOfficeDocumentRef.current.click();
              }
            }}
          >
            Ticaret Sicil Gazetesi Yükle
          </Button>
          {errors.tradeOfficeDocument && <FormErrorMessage>{errors.tradeOfficeDocument}</FormErrorMessage>}
        </FormControl> */}
      </SimpleGrid>
    </form>
  );
};
