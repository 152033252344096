import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authReducer, customerReducer, coreReducer, expertiseReportReducer } from 'features';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    core: coreReducer,
    expertiseReport: expertiseReportReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
