/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AuthUserInfo } from 'types';

export interface AuthState {
  authUserInfo?: AuthUserInfo;
  isAuthInitDone: boolean;
}

const initialState: AuthState = {
  isAuthInitDone: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUserInfo: (state, action: PayloadAction<AuthUserInfo>) => {
      state.authUserInfo = action.payload;
    },
    logout: (state) => {
      state.authUserInfo = undefined;
    },
    setIsAuthInitDone: (state, action: PayloadAction<boolean>) => {
      state.isAuthInitDone = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthUserInfo, logout, setIsAuthInitDone } = authSlice.actions;

export const { reducer: authReducer } = authSlice;
