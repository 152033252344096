import React from 'react';
import { LoadingIndicator } from 'components';
import { useFetchAdminManagementUsers } from 'core';
import { AdminExpertisePointUserManagementDataTable, AdminPageTitle } from '../components';

export const AdminUserManagementPage: React.FC = () => {
  const { data: users, isLoading, refetch: refetchUsers } = useFetchAdminManagementUsers();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AdminPageTitle title="Kullanıcı Yönetimi" />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <AdminExpertisePointUserManagementDataTable users={users || []} refetchUsers={refetchUsers} />
      )}
    </>
  );
};
