import {
  ApiBase,
  ApiResponse,
  TramerQueryType,
  TramerDamageResponse,
  TramerDamageResponseData,
  KmResultResponseData,
  InquiryKmData,
  InquiryTramerData,
} from 'types';

export class IntegrationApi extends ApiBase {
  private readonly baseIntegrationUrl = 'integration';

  public async getTramerDamage(customerId: number): Promise<ApiResponse<TramerDamageResponse>> {
    const response = await this.apiClient.post(
      `${this.baseIntegrationUrl}/get-tramer-query`,
      {
        customerId,
        queryTypeId: TramerQueryType.Damage,
      },
      {
        timeout: 20000,
      },
    );
    return response.data;
  }

  public async getOldTramerQueries(
    customerId: number,
    queryTypeId: TramerQueryType,
  ): Promise<ApiResponse<TramerDamageResponseData[]>> {
    const response = await this.apiClient.post(
      `${this.baseIntegrationUrl}/get-old-tramer-queries`,
      {
        customerId,
        queryTypeId,
      },
      {
        timeout: 20000,
      },
    );
    return response.data;
  }

  public async getTramerChangedPart(
    customerId: number,
    damageDateKeyValue: string,
  ): Promise<ApiResponse<TramerDamageResponse>> {
    const response = await this.apiClient.post(`${this.baseIntegrationUrl}/get-tramer-query`, {
      customerId,
      queryTypeId: TramerQueryType.ChangedPart,
      damageDateKeyValue,
    });
    return response.data;
  }

  public async getKilometerResult(customerId: number): Promise<ApiResponse<KmResultResponseData>> {
    const response = await this.apiClient.get(`${this.baseIntegrationUrl}/get-kilometer-result-image/${customerId}`);
    return response.data;
  }

  public async getKilometerResultImageByChassis(chassisNumber: string): Promise<ApiResponse<KmResultResponseData>> {
    const response = await this.apiClient.get(
      `${this.baseIntegrationUrl}/get-kilometer-result-image-by-chassis/${chassisNumber}`,
    );
    return response.data;
  }

  public async getTramerResultsByChassis(chassisNumber: string): Promise<ApiResponse<TramerDamageResponse>> {
    const response = await this.apiClient.post(
      `${this.baseIntegrationUrl}/get-tramer-query`,
      {
        chassisNumber,
        queryTypeId: TramerQueryType.Damage,
      },
      {
        timeout: 20000,
      },
    );
    return response.data;
  }

  public async getExternalKilometerQueries(): Promise<ApiResponse<InquiryKmData[]>> {
    const response = await this.apiClient.get(`${this.baseIntegrationUrl}/get-external-kilometer-queries`);
    return response.data;
  }

  public async getExternalTramerQueries(): Promise<ApiResponse<InquiryTramerData[]>> {
    const response = await this.apiClient.get(`${this.baseIntegrationUrl}/get-external-tramer-queries`);
    return response.data;
  }
}
