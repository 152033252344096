import React, { useRef } from 'react';
import { EditIcon, DeleteIcon, CheckIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import {
  useToast,
  useDisclosure,
  Flex,
  IconButton,
  Portal,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Checkbox,
  ModalFooter,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Tooltip,
} from '@chakra-ui/react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable } from 'components';
import { GenericMessages, getErrorMessage, FormControlFormInput } from 'core';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { UpdateUserRequest, RegisterUserRequest } from 'types';
import * as yup from 'yup';
import { AdminUserManagementUser } from '../admin.types';

const userRegisterFormValidation = yup.object({
  name: yup.string().required(GenericMessages.CannotBeEmpty),
  surname: yup.string().required(GenericMessages.CannotBeEmpty),
  email: yup.string().email(GenericMessages.InvalidEmail).required(GenericMessages.CannotBeEmpty),
  password: yup.string().required(GenericMessages.CannotBeEmpty),
  isAdmin: yup.bool(),
});

const userEditFormValidation = yup.object({
  name: yup.string().required(GenericMessages.CannotBeEmpty),
  surname: yup.string().required(GenericMessages.CannotBeEmpty),
  email: yup.string().email(GenericMessages.InvalidEmail).required(GenericMessages.CannotBeEmpty),
  password: yup.string(),
  isAdmin: yup.bool(),
});

type RowActionProps = {
  row: Row<AdminUserManagementUser>;
  refetchUsers: () => void;
};

type RegisterUserFormData = {
  name: string;
  surname: string;
  email: string;
  isAdmin: boolean;
  password: string;
};

type UpdateUserFormData = {
  name: string;
  surname: string;
  email: string;
  password: string;
  isAdmin: boolean;
};

const RowAction: React.FC<RowActionProps> = ({ row, refetchUsers }) => {
  const toast = useToast();
  const { id, name, surname, email, isAdmin } = row.original;
  const deleteUserCancelButtonRef = useRef<HTMLButtonElement>(null);

  const deleteUserMutation = useMutation<any, any, number>(
    async (userId: number) => {
      await Api.User.removeUser(userId);
    },
    {
      onSuccess() {
        // eslint-disable-next-line no-use-before-define
        onUserDeleteConfirmClose();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Kullanıcı başarı ile silindi',
        });
        refetchUsers();
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcı silerken hata olustu'),
        });
      },
    },
  );

  const { handleSubmit, values, errors, handleChange, setFieldValue } = useFormik({
    validationSchema: userEditFormValidation,
    initialValues: {
      name,
      surname,
      email,
      password: '',
      isAdmin,
    },
    onSubmit: (data: UpdateUserFormData) => {
      // eslint-disable-next-line no-use-before-define
      editUserMutation.mutate({
        id,
        name: data.name,
        surname: data.surname,
        email: data.email,
        password: data.password,
        isAdmin: data.isAdmin,
      });
    },
  });

  const formId = `adminUserEditForm${id}`;

  const { onOpen: onUserEditFormOpen, onClose: onUserEditFormClose, isOpen: isUserEditFormOpen } = useDisclosure();

  const {
    onOpen: onUserDeleteConfirmOpen,
    onClose: onUserDeleteConfirmClose,
    isOpen: isUserDeleteFormOpen,
  } = useDisclosure();

  const onDeleteRemoveConfirm = () => {
    deleteUserMutation.mutate(id);
  };

  const editUserMutation = useMutation<void, any, UpdateUserRequest>(
    async (request: UpdateUserRequest) => {
      await Api.User.updateUser(request);
    },
    {
      onSuccess() {
        onUserEditFormClose();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Kullanıcı başarı ile düzenlendi',
        });
        refetchUsers();
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcı kayıt sırasında hata oluştu'),
        });
      },
    },
  );

  return (
    <Flex gap={4}>
      <Tooltip label="Düzenle">
        <IconButton aria-label="Düzenle" icon={<EditIcon />} onClick={onUserEditFormOpen} />
      </Tooltip>
      <Tooltip label="Sil">
        <IconButton aria-label="Sil" icon={<DeleteIcon />} onClick={onUserDeleteConfirmOpen} />
      </Tooltip>
      <Portal>
        {/** #region Kullanıcıyı Düzenle */}
        <Modal isOpen={isUserEditFormOpen} onClose={onUserEditFormClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Kullanıcıyı Düzenle</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleSubmit} noValidate id={formId}>
                <VStack align="start" gap={2}>
                  <FormControlFormInput
                    label="Ad"
                    placeholder="Ad"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                  <FormControlFormInput
                    label="Soyadı"
                    placeholder="Soyadı"
                    name="surname"
                    onChange={handleChange}
                    value={values.surname}
                    error={errors.surname != null}
                    helperText={errors.surname}
                  />
                  <FormControlFormInput
                    label="E-Posta Adresi"
                    placeholder="E-Posta Adresi"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email != null}
                    helperText={errors.email}
                    type="email"
                  />
                  <FormControlFormInput
                    label="Parola"
                    placeholder="Parola"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    error={errors.password != null}
                    helperText={errors.password}
                    type="password"
                  />
                  <Checkbox
                    colorScheme="blue"
                    name="isAdmin"
                    onChange={(event) => {
                      setFieldValue(event.target.name, event.target.checked);
                    }}
                    isChecked={values.isAdmin}
                  >
                    Yönetici Mi?
                  </Checkbox>
                </VStack>
              </form>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onUserEditFormClose}>
                İptal
              </Button>
              <Button colorScheme="blue" form={formId} type="submit">
                Kaydet
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/** #endregion */}

        {/** #region Kullanici Sil */}
        <AlertDialog
          onClose={onUserDeleteConfirmClose}
          isOpen={isUserDeleteFormOpen}
          leastDestructiveRef={deleteUserCancelButtonRef}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Kullanıcı Sil
              </AlertDialogHeader>

              <AlertDialogBody>Kullanıcı Silmek İstediğinize Emin Misiniz?</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={deleteUserCancelButtonRef} onClick={onUserDeleteConfirmClose}>
                  İptal
                </Button>
                <Button colorScheme="red" onClick={onDeleteRemoveConfirm} ml={3}>
                  Sil
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Portal>
      {/** #endregion */}
    </Flex>
  );
};

export interface AdminExpertisePointUserManagementDataTableProps {
  users: AdminUserManagementUser[];
  refetchUsers: () => void;
  expertisePointId?: number;
}
export const AdminExpertisePointUserManagementDataTable: React.FC<AdminExpertisePointUserManagementDataTableProps> = ({
  users,
  refetchUsers,
  expertisePointId,
}) => {
  const toast = useToast();

  const registerUserMutation = useMutation<void, any, RegisterUserRequest>(
    async (request: RegisterUserRequest) => {
      await Api.User.registerUser(request);
    },
    {
      onSuccess() {
        // eslint-disable-next-line no-use-before-define
        onCreateUserDialogClose();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Kullanıcı başarı ile oluşturuldu',
        });
        refetchUsers();
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcı kayıt sırasında hata oluştu'),
        });
      },
    },
  );

  const columnHelper = createColumnHelper<AdminUserManagementUser>();

  const columns = [
    columnHelper.accessor('id', {
      header: 'Id',
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor((row) => `${row.name} ${row.surname}`, {
      id: 'Ad',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor('isAdmin', {
      header: 'Yönetici Mi?',
      cell: (info) => {
        return info.getValue() ? <CheckIcon /> : <CloseIcon />;
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Aksiyonlar',
      cell: (cellProps) => <RowAction row={cellProps.row} refetchUsers={refetchUsers} />,
    }),
  ];

  const { handleSubmit, values, errors, handleChange, resetForm, setFieldValue } = useFormik({
    validationSchema: userRegisterFormValidation,
    initialValues: {
      name: '',
      surname: '',
      email: '',
      isAdmin: false,
      password: '',
    },
    validateOnChange: false,
    onSubmit: (userFormData: RegisterUserFormData) => {
      registerUserMutation.mutate({
        expertisePointId,
        name: userFormData.name,
        surname: userFormData.surname,
        email: userFormData.email,
        isAdmin: userFormData.isAdmin,
        password: userFormData.password,
      });
    },
  });

  const formId = `adminUserCreateForm`;

  const {
    onOpen: onCreateUserDialogOpen,
    onClose: onCreateUserDialogClose,
    isOpen: isCreateUserDialogOpen,
  } = useDisclosure({
    onClose: () => {
      resetForm();
    },
  });
  return (
    <>
      <DataTable
        data={users || []}
        columns={columns}
        usePagination
        tableProps={{
          variant: 'striped',
          colorScheme: 'teal',
        }}
        title="Kullanıcılar"
        actions={
          <ButtonGroup>
            <Tooltip label="Kullanıcı Ekle">
              <IconButton aria-label="Kullanıcı Ekle" icon={<AddIcon />} onClick={onCreateUserDialogOpen} />
            </Tooltip>
          </ButtonGroup>
        }
      />

      {/** #region Kullanici Olustur */}
      <Modal isOpen={isCreateUserDialogOpen} onClose={onCreateUserDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Kullanıcı Ekle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit} noValidate id={formId}>
              <VStack align="start" gap={2}>
                <FormControlFormInput
                  label="Adı"
                  placeholder="Adı"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  error={errors.name != null}
                  helperText={errors.name}
                />
                <FormControlFormInput
                  label="Soyadı"
                  placeholder="Soyadı"
                  name="surname"
                  onChange={handleChange}
                  value={values.surname}
                  error={errors.surname != null}
                  helperText={errors.surname}
                />
                <FormControlFormInput
                  label="E-Posta Adresi"
                  placeholder="E-Posta Adresi"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  error={errors.email != null}
                  helperText={errors.email}
                  type="email"
                />
                <FormControlFormInput
                  label="Parola"
                  placeholder="Parola"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  error={errors.password != null}
                  helperText={errors.password}
                  type="password"
                />
                <Checkbox
                  colorScheme="blue"
                  name="isAdmin"
                  onChange={(event) => {
                    setFieldValue(event.target.name, event.target.checked);
                  }}
                  isChecked={values.isAdmin}
                >
                  Yönetici Mi?
                </Checkbox>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCreateUserDialogClose}>
              İptal
            </Button>
            <Button colorScheme="blue" type="submit" form={formId}>
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/** #endregion */}
    </>
  );
};
