import React from 'react';
import { Text } from '@chakra-ui/react';

export interface AdminPageTitleProps {
  title: string;
}

export const AdminPageTitle: React.FC<AdminPageTitleProps> = ({ title }) => {
  return (
    <Text fontSize="3xl" mt={2} mb={6}>
      {title}
    </Text>
  );
};
