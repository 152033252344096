import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  IconButton,
  ButtonGroup,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Button,
  useDisclosure,
  UnorderedList,
  ListItem,
  useBoolean,
  useToast,
  Center,
  Text,
} from '@chakra-ui/react';
import { Api } from 'api';
import { LoadingIndicator } from 'components';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ExpertisePackageGroup, ExpertiseQueryCacheKey } from 'types';
import { AdminPageTitle, AdminPriceListForm } from '../components';

export const AdminPriceListPage: React.FC = () => {
  const toast = useToast();

  const {
    isOpen: isCreatePriceListDialogOpen,
    onOpen: onCreatePriceListDialogOpen,
    onClose: onCreatePriceListDialogClose,
  } = useDisclosure();
  const {
    isOpen: isEditPriceListDialogOpen,
    onOpen: onEditPriceListDialogOpen,
    onClose: onEditPriceListDialogClose,
  } = useDisclosure();

  const query = useQuery(
    ExpertiseQueryCacheKey.GetAllPackageGroups,
    async () => {
      const response = await Api.Expertise.getAllPackageGroups();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  const deleteMutation = useMutation<void, void, number>(
    async (expertisePackageGroupId: number) => {
      await Api.Expertise.deletePackageGroup(expertisePackageGroupId);
    },
    {
      onSuccess: () => {
        toast({
          title: 'Fiyat Listesi başarı ile silindi',
          isClosable: false,
          status: 'success',
        });
        query.refetch();
      },
      onError: () => {
        toast({
          title: 'Fiyat Listesi silinirken hata oluştu',
          isClosable: false,
          status: 'error',
        });
      },
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focusedPriceList, setFocusedPriceList] = useState<ExpertisePackageGroup | undefined>();

  const [isModalLoading, { on: setIsModalLoadingOn, off: setIsModalLoadingOff }] = useBoolean();

  return (
    <>
      <Modal isOpen={isEditPriceListDialogOpen} onClose={onEditPriceListDialogClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Düzenle</ModalHeader>
          <ModalCloseButton disabled={isModalLoading} />
          <ModalBody>
            <AdminPriceListForm
              formId="aplfForm"
              group={focusedPriceList}
              onSubmitStart={setIsModalLoadingOn}
              onSubmitComplete={() => {
                onEditPriceListDialogClose();
                setIsModalLoadingOff();
                query.refetch();
              }}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onEditPriceListDialogClose} disabled={isModalLoading}>
                Kapat
              </Button>
              <Button colorScheme="green" form="aplfForm" type="submit" isLoading={isModalLoading}>
                Kaydet
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isCreatePriceListDialogOpen} onClose={onCreatePriceListDialogClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Oluştur</ModalHeader>
          <ModalCloseButton disabled={isModalLoading} />
          <ModalBody>
            <AdminPriceListForm
              formId="aplfForm"
              onSubmitStart={setIsModalLoadingOn}
              onSubmitComplete={() => {
                onCreatePriceListDialogClose();
                setIsModalLoadingOff();
                query.refetch();
              }}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={onCreatePriceListDialogClose} disabled={isModalLoading}>
                Kapat
              </Button>
              <Button colorScheme="green" form="aplfForm" type="submit" isLoading={isModalLoading}>
                Kaydet
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AdminPageTitle title="Fiyat Listesi" />
      <ButtonGroup justifyContent="flex-end" width="100%" my={2}>
        <Button colorScheme="green" leftIcon={<AddIcon />} onClick={onCreatePriceListDialogOpen}>
          Ekle
        </Button>
      </ButtonGroup>
      {query.isLoading || deleteMutation.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {!query.isLoading && query.data?.length === 0 && (
            <Center>
              <Text>Sonuç Yok</Text>
            </Center>
          )}
          <Accordion allowMultiple>
            {query.data &&
              query.data.map((expertisePackageGroup) => {
                return (
                  <AccordionItem key={`plai${expertisePackageGroup.id}`}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {expertisePackageGroup.name}
                          <ButtonGroup ml={2}>
                            <IconButton
                              aria-label="düzenle"
                              onClick={(event) => {
                                event.stopPropagation();
                                setFocusedPriceList(expertisePackageGroup);
                                onEditPriceListDialogOpen();
                              }}
                              icon={<EditIcon />}
                              colorScheme="blue"
                            />
                            <IconButton
                              aria-label="Sil"
                              onClick={(event) => {
                                event.stopPropagation();
                                deleteMutation.mutate(expertisePackageGroup.id);
                              }}
                              icon={<DeleteIcon />}
                              colorScheme="red"
                            />
                          </ButtonGroup>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <UnorderedList>
                        {expertisePackageGroup.items.map((item) => (
                          <ListItem key={`plaioi${item.id}`}>
                            {item.expertisePackageName} - {item.price} ₺
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
          </Accordion>
        </>
      )}
    </>
  );
};
