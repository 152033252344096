import React from 'react';
import { AddIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalFooter,
  Button,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable, ExpertisePointSelect, LoadingIndicator } from 'components';
import {
  // GenericMessages,
  getErrorMessage,
  FormControlFormInput,
  FormControlFormSelect,
  useFetchInhouseCodeExpertisePackages,
  downloadBlobAsFile,
} from 'core';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { CreateInhouseCodeRequest, ExpertiseQueryCacheKey, ExternalPackageCodeStatus, InhouseCode } from 'types';
import * as yup from 'yup';
import { hideLoading, showLoading } from 'features';
import { useDispatch } from 'react-redux';

const inhouseCodeCreateFormValidation = yup.object({
  expertisePackageGroupItemId: yup.number().required('Paket seçiniz'),
  expertisePointId: yup.number().required('Bayi seçiniz'),
  price: yup.string().required('Fiyat giriniz'),
  quantity: yup.string().required('Adet giriniz'),
});

type CreateInhouseCodeFormData = {
  expertisePointId: number;
  expertisePackageGroupItemId: number;
  expertisePackageGroupSource: number;
  price: string;
  quantity: string;
};

export const AdminInhouseCodeManagementDataTable: React.FC = () => {
  const [selectedStatus, setSelectedStatus] = React.useState<number | null>(null);
  const [expertisePointId, setExpertisePointId] = React.useState<number | null>(null);
  const [expertisePackageGroupItemId, setExpertisePackageGroupItemId] = React.useState<number | null>(null);

  const toast = useToast();
  const dispatch = useDispatch();
  const expertisePackagesQuery = useFetchInhouseCodeExpertisePackages();

  const {
    isLoading,
    data: codes,
    refetch: refetchCodes,
  } = useQuery(
    [ExpertiseQueryCacheKey.GetInhouseCodes, selectedStatus, expertisePointId, expertisePackageGroupItemId],
    async () => {
      const response = await Api.Expertise.getInhouseCodes(
        selectedStatus,
        expertisePointId,
        expertisePackageGroupItemId,
      );
      return response.data?.codes || [];
    },
    {
      onError(error: Error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcıları çekilirken hata oluştu'),
        });
      },
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const createInhouseCodeMutation = useMutation<void, any, CreateInhouseCodeRequest>(
    async (request: CreateInhouseCodeRequest) => {
      await Api.Expertise.createInhouseCode(request);
    },
    {
      onSuccess() {
        // eslint-disable-next-line no-use-before-define
        onCreateInhouseCodeDialogClose();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Koçan başarı ile oluşturuldu',
        });
        refetchCodes();
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Koçan oluşturma sırasında hata oluştu'),
        });
      },
    },
  );

  const getExportInhouseCodesQueriesReport = useMutation<any, any>(
    async () => {
      dispatch(showLoading());
      const response = await Api.Expertise.exportInhouseReport(
        selectedStatus,
        expertisePointId,
        expertisePackageGroupItemId,
      );

      return response;
    },
    {
      onSuccess(data) {
        if (data) {
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const fileName = `Sorgulamalar${formattedDate}.xlsx`;
          downloadBlobAsFile(data, fileName);
        } else {
          toast({
            status: 'info',
            title: 'Rapor Bulunamadı',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Rapor İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  const columnHelper = createColumnHelper<InhouseCode>();

  const columns = [
    columnHelper.accessor('expertisePointName', {
      header: 'Bayi Adı',
      cell: (info) => {
        return info.getValue() || '-';
      },
    }),

    columnHelper.accessor('expertisePackageName', {
      header: 'Paket Adı',
      cell: (info) => {
        return info.getValue() || '-';
      },
    }),

    columnHelper.accessor('price', {
      header: 'Fiyat',
      cell: (info) => `₺${info.getValue()}`,
      meta: {
        isNumeric: true,
      },
    }),

    columnHelper.accessor('code', {
      header: 'Kod',
      cell: (info) => {
        return info.getValue();
      },
    }),

    columnHelper.accessor('statusStr', {
      header: 'Kullanım Durumu',
      cell: (info) => {
        return info.getValue();
      },
    }),

    columnHelper.accessor('endDate', {
      header: 'Geçerlilik Tarihi',
      cell: (info) => new Date(info.getValue()).toLocaleString('tr-TR'),
    }),
    // columnHelper.display({
    //   id: 'actions',
    //   header: 'Aksiyonlar',
    //   cell: (cellProps) => <RowAction row={cellProps.row} refetchUsers={refetchUsers} />,
    // }),
  ];

  const { handleSubmit, values, errors, handleChange, resetForm, setFieldValue } = useFormik({
    validationSchema: inhouseCodeCreateFormValidation,
    initialValues: {
      expertisePointId: 0,
      expertisePackageGroupItemId: 0,
      expertisePackageGroupSource: 20,
      price: '',
      quantity: '',
    },
    validateOnChange: false,
    onSubmit: (userFormData: CreateInhouseCodeFormData) => {
      createInhouseCodeMutation.mutate({
        expertisePointId: userFormData?.expertisePointId,
        expertisePackageGroupItemId: userFormData?.expertisePackageGroupItemId,
        externalPackageGroupSource: 20,
        price: parseInt(userFormData?.price, 10),
        quantity: parseInt(userFormData?.quantity, 10),
      });
    },
  });

  const formId = `adminInhouseCodeCreateForm`;

  const {
    onOpen: onCreateInhouseCodeDialogOpen,
    onClose: onCreateInhouseCodeDialogClose,
    isOpen: isCreateInhouseCodeDialogOpen,
  } = useDisclosure({
    onClose: () => {
      resetForm();
    },
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <DataTable
        data={codes || []}
        columns={columns}
        usePagination
        tableProps={{
          variant: 'striped',
          colorScheme: 'teal',
        }}
        title="Koçanlar"
        actions={
          <Flex alignItems="center" gap={4}>
            <FormControlFormSelect
              label=""
              value={expertisePackageGroupItemId || undefined}
              onChange={(e) => {
                setExpertisePackageGroupItemId(parseInt(e.target.value, 10));
              }}
            >
              <option value={undefined} key="Tümü">
                Tümü
              </option>
              {expertisePackagesQuery &&
                expertisePackagesQuery.data &&
                expertisePackagesQuery.data.map((campaing) => (
                  <option value={campaing.id} key={`aplcs${campaing.id}`}>
                    {campaing.name}
                  </option>
                ))}
            </FormControlFormSelect>

            <ExpertisePointSelect
              onExpertisePointSelected={(expertisePoint) => {
                setExpertisePointId(expertisePoint?.id || null);
              }}
              enableAll
              label=""
              value={expertisePointId || undefined}
            />

            <FormControlFormSelect
              value={selectedStatus || undefined}
              onChange={(e) => {
                setSelectedStatus(parseInt(e.target.value, 10));
              }}
            >
              <option value={undefined}>Tümü</option>
              <option value={ExternalPackageCodeStatus.Used}>Kullanıldı</option>
              <option value={ExternalPackageCodeStatus.NotUsed}>Kullanılmadı</option>
              <option value={ExternalPackageCodeStatus.Expired}>Süresi Geçti</option>
            </FormControlFormSelect>

            <Button width="100%" leftIcon={<AddIcon />} aria-label="Ekle" onClick={onCreateInhouseCodeDialogOpen}>
              Oluştur
            </Button>
          </Flex>
        }
      />

      <Button
        mt={2}
        variant="solid"
        leftIcon={<Icon as={DownloadIcon} />}
        onClick={() => getExportInhouseCodesQueriesReport.mutate()}
      >
        Excel İndir
      </Button>

      {/** #region Koçan Olustur */}
      <Modal isOpen={isCreateInhouseCodeDialogOpen} onClose={onCreateInhouseCodeDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Koçan Oluştur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit} noValidate id={formId}>
              <VStack align="start" gap={2}>
                <ExpertisePointSelect
                  onExpertisePointSelected={(expertisePoint) => {
                    setFieldValue('expertisePointId', expertisePoint?.id);
                  }}
                  error={errors.expertisePointId != null}
                  helperText={errors.expertisePointId != null ? errors.expertisePointId : ''}
                  value={values.expertisePointId}
                  filterActive
                />

                <FormControlFormSelect
                  name="expertisePackageGroupItemId"
                  label="Paket"
                  value={values.expertisePackageGroupItemId}
                  error={errors.expertisePackageGroupItemId != null}
                  helperText={errors.expertisePackageGroupItemId}
                  onChange={handleChange}
                  placeholder="Paket Seç"
                >
                  {expertisePackagesQuery &&
                    expertisePackagesQuery.data &&
                    expertisePackagesQuery.data.map((campaing) => (
                      <option value={campaing.id} key={`aplcs${campaing.id}`}>
                        {campaing.name}
                      </option>
                    ))}
                </FormControlFormSelect>

                <FormControlFormInput
                  name="price"
                  value={values.price}
                  error={errors.price != null}
                  helperText={errors.price}
                  onChange={handleChange}
                  label="Fiyat"
                  type="number"
                />

                <FormControlFormInput
                  name="quantity"
                  value={values.quantity}
                  error={errors.quantity != null}
                  helperText={errors.quantity}
                  onChange={handleChange}
                  label="Adet"
                  type="number"
                />
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCreateInhouseCodeDialogClose}>
              İptal
            </Button>
            <Button colorScheme="blue" type="submit" form={formId}>
              Oluştur
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/** #endregion */}
    </>
  );
};
