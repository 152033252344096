// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ExpertisePage, LoginPage } from 'apps';
import { RootState } from 'store';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Navigate, useLocation, Routes } from 'react-router-dom';
import { AdminPage, AdminPages } from 'apps/admin';
import {
  AdminBalancePage,
  AdminPackageManagementPage,
  AdminPriceListPage,
  AdminReportPage,
  AdminUserManagementPage,
  AdminAnnouncementsPage,
  AdminPanelPage,
  AdminExpertiseDictionaryManagementPage,
  AdminInquiriesPage,
  AdminInhouseCodeManagementPage,
} from 'apps/admin/pages';
import { AdminExpertisePointsManagementPage } from 'apps/admin/pages/AdminExpertisePointsManagementPage';

export const AppRoutes: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const location = useLocation();

  return (
    <Routes>
      {!userInfo ? (
        /* Render auth page when user at `/auth` and not authorized. */
        <Route path="/auth/login" element={<LoginPage />} />
      ) : (
        /* Otherwise redirect to root page (`/`) */
        <Route path="/auth/login" element={<Navigate to="/" state={{ from: location }} replace />} />
      )}

      {!userInfo ? (
        <Route path="*" element={<Navigate to="/auth/login" state={{ from: location }} replace />} />
      ) : (
        <>
          <Route path="/admin" element={<AdminPage />}>
            {userInfo.isSuperAdmin ? (
              <Route index element={<AdminBalancePage />} />
            ) : (
              <Route index element={<AdminPanelPage />} />
            )}
            <Route path={AdminPages.BALANCE} element={<AdminBalancePage />} />
            <Route path={AdminPages.REPORTS} element={<AdminReportPage />} />
            <Route path={AdminPages.USERS} element={<AdminUserManagementPage />} />
            <Route path={AdminPages.ANNOUNCEMENT} element={<AdminAnnouncementsPage />} />
            <Route path={AdminPages.PANEL} element={<AdminPanelPage />} />
            <Route path={AdminPages.INQUIRIES} element={<AdminInquiriesPage />} />
            <Route path={AdminPages.INHOUSE_CODE} element={<AdminInhouseCodeManagementPage />} />

            {userInfo.isSuperAdmin && (
              <>
                <Route path={AdminPages.PRICE_LIST} element={<AdminPriceListPage />} />

                <Route path={AdminPages.PACKAGE_MANAGEMENT} element={<AdminPackageManagementPage />} />

                <Route path={AdminPages.EXPERTISE_POINT_MANAGEMENT} element={<AdminExpertisePointsManagementPage />} />

                <Route path={AdminPages.DICTIONARY} element={<AdminExpertiseDictionaryManagementPage />} />
              </>
            )}
          </Route>
          <Route path="*" element={<ExpertisePage />} />
        </>
      )}
    </Routes>
  );
};
