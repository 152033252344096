import { Customer } from './customer.types';

// #region Enums
export const enum ExpertisePointQueryCacheKey {
  GetPreviousBalanceTransactions = 'GetPreviousBalanceTransactions',
  GetUsers = 'GetUsers',
  GetExpertisePointSpendingHistory = 'GetExpertisePointSpendingHistory',
  GetAllExpertisePoints = 'GetAllExpertisePoints',
  GetPanelInfo = 'GetPanelInfo',
  GetProductPriceList = 'GetProductPriceList',
}

export enum ExpertisePointGroupType {
  OccoExpertise = 1,
  AutoKaleExpertise = 2,
}

export enum ExpertisePointGroupTypeTitle {
  OccoEkspertiz = 1,
  AutoKaleEkspertiz = 2,
}

// #endregion

// #region Types

export interface PaymentTransationStartProcessResponse {
  paymentTransactionId: number;
  token: string;
}

export interface PaymentTransationStartProcessData extends PaymentTransationStartProcessResponse {
  amount: number;
}

export interface TotalBalanceSheetTransactionResponse {
  id: number;
  amount: string;
  balanceAfterTransaction: string;
  isCompleted: boolean;
  transactionDate?: string;
  expertisePointName?: string;
  expertisePointCityName?: string;
  expertisePointCountyName?: string;
  transactionSource?: string;
}
export interface TotalBalanceSheetDataResponse {
  totalBalance: number;
  transactions: TotalBalanceSheetTransactionResponse[];
}

export interface TotalBalanceSheetTransaction {
  id: number;
  amount: string;
  balanceAfterTransaction: string;
  isCompleted: boolean;
  transactionDate?: Date;
  expertisePointName?: string;
  expertisePointCityName?: string;
  expertisePointCountyName?: string;
  transactionSource?: string;
}
export interface TotalBalanceSheetData {
  totalBalance: number;
  transactions: TotalBalanceSheetTransaction[];
}

export interface ExpertisePoint {
  active: boolean;
  address: string;
  cityId: number;
  countyId: number;
  email: string;
  id: number;
  latitude: string;
  longitude: string;
  name: string;
  officialName: string;
  iban: string;
  taxNo: string;
  taxOfficeName: string;
  commissionRate: number;
  mobilePhoneNumber: number;
  tseCode: string;
  mersisNo?: string;
  companyTitle?: string;
  groupType: ExpertisePointGroupType;
}
export interface ExpertisePointSpendingTransaction extends TotalBalanceSheetTransaction {
  createdAt: Date;
  isCompleted: boolean;
  transactionTypeDesc: string;
}

export interface ExpertisePointSpendingTransactionResponse extends TotalBalanceSheetTransactionResponse {
  createdAt: string;
  isCompleted: boolean;
  transactionTypeDesc: string;
  transactionDate: string;
}

export interface GetExpertisePointSpendingsResponse {
  totalBalance: number;
  totalTransactionAmount: number;
  transactions: ExpertisePointSpendingTransactionResponse[];
}

export interface GetExpertisePointSpendingsData {
  totalBalance: number;
  totalTransactionAmount: number;
  transactions: ExpertisePointSpendingTransaction[];
}

export interface SaveExpertisePointRequest {
  id?: number;
  email: string;
  name: string;
  officialName: string;
  address: string;
  phoneNumber: number;
  cityId: number;
  countyId: number;
  tseCode: string;
  taxNo: string;
  taxOfficeName: string;
  iban: string;
  commissionRate: number;
  mersisNo: string;
  companyTitle: string;
}

export interface PanelInfo {
  totalSalesAmount: number;
  customersCount: number;
  customers: Customer[];
}

export interface ProductPriceList {
  productId: number;
  price: number;
  buyPrice: number;
  expertisePointGroupTypeId: number;
  startDate?: Date;
  endDate?: Date;
}
// #endregion
