import { HamburgerIcon } from '@chakra-ui/icons';
import { Portal, Grid, GridItem, Box, HStack, IconButton, useDisclosure, Flex } from '@chakra-ui/react';
import {
  useBodyBackground,
  useGetMainDrawerVariant,
  useIsBaseSize,
  useMainLayoutTemplateColumns,
  useMainLayoutTemplateRows,
} from 'core';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AdminDrawer } from './components/AdminDrawer';

export const AdminPage = () => {
  const isBaseSize = useIsBaseSize();
  const templateColumns = useMainLayoutTemplateColumns();
  const templateRows = useMainLayoutTemplateRows();
  const bodyBg = useBodyBackground();
  const variant = useGetMainDrawerVariant();

  const {
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
    isOpen: isDrawerOpen,
  } = useDisclosure({
    defaultIsOpen: variant === 'permanent',
  });

  useEffect(() => {
    if (variant === 'permanent') {
      onDrawerOpen();
    } else {
      onDrawerClose();
    }
  }, [variant]);

  return (
    <>
      {isBaseSize && (
        <Portal>
          <AdminDrawer onClose={onDrawerClose} isOpen={isDrawerOpen} variant={variant} />
        </Portal>
      )}
      <Grid width="100vw" height="100%" templateRows={templateRows} templateColumns={templateColumns} bg={bodyBg}>
        {isBaseSize && (
          <GridItem minW={0}>
            <Box
              zIndex="var(--chakra-zIndices-sticky)"
              bg="var(--chakra-colors-chakra-body-bg)"
              width="100%"
              height="56px"
              position="fixed"
              top={0}
              left={0}
              right={0}
              boxShadow="lg"
              p={2}
            >
              <HStack height="100%">
                <IconButton aria-label="Menu" icon={<HamburgerIcon />} onClick={onDrawerOpen} />
              </HStack>
            </Box>
          </GridItem>
        )}

        {!isBaseSize && (
          <GridItem minW={0}>
            <AdminDrawer onClose={onDrawerClose} isOpen={isDrawerOpen} variant={variant} />
          </GridItem>
        )}

        <GridItem minW={0} height="100%" minH="calc(100vh)" overflow="hidden">
          <Flex width="100%" height="100%" flexDirection="column">
            <Box height="100%">
              <Box width="100%" height="100%" bg={bodyBg} p={6} maxWidth="100%" overflow="auto">
                <Outlet />
              </Box>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};
