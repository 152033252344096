import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { VehicleQueryCacheKeys } from 'types';

export type VehicleBrandSelectProps = FormControlFormSelectProps;
export const VehicleBrandSelect: React.FC<VehicleBrandSelectProps> = ({ ...formSelectProps }) => {
  const query = useQuery(
    VehicleQueryCacheKeys.GetBrands,
    async () => {
      const response = await Api.Vehicle.getBrands();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <FormControlFormSelect label="Marka" placeholder="Marka Seç" {...formSelectProps}>
      {query.data?.map((brand) => (
        <option value={brand.id} key={`vhbsb${brand.id}`}>
          {brand.name}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
