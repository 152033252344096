// #region Enums
export const enum VehicleQueryCacheKeys {
  GetBrands = 'GetBrands',
  GetModels = 'GetModels',
  GetVehicleColors = 'GetVehicleColors',
  GetVehicleTypes = 'GetVehicleTypes',
  GetVehicleFuelTypes = 'GetVehicleFuelTypes',
  GetVehicleGearTypes = 'GetVehicleGearTypes',
  GetBodyWorkExpertiseTemplates = 'GetBodyWorkExpertiseTemplates',
  GetBodyWorkExpertises = 'GetBodyWorkExpertises',
  GetBodyWorkIcDisControl = 'GetBodyWorkIcDisControl',
  GetBodyWorkMicrons = 'GetBodyWorkMicrons',
  GetChassisControl = 'GetChassisControl',
  GetChassisControlExpertiseTemplates = 'GetChassisControlExpertiseTemplates',
  GetBodyWorkMicronTemplates = 'GetBodyWorkMicronTemplates',
  GetYears = 'GetYears',
  GetBodyWorkMotorControl = 'GetBodyWorkMotorControl',
  GetBodyWorkMotorControlTemplate = 'GetBodyWorkMotorControlTemplate',
  GetBodyWorkUndercarriageControl = 'GetBodyWorkUndercarriageControl',
  GetBodyWorkUndercarriageControlTemplate = 'GetBodyWorkUndercarriageControlTemplate',
}

export const enum ExpertiseCarBodyTypes {
  Sedan = 'Sedan',
  Hatchback5 = 'Hatchback 4 Kapı',
  Hatchback2 = 'Hatchback 2 Kapı',
  Pickup = 'Pickup 4 Kapı',
  Pickup2 = 'Pickup 2 Kapı',
  Minibus = 'Minibüs',
  Panelvan = 'Panelvan',
  CanliPanelvan = 'Camlı Van',
  TekSurguPanelvan = 'Panelvan Tek Sürgü',
  Station = 'Station',
  SUV = 'SUV',
}

export const enum ExpertiseCarBodyPartLabel {
  // Orta
  OnTampon = 'Ön Tampon',
  OnPanel = 'Ön Panel',
  Kaput = 'Kaput',
  Tavan = 'Tavan',
  BagajKapagi = 'Bagaj Kapağı',
  ArkaTampon = 'Arka Tampon',
  ArkaPanel = 'Arka Panel',

  // Sol
  SolOnSasi = 'Sol Ön Şasi',
  SolOnCamurluk = 'Sol Ön Çamurluk',
  SolOnKapi = 'Sol Ön Kapı',
  SolMarspiyel = 'Sol Marspiyel',
  SolArkaKapi = 'Sol Arka Kapı',
  SolArkaCamurluk = 'Sol Arka Çamurluk',
  SolArkaSasi = 'Sol Arka Şasi',

  // Sag
  SagOnSasi = 'Sağ Ön Şasi',
  SagOnCamurluk = 'Sağ Ön Çamurluk',
  SagOnKapi = 'Sağ Ön Kapı',
  SagMarspiyel = 'Sağ Marspiyel',
  SagArkaKapi = 'Sağ Arka Kapı',
  SagArkaCamurluk = 'Sağ Arka Çamurluk',
  SagArkaSasi = 'Sağ Arka Şasi',
}

export const enum ExpertiseCarBodyPartName {
  // Orta
  OnTampon = 1,
  OnPanel = 2,
  Kaput = 3,
  Tavan = 21,
  BagajKapagi = 13,
  ArkaTampon = 12,
  ArkaPanel = 11,

  // Sol
  SolOnSasi = 4,
  SolOnKapi = 6,
  SolOnCamurluk = 5,
  SolMarspiyel = 7,
  SolArkaKapi = 8,
  SolArkaSasi = 10,
  SolArkaCamurluk = 9,

  // Sag
  SagOnSasi = 20,
  SagOnKapi = 18,
  SagOnCamurluk = 19,
  SagMarspiyel = 17,
  SagArkaKapi = 16,
  SagArkaSasi = 14,
  SagArkaCamurluk = 15,
}

export const enum ExpertiseChassisCarBodyPartLabel {
  SolOnSasi = 'Sol Ön Şasi',
  SagOnSasi = 'Sağ Ön Şasi',
  SolArkaSasi = 'Sol Arka Şasi',
  SagArkaSasi = 'Sağ Arka Şasi',
  SolPodye = 'Sol Podye',
  SagPodye = 'Sağ Podye',
  SolUstDirek = 'Sol Üst Direk',
  SagUstDirek = 'Sağ Üst Direk',
  SolOnDirek = 'Sol Ön Direk',
  SagOnDirek = 'Sağ Ön Direk',
  SolOrtaDirek = 'Sol Orta Direk',
  SagOrtaDirek = 'Sağ Orta Direk',
  SolArkaCamurlukKilitKarsiligi = 'Sol Arka Çamurluk Kilit Karşılığı',
  SagArkaCamurlukKilitKarsiligi = 'Sağ Arka Çamurluk Kilit Karşılığı',
  SolArkaUstDirek = 'Sol Arka Üst Direk',
  SagArkaUstDirek = 'Sağ Arka Üst Direk',
  OnPanel = 'Ön Panel',
  ArkaPanel = 'Arka Panel',
  BagajHavuzu = 'Bagaj Havuzu',
}

export const enum ExpertiseChassisCarBodyPartName {
  SolOnSasi = 1,
  SagOnSasi = 2,
  SolArkaSasi = 3,
  SagArkaSasi = 4,
  SolPodye = 5,
  SagPodye = 6,
  SolUstDirek = 7,
  SagUstDirek = 8,
  SolOnDirek = 9,
  SagOnDirek = 10,
  SolOrtaDirek = 11,
  SagOrtaDirek = 12,
  SolArkaCamurlukKilitKarsiligi = 13,
  SagArkaCamurlukKilitKarsiligi = 14,
  SolArkaUstDirek = 15,
  SagArkaUstDirek = 16,
  OnPanel = 17,
  ArkaPanel = 18,
  BagajHavuzu = 19,
}

// #endregion

export interface Brand {
  id: number;

  name: string;

  active: boolean;
}

export interface Model {
  id: number;

  name: string;

  active: boolean;

  brandId: number;
}

export interface VehicleColor {
  key: string;
  value: string;
}

export interface VehicleType {
  key: string;
  value: string;
}

export interface VehicleFuelType {
  key: string;
  value: string;
}

export interface PictureNoteElem {
  id: number;
  label: string;
  image?: string | null;
}
