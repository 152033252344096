import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { VehicleQueryCacheKeys } from 'types';

export type VehicleGearTypeSelectProps = FormControlFormSelectProps;
export const VehicleGearTypeSelect: React.FC<VehicleGearTypeSelectProps> = ({ ...formSelectProps }) => {
  const query = useQuery(
    VehicleQueryCacheKeys.GetVehicleGearTypes,
    async () => {
      const response = await Api.Vehicle.getVehicleGearTypes();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <FormControlFormSelect label="Vites Tipi" placeholder="Vites Tipi" {...formSelectProps}>
      {query.data?.map((gearType) => (
        <option value={gearType.key} key={`vft${gearType.value}`}>
          {gearType.value}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
