export const enum ExpertiseBodyworkExpertiseStates {
  Original = 'ORİJİNAL',
  Painted = 'BOYALI',
  Changed = 'DEĞİŞEN',
  HalfPainted = 'Y. BOYALI',
  LocallyPainted = 'L. BOYALI',
  Removed = 'SÖK TAK',
  Cutting = 'KESME',
  Processed = 'İŞLEM GÖRMÜŞ',
  FoilCoated = 'FOLYO KAPLI',
  Damaged = 'HASARLI',
  Sunroof = 'CAM TAVAN',
  Meek = 'EZİK',
}

export const enum ExpertiseCarBodyTypes {
  SEDAN = 'sedan',
}
