import { useToast } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { CustomerQueryCacheKey } from 'types';

export type ExpertisePackageProps = FormControlFormSelectProps & { expertisePackageGroupId?: string };

export const ExpertisePackageSelect: React.FC<ExpertisePackageProps> = ({
  expertisePackageGroupId,
  ...formSelectProps
}) => {
  const toast = useToast();
  const query = useQuery(
    [CustomerQueryCacheKey.GetExpertisePackagesByGroup, expertisePackageGroupId],
    async () => {
      const response = await Api.Expertise.getExpertisePackagesByGroup(expertisePackageGroupId!);
      const packagesResponse = response.data;
      return packagesResponse.expertisePackageGroup;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!expertisePackageGroupId,
      onError: (e) => {
        console.error(e);
        toast({
          title: 'Ekspertiz Paketleri Çekilirken Hata Oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
  return (
    <FormControlFormSelect label="Ekspertiz Paketi Seç" placeholder="Ekspertiz Paketi Seç" {...formSelectProps}>
      {query.data?.items.map((expertisePackage) => (
        <option value={expertisePackage.expertisePackageId} key={`exps${expertisePackage.expertisePackageId}`}>
          {expertisePackage.expertisePackageName} - {expertisePackage.price}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
