/* eslint-disable no-nested-ternary */
import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tooltip,
  Text,
  TableProps,
  Box,
  chakra,
  Heading,
} from '@chakra-ui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
  TableOptions,
  Table as TanstackTable,
} from '@tanstack/react-table';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  usePagination?: boolean;
  tableProps?: TableProps;
  title?: string;
  actions?: React.ReactNode | ((table: TanstackTable<Data>) => React.ReactNode);
  tableOptions?: Omit<TableOptions<Data>, 'columns' | 'data' | 'getCoreRowModel'>;
};

export const DataTable = <Data extends object>({
  data,
  columns,
  usePagination,
  tableProps,
  title,
  actions,
  tableOptions,
}: DataTableProps<Data>) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: usePagination ? getPaginationRowModel() : undefined,
    state: {
      sorting,
    },
    ...tableOptions,
  });
  const showHeader = title || actions;
  return (
    <Box borderRadius="md" border="1px" borderColor="gray.200" overflowX="hidden">
      {showHeader && (
        <Flex alignItems="center" p={4}>
          {title && (
            <Box flex="1">
              <Heading size="md">{title}</Heading>
            </Box>
          )}
          {actions && (
            <Box flex="1" display="flex" justifyContent="flex-end">
              {typeof actions === 'function' ? actions(table) : actions}
            </Box>
          )}
        </Flex>
      )}

      <Box overflowX="auto">
        <Table {...tableProps}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  if (header.isPlaceholder) {
                    return null;
                  }
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const { meta } = header.column.columnDef as { meta: any };

                  return (
                    <React.Fragment key={header.id}>
                      <Th
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric || false}
                        colSpan={header.colSpan}
                        style={meta?.style}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        <chakra.span pl="4">
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    </React.Fragment>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const { meta } = cell.column.columnDef;
                  return (
                    <React.Fragment key={cell.id}>
                      {/* @ts-ignore */}
                      <Td isNumeric={meta?.isNumeric}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                    </React.Fragment>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {usePagination && (
        <Flex justifyContent="space-between" m={4} alignItems="center">
          <Flex>
            <Tooltip label="İlk Sayfa">
              <IconButton
                onClick={() => table.setPageIndex(0)}
                isDisabled={!table.getCanPreviousPage()}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
                aria-label=""
              />
            </Tooltip>
            <Tooltip label="Önceki Sayfa">
              <IconButton
                onClick={table.previousPage}
                isDisabled={!table.getCanPreviousPage()}
                icon={<ChevronLeftIcon h={6} w={6} />}
                aria-label=""
              />
            </Tooltip>
          </Flex>

          <Flex alignItems="center">
            <Text flexShrink="0" mr={8}>
              Sayfa{' '}
              <Text fontWeight="bold" as="span">
                {table.getState().pagination.pageIndex + 1}
              </Text>{' '}
              /{' '}
              <Text fontWeight="bold" as="span">
                {table.getPageOptions().length}
              </Text>
            </Text>
            <Text flexShrink="0">Sayfaya Git:</Text>{' '}
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={table.getPageOptions().length}
              onChange={(value: string) => {
                const valNum = value ? parseInt(value, 10) : 0;
                const page = valNum ? valNum - 1 : 0;
                table.setPageIndex(page);
              }}
              defaultValue={table.getState().pagination.pageIndex + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              w={36}
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Satır Göster
                </option>
              ))}
            </Select>
          </Flex>
          <Flex>
            <Tooltip label="Sonraki Sayfa">
              <IconButton
                onClick={table.nextPage}
                isDisabled={!table.getCanNextPage()}
                icon={<ChevronRightIcon h={6} w={6} />}
                aria-label=""
              />
            </Tooltip>
            <Tooltip label="Son Sayfa">
              <IconButton
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                isDisabled={!table.getCanNextPage()}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
                aria-label=""
              />
            </Tooltip>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
