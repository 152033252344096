import { ApiBase, ApiResponse, RegisterUserRequest, UpdateUserRequest } from 'types';

const BASE_URL = 'user';

export class UserApi extends ApiBase {
  public async registerUser(request: RegisterUserRequest): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${BASE_URL}/register`, request);

    return response.data;
  }

  public async updateUser(request: UpdateUserRequest): Promise<ApiResponse<void>> {
    const response = await this.apiClient.put(`${BASE_URL}/update`, request);

    return response.data;
  }

  public async removeUser(userId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.delete(`${BASE_URL}/remove`, {
      params: {
        userId,
      },
    });

    return response.data;
  }
}
