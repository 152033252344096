import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Flex,
  Spacer,
  Box,
  Divider,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { CustomerHistories } from 'types';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Api } from 'api';
import { onExpertiseReportFilesDialogOpen, setExpertiseReportFileInfos } from 'features';
import { useDispatch } from 'react-redux';

type CustomerHistoriesModalProps = {
  showCustomerHistories: boolean;
  histories: CustomerHistories[];
};

export const CustomerHistoriesModal: React.FC<CustomerHistoriesModalProps> = ({ showCustomerHistories, histories }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useDispatch();
  const toast = useToast();
  useEffect(() => {
    if (showCustomerHistories) {
      onOpen();
    }
  }, [showCustomerHistories, histories]);

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString();
  };

  const goToCustomerDetails = async (customerId: number) => {
    Api.Expertise.getExpertiseReportPdfLink(customerId)
      .then((response) => {
        if (response.data.length === 1) {
          window.open(response.data[0].url);
        } else {
          dispatch(setExpertiseReportFileInfos(response.data));
          dispatch(onExpertiseReportFilesDialogOpen());
        }
      })
      .catch((err) => {
        console.error(err);
        toast({
          status: 'error',
          title: 'Tamamlanmamış işlem kayıtlarında rapor çıktısı verilememektedir',
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Geçmiş Ekspertiz Kayıtları</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column">
            {histories &&
              histories.map((history) => {
                return (
                  <React.Fragment key={history.customerId}>
                    <Flex>
                      <Box p="2">{history.expertisePointName}</Box>
                      <Spacer />
                      <Box p="2">{formatDate(history.expertiseDate)}</Box>
                      <IconButton
                        ml="10px"
                        size="sm"
                        icon={<ExternalLinkIcon />}
                        aria-label="Search database"
                        onClick={() => goToCustomerDetails(history?.customerId)}
                        colorScheme="blue"
                      />
                    </Flex>
                    <Divider />
                  </React.Fragment>
                );
              })}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Kapat</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
