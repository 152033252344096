import { AdminUserManagementUser } from 'apps/admin';
import {
  ApiBase,
  ApiResponse,
  ExpertisePoint,
  GetExpertisePointSpendingsResponse,
  PanelInfo,
  PaymentTransationStartProcessResponse,
  ProductPriceList,
  SaveExpertisePointRequest,
  TotalBalanceSheetDataResponse,
} from 'types';

const BASE_URL = 'expertise-point';

export class ExpertisePointApi extends ApiBase {
  public async startProcess(amount: number): Promise<ApiResponse<PaymentTransationStartProcessResponse>> {
    const result = await this.apiClient.post(`${BASE_URL}/balance/start-process`, { amount });
    return result.data;
  }

  public async getTransactions(
    expertisePointId?: number,
    startDateTimestamp?: number,
    endDateTimestamp?: number,
  ): Promise<ApiResponse<TotalBalanceSheetDataResponse>> {
    const result = await this.apiClient.post(`${BASE_URL}/balance/transactions`, {
      expertisePointId,
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
    });
    return result.data;
  }

  public async exportTransactions(
    expertisePointId?: number,
    startDateTimestamp?: number,
    endDateTimestamp?: number,
  ): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/balance/export-transactions`,
      {
        expertisePointId,
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
      },
      {
        responseType: 'blob',
      },
    );

    return response.data;
  }

  public async getUsers(): Promise<ApiResponse<AdminUserManagementUser[]>> {
    const result = await this.apiClient.get(`${BASE_URL}/get-users`);
    return result.data;
  }

  public async getUsersForExpertisePoint(expertisePointId: number): Promise<ApiResponse<AdminUserManagementUser[]>> {
    const result = await this.apiClient.get(`${BASE_URL}/get-users/${expertisePointId}`);
    return result.data;
  }

  public async getAllExpertisePoints(): Promise<ApiResponse<ExpertisePoint[]>> {
    const result = await this.apiClient.get(`${BASE_URL}/get-all`);
    return result.data;
  }

  public async deleteExpertisePoint(expertisePointId: number): Promise<ApiResponse<void>> {
    const result = await this.apiClient.delete(`${BASE_URL}/remove/${expertisePointId}`);
    return result.data;
  }

  public async activateExpertisePoint(expertisePointId: number): Promise<ApiResponse<void>> {
    const result = await this.apiClient.post(`${BASE_URL}/activate/${expertisePointId}`);
    return result.data;
  }

  public async getSpendingTransactions(
    expertisePointId: number,
    startDateTimestamp: any,
    endDateTimestamp: any,
  ): Promise<ApiResponse<GetExpertisePointSpendingsResponse>> {
    const result = await this.apiClient.get(`${BASE_URL}/get-spending-transactions`, {
      params: {
        expertisePointId,
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
      },
    });
    return result.data;
  }

  public async exportSpendingTransactions(
    expertisePointId: number,
    startDateTimestamp: any,
    endDateTimestamp: any,
  ): Promise<Blob> {
    const result = await this.apiClient.get(`${BASE_URL}/export-spending-transactions`, {
      params: {
        expertisePointId,
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
      },
      responseType: 'blob',
    });
    return result.data;
  }

  public async saveExpertisePoint(saveExpertisePointRequest: SaveExpertisePointRequest): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${BASE_URL}/save-expertise-point`, saveExpertisePointRequest);
    return response.data;
  }

  public async getExpertisePointPanel(
    startDateTimestamp: number,
    endDateTimestamp: number,
  ): Promise<ApiResponse<PanelInfo>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-panel-info`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
    });
    return response.data;
  }

  public async getProductPriceList(): Promise<ApiResponse<ProductPriceList[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-expertise-point-product-prices`);
    return response.data;
  }

  public async saveProductPrice(productPrice: ProductPriceList): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${BASE_URL}/save-expertise-point-product-price`, productPrice);
    return response.data;
  }
}
