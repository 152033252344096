/* eslint-disable no-console */
import { DownloadIcon } from '@chakra-ui/icons';
import { Button, Icon, useToast } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable } from 'components';
import { downloadBlobAsFile } from 'core';
import { hideLoading, showLoading } from 'features';
import React from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { TotalBalanceSheetTransaction } from 'types';

export interface AdminExpertisePointBalanceHistoryTableProps {
  transactions: TotalBalanceSheetTransaction[];
  expertisePointId?: number;
  startDate?: number;
  endDate?: number;
}

export const AdminExpertisePointBalanceHistoryTable: React.FC<AdminExpertisePointBalanceHistoryTableProps> = ({
  transactions,
  expertisePointId,
  startDate,
  endDate,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const getExportBalanceHistories = useMutation<any, any>(
    async () => {
      dispatch(showLoading());
      const response = await Api.ExpertisePoint.exportTransactions(expertisePointId, startDate, endDate);

      return response;
    },
    {
      onSuccess(data) {
        if (data) {
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const fileName = `Bakiye-Gecmisi${formattedDate}.xlsx`;
          downloadBlobAsFile(data, fileName);
        } else {
          toast({
            status: 'info',
            title: 'Rapor Bulunamadı',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Rapor İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  const columnHelper = createColumnHelper<TotalBalanceSheetTransaction>();

  const columns = [
    // columnHelper.accessor('id', {
    //   cell: (info) => `₺${info.getValue()}`,
    //   header: 'Miktar',
    //   meta: {
    //     isNumeric: true,
    //   },
    // }),
    columnHelper.accessor('transactionDate', {
      cell: (info) => info.getValue()?.toLocaleDateString() || '-',
      header: 'Tarih',
    }),
    columnHelper.accessor('expertisePointName', {
      cell: (info) => info.getValue(),
      header: 'Bayi Adı',
    }),
    columnHelper.accessor('expertisePointCityName', {
      cell: (info) => info.getValue(),
      header: 'İl',
    }),
    columnHelper.accessor('expertisePointCountyName', {
      cell: (info) => info.getValue(),
      header: 'İlçe',
    }),
    columnHelper.accessor('amount', {
      cell: (info) => `₺${info.getValue()}`,
      header: 'Yüklenen',
      meta: {
        isNumeric: true,
      } as any,
    }),
    columnHelper.accessor('transactionSource', {
      cell: (info) => info.getValue(),
      header: 'Kaynak',
    }),
    columnHelper.accessor('isCompleted', {
      cell: (info) => (info.getValue() ? 'Tamamlandı' : 'Tamamlanmadı'),
      header: 'Durum',
    }),
    columnHelper.accessor('balanceAfterTransaction', {
      cell: (info) => `₺${info.getValue()}`,
      header: 'İşlem S. Bakiye',
      meta: {
        isNumeric: true,
      } as any,
    }),
  ];

  return (
    <>
      <DataTable
        data={transactions}
        columns={columns}
        usePagination
        tableProps={{
          variant: 'striped',
          colorScheme: 'teal',
        }}
        title="Bakiye Geçmişi"
      />
      <Button
        mt={2}
        variant="solid"
        leftIcon={<Icon as={DownloadIcon} />}
        onClick={() => getExportBalanceHistories.mutate()}
      >
        Excel İndir
      </Button>
    </>
  );
};
