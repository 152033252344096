import {
  ApiBase,
  ApiResponse,
  ExpertisePackageReportResponse,
  ExtraPackagesReportAdminResponse,
  ExtraPackagesReportResponse,
  QueriesReportAdminResponse,
  QueriesReportResponse,
  SaleReportAdminResponse,
  SaleReportResponse,
} from 'types';

const BASE_URL = 'reporting';

export class ReportingApi extends ApiBase {
  public async getSalesReport(
    startDateTimestamp: number,
    endDateTimestamp: number,
    externalPackageGroupSource?: number | null,
    onlyDiscountedSales?: boolean,
  ): Promise<ApiResponse<SaleReportResponse>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-sales-report`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
      externalPackageGroupSource,
      onlyDiscountedSales,
    });

    return response.data;
  }

  public async getSalesReportAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
    externalPackageGroupSource?: number | null,
    onlyDiscountedSales?: boolean,
  ): Promise<ApiResponse<SaleReportAdminResponse>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-sales-report-admin`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
      expertisePointId,
      externalPackageGroupSource,
      onlyDiscountedSales,
    });

    return response.data;
  }

  public async exportSalesReport(
    startDateTimestamp: number,
    endDateTimestamp: number,
    externalPackageGroupSource?: number | null,
  ): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-sales-report`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
        externalPackageGroupSource,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }

  public async exportSalesReportAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
    externalPackageGroupSource?: number | null,
    onlyDiscountedSales?: boolean,
  ): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-sales-report-admin`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
        expertisePointId,
        externalPackageGroupSource,
        onlyDiscountedSales,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }

  public async getSalesReportByExpertisePackage(
    startDateTimestamp: number,
    endDateTimestamp: number,
  ): Promise<ApiResponse<ExpertisePackageReportResponse[]>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-sales-report-by-expertise-package`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
    });

    return response.data;
  }

  public async getSalesReportByExpertisePackageAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
  ): Promise<ApiResponse<ExpertisePackageReportResponse[]>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-sales-report-by-expertise-package-admin`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
      expertisePointId,
    });

    return response.data;
  }

  public async exportSalesReportByExpertisePackage(
    startDateTimestamp: number,
    endDateTimestamp: number,
  ): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-sales-report-by-expertise-package`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }

  public async exportSalesReportByExpertisePackageAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
  ): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-sales-report-by-expertise-package-admin`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
        expertisePointId,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }

  public async getQueriesReport(
    startDateTimestamp: number,
    endDateTimestamp: number,
  ): Promise<ApiResponse<QueriesReportResponse>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-queries-report`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
    });

    return response.data;
  }

  public async getQueriesReportAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
  ): Promise<ApiResponse<QueriesReportAdminResponse>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-queries-report-admin`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
      expertisePointId,
    });

    return response.data;
  }

  public async exportQueriesReport(startDateTimestamp: number, endDateTimestamp: number): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-queries-report`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }

  public async getExtraPackageReportAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
  ): Promise<ApiResponse<ExtraPackagesReportAdminResponse>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-extra-packages-report-admin`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
      expertisePointId,
    });

    return response.data;
  }

  public async getExtraPackageReport(
    startDateTimestamp: number,
    endDateTimestamp: number,
  ): Promise<ApiResponse<ExtraPackagesReportResponse>> {
    const response = await this.apiClient.post(`${BASE_URL}/get-extra-packages-report`, {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
    });

    return response.data;
  }

  public async exportExtraPackageReport(startDateTimestamp: number, endDateTimestamp: number): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-extra-packages-report`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }

  public async exportExtraPackageReportAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
  ): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-extra-packages-report-admin`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
        expertisePointId,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }

  public async exportQueriesReportAdmin(
    startDateTimestamp: number,
    endDateTimestamp: number,
    expertisePointId?: number,
  ): Promise<Blob> {
    const response = await this.apiClient.post(
      `${BASE_URL}/export-queries-report-admin`,
      {
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
        expertisePointId,
      },
      { responseType: 'blob' },
    );

    return response.data;
  }
}
