/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface CoreState {
  isLoading: boolean;
}

const initialState: CoreState = { isLoading: false };

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    toggleLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleLoading, showLoading, hideLoading } = coreSlice.actions;

export const { reducer: coreReducer } = coreSlice;
