import { Box, FormControl, FormLabel, HStack, StackItem } from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { DateRangePreSelector, ExpertisePointSelect } from 'components';
import { FormControlFormSelect } from 'core';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ExpertisePoint } from 'types';
import { AdminReportPages } from '../admin.types';
import { AdminPageTitle } from '../components';
import { AdminReportPackages, AdminReportQueries, AdminReportSales } from './AdminReportsPages';

export const AdminReportPage = () => {
  const authUserInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const [selectedReportType, setSelectedReportType] = useState<string>(AdminReportPages.QUERIES);
  const [expertisePoint, setExpertisePoint] = useState<ExpertisePoint>();
  const [selectedCampaignType, setSelectedCampaignType] = useState<string>('1');

  const ReportComponent = useMemo(() => {
    switch (selectedReportType) {
      case AdminReportPages.SALES:
        return AdminReportSales;
      case AdminReportPages.QUERIES:
        return AdminReportQueries;
      case AdminReportPages.PACKAGE:
      default:
        return AdminReportPackages;
    }
  }, [selectedReportType]);
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
  return (
    <>
      <AdminPageTitle title="Raporlar" />
      <HStack align="center" gap={10}>
        <StackItem>
          <DateRangePreSelector
            onDateRangeSelected={(startDate: Date, endDate: Date) => {
              setSelectedDates([startDate, endDate]);
            }}
          />
        </StackItem>
        {(authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin) && (
          <StackItem w="300px">
            <ExpertisePointSelect
              enableAll
              onExpertisePointSelected={(selectedExpertisePoint) => {
                setExpertisePoint(selectedExpertisePoint);
              }}
            />
          </StackItem>
        )}
      </HStack>

      <HStack align="center">
        <FormControl>
          <FormLabel>Tarih Aralığı</FormLabel>

          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            configs={{
              dateFormat: 'dd MMMM yyyy',
            }}
          />
        </FormControl>

        <FormControlFormSelect
          label="Rapor Türü"
          value={selectedReportType}
          onChange={(event) => {
            setSelectedReportType(event.target.value);
          }}
        >
          <option value={AdminReportPages.PACKAGE}>Paketler</option>
          <option value={AdminReportPages.SALES}>Satışlar</option>
          <option value={AdminReportPages.QUERIES}>Sorgulamalar</option>
        </FormControlFormSelect>

        {selectedReportType === AdminReportPages.SALES ? (
          <FormControlFormSelect
            label="Kampanya Türü"
            value={selectedCampaignType}
            onChange={(event) => {
              setSelectedCampaignType(event.target.value);
            }}
          >
            <option value="1">Toplam</option>
            <option value="2">Occo Car</option>
            <option value="3">İndirimli</option>
          </FormControlFormSelect>
        ) : null}
      </HStack>
      <Box pl={16} pr={16} my={8}>
        <ReportComponent
          beginDate={selectedDates[0]}
          endDate={selectedDates[1]}
          expertisePoint={expertisePoint}
          externalPackageGroupSource={selectedCampaignType === '1' || selectedCampaignType === '3' ? null : 10}
          onlyDiscountedSales={selectedCampaignType === '3'}
        />
      </Box>
    </>
  );
};
