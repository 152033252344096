// #region Enums
export const enum LocationQueryCacheKeys {
  GetCities = 'GetCities',
  GetCounties = 'GetCounties',
}
// #endregion
export interface City {
  active: boolean;
  name: string;
  id: number;
}

export interface County {
  id: number;
  cityId: number;
  active: boolean;
  name: string;
}
