import { io, Socket } from 'socket.io-client';

const BASE_URL = 'https://api.ekspertiz.occocar.com';

const socketOptions = {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 3000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
};

// Create a socket for customer-specific communication
export const createCustomerSocket = (customerId: string): Socket => {
  const socket = io(`${BASE_URL}/customer`, socketOptions);

  socket.on('connect', () => {
    console.warn('Connected to customer namespace');
    socket.emit('joinRoom', customerId);
  });

  socket.on('reconnect', () => {
    console.warn('Reconnected to customer namespace');
    socket.emit('joinRoom', customerId); // Rejoin room after reconnection
  });

  return socket;
};

// Create a socket for expertise-point-specific communication
export const createExpertisePointSocket = (userId: string): Socket => {
  const socket = io(`${BASE_URL}/expertise-point`, socketOptions);

  socket.on('connect', () => {
    console.warn('Connected to expertise-point namespace');
    socket.emit('joinRoom', userId);
  });

  socket.on('reconnect', () => {
    console.warn('Reconnected to expertise-point namespace');
    socket.emit('joinRoom', userId); // Rejoin room after reconnection
  });

  return socket;
};

// Create a general toggle socket
export const createToggleSocket = (): Socket => {
  return io(`${BASE_URL}/toggle`, socketOptions);
};

// Legacy sockets for transition period (if needed)
export const customerSocket = io(`${BASE_URL}/customer`, socketOptions);
export const expertisePointSocket = io(`${BASE_URL}/expertise-point`, socketOptions);
export const toggleSocket = io(`${BASE_URL}/toggle`, socketOptions);
export const expertiseSocket = io(`${BASE_URL}/expertise`, socketOptions); // No longer used
