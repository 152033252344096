import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { ExpertisePackageGroup, ExpertiseQueryCacheKey } from 'types';

export interface ExpertisePackageGroupProps extends FormControlFormSelectProps {
  onPackageGroupChange?: (selectedGroup: ExpertisePackageGroup) => void;
}
export const ExpertisePackageGroupSelect: React.FC<ExpertisePackageGroupProps> = ({
  onPackageGroupChange,
  ...formSelectProps
}) => {
  const query = useQuery(
    ExpertiseQueryCacheKey.GetAllPackageGroups,
    async () => {
      const response = await Api.Expertise.getAllPackageGroups();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGroup = query.data?.find((group) => group.id === Number(e.target.value));
    if (selectedGroup && onPackageGroupChange) {
      onPackageGroupChange(selectedGroup);
    }
    if (formSelectProps.onChange) {
      formSelectProps.onChange(e);
    }
  };
  return (
    <FormControlFormSelect label="Kampanya Seç" placeholder="Kampanya Seç" {...formSelectProps} onChange={handleChange}>
      {query.data?.map((expertisePackageGroup) => (
        <option value={expertisePackageGroup.id} key={`exps${expertisePackageGroup.id}`}>
          {expertisePackageGroup.name}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
