import React, { useMemo } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Flex,
  VStack,
  Image,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { localStorageUserAuthInfoKey, useAuthUser, useIsBaseSize } from 'core';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { AdminPages } from '../admin.types';

export interface AdminDrawerProps {
  onClose: () => void;
  isOpen: boolean;
  variant?: string;
}
export const AdminDrawer: React.FC<AdminDrawerProps> = ({ onClose, isOpen, variant }) => {
  const isBaseSize = useIsBaseSize();
  const navigate = useNavigate();
  const location = useLocation();

  const currentPageName = useMemo(() => {
    const parts = location.pathname.split('/');
    if (parts.length < 3) {
      return '';
    }

    return parts[2];
  }, [location, location.pathname]);

  const authUserInfo = useAuthUser();

  return (
    <Drawer
      closeOnEsc={false}
      variant={variant}
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      autoFocus={false}
      trapFocus={false}
      blockScrollOnMount={false}
    >
      {isBaseSize && <DrawerOverlay />}
      <DrawerContent>
        {isBaseSize && <DrawerCloseButton />}
        <DrawerHeader borderBottomWidth="1px">
          <Flex align="center" justify="space-between" w="100%">
            <IconButton onClick={() => navigate(`/`)} icon={<ChevronLeftIcon h={10} w={5} />} ml={4} aria-label="" />
            <Image src="/images/occo-ekspertiz-logo-seffaf.png" width="100px" />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Flex width="100%" height="100%" flexDirection="column">
            <VStack spacing="12px" flex="1 0 0" mb={2} overflowY="auto">
              {authUserInfo && !authUserInfo?.isSuperAdmin && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.PANEL ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.PANEL}`);
                  }}
                >
                  Pano
                </Button>
              )}
              <Button
                width="100%"
                size="sm"
                variant={currentPageName === AdminPages.BALANCE ? 'solid' : 'ghost'}
                onClick={() => {
                  navigate(`/admin/${AdminPages.BALANCE}`);
                }}
              >
                Bakiye
              </Button>
              <Button
                width="100%"
                size="sm"
                variant={currentPageName === AdminPages.REPORTS ? 'solid' : 'ghost'}
                onClick={() => {
                  navigate(`/admin/${AdminPages.REPORTS}`);
                }}
              >
                Raporlar
              </Button>

              {authUserInfo && authUserInfo?.isSuperAdmin && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.USERS ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.USERS}`);
                  }}
                >
                  Kullanıcılar
                </Button>
              )}

              {authUserInfo && authUserInfo?.isSuperAdmin && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.PRICE_LIST ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.PRICE_LIST}`);
                  }}
                >
                  Fiyat Listesi
                </Button>
              )}
              {authUserInfo && authUserInfo?.isSuperAdmin && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.PRODUCT_PRICE_LIST ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.PRODUCT_PRICE_LIST}`);
                  }}
                >
                  Ürün Fiyat Listesi
                </Button>
              )}
              {authUserInfo && authUserInfo?.isSuperAdmin && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.PACKAGE_MANAGEMENT ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.PACKAGE_MANAGEMENT}`);
                  }}
                >
                  Paket Yönetimi
                </Button>
              )}
              {authUserInfo && authUserInfo?.isSuperAdmin && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.EXPERTISE_POINT_MANAGEMENT ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.EXPERTISE_POINT_MANAGEMENT}`);
                  }}
                >
                  Bayi Yönetimi
                </Button>
              )}
              <Button
                width="100%"
                size="sm"
                variant={currentPageName === AdminPages.ANNOUNCEMENT ? 'solid' : 'ghost'}
                onClick={() => {
                  navigate(`/admin/${AdminPages.ANNOUNCEMENT}`);
                }}
              >
                Duyurular
              </Button>

              {authUserInfo && (authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin) && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.DICTIONARY ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.DICTIONARY}`);
                  }}
                >
                  Not Sözlük Yönetimi
                </Button>
              )}
              <Button
                width="100%"
                size="sm"
                variant={currentPageName === AdminPages.INQUIRIES ? 'solid' : 'ghost'}
                onClick={() => {
                  navigate(`/admin/${AdminPages.INQUIRIES}`);
                }}
              >
                Sorgulamalar
              </Button>

              {authUserInfo && (authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin) && (
                <Button
                  width="100%"
                  size="sm"
                  variant={currentPageName === AdminPages.INHOUSE_CODE ? 'solid' : 'ghost'}
                  onClick={() => {
                    navigate(`/admin/${AdminPages.INHOUSE_CODE}`);
                  }}
                >
                  Koçan Yönetimi
                </Button>
              )}

              <Button
                width="100%"
                size="sm"
                variant="ghost"
                color="red"
                onClick={() => {
                  localStorage.removeItem(localStorageUserAuthInfoKey);
                  // eslint-disable-next-line no-restricted-globals
                  window?.location?.reload();
                }}
              >
                Çıkış Yap
              </Button>
            </VStack>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
