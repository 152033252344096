import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Api } from 'api';
import { OccopayDrawer } from 'components';
import { FormControlFormInput, GenericMessages, OnlyNumberRegex } from 'core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ExpertisePointQueryCacheKey, PaymentTransationStartProcessData, TotalBalanceSheetData } from 'types';

import * as yup from 'yup';
import { AdminExpertisePointBalanceHistoryTable, AdminPageTitle } from '../components';

const validationSchema = yup.object({
  amount: yup
    .string()
    .required(GenericMessages.CannotBeEmpty)
    .matches(OnlyNumberRegex, GenericMessages.OnlyNumber)
    .test(
      'less than 1000',
      GenericMessages.CantBeLessThanNumber(1000),
      (value) => !value || parseInt(value, 10) >= 1000,
    ),
});

export const AdminBalancePage = () => {
  const toast = useToast();
  const [occopayPaymentData, setOccopayPaymentData] = useState<PaymentTransationStartProcessData>();
  const { isOpen: isOccopayDrawerOpen, onClose: onOccopayDrawerClose, onOpen: onOccopayDrawerOpen } = useDisclosure();
  const { data: transactionData } = useQuery(
    ExpertisePointQueryCacheKey.GetPreviousBalanceTransactions,
    async () => {
      const response = await Api.ExpertisePoint.getTransactions();
      const responseData = response.data;
      const convertedTransactionData: TotalBalanceSheetData = {
        totalBalance: responseData.totalBalance,
        transactions: responseData.transactions.map((transaction) => {
          return {
            id: transaction.id,
            amount: transaction.amount,
            isCompleted: transaction.isCompleted,
            transactionDate: transaction.transactionDate ? new Date(transaction.transactionDate) : undefined,
          };
        }),
      };
      return convertedTransactionData;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  const paymentStartMutation = useMutation<PaymentTransationStartProcessData, any, number>(
    async (amount: number) => {
      const response = await Api.ExpertisePoint.startProcess(amount);
      return { ...response.data, amount };
    },
    {
      onSuccess: (data: PaymentTransationStartProcessData) => {
        setOccopayPaymentData(data);
        onOccopayDrawerOpen();
        // eslint-disable-next-line no-use-before-define
        resetForm();
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Ödeme sistemi ile haberleşirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
  const { values, handleChange, handleSubmit, errors, resetForm } = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema,
    onSubmit: (data: { amount: string }) => {
      paymentStartMutation.mutate(parseInt(data.amount, 10));
    },
  });

  return (
    <>
      <OccopayDrawer paymentData={occopayPaymentData} isOpen={isOccopayDrawerOpen} onClose={onOccopayDrawerClose} />
      <AdminPageTitle title="Sorgulama Bakiyesi" />

      <Flex gap={12} direction="column">
        <Card width={['100%', '100%', '50%']}>
          <CardHeader>
            <Heading size="md">Bakiye Yükle</Heading>
          </CardHeader>
          <CardBody>
            <form id="occopayAmountForm" onSubmit={handleSubmit}>
              <FormControlFormInput
                label="Miktar"
                type="number"
                name="amount"
                onChange={handleChange}
                value={values.amount || ''}
                error={errors.amount != null}
                helperText={errors.amount}
              />
            </form>
          </CardBody>
          <CardFooter justifyContent="flex-end">
            <Button type="submit" form="occopayAmountForm" colorScheme="blue">
              Yükle
            </Button>
          </CardFooter>
        </Card>

        {transactionData?.transactions && (
          <AdminExpertisePointBalanceHistoryTable transactions={transactionData?.transactions} />
        )}
      </Flex>
    </>
  );
};
