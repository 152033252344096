import {
  ApiBase,
  ApiResponse,
  Customer,
  CustomerAbilityDefinitionAllResponse,
  CustomerExpertiseFileType,
  CustomerInitiateRequest,
  CustomerInitiateResponse,
  CustomerResponse,
  CustomersResponse,
  CustomerTestResultFile,
  CustomerTestResultFileUploadResponse,
  CustomerVehiclePhotosResponse,
  ExpertisePackageAbilityDefinitionType,
  GasLeakInfo,
  GetCustomerVehicleResponse,
  SaveCustomerRequest,
  SaveCustomerVehiclePhotoRequest,
  SaveCustomerVehicleRequest,
  SearchCustomerParams,
  SendKvKKRequestResponse,
} from 'types';

const BASE_URL = 'customer';
const CUSTOMER_AGREEMENT_BASE_URL = 'customer-agreement';
const BASE_URL_CUSTOMER_FILE = 'customer-file';

export class CustomerApi extends ApiBase {
  public async initiateCustomer(
    customerInıtiateRequest: CustomerInitiateRequest,
  ): Promise<ApiResponse<CustomerInitiateResponse>> {
    const response = await this.apiClient.post(`${BASE_URL}/initiate-customer`, null, {
      params: {
        plate: customerInıtiateRequest.license,
        expertisePackageId: parseInt(customerInıtiateRequest.expertisePackage!, 10),
        expertisePackageGroupId: parseInt(customerInıtiateRequest.expertisePackageGroup!, 10),
        packageCode: customerInıtiateRequest?.packageCode,
        discountApplied: customerInıtiateRequest?.discountApplied,
        discountedPrice: customerInıtiateRequest?.discountedPrice
          ? parseInt(customerInıtiateRequest?.discountedPrice!, 10)
          : null,
      },
    });

    return response.data;
  }

  public async getCustomerById(id: number): Promise<ApiResponse<CustomerResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-customer`, {
      params: {
        id,
      },
    });

    return response.data;
  }

  public async getCustomerByPlate(plate: string): Promise<ApiResponse<CustomerResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-customer`, {
      params: {
        plate,
      },
    });

    return response.data;
  }

  public async getCustomerByChassisNumber(chassisNumber: string): Promise<ApiResponse<CustomerResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-customer`, {
      params: {
        chassisNumber,
      },
    });

    return response.data;
  }

  public async getDailyCustomers(): Promise<ApiResponse<CustomersResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-customers`);
    return response.data;
  }

  public async saveCustomerInfo(saveCustomerRequest: SaveCustomerRequest) {
    await this.apiClient.post(`${BASE_URL}/save-customer`, saveCustomerRequest);
  }

  public async saveCustomerVehicleInfo(saveCustomerVehicleRequest: SaveCustomerVehicleRequest): Promise<void> {
    await this.apiClient.post(`${BASE_URL}/save-customer-vehicle`, saveCustomerVehicleRequest);
  }

  public async saveCustomerVehiclePhoto(
    saveCustomerVehiclePhotoRequest: SaveCustomerVehiclePhotoRequest,
  ): Promise<void> {
    const formData = new FormData();

    formData.append('customerId', saveCustomerVehiclePhotoRequest.customerId.toString());

    if (saveCustomerVehiclePhotoRequest.file) {
      formData.append('files', saveCustomerVehiclePhotoRequest.file);
    }

    if (saveCustomerVehiclePhotoRequest.id) {
      formData.append('id', saveCustomerVehiclePhotoRequest.id.toString());
    }
    await this.apiClient.post(`${BASE_URL}/save-customer-vehicle-photo`, formData);
  }

  public async getCustomerVehicle(customerId: number): Promise<ApiResponse<GetCustomerVehicleResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-customer-vehicle`, {
      params: {
        customerId,
      },
    });
    return response.data;
  }

  public async getCustomerVehiclePhotos(customerId: number): Promise<ApiResponse<CustomerVehiclePhotosResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-customer-vehicle-photos`, {
      params: {
        customerId,
      },
    });
    return response.data;
  }

  public async setAsCompleted(customerId: number) {
    const response = await this.apiClient.put(`${BASE_URL}/set-as-completed/${customerId}`);
    return response.data;
  }

  public async setAsCancelled(customerId: number) {
    const response = await this.apiClient.put(`${BASE_URL}/set-as-cancelled/${customerId}`);
    return response.data;
  }

  public async deleteCustomerVehiclePhoto(photoId: number): Promise<void> {
    await this.apiClient.delete(`${BASE_URL}/delete-customer-vehicle-photo/${photoId}`);
  }

  public async getCustomerAbilities(customerId: number): Promise<ApiResponse<CustomerAbilityDefinitionAllResponse>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-customer-all-abilities/${customerId}`);
    return response.data;
  }

  public async searchCustomer(plate: string): Promise<ApiResponse<Customer>> {
    const response = await this.apiClient.get(`${BASE_URL}/search-customers`, {
      params: {
        plate,
      },
    });
    return response.data;
  }

  public async searchCustomer2(searchCustomerParams: SearchCustomerParams): Promise<ApiResponse<Customer[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/search-customers-v2`, {
      params: {
        plate: searchCustomerParams.plate !== '' ? searchCustomerParams.plate : null,
        chassisNumber: searchCustomerParams.chassisNumber !== '' ? searchCustomerParams.chassisNumber : null,
        startDate: searchCustomerParams.startDate !== '' ? searchCustomerParams.startDate : null,
        endDate: searchCustomerParams.endDate !== '' ? searchCustomerParams.endDate : null,
        brandId: searchCustomerParams.brandId !== '' ? +searchCustomerParams.brandId : null,
        modelId: searchCustomerParams.modelId !== '' ? +searchCustomerParams.modelId : null,
        vehicleTypeId: searchCustomerParams.vehicleTypeId !== '' ? +searchCustomerParams.vehicleTypeId : null,
        customerName: searchCustomerParams.customerName !== '' ? searchCustomerParams.customerName : null,
      },
    });
    return response.data;
  }

  public async sendKVKKSms(
    customerId: number,
    customerName: string,
    phoneNumber: string,
  ): Promise<ApiResponse<SendKvKKRequestResponse>> {
    const response = await this.apiClient.post(`${CUSTOMER_AGREEMENT_BASE_URL}/send-kvkk-agreement-v2`, {
      customerId,
      customerName,
      phoneNumber,
    });
    return response.data;
  }

  public async approveKvKK(
    customerId: number,
    phoneNumber: string,
    confirmationCode: number,
  ): Promise<ApiResponse<boolean>> {
    const response = await this.apiClient.post(`${CUSTOMER_AGREEMENT_BASE_URL}/approve-kvkk-agreement-v2`, {
      customerId,
      phoneNumber,
      confirmationCode,
    });
    return response.data;
  }

  public async saveCustomerFile(
    customerId: number,
    fileType: CustomerExpertiseFileType,
    file: File,
  ): Promise<ApiResponse<CustomerTestResultFileUploadResponse>> {
    const formData = new FormData();
    formData.append('customerId', customerId.toString());
    formData.append('fileTypeId', fileType.toString());
    formData.append('files', file);

    const response = await this.apiClient.post(`${BASE_URL_CUSTOMER_FILE}/save`, formData);
    return response.data;
  }

  public async getCustomerFile(
    customerId: number,
    fileTypeId: CustomerExpertiseFileType,
  ): Promise<ApiResponse<CustomerTestResultFile>> {
    const response = await this.apiClient.get(`${BASE_URL_CUSTOMER_FILE}/get-file`, {
      params: {
        customerId,
        fileTypeId,
      },
    });
    return response.data;
  }

  public async hasExpertiseResultFromMachine(customerId: number): Promise<ApiResponse<boolean>> {
    const response = await await this.apiClient.get(`${BASE_URL}/has-expertise-result-from-machine/${customerId}`);
    return response.data;
  }

  public async getGasketLeakTestResult(customerId: number): Promise<ApiResponse<GasLeakInfo>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-gasket-leak-test-result/${customerId}`);
    return response.data;
  }

  public async sendGasketLeakTestResult(resultType: number, customerId: number): Promise<ApiResponse<GasLeakInfo>> {
    const response = await this.apiClient.post(`${BASE_URL}/save-gasket-leak-test-result`, {
      customerId,
      resultType,
    });
    return response.data;
  }

  public async checkAbilityCompleted(
    customerId?: number,
    abilityDefinitionId?: ExpertisePackageAbilityDefinitionType,
  ): Promise<ApiResponse<boolean>> {
    const response = await this.apiClient.get(`${BASE_URL}/check-ability-completed/${customerId}`, {
      params: {
        abilityDefinitionId,
      },
    });
    return response.data;
  }

  public async checkSetAsCompleted(customerId: number) {
    const response = await this.apiClient.post(`${BASE_URL}/check-old-body-damages/${customerId}`);
    return response.data;
  }

  public async getWorkOrderForm(customerId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.get(`${CUSTOMER_AGREEMENT_BASE_URL}/get-work-order-form/${customerId}`);
    return response.data;
  }

  public async checkCustomerIsEdıtable(customerId: number): Promise<ApiResponse<boolean>> {
    const response = await this.apiClient.get(`${BASE_URL}/check-customer-editable/${customerId}`);
    return response.data;
  }

  public async editCustomer(description: string, customerId: number): Promise<ApiResponse<boolean>> {
    const response = await this.apiClient.post(`${BASE_URL}/edit-customer`, {
      customerId,
      description,
    });
    return response.data;
  }

  public async editCustomerByAdmin(customerId: number): Promise<ApiResponse<boolean>> {
    const response = await this.apiClient.post(`${BASE_URL}/edit-customer-by-admin`, {
      customerId,
    });
    return response.data;
  }
}
