import React, { ReactElement } from 'react';
import { Alert, AlertIcon, Link } from '@chakra-ui/react';

export interface DownloadAlertProps {
  label: string;
  icon: ReactElement;
  href: string;
}

export const DownloadAlert: React.FC<DownloadAlertProps> = ({ href, icon, label }) => {
  return (
    <Alert status="info">
      <AlertIcon />
      <Link href={href}>
        <>
          {label} {icon}
        </>
      </Link>
    </Alert>
  );
};
