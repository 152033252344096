// #region Enums

export const enum ReportingQueryCacheKey {
  GetSalesReport = 'GetSalesReport',
  GetPackageSalesReport = 'GetPackageSalesReport',
  GetQueriesReport = 'GetQueriesReport',
}

// #endregion

// ##region Types

export interface SaleReportRowResponse {
  date: string;
  count: string;
  amount: string;
}

export interface SaleReportResponse {
  sales: SaleReportRowResponse[];
  totalSalesCount: number;
  totalSalesAmount: number;
}

export interface SaleReportAdminResponse extends SaleReportResponse {
  royaltiAmount?: number;
}

export interface AdminSaleReportGraphData {
  dateLabels: string[];
  saleReportLineData: number[];
  countReportBarData: number[];
}

export interface ExpertisePackageReportRowResponse {
  id: number;
  name: string;
}

export interface ExpertisePackageReportResponse {
  expertisePackage: ExpertisePackageReportRowResponse;
  count: number;
}

export interface AdminPackageSaleReportGraphData {
  packageLabels: string[];
  packageSales: number[];
}

export interface QueriesReportKmRowResponse {
  date: string;
  count: string;
}

export interface QueriesReportTramerRowResponse {
  date: string;
  count: string;
}

export interface QueriesReportResponse {
  kilometerQueries: QueriesReportKmRowResponse[];
  tramerQueries: QueriesReportTramerRowResponse[];
}

export interface QueriesReportAdminResponse extends QueriesReportResponse {
  totalQueriesAmount: number;
  totalQueriesCount: number;
  totalTramerQueriesCount?: number;
  totalTramerQueriesAmount?: number;
  totalKilometerQueriesCount?: number;
  totalKilometerQueriesAmount?: number;
}

export interface AdminQueriesReportGraphData {
  dateLabels: string[];
  tramerReportBarData: number[];
  kmReportBarData: number[];
  totalCountReportLineData: number[];
}
// #endregion
