import { LoginInfo, ApiBase, ApiResponse } from 'types';

const BASE_URL = 'auth';

export class AuthApi extends ApiBase {
  public async login(email: string, password: string): Promise<ApiResponse<LoginInfo>> {
    const response = await this.apiClient.post(`${BASE_URL}/login`, {
      email,
      password,
    });

    return response.data;
  }
}
