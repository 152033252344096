/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpertiseReportFileInfo } from 'types';

export interface ExpertiseReportState {
  expertiseReportFiles: ExpertiseReportFileInfo[];
  isExpertiseReportFilesDialogOpen: boolean;
}

const initialState: ExpertiseReportState = { expertiseReportFiles: [], isExpertiseReportFilesDialogOpen: false };

export const expertiseReportSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    onExpertiseReportFilesDialogOpen: (state) => {
      state.isExpertiseReportFilesDialogOpen = true;
    },
    onExpertiseReportFilesDialogClose: (state) => {
      state.isExpertiseReportFilesDialogOpen = false;
    },
    setExpertiseReportFileInfos: (state, payload: PayloadAction<ExpertiseReportFileInfo[]>) => {
      state.expertiseReportFiles = payload.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { onExpertiseReportFilesDialogOpen, onExpertiseReportFilesDialogClose, setExpertiseReportFileInfos } =
  expertiseReportSlice.actions;

export const { reducer: expertiseReportReducer } = expertiseReportSlice;
