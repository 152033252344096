import { Button, Divider, HStack } from '@chakra-ui/react';
import React from 'react';

export interface DateRangePreSelectorProps {
  onDateRangeSelected: (dateBegin: Date, dateEnd: Date) => void;
}

export const DateRangePreSelector: React.FC<DateRangePreSelectorProps> = ({ onDateRangeSelected }) => {
  return (
    <HStack gap={4} my={2} height="20px">
      <Button
        colorScheme="teal"
        variant="link"
        onClick={() => {
          onDateRangeSelected(new Date(), new Date());
        }}
      >
        Bugün
      </Button>
      <Divider orientation="vertical" />
      <Button
        colorScheme="teal"
        variant="link"
        onClick={() => {
          const currentDate = new Date();
          const day = currentDate.getDay();
          const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
          onDateRangeSelected(new Date(currentDate.setDate(diff)), new Date());
        }}
      >
        Bu Hafta
      </Button>
      <Divider orientation="vertical" />
      <Button
        colorScheme="teal"
        variant="link"
        onClick={() => {
          const currentDate = new Date();
          onDateRangeSelected(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), currentDate);
        }}
      >
        Bu Ay
      </Button>
    </HStack>
  );
};
