import { Button, ButtonProps, Menu, MenuButton, MenuItem, MenuList, Text, useToken, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ExpertiseChassisDamageType, ExpertiseChassisDamageTypeLabel, ExpertiseChassisCarBodyPartName } from 'types';
import { ExpertiseBodyworkExpertiseStates } from '../models';

export interface ExpertiseBodyworkExpertiseChassisStateSelectorProps extends Omit<ButtonProps, 'children'> {
  expertiseKeyName: ExpertiseChassisCarBodyPartName;
  displayId: number;
  onBodyworkExpertiseChanged: (
    expertiseKeyName: ExpertiseChassisCarBodyPartName,
    expertiseBodyworkExpertiseState: ExpertiseChassisDamageType,
  ) => void;
  value: ExpertiseChassisDamageType;
  label: string;
  isDisabled?: boolean;
}

export const ExpertiseBodyworkExpertiseChassisStateSelector: React.FC<
  ExpertiseBodyworkExpertiseChassisStateSelectorProps
> = ({ value, onBodyworkExpertiseChanged, expertiseKeyName, label, isDisabled, ...buttonProps }) => {
  const [originalColor, paintedColor, changedColor, cutColor, processedColor, damagedColor, sunroofColor] = useToken(
    // the key within the theme, in this case `theme.colors`
    'colors',
    // the subkey(s), resolving to `theme.colors.red.100`
    [
      'gray.900',
      'red.400',
      'green.300',
      'orange.400',
      'orange.900',
      'cyan.400',
      'purple.400',
      'pink.400',
      'yellow.400',
      'red.600',
      'yellow.600',
    ],
    // a single fallback or fallback array matching the length of the previous arg
  );

  const lookupTable = useMemo(
    () => ({
      [ExpertiseChassisDamageType.Original]: {
        bg: originalColor,
        label: ExpertiseBodyworkExpertiseStates.Original,
      },
      [ExpertiseChassisDamageType.Painted]: {
        bg: paintedColor,
        label: ExpertiseBodyworkExpertiseStates.Painted,
      },
      [ExpertiseChassisDamageType.Changed]: {
        bg: changedColor,
        label: ExpertiseBodyworkExpertiseStates.Changed,
      },
      [ExpertiseChassisDamageType.Cutting]: {
        bg: cutColor,
        label: ExpertiseBodyworkExpertiseStates.Cutting,
      },
      [ExpertiseChassisDamageType.Processed]: {
        bg: processedColor,
        label: ExpertiseBodyworkExpertiseStates.Processed,
      },
      [ExpertiseChassisDamageType.Damaged]: {
        bg: damagedColor,
        label: ExpertiseBodyworkExpertiseStates.Damaged,
      },
      [ExpertiseChassisDamageType.Meek]: {
        bg: sunroofColor,
        label: ExpertiseBodyworkExpertiseStates.Meek,
      },
    }),
    [],
  );

  const onStateChange = (expertiseBodyworkExpertiseStates: ExpertiseChassisDamageType) => () => {
    onBodyworkExpertiseChanged(expertiseKeyName, expertiseBodyworkExpertiseStates);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        width={8}
        height={8}
        borderRadius="50%"
        variant="solid"
        {...buttonProps}
        bg={lookupTable[value]?.bg}
        _hover={{
          bg: lookupTable[value]?.bg,
        }}
        _active={{
          bg: lookupTable[value].bg,
        }}
      >
        <VStack spacing={0} width="100%" height="100%" justify="center">
          <Text fontSize="sm" color="white">
            {value}
          </Text>
        </VStack>
      </MenuButton>
      <MenuList>
        <MenuItem disabled isDisabled>
          {label}
        </MenuItem>
        {!isDisabled && (
          <>
            <MenuItem onClick={onStateChange(ExpertiseChassisDamageType.Original)}>
              {ExpertiseChassisDamageTypeLabel.get(ExpertiseChassisDamageType.Original)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseChassisDamageType.Painted)}>
              {ExpertiseChassisDamageTypeLabel.get(ExpertiseChassisDamageType.Painted)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseChassisDamageType.Changed)}>
              {ExpertiseChassisDamageTypeLabel.get(ExpertiseChassisDamageType.Changed)}
            </MenuItem>

            <MenuItem onClick={onStateChange(ExpertiseChassisDamageType.Cutting)}>
              {ExpertiseChassisDamageTypeLabel.get(ExpertiseChassisDamageType.Cutting)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseChassisDamageType.Processed)}>
              {ExpertiseChassisDamageTypeLabel.get(ExpertiseChassisDamageType.Processed)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseChassisDamageType.Meek)}>
              {ExpertiseChassisDamageTypeLabel.get(ExpertiseChassisDamageType.Meek)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseChassisDamageType.Damaged)}>
              {ExpertiseChassisDamageTypeLabel.get(ExpertiseChassisDamageType.Damaged)}
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

ExpertiseBodyworkExpertiseChassisStateSelector.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  position: 'absolute',
  // eslint-disable-next-line react/default-props-match-prop-types
  bg: 'gray.900',
};
