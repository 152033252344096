import React, { useEffect, useState, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import {
  Flex,
  Heading,
  Spacer,
  Button,
  Stack,
  Center,
  Text,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { useQuery } from 'react-query';
import { AppDispatch, RootState } from 'store';
import { Api } from 'api';
import {
  CustomerQueryCacheKey,
  ProductTypeQueryCacheKey,
  CustomerExpertiseFileType,
  ExpertisePackageAbilityDefinitionType,
  ProductType,
  ApplicationToggleType,
  ExpertiseDamageNoteType,
} from 'types';
import { DownloadAlert, GasketLeakResults, KmResults, LoadingText, NoteAutoCompleteSelect } from 'components';
import { useGetUploadedClientFile, useUploadClientFile, useCheckAbilityCompleted } from 'core';
import { createCustomerSocket, createToggleSocket } from 'core/socket';
import { hideLoading, showLoading } from 'features';

export const ExpertiseMechanicInformationPage: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const { isOpen: isKmResultModalOpen, onOpen: onKmResultModalOpen, onClose: onKmResultModalClose } = useDisclosure();
  const {
    isOpen: isKmResultWarningModalOpen,
    onOpen: onKmResultWarningModalOpen,
    onClose: onKmResultWarningModalClose,
  } = useDisclosure();
  const {
    isOpen: isGasketLeakResultModalOpen,
    onOpen: onGasketLeakResultModalOpen,
    onClose: onGasketLeakResultModalClose,
  } = useDisclosure();

  const customer = useSelector((state: RootState) => state.customer.focusedCustomer);
  const canCustomerContinue = useSelector((state: RootState) => state.customer.canCustomerContinue);
  const customerAbilityDefinitionTable = useSelector(
    (state: RootState) => state.customer.customerAbilityDefinitionTable,
  );
  const testResultFileInputRef = useRef<HTMLInputElement>(null);
  const kmTestResultFileInputRef = useRef<HTMLInputElement>(null);
  const [isKmKaydiEnabled, setIsKmKaydiEnabled] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toggleSocket, setToggleSocket] = useState<Socket | null>(null);

  const onUploadTestResultsClicked = (fileType: CustomerExpertiseFileType) => () => {
    let ref: React.RefObject<HTMLInputElement>;
    switch (fileType) {
      case CustomerExpertiseFileType.FaultDetectionResult:
        ref = testResultFileInputRef;
        break;
      case CustomerExpertiseFileType.KMDetectionResult:
        ref = kmTestResultFileInputRef;
        break;
      default:
        ref = testResultFileInputRef;
    }
    if (ref.current) {
      ref.current.click();
    }
  };

  const uploadTestResultFileMutation = useUploadClientFile(
    customer?.id,
    CustomerExpertiseFileType.FaultDetectionResult,
    {
      onMutate: () => {
        dispatch(showLoading());
      },
      onSettled: () => {
        dispatch(hideLoading());
        if (testResultFileInputRef.current) {
          testResultFileInputRef.current.value = '';
        }
        // eslint-disable-next-line no-use-before-define
        refetchFaultDetectionResultFileResponse();
        // eslint-disable-next-line no-use-before-define
        refetchIsUploadTestResultsCompleted();
      },
      onError: (error: any) => {
        console.error(error);
        toast({
          status: 'error',
          title: 'Dosya yüklenirken hata oluştu',
          isClosable: true,
        });
      },
    },
  );

  const uploadKMTestResultFileMutation = useUploadClientFile(
    customer?.id,
    CustomerExpertiseFileType.KMDetectionResult,
    {
      onMutate: () => {
        dispatch(showLoading());
      },
      onSettled: () => {
        dispatch(hideLoading());
        if (kmTestResultFileInputRef.current) {
          kmTestResultFileInputRef.current.value = '';
        }
        // eslint-disable-next-line no-use-before-define
        refetchKmTestDetectionResultFileResponse();
        // eslint-disable-next-line no-use-before-define
        refetchIsKmDetectionResultCompleted();
      },
      onError: (error: any) => {
        console.error(error);
        toast({
          status: 'error',
          title: 'Dosya yüklenirken hata oluştu',
          isClosable: true,
        });
      },
    },
  );

  const { data: gasketLeakTestResultFileResponse, refetch: refetchGasketLeakTestDetectionResultFileResponse } =
    useGetUploadedClientFile(customer?.id, CustomerExpertiseFileType.GasketLeakResult, {
      onError: (err: any) => {
        console.error(err);
        toast({
          title: 'Conta Kaçak Test sonuçları çekilirken hata oluştu',
          status: 'error',
        });
      },
    });

  const { data: faultDetectionResultFileResponse, refetch: refetchFaultDetectionResultFileResponse } =
    useGetUploadedClientFile(customer?.id, CustomerExpertiseFileType.FaultDetectionResult, {
      onError: (err: any) => {
        console.error(err);
        toast({
          title: 'Araç testi sonuçları çekilirken hata oluştu',
          status: 'error',
        });
      },
    });

  const { data: kmTestResultFileResponse, refetch: refetchKmTestDetectionResultFileResponse } =
    useGetUploadedClientFile(customer?.id, CustomerExpertiseFileType.KMDetectionResult, {
      onError: (err: any) => {
        console.error(err);
        toast({
          title: 'KM testi sonuçları çekilirken hata oluştu',
          status: 'error',
        });
      },
    });

  const { data: isKmKaydiResultCompleted, refetch: refetchIsKmKaydiResultCompleted } = useCheckAbilityCompleted(
    customer?.id,
    ExpertisePackageAbilityDefinitionType.KMKaydiSorgulama,
  );

  const { data: isUploadTestResultsCompleted, refetch: refetchIsUploadTestResultsCompleted } = useCheckAbilityCompleted(
    customer?.id,
    ExpertisePackageAbilityDefinitionType.ArizaTespitSonuclari,
  );

  const { data: isKmDetectionResultCompleted, refetch: refetchIsKmDetectionResultCompleted } = useCheckAbilityCompleted(
    customer?.id,
    ExpertisePackageAbilityDefinitionType.KMTespitSonuclari,
  );

  const { data: isGasketLeakResultCompleted, refetch: refetchIsGasketLeakResultCompleted } = useCheckAbilityCompleted(
    customer?.id,
    ExpertisePackageAbilityDefinitionType.ContaKacakTespitSonuclari,
  );

  const {
    data: testResult,
    isLoading: isTestResultLoading,
    isError: isTestResultHasError,
  } = useQuery(
    CustomerQueryCacheKey.HasExpertiseResultFromMachine,
    async () => {
      try {
        if (customer?.id) {
          const response = await Api.Customer.hasExpertiseResultFromMachine(customer.id);
          return response.data;
        }
        return false;
      } catch (e) {
        throw Error();
      }
    },
    {
      onError: () => {
        toast({
          title: 'Test Sonuçları kontrolü sırasında hata oluştu',
          status: 'error',
        });
      },
      enabled: customer != null,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  const { data: customerVehicle, isLoading } = useQuery(
    CustomerQueryCacheKey.GetCustomerVehicle,
    async () => {
      const response = await Api.Customer.getCustomerVehicle(customer!.id);
      if (!response.data) {
        return null;
      }
      const getCustomerVehicleResponse = response.data;
      return getCustomerVehicleResponse.customerVehicle;
    },
    {
      cacheTime: 0,
      retry: 0,
      enabled: customer != null,
      refetchOnWindowFocus: false,
    },
  );

  const { data: KmResultProductPrice } = useQuery(
    ProductTypeQueryCacheKey.GetKilometerQuery,
    async () => {
      const response = await Api.Util.getProductPriceByType(ProductType.KilometerQuery);
      return response.data;
    },
    {
      enabled: customer != null && isKmResultWarningModalOpen,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (error: any) => {
        const {
          response: {
            data: { message },
          },
        } = error;
        toast({
          title: message,
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  useQuery(
    ['getToggleEnabledKMKaydi', ApplicationToggleType.KmQuery],
    async () => {
      const response = await Api.Util.getToggleEnabled(ApplicationToggleType.KmQuery);
      return response?.data;
    },
    {
      onSuccess: (data) => {
        setIsKmKaydiEnabled(data?.isEnabled);
      },
      onError: () => {
        toast({
          title: 'Şu an oto sorgudaki arıza sebebi ile km sorgulama işleminiz yapılmamıştır',
          status: 'error',
        });
      },
      refetchOnWindowFocus: false,
    },
  );

  const onKmResultButtonClicked = () => {
    onKmResultWarningModalOpen();
  };

  useEffect(() => {
    if (customer) {
      const newCustomerSocket = createCustomerSocket(customer.id.toString());
      setCustomerSocket(newCustomerSocket);

      newCustomerSocket.on(
        'customerAbilityCompleted',
        ({ customerId, abilityDefinitionType }: { customerId: number; abilityDefinitionType: number }) => {
          if (Number(customerId) === customer.id) {
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.KMKaydiSorgulama) {
              refetchIsKmKaydiResultCompleted();
            }
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.ArizaTespitSonuclari) {
              refetchIsUploadTestResultsCompleted();
            }
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.KMTespitSonuclari) {
              refetchIsKmDetectionResultCompleted();
            }
            if (abilityDefinitionType === ExpertisePackageAbilityDefinitionType.ContaKacakTespitSonuclari) {
              refetchIsGasketLeakResultCompleted();
            }
          }
        },
      );

      return () => {
        newCustomerSocket.disconnect();
      };
    }
    return undefined;
  }, [customer]);

  useEffect(() => {
    const newToggleSocket = createToggleSocket();
    setToggleSocket(newToggleSocket);

    newToggleSocket.on('toggleChanged', (data: { type: ApplicationToggleType; isEnabled: boolean }) => {
      if (data?.type === ApplicationToggleType.KmQuery) {
        setIsKmKaydiEnabled(data?.isEnabled);
      }
    });

    return () => {
      newToggleSocket.disconnect();
    };
  }, []);

  if (isLoading || !customer) {
    return (
      <Center>
        <LoadingText />
      </Center>
    );
  }
  return (
    <>
      {customerVehicle && customerVehicle.chassisNumber && KmResultProductPrice && (
        <>
          <KmResults
            customerId={customer?.id}
            isOpen={isKmResultModalOpen}
            onClose={() => {
              onKmResultModalClose();
              refetchIsKmKaydiResultCompleted();
            }}
          />
          <Modal isOpen={isKmResultWarningModalOpen} onClose={onKmResultWarningModalClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>KM Kaydı Sorgu Onay?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  KM Sorgusu için ₺{KmResultProductPrice} sorgu bedeli kullanılacaktır. Devam etmek istiyor musunuz ?
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    onKmResultModalOpen();
                    onKmResultWarningModalClose();
                  }}
                >
                  Evet
                </Button>
                <Button onClick={onKmResultWarningModalClose}>Hayır</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}

      {customer && (
        <GasketLeakResults
          customerId={customer?.id}
          isOpen={isGasketLeakResultModalOpen}
          onClose={() => {
            onGasketLeakResultModalClose();
            refetchGasketLeakTestDetectionResultFileResponse();
            refetchIsGasketLeakResultCompleted();
          }}
        />
      )}
      <input
        type="file"
        accept="application/pdf"
        hidden
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files && e.target.files.length === 1) {
            uploadTestResultFileMutation.mutate(e.target.files[0]);
          }
        }}
        ref={testResultFileInputRef}
      />
      <input
        type="file"
        accept="application/pdf"
        hidden
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files && e.target.files.length === 1) {
            uploadKMTestResultFileMutation.mutate(e.target.files[0]);
          }
        }}
        ref={kmTestResultFileInputRef}
      />
      <Flex align="stretch" direction="column" basis="100%" gap="2">
        <Heading as="h4" size="md">
          Mekanik İnceleme
        </Heading>
        <Spacer />
        {faultDetectionResultFileResponse && (
          <DownloadAlert
            href={faultDetectionResultFileResponse.url}
            label="Arıza Tespit Sonucu İndir"
            icon={<DownloadIcon />}
          />
        )}
        {kmTestResultFileResponse && (
          <DownloadAlert href={kmTestResultFileResponse.url} label="KM Tespit Sonucu İndir" icon={<DownloadIcon />} />
        )}

        {gasketLeakTestResultFileResponse && (
          <DownloadAlert
            href={gasketLeakTestResultFileResponse.url}
            label="Conta Kaçak Test Sonucu İndir"
            icon={<DownloadIcon />}
          />
        )}

        {(!isTestResultLoading || isTestResultHasError) &&
          customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.EkspertizCihaziTestSonuclari] && (
            <Alert status={testResult ? 'success' : 'error'}>
              <AlertIcon />
              {testResult ? 'Test verileri yüklü' : 'Test verileri yüklü değil'}
            </Alert>
          )}

        <Stack direction={['column', 'column', 'column', null, 'row']} spacing="24px">
          <Button
            border={isKmKaydiResultCompleted ? '2px solid green' : 'none'}
            onClick={onKmResultButtonClicked}
            disabled={
              !customerVehicle?.chassisNumber ||
              !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.KMKaydiSorgulama] ||
              !isKmKaydiEnabled
            }
          >
            KM Kaydi Sorgula
          </Button>
          <Button
            border={isUploadTestResultsCompleted ? '2px solid green' : 'none'}
            disabled={!customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.ArizaTespitSonuclari]}
            onClick={onUploadTestResultsClicked(CustomerExpertiseFileType.FaultDetectionResult)}
          >
            Arıza Tespit Test Sonuçlarını Yükle
          </Button>
          <Button
            border={isKmDetectionResultCompleted ? '2px solid green' : 'none'}
            disabled={!customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.KMTespitSonuclari]}
            onClick={onUploadTestResultsClicked(CustomerExpertiseFileType.KMDetectionResult)}
          >
            KM Test Sonuçlarını Yükle
          </Button>
          <Button
            border={isGasketLeakResultCompleted ? '2px solid green' : 'none'}
            disabled={!customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.ContaKacakTespitSonuclari]}
            onClick={() => {
              onGasketLeakResultModalOpen();
            }}
          >
            Conta Kaçak Test Sonuçlarını Yükle
          </Button>
        </Stack>
        <Spacer />

        <NoteAutoCompleteSelect
          customerId={customer.id}
          noteType={ExpertiseDamageNoteType.Mechanic}
          isDisabled={
            !canCustomerContinue ||
            !customerAbilityDefinitionTable[ExpertisePackageAbilityDefinitionType.MotorMekanikKontrolNotlari]
          }
        />
      </Flex>
    </>
  );
};
