import { DownloadIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Box,
  DrawerFooter,
  Button,
  VStack,
  Image,
  // HStack,
  // Radio,
  Center,
  useToast,
  Portal,
  Checkbox,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Modal,
  useDisclosure,
  Icon,
  ModalBody,
} from '@chakra-ui/react';
import { Api } from 'api';
import { LoadingText } from 'components';
import { createCustomerSocket, getExpertiseBackroundImageUrl } from 'core';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  BodyPartAnswerInfo,
  BodyPartExpertiseInfo,
  CheckOldBodyDamagesResponse,
  ExpertiseBodyDamagePartType,
  ExpertiseBodyDamageType,
  ExpertiseCarBodyPartName,
  ExpertiseCarBodyTypes,
  VehicleQueryCacheKeys,
} from 'types';
import { Socket } from 'socket.io-client';
import { ExpertiseBodyworkExpertiseStateSelector } from './ExpertiseBodyworkExpertiseStateSelector';

export interface ExpertiseBodyworkExpertiseDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  carBodyType: ExpertiseCarBodyTypes;
  isDisabled?: boolean;
}

type ExpertisePartsData = {
  [key: number]: ExpertiseBodyDamageType;
};

type ExpertiseQuestionData = {
  [key: number]: string;
};

type ExpertiseSaveData = { expertises: ExpertisePartsData; answers: ExpertiseQuestionData };

type PositionLookupTableRow = {
  left?: string;
  bottom?: string;
  top?: string;
  right?: string;
};

type PositionLookupTable = {
  [key in ExpertiseCarBodyPartName]: PositionLookupTableRow;
};

const genericPositionLookupTable: PositionLookupTable = {
  [ExpertiseCarBodyPartName.SagOnSasi]: {
    left: '0%',
    top: '15%',
  },
  [ExpertiseCarBodyPartName.SagOnCamurluk]: {
    left: '20%',
    top: '13%',
  },
  [ExpertiseCarBodyPartName.SagOnKapi]: {
    left: '40%',
    top: '10%',
  },
  [ExpertiseCarBodyPartName.SagMarspiyel]: {
    left: '50%',
    top: '2%',
  },
  [ExpertiseCarBodyPartName.SagArkaCamurluk]: {
    left: '75%',
    top: '13%',
  },
  [ExpertiseCarBodyPartName.SagArkaKapi]: {
    left: '60%',
    top: '10%',
  },
  [ExpertiseCarBodyPartName.SagArkaSasi]: {
    right: '0%',
    top: '12%',
  },

  [ExpertiseCarBodyPartName.OnTampon]: {
    left: '2%',
    top: '35%',
  },
  [ExpertiseCarBodyPartName.OnPanel]: {
    left: '2%',
    top: '55%',
  },
  [ExpertiseCarBodyPartName.Kaput]: {
    left: '20%',
    top: '45%',
  },
  [ExpertiseCarBodyPartName.Tavan]: {
    left: '58%',
    top: '45%',
  },
  [ExpertiseCarBodyPartName.BagajKapagi]: {
    left: '80%',
    top: '45%',
  },
  [ExpertiseCarBodyPartName.ArkaTampon]: {
    left: '95%',
    top: '35%',
  },
  [ExpertiseCarBodyPartName.ArkaPanel]: {
    left: '95%',
    top: '55%',
  },

  [ExpertiseCarBodyPartName.SolOnSasi]: {
    left: '0%',
    bottom: '15%',
  },
  [ExpertiseCarBodyPartName.SolOnCamurluk]: {
    left: '20%',
    bottom: '13%',
  },
  [ExpertiseCarBodyPartName.SolOnKapi]: {
    left: '40%',
    bottom: '10%',
  },
  [ExpertiseCarBodyPartName.SolMarspiyel]: {
    left: '50%',
    bottom: '2%',
  },
  [ExpertiseCarBodyPartName.SolArkaCamurluk]: {
    left: '75%',
    bottom: '13%',
  },
  [ExpertiseCarBodyPartName.SolArkaKapi]: {
    left: '60%',
    bottom: '10%',
  },
  [ExpertiseCarBodyPartName.SolArkaSasi]: {
    right: '0%',
    bottom: '12%',
  },
};

const VehicleTypePositionTable: {
  [key in ExpertiseCarBodyTypes]: PositionLookupTable;
} = {
  [ExpertiseCarBodyTypes.Sedan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Hatchback5]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Hatchback2]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Pickup]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Pickup2]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Minibus]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Panelvan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.CanliPanelvan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.TekSurguPanelvan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.SUV]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Station]: genericPositionLookupTable,
};

export const ExpertiseBodyworkExpertiseDrawer: React.FC<ExpertiseBodyworkExpertiseDrawerProps> = ({
  isOpen,
  onClose,
  carBodyType,
  isDisabled,
}) => {
  const customer = useSelector((selector: RootState) => selector.customer.focusedCustomer);
  const inputPositionTable = useMemo(() => VehicleTypePositionTable[carBodyType], [carBodyType]);
  const toast = useToast();
  const allPaintedRef = useRef<HTMLInputElement>(null);
  const [isAllPainted, setIsAllPainted] = useState(false);
  const [oldReportUrl, setOldReportUrl] = useState('');
  const {
    isOpen: checkOldDamagesModalIsOpen,
    onOpen: checkOldDamagesModalOnOpen,
    onClose: checkOldDamagesModalOnClose,
  } = useDisclosure();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);
  const {
    data: expertises,
    isLoading: isExpertisesLoading,
    refetch: refetchExpertises,
  } = useQuery(
    [VehicleQueryCacheKeys.GetBodyWorkExpertises, customer!.id],
    async () => {
      const { data: expertiseResponse } = await Api.Expertise.getCustomerBodyPartsExpertise(
        customer!.id,
        ExpertiseBodyDamagePartType.Expertise,
      );
      const { expertiseBodyDamages, expertiseBodyAnswers } = expertiseResponse;

      const filteredExpertiseBodyDamages = expertiseBodyDamages.filter(
        (bodyDamage: BodyPartExpertiseInfo) =>
          bodyDamage!.expertiseBodyDamagePart!.vehicleType?.name === customer!.customerVehicle!.vehicleType?.name,
      );
      if (filteredExpertiseBodyDamages.length) {
        if (filteredExpertiseBodyDamages?.some((bodyDamage) => bodyDamage.expertiseBodyDamageValue !== 'B')) {
          setIsAllPainted(false);
        } else {
          setIsAllPainted(true);
        }
      } else {
        setIsAllPainted(false);
      }

      return {
        expertises: expertiseBodyDamages.reduce((accum: any, nextValue: BodyPartExpertiseInfo) => {
          // eslint-disable-next-line no-param-reassign
          accum[nextValue.expertiseBodyDamagePartId] = nextValue.expertiseBodyDamageValue;
          return accum;
        }, {} as ExpertisePartsData),
        answers: expertiseBodyAnswers.reduce((accum: any, nextValue: BodyPartAnswerInfo) => {
          // eslint-disable-next-line no-param-reassign
          accum[nextValue.expertiseBodyQuestionId] = nextValue.answer;
          return accum;
        }, {} as ExpertiseQuestionData),
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: customer != null && isOpen,
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Boyalı Değişen verileri çekilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const { data: expertiseTemplates, isLoading: isExpertisesTemplateLoading } = useQuery(
    VehicleQueryCacheKeys.GetBodyWorkExpertiseTemplates,
    async () => {
      const response = await Api.Expertise.getExpertiseTemplate(
        customer!.customerVehicle!.vehicleTypeId,
        ExpertiseBodyDamagePartType.Expertise,
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: customer != null && isOpen,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Boyalı değişen işlemleri için gereken verileri çekerken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      answers: (expertises?.answers || {}) as ExpertiseQuestionData,
      expertises: (expertises?.expertises || {}) as ExpertisePartsData,
    },
    onSubmit: async (data: ExpertiseSaveData) => {
      if (expertiseTemplates) {
        const filledExpertises = expertiseTemplates.expertiseBodyDamageParts.reduce((accum, next) => {
          // eslint-disable-next-line no-param-reassign
          accum[next.id] = data.expertises[next.id] || ExpertiseBodyDamageType.Original;
          return accum;
        }, {} as any);

        const filledAnswers = expertiseTemplates.expertiseBodyQuestions.reduce((accum, next) => {
          // eslint-disable-next-line no-param-reassign
          accum[next.id] = data.answers[next.id] || '0';
          return accum;
        }, {} as any);

        if (checkOldDamagesModalIsOpen) {
          checkOldDamagesModalOnClose();
          // eslint-disable-next-line no-use-before-define
          saveExpertiseMutate.mutateAsync({ expertises: filledExpertises, answers: filledAnswers });
        } else {
          // eslint-disable-next-line no-use-before-define
          const oldResults = await checkOldDamageMutate.mutateAsync({
            expertises: filledExpertises,
            answers: filledAnswers,
          });
          if (!oldResults.hasInconsistency) {
            // eslint-disable-next-line no-use-before-define
            saveExpertiseMutate.mutateAsync({ expertises: filledExpertises, answers: filledAnswers });
          } else {
            setOldReportUrl(oldResults.oldReportUrl!);
            checkOldDamagesModalOnOpen();
          }
        }
      }
    },
  });
  const saveExpertiseMutate = useMutation<void, any, ExpertiseSaveData>(
    async (data: ExpertiseSaveData) => {
      await Api.Expertise.saveExpertiseBodyParts({
        customerId: customer!.id,
        expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType.Expertise,
        bodyDamageAnswers: Object.entries(data.answers).map(([key, value]) => {
          return {
            answer: value,
            expertiseBodyQuestionId: parseInt(key, 10),
          };
        }),
        bodyDamageParts: Object.entries(data.expertises).map(([key, value]) => {
          return {
            bodyDamageValue: value,
            expertiseBodyDamagePartId: parseInt(key, 10),
          };
        }),
      });
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: 'Başarı ile kayıt edildi',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Kayıt sırasında hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const checkOldDamageMutate = useMutation<CheckOldBodyDamagesResponse, any, ExpertiseSaveData>(
    async (data: ExpertiseSaveData) => {
      const response = await Api.Expertise.checkOldBodyDamages({
        customerId: customer!.id,
        expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType.Expertise,
        bodyDamageAnswers: Object.entries(data.answers).map(([key, value]) => {
          return {
            answer: value,
            expertiseBodyQuestionId: parseInt(key, 10),
          };
        }),
        bodyDamageParts: Object.entries(data.expertises).map(([key, value]) => {
          return {
            bodyDamageValue: value,
            expertiseBodyDamagePartId: parseInt(key, 10),
          };
        }),
      });
      return response.data;
    },
    {
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Kontrol sırasında hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
  const { setFieldValue, values, handleSubmit, resetForm } = formik;
  const onBodyworkExpertiseChanged = (
    expertiseKeyName: ExpertiseCarBodyPartName,
    expertiseBodyworkExpertiseState: ExpertiseBodyDamageType,
  ) => {
    setFieldValue(`expertises.${expertiseKeyName}`, expertiseBodyworkExpertiseState);
  };

  const selectAllPainted = () => {
    if (allPaintedRef!.current?.checked) {
      expertiseTemplates?.expertiseBodyDamageParts.forEach((bodyDamagePart) => {
        setFieldValue(`expertises.${bodyDamagePart.id}`, 'B');
      });
      setIsAllPainted(true);
    } else {
      resetForm();
      setIsAllPainted(false);
    }
  };

  const expertiseImageUrl = getExpertiseBackroundImageUrl(carBodyType);

  useEffect(() => {
    if (customer && isOpen) {
      const newCustomerSocket = createCustomerSocket(customer.id.toString());
      setCustomerSocket(newCustomerSocket);

      const handleExpertiseBodyDamageChanged = (data: any) => {
        if (data.customer.id === customer.id) {
          refetchExpertises();
        }
      };

      newCustomerSocket.on('expertiseBodyDamageChanged', handleExpertiseBodyDamageChanged);

      return () => {
        newCustomerSocket.off('expertiseBodyDamageChanged', handleExpertiseBodyDamageChanged);
        newCustomerSocket.disconnect();
      };
    }
    return () => {
      if (customerSocket) {
        customerSocket.disconnect();
      }
    };
  }, [customer, isOpen, refetchExpertises]);

  const showLoading = !customer || !expertiseTemplates || isExpertisesTemplateLoading || isExpertisesLoading || !isOpen;

  return (
    <Portal>
      <Modal isOpen={checkOldDamagesModalIsOpen} onClose={checkOldDamagesModalOnClose} size="md" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uyarı</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Geçmiş ekspertiz raporuna göre boya değişenlerde değişiklik tespit edilmiştir.</ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              leftIcon={<Icon as={DownloadIcon} />}
              colorScheme="teal"
              onClick={() => {
                window.open(oldReportUrl);
              }}
            >
              Geçmiş Ekspertiz Raporu
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent overflow="auto">
          <form noValidate onSubmit={handleSubmit}>
            <DrawerCloseButton disabled={showLoading || saveExpertiseMutate.isLoading} />
            <DrawerHeader>Kaporta Boya Değişen Kontrolü</DrawerHeader>

            <DrawerBody>
              {showLoading ? (
                <Center>
                  <LoadingText />
                </Center>
              ) : (
                <VStack gap={24} align="stretch">
                  <VStack>
                    <Box width="100%" position="relative" maxWidth="450px">
                      <Image src={expertiseImageUrl} fit="fill" width="100%" />
                      <Box position="absolute" top="0" left="0" right="0" bottom="0">
                        {expertiseTemplates.expertiseBodyDamageParts.map((expertiseTemplate) => {
                          const partInfo = inputPositionTable[expertiseTemplate.displayId];
                          return (
                            <ExpertiseBodyworkExpertiseStateSelector
                              key={`ebw${expertiseTemplate.displayId}`}
                              expertiseKeyName={expertiseTemplate.id}
                              displayId={expertiseTemplate.displayId}
                              onBodyworkExpertiseChanged={onBodyworkExpertiseChanged}
                              value={values.expertises[expertiseTemplate.id] || ExpertiseBodyDamageType.Original}
                              left={partInfo.left}
                              top={partInfo.top}
                              bottom={partInfo.bottom}
                              right={partInfo.right}
                              label={expertiseTemplate.name}
                              isDisabled={isDisabled}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </VStack>
                  <Box>
                    <Box mb={4}>
                      <Checkbox
                        ref={allPaintedRef}
                        colorScheme="blue"
                        onChange={selectAllPainted}
                        isChecked={isAllPainted}
                      >
                        Tamamı Boyalı
                      </Checkbox>
                    </Box>
                    <Box height="xs" />

                    {/* {expertiseTemplates.expertiseBodyQuestions.map((expertiseBodyQuestion) => (
                      <FormControlFormRadioGroup
                        key={`bweq${expertiseBodyQuestion.id}`}
                        label={expertiseBodyQuestion.question}
                        onChange={(value) => {
                          setFieldValue(`answers.${expertiseBodyQuestion.id}`, value);
                        }}
                        value={values.answers[expertiseBodyQuestion.id] || '0'}
                        isDisabled={isDisabled}
                      >
                        <HStack spacing="24px">
                          <Radio value="1">Evet</Radio>
                          <Radio value="0">Hayır</Radio>
                        </HStack>
                      </FormControlFormRadioGroup>
                    ))} */}
                  </Box>
                </VStack>
              )}
            </DrawerBody>

            <DrawerFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={onClose}
                disabled={showLoading || saveExpertiseMutate.isLoading}
              >
                İptal
              </Button>
              {!isDisabled && (
                <Button type="submit" disabled={showLoading || saveExpertiseMutate.isLoading}>
                  Kaydet
                </Button>
              )}
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
    </Portal>
  );
};
