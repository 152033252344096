import {
  InputProps,
  InputAddonProps,
  InputGroupProps,
  Input,
  InputGroup,
  InputAddon,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  FormControlProps,
  FormControl,
  Select,
  SelectProps,
  TextareaProps,
  Textarea,
  RadioGroupProps,
  RadioGroup,
} from '@chakra-ui/react';
import React from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

export interface FormElementBaseProps {
  label?: string;
  error?: boolean;
  helperText?: string;
  isRequired?: boolean;
}

export interface FormControlBaseProps {
  formControlProps?: FormControlProps;
}

export interface FormControlGovIdProps {
  isIndividual?: boolean;
}

interface CustomFormControlProps extends FormControlProps {
  elementProps: FormElementBaseProps;
}

const CustomFormElement: React.FC<CustomFormControlProps> = ({ elementProps, children, ...formControlProps }) => {
  return (
    <FormControl isInvalid={elementProps.error} isRequired={elementProps.isRequired} {...formControlProps}>
      {children}
    </FormControl>
  );
};

// #region Form Input
export type FormInputProps = InputProps & FormElementBaseProps & FormControlGovIdProps;
export type FormControlFormInputProps = FormInputProps & FormControlBaseProps;

export const createFormControlInput = <
  ElemProps extends FormControlFormInputProps,
  NodeElemProps extends FormInputProps = any,
>(
  NodeElem: React.ForwardRefExoticComponent<NodeElemProps & FormInputProps & React.RefAttributes<HTMLInputElement>>,
) => {
  return React.forwardRef<HTMLInputElement, ElemProps & FormControlBaseProps>(
    ({ formControlProps, ...inputProps }, ref) => (
      <CustomFormElement elementProps={inputProps} {...formControlProps}>
        {/* @ts-ignore */}
        <NodeElem {...(inputProps as FormInputProps)} ref={ref} />
      </CustomFormElement>
    ),
  );
};

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ label, error, helperText, ...inputProps }, ref) => (
    <>
      {!!label && <FormLabel htmlFor={inputProps.name}>{label}</FormLabel>}
      <Input {...inputProps} ref={ref} />
      {helperText && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{error ? <FormErrorMessage>{helperText}</FormErrorMessage> : <FormHelperText>{helperText}</FormHelperText>}</>
      )}
    </>
  ),
);

export const FormControlFormInput = React.forwardRef<HTMLInputElement, FormControlFormInputProps>(
  ({ formControlProps, ...inputProps }, ref) => (
    <CustomFormElement elementProps={inputProps} {...formControlProps}>
      <FormInput {...inputProps} ref={ref} />
    </CustomFormElement>
  ),
);
// #endregion

// #region Form Input With Addons
export type FormInputWithAddonsProps = InputProps &
  InputGroupProps &
  FormElementBaseProps & {
    inputAddonProps?: InputAddonProps;
    addon: string | React.ReactElement;
  };
export type FormControlFormInputWithAddonsProps = FormInputWithAddonsProps & FormControlBaseProps;

export const FormInputWithAddons = React.forwardRef<HTMLInputElement, FormInputWithAddonsProps>(
  ({ label, error, helperText, inputAddonProps, addon, onChange, ...inputProps }, ref) => (
    <>
      {!!label && <FormLabel htmlFor={inputProps.name}>{label}</FormLabel>}
      <InputGroup>
        <Input
          {...inputProps}
          ref={ref}
          onChange={(event) => {
            if (onChange) {
              const newEvent = event;
              const number = newEvent.target.value.toString();
              if (number.length < 4) {
                onChange(event);
              }
            }
          }}
          onKeyDown={(event) => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()}
        />
        <InputAddon {...inputAddonProps}>{addon}</InputAddon>
      </InputGroup>
      {helperText && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{error ? <FormErrorMessage>{helperText}</FormErrorMessage> : <FormHelperText>{helperText}</FormHelperText>}</>
      )}
    </>
  ),
);

export const FormControlFormInputWithAddons = React.forwardRef<HTMLInputElement, FormControlFormInputWithAddonsProps>(
  ({ formControlProps, ...inputProps }, ref) => (
    <CustomFormElement elementProps={inputProps} {...formControlProps}>
      <FormInputWithAddons {...inputProps} ref={ref} />
    </CustomFormElement>
  ),
);
// #endregion Form Input With Addons

// #region Form Select
export type FormSelectProps = SelectProps & FormElementBaseProps;
export type FormControlFormSelectProps = FormSelectProps & FormControlBaseProps;

export const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  ({ label, error, helperText, children, ...selectProps }, ref) => (
    <>
      {!!label && <FormLabel htmlFor={selectProps.name}>{label}</FormLabel>}
      <Select {...selectProps} ref={ref}>
        {children}
      </Select>
      {helperText && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{error ? <FormErrorMessage>{helperText}</FormErrorMessage> : <FormHelperText>{helperText}</FormHelperText>}</>
      )}
    </>
  ),
);

export const FormControlFormSelect = React.forwardRef<HTMLSelectElement, FormControlFormSelectProps>(
  ({ formControlProps, children, ...selectProps }, ref) => (
    <CustomFormElement elementProps={selectProps} {...formControlProps}>
      <FormSelect {...selectProps} ref={ref}>
        {children}
      </FormSelect>
    </CustomFormElement>
  ),
);
// #endregion

// #region Form Textarea
export type FormTextareaProps = TextareaProps & FormElementBaseProps;
export type FormControlFormTextareaProps = FormTextareaProps & FormControlBaseProps;

export const FormTextarea = React.forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  ({ label, error, helperText, ...textareaProps }, ref) => (
    <>
      {!!label && <FormLabel htmlFor={textareaProps.name}>{label}</FormLabel>}
      <Textarea {...textareaProps} ref={ref} />
      {helperText && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{error ? <FormErrorMessage>{helperText}</FormErrorMessage> : <FormHelperText>{helperText}</FormHelperText>}</>
      )}
    </>
  ),
);

export const FormControlFormTextarea = React.forwardRef<HTMLTextAreaElement, FormControlFormTextareaProps>(
  ({ formControlProps, ...textareaProps }, ref) => (
    <CustomFormElement elementProps={textareaProps} {...formControlProps}>
      <FormTextarea {...textareaProps} ref={ref} />
    </CustomFormElement>
  ),
);
// #endregion

// #region Form Radio Group
export type FormRadioGroupProps = RadioGroupProps & FormElementBaseProps;
export type FormControlFormRadioGroupProps = FormRadioGroupProps & FormControlBaseProps;

export const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  label,
  error,
  helperText,
  children,
  ...radioGroupProps
}) => {
  return (
    <>
      {!!label && <FormLabel htmlFor={radioGroupProps.name}>{label}</FormLabel>}
      <RadioGroup {...radioGroupProps}>{children}</RadioGroup>
      {helperText && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{error ? <FormErrorMessage>{helperText}</FormErrorMessage> : <FormHelperText>{helperText}</FormHelperText>}</>
      )}
    </>
  );
};

export const FormControlFormRadioGroup: React.FC<FormControlFormRadioGroupProps> = ({
  formControlProps,
  children,
  ...radioGroupProps
}) => {
  return (
    <CustomFormElement elementProps={radioGroupProps} {...formControlProps}>
      <FormRadioGroup {...radioGroupProps}>{children}</FormRadioGroup>
    </CustomFormElement>
  );
};
// #endregion

// #region Form Masked Input
export type FormMaskedInputProps = FormInputProps & InputMaskProps;
export type FormControlFormMaskedInputProps = FormMaskedInputProps & FormControlBaseProps;

export const FormMaskedInput = React.forwardRef<HTMLInputElement, FormMaskedInputProps>(({ ...inputProps }, ref) => (
  <FormInput as={InputMask} {...inputProps} ref={ref} />
));

export const FormControlFormMaskedInput = createFormControlInput<FormControlFormMaskedInputProps>(FormMaskedInput);
// #endregion Form Masked Input

// #region Form Phone Input
export type FormPhoneInputProps = Omit<FormMaskedInputProps, 'mask'>;
export type FormControlFormPhoneInputProps = FormPhoneInputProps & FormControlBaseProps;

export const FormPhoneInput = React.forwardRef<HTMLInputElement, FormPhoneInputProps>(
  ({ onChange, ...inputProps }, ref) => (
    <FormMaskedInput
      {...inputProps}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
      }}
      onBlur={(event) => {
        if (onChange) {
          const newEvent = event;
          const phoneNumber = newEvent.target.value;
          let formattedPhoneNumber = phoneNumber.replace(/ +/g, '').replaceAll('(', '').replaceAll(')', '');

          if (phoneNumber.length > 10) {
            formattedPhoneNumber = formattedPhoneNumber.substring(3);
          }

          newEvent.target.value = formattedPhoneNumber;
          onChange(newEvent);
        }
      }}
      // eslint-disable-next-line no-nonoctal-decimal-escape
      mask="+\9\0 (999) 999 99 99"
      ref={ref}
    />
  ),
);

export const FormControlFormPhoneInput = createFormControlInput<FormControlFormPhoneInputProps>(FormPhoneInput);
// #endregion Form Phone Input

// #region Form GovId Input
export type FormGovIdInputProps = Omit<FormMaskedInputProps, 'mask'>;
export type FormControlFormGovIdInputProps = FormGovIdInputProps & FormControlBaseProps;

export const FormGovIdInput = React.forwardRef<HTMLInputElement, FormGovIdInputProps>(
  ({ isIndividual, ...inputProps }, ref) => (
    <FormMaskedInput {...inputProps} mask={isIndividual ? '99999999999' : '9999999999'} ref={ref} />
  ),
);

export const FormControlFormGovIdInput = createFormControlInput<FormControlFormGovIdInputProps>(FormGovIdInput);
// #endregion Form GovId Input

// #region Form Name Input
export type FormNameInputProps = FormInputProps;
export type FormControlFormNameInputProps = FormNameInputProps & FormControlBaseProps;

export const FormNameInput = React.forwardRef<HTMLInputElement, FormNameInputProps>(
  ({ onChange, ...inputProps }, ref) => (
    <FormInput
      {...inputProps}
      ref={ref}
      onChange={(event) => {
        if (onChange) {
          // accept only letter and whitespace
          const regex = /^[\p{L}\s]+$/gu;
          // check white space number input
          const spaceCount = event.target.value.split(' ').length - 1;
          if (event.target.value === '' || (regex.test(event.target.value) && spaceCount < 3)) {
            onChange(event);
          }
        }
      }}
    />
  ),
);

export const FormControlFormNameInput = createFormControlInput<FormControlFormNameInputProps>(FormNameInput);
// #endregion Form Name Input

// #region Form MotorNo Input
export type FormMotorNoInputProps = FormInputProps;
export type FormControlFormMotorNoInputProps = FormMotorNoInputProps & FormControlBaseProps;

export const FormMotorNoInput = React.forwardRef<HTMLInputElement, FormMotorNoInputProps>(
  ({ onChange, ...inputProps }, ref) => (
    <FormInput
      {...inputProps}
      ref={ref}
      onChange={(event) => {
        if (onChange) {
          const newEvent = event;
          const textUpperCase = newEvent.target.value.toUpperCase();
          newEvent.target.value = textUpperCase;
          onChange(newEvent);
        }
      }}
    />
  ),
);

export const FormControlFormMotorNoInput = createFormControlInput<FormControlFormMotorNoInputProps>(FormMotorNoInput);
// #endregion Form MotorNo Input

// #region Form ChasisNo Input
export type FormChasisNoInputProps = FormInputProps;
export type FormControlFormChasisNoInputProps = FormChasisNoInputProps & FormControlBaseProps;

export const FormChasisNoInput = React.forwardRef<HTMLInputElement, FormChasisNoInputProps>(
  ({ onChange, ...inputProps }, ref) => (
    <FormInput
      {...inputProps}
      ref={ref}
      onChange={(event) => {
        if (onChange) {
          const newEvent = event;
          const textUpperCase = newEvent.target.value.toUpperCase();
          newEvent.target.value = textUpperCase;
          onChange(newEvent);
        }
      }}
    />
  ),
);

export const FormControlFormChasisNoInput =
  createFormControlInput<FormControlFormChasisNoInputProps>(FormChasisNoInput);
// #endregion Form ChasisNo Input

// #region Form KM Input
export type FormKMInputProps = FormInputProps;
export type FormControlFormKMInputProps = FormKMInputProps & FormControlBaseProps;

export const FormKMInput = React.forwardRef<HTMLInputElement, FormKMInputProps>(({ onChange, ...inputProps }, ref) => (
  <FormInput
    {...inputProps}
    ref={ref}
    onChange={(event) => {
      if (onChange) {
        const newEvent = event;
        // add comma after every 3 digits
        const commaAddedNumber = newEvent.target.value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        if (Number(commaAddedNumber.replaceAll('.', '')) <= 999999 && commaAddedNumber !== '00') {
          newEvent.target.value = commaAddedNumber;
          onChange(newEvent);
        }
      }
    }}
  />
));

export const FormControlFormKMInput = createFormControlInput<FormControlFormKMInputProps>(FormKMInput);
// #endregion Form KM Input
