/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackItem,
  Tag,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable } from 'components';
import { FormControlFormInput, FormControlFormSelect, GenericMessages, getErrorMessage } from 'core';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  ExpertisePointGroupType,
  ExpertisePointGroupTypeTitle,
  ExpertisePointQueryCacheKey,
  ProductPriceList,
} from 'types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ProductSelect } from 'components/ProductSelect';
import { AdminPageTitle } from '../components';

const validationSchema = yup.object({
  buyPrice: yup.number().required(GenericMessages.CannotBeEmpty),
  price: yup.number().required(GenericMessages.CannotBeEmpty),
  expertisePointGroupTypeId: yup.number().required(GenericMessages.CannotBeEmpty),
  productId: yup.number().required(GenericMessages.CannotBeEmpty),
});

export const AdminProductPricePage: React.FC = () => {
  const columnHelper = createColumnHelper<any>();
  const toast = useToast();

  const {
    isOpen: isAddProductPriceDialogOpen,
    onClose: onAddProductPriceDialogClose,
    onOpen: onAddProductPriceDialogOpen,
  } = useDisclosure();

  const { data: allProductPriceList, refetch: refetchAllProductPrices } = useQuery(
    [ExpertisePointQueryCacheKey.GetProductPriceList],
    async () => {
      const response = await Api.ExpertisePoint.getProductPriceList();

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError(error: any) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Ürün fiyat listesini çekerken hata oluştu'),
        });
      },
    },
  );

  const saveProductPriceMutation = useMutation<any, any, ProductPriceList>(
    async (productPrice: ProductPriceList) => {
      const response = await Api.ExpertisePoint.saveProductPrice(productPrice);
      return response.data;
    },
    {
      onSuccess: () => {
        toast({
          isClosable: true,
          status: 'success',
          title: 'Ürün fiyatı kaydedildi',
        });
        onAddProductPriceDialogClose();
        refetchAllProductPrices();
        // eslint-disable-next-line no-use-before-define
        resetForm();
      },
      onError: () => {
        toast({
          isClosable: true,
          status: 'error',
          title: 'Ürün fiyatı kaydedilirken hata oluştu',
        });
      },
    },
  );

  const columns: ColumnDef<any, string>[] = [
    columnHelper.accessor('expertisePointGroupTypeId', {
      header: 'Ürün Grubu',
      cell: (info) => {
        const { original } = info.row;
        return (
          <Tag colorScheme={original.expertisePointGroupTypeId % 2 === 0 ? 'red' : 'green'}>
            {ExpertisePointGroupTypeTitle[original.expertisePointGroupTypeId]}
          </Tag>
        );
      },
    }),
    columnHelper.accessor('title', {
      header: 'Ürün Adı',
      cell: (info) => {
        const { original } = info.row;
        return original.product.name;
      },
    }),
    columnHelper.accessor('price', {
      header: 'Satış Fiyatı',
      cell: (info) => {
        const { original } = info.row;
        return original.price;
      },
    }),
    columnHelper.accessor('buyPrice', {
      header: 'Alış Fiyatı',
      cell: (info) => {
        const { original } = info.row;
        return original.buyPrice;
      },
    }),
  ];

  const actionButtons = (
    <Button leftIcon={<AddIcon />} aria-label="Ekle" onClick={onAddProductPriceDialogOpen}>
      Ekle
    </Button>
  );

  const { setFieldValue, values, handleChange, errors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      buyPrice: 0,
      price: 0,
      expertisePointGroupTypeId: ExpertisePointGroupType.OccoExpertise,
      productId: 0,
    },
    onSubmit: (formData) => {
      console.log(formData);
      saveProductPriceMutation.mutateAsync({
        buyPrice: formData.buyPrice,
        price: formData.price,
        expertisePointGroupTypeId: formData.expertisePointGroupTypeId,
        productId: formData.productId,
      });
    },
    validationSchema,
  });

  return (
    <>
      <AdminPageTitle title="Ürün Fiyat Listesi" />
      <DataTable
        columns={columns}
        data={allProductPriceList || []}
        tableProps={{
          variant: 'striped',
          colorScheme: 'teal',
        }}
        title="Ürün Fiyat Listesi"
        actions={actionButtons}
        usePagination
      />
      <Modal isOpen={isAddProductPriceDialogOpen} onClose={onAddProductPriceDialogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ürün Fiyatı Ekle</ModalHeader>
          <form id="createProductPriceDialog" noValidate onSubmit={handleSubmit}>
            <ModalBody>
              <VStack align="stretch" gap={2}>
                <StackItem>
                  <ProductSelect
                    value={values.productId}
                    onChange={(e) => {
                      setFieldValue('productId', parseInt(e.target.value, 10));
                    }}
                    error={errors.productId != null}
                    helperText={errors.productId}
                  />
                </StackItem>
                <StackItem>
                  <FormControlFormSelect
                    mb="10"
                    name="expertisePointGroupTypeId"
                    value={values.expertisePointGroupTypeId}
                    onChange={(e) => {
                      setFieldValue('expertisePointGroupTypeId', parseInt(e.target.value, 10));
                    }}
                  >
                    <option value={ExpertisePointGroupType.OccoExpertise}>Occo Ekspertiz</option>
                    <option value={ExpertisePointGroupType.AutoKaleExpertise}>Auto Kale Ekspertiz</option>
                  </FormControlFormSelect>
                </StackItem>
                <StackItem>
                  <FormControlFormInput
                    label="Satış Fiyatı"
                    placeholder="Satış Fiyatı"
                    value={values.price}
                    onChange={handleChange}
                    name="price"
                    error={errors.price != null}
                    helperText={errors.price}
                    type="number"
                  />
                </StackItem>
                <StackItem>
                  <FormControlFormInput
                    label="Alış Fiyatı"
                    placeholder="Alış Fiyatı"
                    value={values.buyPrice}
                    onChange={handleChange}
                    name="buyPrice"
                    error={errors.buyPrice != null}
                    helperText={errors.buyPrice}
                    type="number"
                  />
                </StackItem>
              </VStack>
            </ModalBody>
          </form>
          <ModalFooter>
            <Button mr={3} onClick={onAddProductPriceDialogClose} disabled={saveProductPriceMutation.isLoading}>
              İptal
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              form="createProductPriceDialog"
              isLoading={saveProductPriceMutation.isLoading}
            >
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
