/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Customer, CustomerAbilityDefinitionTable } from 'types';
import { Api } from 'api';
import { createEmptyCustomerAbilityTable, customerResponseToCustomer } from 'core';

export interface CustomerState {
  focusedCustomer?: Customer;
  isFocusCustomerLoading: boolean;
  canCustomerContinue: boolean;
  customerAbilityDefinitionTable: CustomerAbilityDefinitionTable;
  isCustomerFormDirty: boolean;
  currentTabIndex: number;
  hasKvkkApproval: boolean;
}

const initialState: CustomerState = {
  isFocusCustomerLoading: false,
  canCustomerContinue: false,
  customerAbilityDefinitionTable: createEmptyCustomerAbilityTable(),
  isCustomerFormDirty: false,
  currentTabIndex: 0,
  hasKvkkApproval: false,
};

export const refetchCustomer = createAsyncThunk('customer/refetchCustomer', async (customerId: number) => {
  const { data: customerResponse } = await Api.Customer.getCustomerById(customerId);
  return customerResponseToCustomer(customerResponse);
});

export const customerSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFocusCustomerLoading: (state, action: PayloadAction<boolean>) => {
      state.isFocusCustomerLoading = action.payload;
    },
    setFocusedCustomer: (state, action: PayloadAction<Customer | undefined>) => {
      state.focusedCustomer = action.payload;
    },
    setCustomerCanContinue: (state, action: PayloadAction<boolean>) => {
      state.canCustomerContinue = action.payload;
    },
    setCustomerAbilityDefinitionTable: (state, action: PayloadAction<CustomerAbilityDefinitionTable>) => {
      state.customerAbilityDefinitionTable = action.payload;
    },
    setCustomerFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isCustomerFormDirty = action.payload;
    },
    setCurrentTabIndex: (state, action: PayloadAction<number>) => {
      state.currentTabIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refetchCustomer.fulfilled, (state, action) => {
      state.focusedCustomer = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setFocusedCustomer,
  setFocusCustomerLoading,
  setCustomerCanContinue,
  setCustomerAbilityDefinitionTable,
  setCustomerFormDirty,
  setCurrentTabIndex,
} = customerSlice.actions;

export const { reducer: customerReducer } = customerSlice;
