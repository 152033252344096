import { Customer, CustomerResponse } from 'types';
import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { customerResponseToCustomer, FormControlFormChasisNoInput, FormControlFormInput } from 'core';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Center,
  SimpleGrid,
  useBoolean,
  Button,
  Td,
  Tr,
  Th,
  Table,
  TableContainer,
  Tbody,
  Thead,
  HStack,
} from '@chakra-ui/react';
import { Api } from 'api';
import { ExternalLinkIcon, SearchIcon } from '@chakra-ui/icons';
import { LoadingIndicator, VehicleBrandSelect, VehicleModelSelect, VehicleTypeSelect } from 'components';

interface SearchLicenseProps {
  isOpen: boolean;
  onClose: () => void;
  onSearchComplete: (customer: Customer) => void;
}

export const SearchLicense: React.FC<SearchLicenseProps> = ({ isOpen, onClose, onSearchComplete }) => {
  const initialRef = useRef(null);
  const [isLoading, { on, off }] = useBoolean();
  const toast = useToast();
  const [searchedCustomers, setSearchedCustomers] = useState<Customer[]>([]);

  const { handleSubmit, handleChange, values, errors, resetForm, dirty } = useFormik({
    initialValues: {
      plate: '',
      chassisNumber: '',
      // startDate: new Date().toISOString().substring(0, 10),
      startDate: '',
      endDate: '',
      customerName: '',
      brandId: '',
      modelId: '',
      vehicleTypeId: '',
    },
    onSubmit: (data) => {
      if (data) {
        on();
        Api.Customer.searchCustomer2(data)
          .then((result) => {
            if (result.data.length) {
              const results = result.data;
              setSearchedCustomers(results);
            } else {
              setSearchedCustomers([]);
              toast({
                status: 'warning',
                title: 'Sonuç bulunamadı',
                isClosable: true,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .then(() => off());
      }
    },
  });

  const closeModal = () => {
    resetForm();
    onClose();
  };

  const goToCustomerDetails = (selectedCustomer: Customer) => {
    const formatCustomer: CustomerResponse = {
      customer: selectedCustomer,
      canContinue: selectedCustomer.canContinue,
      displayPlate: selectedCustomer.plate,
      hasKvkkApproval: selectedCustomer.hasKvkkApproval,
    };
    onSearchComplete(customerResponseToCustomer(formatCustomer));
    toast({
      status: 'info',
      title: 'Veri arama sonuçları listesine eklendi',
      isClosable: true,
    });
    // setSearchedCustomers([]);
    // closeModal();
  };

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      initialFocusRef={initialRef}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Arama</ModalHeader>
        <ModalCloseButton disabled={isLoading} />
        <ModalBody>
          <form id="searchLicenseForm" noValidate onSubmit={handleSubmit}>
            <Center>
              <SimpleGrid spacing="30px" columns={[1, null, 2, 3, 3, 7]} justifyContent="center">
                <FormControlFormInput
                  label="Başlangıç Tarihi"
                  ref={initialRef}
                  type="date"
                  name="startDate"
                  onChange={handleChange}
                  value={values.startDate}
                  error={errors.startDate != null}
                  helperText={errors.startDate}
                  defaultValue={values.startDate}
                />
                <FormControlFormInput
                  label="Bitiş Tarihi"
                  ref={initialRef}
                  type="date"
                  name="endDate"
                  onChange={handleChange}
                  value={values.endDate}
                  error={errors.endDate != null}
                  helperText={errors.endDate}
                  defaultValue={values.endDate}
                />
                <FormControlFormInput
                  label="Plaka"
                  ref={initialRef}
                  placeholder="Plaka"
                  name="plate"
                  onChange={handleChange}
                  value={values.plate}
                  error={errors.plate != null}
                  helperText={errors.plate}
                />
                <FormControlFormChasisNoInput
                  label="Şase No"
                  name="chassisNumber"
                  onChange={handleChange}
                  value={values.chassisNumber}
                  error={errors.chassisNumber != null}
                  helperText={errors.chassisNumber}
                />
                <FormControlFormInput
                  label="Ad"
                  ref={initialRef}
                  placeholder="Müşteri"
                  name="customerName"
                  onChange={handleChange}
                  value={values.customerName}
                  error={errors.customerName != null}
                  helperText={errors.customerName}
                />
                <VehicleBrandSelect
                  name="brandId"
                  onChange={handleChange}
                  value={values.brandId}
                  error={errors.brandId != null}
                  helperText={errors.brandId}
                />
                <VehicleModelSelect
                  brandId={values.brandId}
                  name="modelId"
                  value={values.modelId}
                  onChange={handleChange}
                  error={errors.modelId != null}
                  helperText={errors.modelId}
                />
                <VehicleTypeSelect
                  name="vehicleTypeId"
                  onChange={handleChange}
                  value={values.vehicleTypeId}
                  error={errors.vehicleTypeId != null}
                  helperText={errors.vehicleTypeId}
                />
              </SimpleGrid>
            </Center>
            <HStack justifyContent="center" my="15">
              <Button
                width="200px"
                type="submit"
                form="searchLicenseForm"
                leftIcon={<SearchIcon />}
                colorScheme="blue"
                isLoading={isLoading}
                isDisabled={!dirty}
              >
                Ara
              </Button>
            </HStack>

            {searchedCustomers.length >= 1 && (
              <TableContainer py="10" px="5">
                <Table variant="striped" colorScheme="teal">
                  <Thead>
                    <Tr>
                      <Th>Tarih</Th>
                      <Th>Paket</Th>
                      <Th>Plaka</Th>
                      <Th>Marka</Th>
                      <Th>Model</Th>
                      <Th>Şase No</Th>
                      <Th>Tipi</Th>
                      <Th>Yılı</Th>
                      <Th>Müşteri</Th>
                      <Th>Telefon</Th>
                      <Th>Aksiyon</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {searchedCustomers.map((customer: Customer) => {
                      return (
                        <Tr key={customer.id}>
                          <Td>{new Date(customer.createdAt!).toLocaleDateString() || '-'}</Td>
                          <Td>{customer.expertisePackage?.name}</Td>
                          <Td>{customer.plate}</Td>
                          <Td>{customer.customerVehicle?.brand.name}</Td>
                          <Td>{customer.customerVehicle?.model.name}</Td>
                          <Td>{customer.customerVehicle?.chassisNumber}</Td>
                          <Td>{customer.customerVehicle?.vehicleType?.name}</Td>
                          <Td>{customer.customerVehicle?.modelYear}</Td>
                          <Td>{customer.name || '-'}</Td>
                          <Td>{customer.phoneNumber || '-'}</Td>
                          <Td>
                            <Button
                              onClick={() => goToCustomerDetails(customer)}
                              leftIcon={<ExternalLinkIcon />}
                              colorScheme="blue"
                              variant="solid"
                            >
                              Detay
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </form>
          {isLoading && <LoadingIndicator />}
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button onClick={closeModal} disabled={isLoading}>
            Kapat
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
