import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useToast,
  Image,
  Center,
  Box,
  Button,
  useDisclosure,
  Text,
  ModalFooter,
  useBoolean,
} from '@chakra-ui/react';
import { Api } from 'api';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  IntegrationQueryCacheKey,
  ProductType,
  ProductTypeQueryCacheKey,
  TramerDamageData,
  TramerDamageDateKeyResponse,
  TramerDamageResponseData,
  TramerQueryType,
} from 'types';
import { LoadingIndicator } from './LoadingIndicator';

export interface ExpertiseTramerViewProps extends Omit<ModalProps, 'children'> {
  customerId: number;
}

export const ExpertiseTramer: React.FC<ExpertiseTramerViewProps> = ({ customerId, ...modalProps }) => {
  const { isOpen: isConfirmDialogOpen, onOpen: onConfirmDialogOpen, onClose: onConfirmDialogClose } = useDisclosure();
  const [isDamageDataKeyLoading, { on: onDamageDataKeyLoading, off: offDamageDataKeyLoading }] = useBoolean();
  const [changedPart, setChangedPart] = useState<TramerDamageResponseData | null>(null);
  const [currentDamageDateKey, setCurrentDamageDateKey] = useState<string>();
  const {
    isOpen: isDamageDateKeyDialogOpen,
    onOpen: onDamageDateKeyDialogOpen,
    onClose: onDamageDateKeyDialogClose,
  } = useDisclosure();
  const toast = useToast();
  const { data: tramerData, isLoading } = useQuery(
    [IntegrationQueryCacheKey.GetExpertiseTramerDamage, customerId],
    async () => {
      const { data } = await Api.Integration.getTramerDamage(customerId);
      const { tramerResult } = data;
      let convertedTramerResult = (
        tramerResult.damageDateKeys ? JSON.parse(tramerResult.damageDateKeys) : []
      ) as TramerDamageDateKeyResponse[];
      if (typeof convertedTramerResult === 'string') {
        convertedTramerResult = JSON.parse(convertedTramerResult);
      }
      return {
        url: tramerResult.url,
        damageDateKeys: convertedTramerResult
          .filter((damageDateKey: TramerDamageDateKeyResponse) => damageDateKey.Value !== '')
          .map((damageDateKey: TramerDamageDateKeyResponse) => ({
            orderId: damageDateKey.OrderId,
            value: damageDateKey.Value,
          })),
      } as TramerDamageData;
    },
    {
      retry: 0,
      enabled: modalProps.isOpen,
      refetchOnWindowFocus: false,
      onError() {
        toast({
          title: 'Hasar Kayıt Bilgileri Çekilirken Hata Oluştu',
          isClosable: true,
          status: 'error',
        });
      },
    },
  );

  const { refetch: refetchDamageDateKeySet, data: damageDateKeySet } = useQuery(
    [IntegrationQueryCacheKey.GetTramerOldQueries, customerId],
    async () => {
      const { data } = await Api.Integration.getOldTramerQueries(customerId, TramerQueryType.ChangedPart);
      const dataSet = new Set<string>([]);
      data.forEach((value: TramerDamageResponseData) => {
        dataSet.add(value.damageDateKeyValue);
      });
      return dataSet;
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: modalProps.isOpen,
    },
  );

  const { data: TramerResultProductPrice } = useQuery(
    ProductTypeQueryCacheKey.GetTramerQuery,
    async () => {
      const response = await Api.Util.getProductPriceByType(ProductType.TramerQuery);
      return response.data;
    },
    {
      // enabled: isDamageDateKeyDialogOpen,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (error: any) => {
        const {
          response: {
            data: { message },
          },
        } = error;
        toast({
          title: message,
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const fetchDamageDateDate = async (damageDateKey: string) => {
    onDamageDataKeyLoading();
    try {
      const response = await Api.Integration.getTramerChangedPart(customerId, damageDateKey);
      const damageDateData = response.data.tramerResult;
      setChangedPart(damageDateData);
    } catch (err) {
      console.error(err);
      toast({
        title: 'Parça Değişen Bilgileri Çekilirken Hata Oluştu',
        isClosable: true,
        status: 'error',
      });
    } finally {
      offDamageDataKeyLoading();
    }
  };
  const onDamageDateKeyClicked = (damageDateKey: string) => {
    if (damageDateKeySet?.has(damageDateKey)) {
      onDamageDateKeyDialogOpen();
      fetchDamageDateDate(damageDateKey);
    } else {
      setCurrentDamageDateKey(damageDateKey);
      onConfirmDialogOpen();
    }
  };

  const onDamageDateKeyConfirmedClicked = async () => {
    if (currentDamageDateKey) {
      await fetchDamageDateDate(currentDamageDateKey);
      onDamageDateKeyDialogOpen();
    }
    onConfirmDialogClose();
  };

  return (
    <>
      <Modal
        isOpen={isDamageDateKeyDialogOpen}
        onClose={() => {
          refetchDamageDateKeySet();
          onDamageDateKeyDialogClose();
        }}
        isCentered
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Araç Değişen Sorgu</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              {isDamageDataKeyLoading || !changedPart ? (
                <LoadingIndicator />
              ) : (
                <Box maxHeight="80vh" maxWidth="100%" overflowY="auto" marginTop="2rem" padding="1rem">
                  <Image src={changedPart.url} boxSizing="border-box" width="100%" height="auto" />
                </Box>
              )}
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                refetchDamageDateKeySet();
                onDamageDateKeyDialogClose();
              }}
            >
              Kapat
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isConfirmDialogOpen}
        onClose={onConfirmDialogClose}
        isCentered
        closeOnOverlayClick={!isDamageDataKeyLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Araç Değişen Sorgu Onay?</ModalHeader>
          <ModalCloseButton disabled={isDamageDataKeyLoading} />
          <ModalBody>
            {TramerResultProductPrice && (
              <Text>
                Hasar Kaydı Sorgusu için ₺{TramerResultProductPrice} sorgu bedeli kullanılacaktır. Devam etmek istiyor
                musunuz ?
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onDamageDateKeyConfirmedClicked}
              isLoading={isDamageDataKeyLoading}
            >
              Evet
            </Button>
            <Button onClick={onConfirmDialogClose} disabled={isDamageDataKeyLoading}>
              Hayır
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal {...modalProps} isCentered size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Hasar Kaydı Sorgula</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading || !tramerData ? (
              <LoadingIndicator />
            ) : (
              <Center>
                <Box width={720} position="relative">
                  <Image src={tramerData.url} width={720} />
                  {tramerData.damageDateKeys.map((damageDateKey) => {
                    const top = 40 + damageDateKey.orderId * 40;
                    return (
                      <Button
                        variant="solid"
                        key={`ddk${damageDateKey.value}`}
                        position="absolute"
                        colorScheme="blue"
                        top={`${top}px`}
                        left="55%"
                        onClick={() => onDamageDateKeyClicked(damageDateKey.value)}
                      >
                        Araç Değişen Sorgu
                      </Button>
                    );
                  })}
                </Box>
              </Center>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={modalProps.onClose}>Kapat</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
