import {
  LockIcon,
  CloseIcon,
  HamburgerIcon,
  ArrowForwardIcon,
  SearchIcon,
  SettingsIcon,
  ExternalLinkIcon,
  DownloadIcon,
  EditIcon,
  UnlockIcon,
} from '@chakra-ui/icons';

import {
  Grid,
  GridItem,
  Box,
  Button,
  HStack,
  Icon,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  VStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  IconButton,
  Portal,
  DrawerCloseButton,
  DrawerOverlay,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Link,
  Heading,
  useMediaQuery,
  useColorModeValue,
} from '@chakra-ui/react';
import { Api } from 'api';
import {
  CarEntryRow,
  createCustomerAbilityTable,
  createCustomerSocket,
  createExpertisePointSocket,
  customerResponseToCustomer,
  useBodyBackground,
  useGetMainDrawerVariant,
  useIsBaseSize,
  useMainLayoutTemplateColumns,
  useMainLayoutTemplateRows,
} from 'core';
import React, { useCallback, useEffect, useState } from 'react';
import { MdPrint, MdSmartphone, MdEmail } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  Announcement,
  ApiResponse,
  CheckOldBodyDamagesResponse,
  Customer,
  CustomerAbilityDefinition,
  CustomerHistories,
  CustomerQueryCacheKey,
  CustomerResponse,
  CustomerStatus,
  ExpertisePointQueryCacheKey,
  TotalBalanceSheetData,
} from 'types';
import {
  hideLoading,
  onExpertiseReportFilesDialogClose,
  onExpertiseReportFilesDialogOpen,
  setCurrentTabIndex,
  setCustomerAbilityDefinitionTable,
  setCustomerCanContinue,
  setCustomerFormDirty,
  setExpertiseReportFileInfos,
  setFocusCustomerLoading,
  setFocusedCustomer,
  showLoading,
} from 'features';
import { CustomerHistoriesModal, LoadingIndicator } from 'components';
import { RootState } from 'store';
import { Socket } from 'socket.io-client';
// import { Capacitor } from '@capacitor/core';
// import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';
import { AddCarWithPlatePopoverButton, SearchLicense } from '../components';
import {
  ExpertiseBodyworkInformationPage,
  ExpertiseCarInformationPage,
  ExpertiseClientInformationPage,
  ExpertiseMechanicInformationPage,
} from './expertiseSubPages';
import { EditTest } from '../components/EditTest';

export const ExpertisePage: React.FC = () => {
  const toast = useToast();
  const bodyBg = useBodyBackground();
  const variant = useGetMainDrawerVariant();
  const templateColumns = useMainLayoutTemplateColumns();
  const templateRows = useMainLayoutTemplateRows();
  const isBaseSize = useIsBaseSize();
  const [isDesktop] = useMediaQuery('(min-width: 1025px)');
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: variant === 'permanent',
  });
  const { isOpen: isActionsOpen, onClose: onActionsClose, onOpen: onActionsOpen } = useDisclosure();
  const { isOpen: isSearchDialogOpen, onClose: onSearchDialogClose, onOpen: openSearchDialog } = useDisclosure();
  const { isOpen: isEditTestModalOpen, onClose: onEditTestModalClose, onOpen: onEditTestModalOpen } = useDisclosure();
  const {
    isOpen: isCancelWarningModalOpen,
    onClose: onCancelWarningModalClose,
    onOpen: onCancelWarningModalOpen,
  } = useDisclosure();
  const {
    isOpen: isCompleteWarningModalOpen,
    onClose: onCompleteWarningModalClose,
    onOpen: onCompleteWarningModalOpen,
  } = useDisclosure();
  const {
    isOpen: checkOldDamagesModalIsOpen,
    onOpen: checkOldDamagesModalOnOpen,
    onClose: checkOldDamagesModalOnClose,
  } = useDisclosure();
  const {
    isOpen: isAnnouncementsModalOpen,
    onOpen: onAnnouncementsModalOpen,
    onClose: onAnnouncementsModalClose,
  } = useDisclosure();

  // const { isOpen: isOccopayDrawerOpen, onClose: onOccopayDrawerClose, onOpen: onOccopayDrawerOpen } = useDisclosure();

  const [customSearchCustomers, setCustomSearchCustomers] = useState<Customer[]>([]);
  const [showCustomerHistories, setShowCustomerHistories] = useState<boolean>(false);
  const [customerHistories, setCustomerHistories] = useState<CustomerHistories[]>([]);
  const [oldReportUrl, setOldReportUrl] = useState<string>('');
  const [isCustomerEditable, setIsCustomerEditable] = useState<boolean>(true);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const hasUnreadAnnouncements = announcements?.some((announcement: Announcement) => !announcement.isRead);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expertisePointSocket, setExpertisePointSocket] = useState<Socket | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (variant === 'permanent') {
      onOpen();
    } else {
      onClose();
    }
  }, [variant]);

  const {
    data: customers,
    isLoading,
    refetch: refetchDailyCustomers,
  } = useQuery(
    CustomerQueryCacheKey.GetDailyCustomer,
    async () => {
      const response = await Api.Customer.getDailyCustomers();
      const customerResponse = response.data;
      return customerResponse.customers;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const queryClient = useQueryClient();

  const {
    focusedCustomer,
    isFocusCustomerLoading,
    canCustomerContinue,
    isCustomerFormDirty,
    currentTabIndex,
    authUserInfo,
    isExpertiseReportFilesDialogOpen,
    expertiseReportFiles,
  } = useSelector((state: RootState) => ({
    focusedCustomer: state.customer.focusedCustomer,
    isFocusCustomerLoading: state.customer.isFocusCustomerLoading,
    canCustomerContinue: state.customer.canCustomerContinue,
    isCustomerFormDirty: state.customer.isCustomerFormDirty,
    currentTabIndex: state.customer.currentTabIndex,
    authUserInfo: state.auth.authUserInfo,
    isExpertiseReportFilesDialogOpen: state.expertiseReport.isExpertiseReportFilesDialogOpen,
    expertiseReportFiles: state.expertiseReport.expertiseReportFiles,
  }));

  useQuery(
    [CustomerQueryCacheKey.GetCustomerAbilities, focusedCustomer?.expertisePackageId],
    async (): Promise<CustomerAbilityDefinition[]> => {
      if (focusedCustomer && focusedCustomer.expertisePackageId) {
        const response = await Api.Customer.getCustomerAbilities(focusedCustomer.id);
        return response.data.abilityDefinitions;
      }
      return [];
    },
    {
      onSuccess(data: CustomerAbilityDefinition[]) {
        dispatch(setCustomerAbilityDefinitionTable(createCustomerAbilityTable(data)));
      },
      enabled: focusedCustomer != null && focusedCustomer.expertisePackageId != null && canCustomerContinue,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  const { refetch: refetchIsCustomerEditable } = useQuery(
    [CustomerQueryCacheKey.CheckCustomerEditable, focusedCustomer?.id],
    async () => {
      if (focusedCustomer && focusedCustomer.id) {
        const response = await Api.Customer.checkCustomerIsEdıtable(focusedCustomer.id);
        return response.data;
      }
      return [];
    },
    {
      onSuccess(data: CustomerAbilityDefinition[]) {
        if (data) {
          setIsCustomerEditable(true);
        }
      },
      enabled: focusedCustomer != null && focusedCustomer.id != null,
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 0,
      onError(error) {
        if (error) {
          setIsCustomerEditable(false);
        }
      },
    },
  );

  const { data: transactionData } = useQuery(
    ExpertisePointQueryCacheKey.GetPreviousBalanceTransactions,
    async () => {
      const response = await Api.ExpertisePoint.getTransactions();
      const responseData = response.data;
      const convertedTransactionData: TotalBalanceSheetData = {
        totalBalance: responseData.totalBalance,
        transactions: responseData.transactions.map((transaction) => {
          return {
            id: transaction.id,
            amount: transaction.amount,
            isCompleted: transaction.isCompleted,
            transactionDate: transaction.transactionDate ? new Date(transaction.transactionDate) : undefined,
          };
        }),
      };
      return convertedTransactionData;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const fetchAndUpdateFocusedCustomer = (customerId: number, updateCustomerList: boolean = false) => {
    Api.Customer.getCustomerById(customerId)
      .then((apiResponseCustomer: ApiResponse<CustomerResponse>) => {
        dispatch(setFocusedCustomer(customerResponseToCustomer(apiResponseCustomer.data)));
        dispatch(setCustomerCanContinue(apiResponseCustomer.data.canContinue));
        return apiResponseCustomer.data.customer;
      })
      .then((updatedCustomer) => {
        if (updateCustomerList) {
          refetchDailyCustomers();
          refetchIsCustomerEditable();
          const index = customSearchCustomers.findIndex((customer) => customer.id === customerId);
          if (index !== -1) {
            const newCustomSearchCustomers = [...customSearchCustomers];
            if (updatedCustomer.status === CustomerStatus.Cancelled) {
              newCustomSearchCustomers.splice(index, 1);
            } else {
              newCustomSearchCustomers[index] = updatedCustomer;
            }
            setCustomSearchCustomers(newCustomSearchCustomers);
          }

          if (updatedCustomer.status === CustomerStatus.Cancelled) {
            dispatch(setFocusedCustomer(undefined));
          }
        }
      });
  };

  const setAsCompleteMutation = useMutation<void, any, void>(
    async () => {
      dispatch(showLoading());
      await Api.Customer.setAsCompleted(focusedCustomer!.id);
    },
    {
      onSuccess: () => {
        fetchAndUpdateFocusedCustomer(focusedCustomer!.id, true);
        if (transactionData && transactionData?.totalBalance < 0) {
          toast({
            title: 'Eksi Bakiye',
            status: 'warning',
            isClosable: true,
          });
        }
      },
      onSettled: () => {
        dispatch(hideLoading());
      },
      onError: (error: any) => {
        if (error && error.response) {
          if (error.response.data) {
            toast({
              title: error.response.data?.message || 'Testi tamamlarken bir hata olustu',
              status: 'error',
              isClosable: true,
            });
          }
        } else {
          toast({
            title: 'Testi tamamlarken bir hata olustu',
            status: 'error',
            isClosable: true,
          });
        }
      },
    },
  );

  const checkCompleteMutation = useMutation<CheckOldBodyDamagesResponse, any, void>(
    async () => {
      dispatch(showLoading());
      const response = await Api.Customer.checkSetAsCompleted(focusedCustomer!.id);
      return response.data;
    },
    {
      onSuccess: (data: CheckOldBodyDamagesResponse) => {
        if (data.hasInconsistency) {
          checkOldDamagesModalOnOpen();
          setOldReportUrl(data.oldReportUrl!);
        } else {
          setAsCompleteMutation.mutateAsync();
        }
      },
      onSettled: () => {
        dispatch(hideLoading());
      },
      onError: () => {
        toast({
          title: 'Geçmiş kayıtlar kontrol edilirken bir hata olustu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const setAsCancelMutation = useMutation<void, any, void>(
    async () => {
      dispatch(showLoading());
      await Api.Customer.setAsCancelled(focusedCustomer!.id);
    },
    {
      onSuccess: () => {
        // fetchAndUpdateFocusedCustomer(focusedCustomer!.id, true);
        refetchDailyCustomers();
      },
      onSettled: () => {
        dispatch(hideLoading());
      },
    },
  );

  const onSubmitComplete = (carEntryRow: CarEntryRow) => {
    queryClient.invalidateQueries(CustomerQueryCacheKey.GetDailyCustomer);
    dispatch(setFocusCustomerLoading(true));
    fetchAndUpdateFocusedCustomer(carEntryRow.id);
    if (carEntryRow.hasHistoryByPlate) {
      setShowCustomerHistories(carEntryRow.hasHistoryByPlate);
      setCustomerHistories(carEntryRow.histories);
    }
  };

  const updateFocusedCustomer = (addedCustomer: Customer) => {
    dispatch(setFocusCustomerLoading(true));
    fetchAndUpdateFocusedCustomer(addedCustomer.id);
    if (isBaseSize) {
      onClose();
    }
    dispatch(setCurrentTabIndex(0));
  };
  useEffect(() => {
    if (focusedCustomer) {
      const newCustomerSocket = createCustomerSocket(focusedCustomer.id.toString());
      setCustomerSocket(newCustomerSocket);

      newCustomerSocket.on('customersChanged', (data: any) => {
        console.warn('Customers changed:', data);
        if (data) {
          refetchDailyCustomers();
        }
      });

      newCustomerSocket.on('customerChanged', (data: CustomerResponse) => {
        if (data.customer.id === focusedCustomer.id) {
          dispatch(setCustomerCanContinue(data.canContinue));
        }
      });

      return () => {
        newCustomerSocket.disconnect();
      };
    }
    // Return undefined if no focusedCustomer
    return undefined;
  }, [focusedCustomer, refetchDailyCustomers, dispatch]);

  useEffect(() => {
    if (authUserInfo) {
      const newExpertisePointSocket = createExpertisePointSocket(authUserInfo.id.toString());
      setExpertisePointSocket(newExpertisePointSocket);

      newExpertisePointSocket.on('announcementCreated', (announcementsData: Announcement[]) => {
        const filteredAnnouncements = announcementsData?.filter(
          (announcement: any) => announcement.userId === authUserInfo.id,
        );
        if (filteredAnnouncements.length) {
          setAnnouncements(filteredAnnouncements);
          onAnnouncementsModalOpen();
        }
      });

      return () => {
        newExpertisePointSocket.disconnect();
      };
    }
    return undefined;
  }, [authUserInfo, onAnnouncementsModalOpen]);

  const renderCustomers = (customersToRender: Customer[]) => {
    return customersToRender.map((customerToRender, index) => {
      return (
        <React.Fragment key={`${customerToRender.plate}${customerToRender.id}`}>
          <Button
            leftIcon={customerToRender.canContinue ? <pre /> : <Icon as={LockIcon} />}
            variant={focusedCustomer?.id === customerToRender.id ? 'solid' : 'ghost'}
            width="100%"
            size="sm"
            onClick={() => updateFocusedCustomer(customerToRender)}
            colorScheme="green"
            flexShrink="0"
            autoFocus={false}
            _focus={{ boxShadow: 'none' }}
          >
            {customerToRender.displayPlate}
          </Button>
          {customersToRender.length - 1 !== index && <Divider />}
        </React.Fragment>
      );
    });
  };

  const renderDrawer = () => {
    return (
      <Drawer
        closeOnEsc={false}
        variant={variant}
        isOpen={isOpen}
        onClose={onClose}
        placement="left"
        autoFocus={false}
        trapFocus={false}
        blockScrollOnMount={false}
      >
        {isBaseSize && <DrawerOverlay />}
        <DrawerContent>
          {isBaseSize && <DrawerCloseButton />}
          <DrawerHeader borderBottomWidth="1px">
            <Center>
              <Image src="/images/occo-ekspertiz-logo-seffaf.png" width="100px" />
            </Center>
          </DrawerHeader>
          <DrawerBody>
            <Flex width="100%" height="100%" flexDirection="column">
              <AddCarWithPlatePopoverButton
                onSubmitComplete={onSubmitComplete}
                popoverButtonProps={{
                  mb: '2.5',
                }}
              />
              <VStack spacing="12px" flex="1 0 0" mb={2} overflowY="auto">
                {isLoading && <LoadingIndicator />}
                {customers && renderCustomers(customers)}
                {customSearchCustomers.length && (
                  <Button width="100%" flexShrink="0">
                    Arama Sonuçları
                  </Button>
                )}
                {customSearchCustomers && renderCustomers(customSearchCustomers)}
              </VStack>
              <HStack>
                <Button minH="40px" width="100%" leftIcon={<SearchIcon />} onClick={openSearchDialog}>
                  Ara
                </Button>
                <Menu>
                  <MenuButton as={IconButton}>
                    <SettingsIcon color={hasUnreadAnnouncements ? 'red.400' : useColorModeValue('black', 'white')} />
                  </MenuButton>
                  <MenuList>
                    {authUserInfo && <MenuItem isDisabled>{authUserInfo.email}</MenuItem>}
                    {transactionData && <MenuItem isDisabled>Bakiye: {transactionData.totalBalance}</MenuItem>}
                    <MenuItem onClick={() => navigate('/admin')}>Yönetim Paneli</MenuItem>

                    <MenuItem
                      onClick={() => navigate('/admin/announcement')}
                      color={hasUnreadAnnouncements ? 'red.400' : useColorModeValue('black', 'white')}
                    >
                      Duyurular
                    </MenuItem>
                    {/* <MenuItem
                      color="red"
                      onClick={() => {
                        localStorage.removeItem(localStorageUserAuthInfoKey);
                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                      }}
                    >
                      Çıkış Yap
                    </MenuItem> */}
                  </MenuList>
                </Menu>
              </HStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };

  const onSearchComplete = (searchedCustomer: Customer) => {
    const newCustomers = [...customSearchCustomers];
    newCustomers.push(searchedCustomer);
    setCustomSearchCustomers(newCustomers);
    updateFocusedCustomer(searchedCustomer);
  };

  const onCompleteClicked = () => {
    onCompleteWarningModalOpen();
  };

  const onCancelClicked = () => {
    onCancelWarningModalOpen();
  };

  const onEditTestComplete = () => {
    fetchAndUpdateFocusedCustomer(Number(focusedCustomer?.id));
  };

  React.useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      BluetoothSerial.isEnabled()
        .then((result) => {
          console.warn(result);
          BluetoothSerial.discoverUnpaired()
            .then((unpairedResult) => console.warn(unpairedResult))
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  }, []);

  // const { data: faultDetectionResultFileResponse } = useGetUploadedClientFile(
  //   focusedCustomer?.id,
  //   CustomerExpertiseFileType.FaultDetectionResult,
  //   {
  //     onError: (err: any) => {
  //       console.error(err);
  //       toast({
  //         title: 'Araç testi sonuçları çekilirken hata oluştu',
  //         status: 'error',
  //       });
  //     },
  //   },
  // );

  // const { data: kmTestResultFileResponse } = useGetUploadedClientFile(
  //   focusedCustomer?.id,
  //   CustomerExpertiseFileType.KMDetectionResult,
  //   {
  //     onError: (err: any) => {
  //       console.error(err);
  //       toast({
  //         title: 'KM testi sonuçları çekilirken hata oluştu',
  //         status: 'error',
  //       });
  //     },
  //   },
  // );

  const canCompleteTest = React.useMemo(
    () =>
      focusedCustomer != null && focusedCustomer.expertisePackageId != null && focusedCustomer.customerVehicle != null,
    [focusedCustomer],
  );

  const onTabChange = useCallback(
    (index: number) => {
      if (isCustomerFormDirty) {
        // eslint-disable-next-line no-alert
        const result = window.confirm('Kaydetmediğiniz veriler var. Devam etmek istiyor musunuz?');
        if (!result) {
          return;
        }
      }
      dispatch(setCurrentTabIndex(index));
      dispatch(setCustomerFormDirty(false));
    },
    [currentTabIndex, isCustomerFormDirty],
  );

  const onPrintExpertiseReportClicked = async () => {
    if (!focusedCustomer) {
      return;
    }

    dispatch(showLoading());
    Api.Expertise.getExpertiseReportPdfLink(focusedCustomer.id)
      .then((response) => {
        if (response.data.length === 1) {
          window.open(response.data[0].url);
        } else {
          dispatch(setExpertiseReportFileInfos(response.data));
          dispatch(onExpertiseReportFilesDialogOpen());
        }
      })
      .catch((err) => {
        console.error(err);
        toast({
          status: 'error',
          title: 'Rapor oluştururken hata oluştu',
          isClosable: true,
        });
      })
      .then(() => dispatch(hideLoading()));
  };

  const sendSmsMutation = useMutation<any, any, number>(
    async (customerId: number) => {
      dispatch(showLoading());
      await Api.Expertise.sendExpertiseReportLinkBySMS(customerId);
    },
    {
      onSuccess() {
        toast({
          status: 'success',
          title: 'SMS Başarı İle Gönderildi',
          isClosable: true,
        });
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'SMS Gönderirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  const sendEmailMutation = useMutation<any, any, number>(
    async (customerId: number) => {
      dispatch(showLoading());
      await Api.Expertise.sendExpertiseReportLinkByEmail(customerId);
    },
    {
      onSuccess() {
        toast({
          status: 'success',
          title: 'Email Başarı İle Gönderildi',
          isClosable: true,
        });
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Email Gönderirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  const getWorkOrderForm = useMutation<any, any, number>(
    async (customerId: number) => {
      dispatch(showLoading());
      const response = await Api.Customer.getWorkOrderForm(customerId);
      return response.data;
    },
    {
      onSuccess(data) {
        if (data) {
          window.open(data);
        } else {
          toast({
            status: 'info',
            title: 'İş Emri formu bulunamadı.',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'İş Emri Formu İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  const unlockMutation = useMutation<any, any, number>(
    async (customerId: number) => {
      dispatch(showLoading());
      await Api.Customer.editCustomerByAdmin(customerId);
    },
    {
      onSuccess() {
        fetchAndUpdateFocusedCustomer(focusedCustomer!.id, true);
        toast({
          status: 'success',
          title: 'Kilit Açıldı',
          isClosable: true,
        });
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Kilidi Açarken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  return (
    <>
      {isDesktop && (
        <Modal isOpen={isAnnouncementsModalOpen} onClose={onAnnouncementsModalClose} size="md" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Duyuru</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {announcements?.length === 1 ? (
                <Box mb={4}>
                  <Heading size="md">{announcements[0].title}</Heading>
                  <Text>{announcements[0].description}</Text>
                </Box>
              ) : (
                <Text>{announcements.length} adet okunmamış duyurunuz var</Text>
              )}
            </ModalBody>

            <ModalFooter justifyContent="space-between">
              {announcements.length > 1 && (
                <Button
                  colorScheme="teal"
                  onClick={() => {
                    navigate('/admin/announcement');
                  }}
                >
                  İncele
                </Button>
              )}
              <Button onClick={onAnnouncementsModalClose}>Kapat</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Modal isOpen={checkOldDamagesModalIsOpen} onClose={checkOldDamagesModalOnClose} size="md" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uyarı</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Geçmiş ekspertiz raporuna göre boya değişenlerde değişiklik tespit edilmiştir.</ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              leftIcon={<Icon as={DownloadIcon} />}
              colorScheme="teal"
              onClick={() => {
                window.open(oldReportUrl);
              }}
            >
              Geçmiş Ekspertiz Raporu
            </Button>
            <Button
              onClick={() => {
                setAsCompleteMutation.mutateAsync();
                checkOldDamagesModalOnClose();
              }}
            >
              Testi Tamamla
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isExpertiseReportFilesDialogOpen}
        onClose={() => {
          dispatch(onExpertiseReportFilesDialogClose());
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Raporlar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              {expertiseReportFiles.map((expertiseReportFile) => (
                <Link href={expertiseReportFile.url} isExternal key={`erf${expertiseReportFile.url}`} target="_blank">
                  {expertiseReportFile.fileType}
                  <ExternalLinkIcon mx="2px" />
                </Link>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              onClick={() => {
                dispatch(onExpertiseReportFilesDialogClose());
              }}
            >
              Kapat
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isCancelWarningModalOpen} onClose={onCancelWarningModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Onay</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Bu işlem geri alınamaz! Devam etmek istediğinize emin misiniz?</Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button onClick={onCancelWarningModalClose}>Hayır</Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onCancelWarningModalClose();
                setAsCancelMutation.mutateAsync();
              }}
            >
              Evet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isCompleteWarningModalOpen} onClose={onCompleteWarningModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Onay</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Bu işlem geri alınamaz! Devam etmek istediğinize emin misiniz?</Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button onClick={onCompleteWarningModalClose}>Hayır</Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onCompleteWarningModalClose();
                checkCompleteMutation.mutateAsync();
              }}
            >
              Evet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showCustomerHistories && (
        <Portal>
          <CustomerHistoriesModal showCustomerHistories={showCustomerHistories} histories={customerHistories} />
        </Portal>
      )}
      <SearchLicense isOpen={isSearchDialogOpen} onClose={onSearchDialogClose} onSearchComplete={onSearchComplete} />
      <EditTest
        isOpen={isEditTestModalOpen}
        onClose={onEditTestModalClose}
        onEditTestComplete={onEditTestComplete}
        customerId={Number(focusedCustomer?.id)}
      />
      <Portal>
        <Drawer isOpen={isActionsOpen} onClose={onActionsClose} autoFocus={false} trapFocus={false}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">Aksiyonlar</DrawerHeader>
            <DrawerBody>
              {focusedCustomer && (
                <VStack spacing="12px">
                  {isFocusCustomerLoading && canCustomerContinue ? (
                    <Button width="100%" variant="ghost" leftIcon={<CloseIcon />} onClick={onCancelClicked}>
                      İptal Et
                    </Button>
                  ) : (
                    <>
                      <Button
                        width="100%"
                        variant="ghost"
                        leftIcon={<Icon as={MdPrint} />}
                        onClick={onPrintExpertiseReportClicked}
                      >
                        Yazdır
                      </Button>
                      <Button
                        width="100%"
                        variant="ghost"
                        leftIcon={<Icon as={MdSmartphone} />}
                        onClick={() => sendSmsMutation.mutate(focusedCustomer.id)}
                      >
                        SMS Gönder
                      </Button>
                      <Button
                        width="100%"
                        variant="ghost"
                        leftIcon={<Icon as={MdEmail} />}
                        onClick={() => sendEmailMutation.mutate(focusedCustomer.id)}
                      >
                        Email Gönder
                      </Button>
                      <Button
                        width="100%"
                        variant="ghost"
                        leftIcon={<Icon as={DownloadIcon} />}
                        onClick={() => getWorkOrderForm.mutate(focusedCustomer.id)}
                      >
                        İş Emri Formu
                      </Button>
                      <Button
                        isDisabled={!isCustomerEditable}
                        width="100%"
                        variant="ghost"
                        leftIcon={<Icon as={EditIcon} />}
                        onClick={() => isCustomerEditable && onEditTestModalOpen()}
                      >
                        Düzenle
                      </Button>
                      {authUserInfo?.isSuperAdmin && (
                        <Button
                          // isDisabled={!isCustomerEditable}
                          width="100%"
                          variant="ghost"
                          leftIcon={<Icon as={UnlockIcon} />}
                          onClick={() => unlockMutation.mutate(focusedCustomer.id)}
                        >
                          Kilidi Aç
                        </Button>
                      )}
                    </>
                  )}
                </VStack>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Portal>
      {isBaseSize && <Portal>{renderDrawer()}</Portal>}
      <Grid width="100vw" height="100%" templateRows={templateRows} templateColumns={templateColumns} bg={bodyBg}>
        {isBaseSize && (
          <GridItem minW={0}>
            <Box
              zIndex="var(--chakra-zIndices-sticky)"
              bg="var(--chakra-colors-chakra-body-bg)"
              width="100%"
              height="56px"
              position="fixed"
              top={0}
              left={0}
              right={0}
              boxShadow="lg"
              p={2}
            >
              <HStack height="100%">
                <IconButton aria-label="Menu" icon={<HamburgerIcon />} onClick={onOpen} />
              </HStack>
            </Box>
          </GridItem>
        )}

        {!isBaseSize && <GridItem minW={0}>{renderDrawer()}</GridItem>}

        <GridItem minW={0} height="100%">
          {focusedCustomer ? (
            // <Grid width="100%" height="100%" templateRows="1fr 60px" templateColumns="1fr" overflow="auto">
            //   <GridItem height="100%">
            //     <Box width="100%" height="100%" bg={bodyBg} p="4" maxWidth="100%">
            //       <Tabs
            //         variant="soft-rounded"
            //         colorScheme="green"
            //         align="center"
            //         isLazy
            //         index={currentTabIndex}
            //         onChange={onTabChange}
            //         isManual
            //       >
            //         <TabList flexWrap="wrap">
            //           <Tab>Müşteri Bilgileri</Tab>
            //           <Tab>Araç Bilgileri</Tab>
            //           <Tab>Kaporta Bilgileri</Tab>
            //           <Tab>Mekanik İnceleme</Tab>
            //         </TabList>
            //         <TabPanels>
            //           <TabPanel textAlign="start">
            //             <ExpertiseClientInformationPage />
            //           </TabPanel>
            //           <TabPanel textAlign="start">
            //             <ExpertiseCarInformationPage />
            //           </TabPanel>
            //           <TabPanel textAlign="start">
            //             <ExpertiseBodyworkInformationPage />
            //           </TabPanel>
            //           <TabPanel textAlign="start">
            //             <ExpertiseMechanicInformationPage />
            //           </TabPanel>
            //         </TabPanels>
            //       </Tabs>
            //     </Box>
            //   </GridItem>
            //   <GridItem p={1} height="60px">
            //     {isBaseSize ? (
            //       <HStack height="100%" justify="space-between">
            //         <Button leftIcon={<ArrowForwardIcon />} variant="ghost" onClick={onActionsOpen}>
            //           Aksiyonlar
            //         </Button>
            //         <Button
            //           leftIcon={<LockIcon />}
            //           variant="outline"
            //           onClick={onCompleteClicked}
            //           disabled={!canCompleteTest}
            //         >
            //           Testi Tamamla
            //         </Button>
            //       </HStack>
            //     ) : (
            //       <HStack spacing="24px" justify={['center', null, 'flex-end']} align="center" height="100%">
            //         {isFocusCustomerLoading && canCustomerContinue ? (
            //           <>
            //             <Button leftIcon={<CloseIcon />} variant="ghost" size="xs" onClick={onCancelClicked}>
            //               İptal Et
            //             </Button>
            //             <Button
            //               leftIcon={<LockIcon />}
            //               variant="ghost"
            //               size="xs"
            //               onClick={onCompleteClicked}
            //               disabled={!canCompleteTest}
            //             >
            //               Testi Tamamla
            //             </Button>
            //           </>
            //         ) : (
            //           <>
            //             <Button
            //               leftIcon={<Icon as={MdPrint} />}
            //               variant="ghost"
            //               size="xs"
            //               onClick={onPrintExpertiseReportClicked}
            //             >
            //               Yazdır
            //             </Button>
            //             <Button leftIcon={<Icon as={MdSmartphone} />} variant="ghost" size="xs">
            //               WP Gönder
            //             </Button>
            //           </>
            //         )}
            //       </HStack>
            //     )}
            //   </GridItem>
            // </Grid>
            <Flex width="100%" height="100%" flexDirection="column">
              <Box height="100%">
                <Box width="100%" height="100%" bg={bodyBg} p="4" maxWidth="100%">
                  <Tabs
                    variant="soft-rounded"
                    colorScheme="green"
                    align="center"
                    isLazy
                    index={currentTabIndex}
                    onChange={onTabChange}
                    isManual
                  >
                    <TabList flexWrap="wrap">
                      <Tab>Müşteri Bilgileri</Tab>
                      <Tab>Araç Bilgileri</Tab>
                      <Tab>Kaporta Bilgileri</Tab>
                      <Tab>Mekanik İnceleme</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel textAlign="start">
                        <ExpertiseClientInformationPage />
                      </TabPanel>
                      <TabPanel textAlign="start">
                        <ExpertiseCarInformationPage />
                      </TabPanel>
                      <TabPanel textAlign="start">
                        <ExpertiseBodyworkInformationPage />
                      </TabPanel>
                      <TabPanel textAlign="start">
                        <ExpertiseMechanicInformationPage />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </Box>
              <Box p={1} height="60px" bg={bodyBg}>
                {isBaseSize ? (
                  <HStack height="100%" justify="space-between">
                    <Button leftIcon={<ArrowForwardIcon />} variant="ghost" onClick={onActionsOpen}>
                      Aksiyonlar
                    </Button>
                    {isFocusCustomerLoading && canCustomerContinue && (
                      <Button
                        leftIcon={<LockIcon />}
                        variant="outline"
                        onClick={onCompleteClicked}
                        disabled={!canCompleteTest}
                      >
                        Testi Tamamla
                      </Button>
                    )}
                  </HStack>
                ) : (
                  <HStack spacing="24px" justify={['center', null, 'flex-end']} align="center" height="100%">
                    {isFocusCustomerLoading && canCustomerContinue ? (
                      <>
                        <Button leftIcon={<CloseIcon />} variant="ghost" size="xs" onClick={onCancelClicked}>
                          İptal Et
                        </Button>
                        <Button
                          leftIcon={<LockIcon />}
                          variant="ghost"
                          size="xs"
                          onClick={onCompleteClicked}
                          disabled={!canCompleteTest}
                        >
                          Testi Tamamla
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          leftIcon={<Icon as={MdPrint} />}
                          variant="ghost"
                          size="xs"
                          onClick={onPrintExpertiseReportClicked}
                        >
                          Yazdır
                        </Button>
                        <Button
                          leftIcon={<Icon as={MdSmartphone} />}
                          variant="ghost"
                          size="xs"
                          onClick={() => sendSmsMutation.mutate(focusedCustomer.id)}
                        >
                          SMS Gönder
                        </Button>
                        <Button
                          leftIcon={<Icon as={MdEmail} />}
                          variant="ghost"
                          size="xs"
                          onClick={() => sendEmailMutation.mutate(focusedCustomer.id)}
                        >
                          Email Gönder
                        </Button>
                        <Button
                          leftIcon={<Icon as={DownloadIcon} />}
                          variant="ghost"
                          size="xs"
                          onClick={() => getWorkOrderForm.mutate(focusedCustomer.id)}
                        >
                          İş Emri Formu
                        </Button>
                        <Button
                          isDisabled={!isCustomerEditable}
                          leftIcon={<Icon as={EditIcon} />}
                          variant="ghost"
                          size="xs"
                          onClick={() => isCustomerEditable && onEditTestModalOpen()}
                        >
                          Düzenle
                        </Button>
                        {authUserInfo?.isSuperAdmin && (
                          <Button
                            // isDisabled={!isCustomerEditable}
                            leftIcon={<Icon as={UnlockIcon} />}
                            variant="ghost"
                            size="xs"
                            onClick={() => unlockMutation.mutate(focusedCustomer.id)}
                          >
                            Kilidi Aç
                          </Button>
                        )}
                      </>
                    )}
                  </HStack>
                )}
              </Box>
            </Flex>
          ) : (
            <Center height="100%">Lutfen islemek icin bir arac secin...</Center>
          )}
        </GridItem>
      </Grid>
    </>
  );
};
