import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components';
import { ExpertiseDamageNoteDictItem, ExpertiseDamageNoteType, ExpertiseQueryCacheKey } from 'types';
import { AddIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons';
import { useQuery } from 'react-query';
import { Api } from 'api';
import { FormControlFormInput, FormControlFormSelect } from 'core';
import { AdminSaveDamageDictionaryItemForm } from '../components';

export const AdminExpertiseDictionaryManagementPage = () => {
  const [rowSelection, setRowSelection] = React.useState<{
    [key: string]: boolean;
  }>({});

  const [selectedNoteType, setSelectedNoteType] = useState<ExpertiseDamageNoteType>(ExpertiseDamageNoteType.Bodywork);
  const [focusedDamageNoteDictItem, setFocusedDamageNoteDictItem] = useState<ExpertiseDamageNoteDictItem>();
  const [searchValue, setSearchValue] = useState('');

  const { data: notes, refetch: refetchNotes } = useQuery(
    [ExpertiseQueryCacheKey.GetDamageNotes, selectedNoteType],
    async () => {
      const response = await Api.Expertise.getDamageNoteDictItems(selectedNoteType);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  const handleSearchInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowSelection({});
    setSearchValue(event.target.value);
  }, []);

  const filteredNotes = useMemo(() => {
    if (!notes) {
      return [];
    }

    return notes.filter((note) => note.note.toLowerCase().includes(searchValue.toLowerCase()));
  }, [notes, searchValue]);

  const selectedNotes = useMemo(() => {
    if (!notes) {
      return [];
    }

    const filterNotes = notes.filter((note) => note.note.toLowerCase().includes(searchValue.toLowerCase()));

    return Object.keys(rowSelection)
      .filter((index: string) => rowSelection[index])
      .map((index: string) => filterNotes[parseInt(index, 10)])
      .filter(Boolean);
  }, [notes, rowSelection, searchValue]);

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
  const { isOpen: isMergeModalOpen, onOpen: onMergeModalOpen, onClose: onMergeModalClose } = useDisclosure();

  const columns: ColumnDef<ExpertiseDamageNoteDictItem, any>[] = [
    {
      id: '#',
      cell: ({ row }) => {
        return <Checkbox isChecked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />;
      },
      meta: {
        style: {
          width: '1px',
        } as React.CSSProperties,
      },
    },

    {
      header: 'Not',
      accessorKey: 'note',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Not Turu',
      accessorKey: 'noteType',
      cell: (info) => {
        switch (info.getValue()) {
          case ExpertiseDamageNoteType.Mechanic:
            return 'Mekanik';
          case ExpertiseDamageNoteType.Bodywork:
          default:
            return 'Kaporta';
        }
      },
    },
    {
      id: 'action',
      header: 'Aksyion',
      cell: ({ row }) => {
        return (
          <IconButton
            icon={<EditIcon />}
            aria-label=""
            onClick={() => {
              setFocusedDamageNoteDictItem(row.original);
              onEditModalOpen();
            }}
          />
        );
      },
    },
  ];

  const { isOpen: isCreateModalOpen, onClose: onCreateModalClose, onOpen: onCreateModalOpen } = useDisclosure();

  const [isMutationLoading, { on: onLoading, off: offLoading }] = useBoolean();

  const onSubmitStart = () => {
    onLoading();
  };
  const onSubmitComplete = (data?: ExpertiseDamageNoteDictItem) => {
    offLoading();

    if (data) {
      onEditModalClose();
      onCreateModalClose();
      onMergeModalClose();
      setRowSelection({});
      refetchNotes();
    }
  };
  return (
    <>
      <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Oluştur
            <ModalCloseButton disabled={isMutationLoading} onClick={onEditModalClose} />
          </ModalHeader>
          <ModalBody>
            <AdminSaveDamageDictionaryItemForm
              focusedNote={focusedDamageNoteDictItem}
              formId="editNote"
              selectedNotes={[]}
              onSubmitComplete={onSubmitComplete}
              onSubmitStart={onSubmitStart}
              noteType={selectedNoteType}
            />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button onClick={onEditModalClose} disabled={isMutationLoading}>
              Kapat
            </Button>
            <Button colorScheme="blue" type="submit" form="editNote" isLoading={isMutationLoading}>
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            Oluştur
            <ModalCloseButton disabled={isMutationLoading} onClick={onCreateModalClose} />
          </ModalHeader>
          <ModalBody>
            <AdminSaveDamageDictionaryItemForm
              formId="createNote"
              selectedNotes={[]}
              onSubmitComplete={onSubmitComplete}
              onSubmitStart={onSubmitStart}
              noteType={selectedNoteType}
            />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button onClick={onCreateModalClose} disabled={isMutationLoading}>
              İptal
            </Button>
            <Button colorScheme="blue" type="submit" form="createNote" isLoading={isMutationLoading}>
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isMergeModalOpen} onClose={onMergeModalClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            Birleştir
            <ModalCloseButton disabled={isMutationLoading} onClick={onMergeModalClose} />
          </ModalHeader>
          <ModalBody>
            <AdminSaveDamageDictionaryItemForm
              formId="mergeNote"
              selectedNotes={selectedNotes}
              onSubmitComplete={onSubmitComplete}
              onSubmitStart={onSubmitStart}
              noteType={selectedNoteType}
            />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button onClick={onMergeModalClose} disabled={isMutationLoading}>
              İptal
            </Button>
            <Button colorScheme="blue" type="submit" form="mergeNote" isLoading={isMutationLoading}>
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DataTable
        title="Sözlük"
        data={filteredNotes || []}
        columns={columns}
        tableOptions={{
          state: {
            rowSelection,
          },
          enableRowSelection: true,
          onRowSelectionChange: setRowSelection,
          autoResetPageIndex: false,
        }}
        usePagination
        actions={(table) => {
          const isMergeButtonDisabled = !(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected());

          return (
            <HStack gap={2}>
              <FormControlFormSelect
                label="Not Türü"
                value={selectedNoteType}
                onChange={(e) => {
                  setSelectedNoteType(parseInt(e.target.value, 10));
                }}
              >
                <option value={ExpertiseDamageNoteType.Bodywork}>Kaporta</option>
                <option value={ExpertiseDamageNoteType.Mechanic}>Mekanik</option>
              </FormControlFormSelect>
              <IconButton icon={<AddIcon />} aria-label="" onClick={onCreateModalOpen} alignSelf="end" />
              <IconButton
                alignSelf="end"
                icon={<RepeatIcon />}
                aria-label=""
                disabled={isMergeButtonDisabled}
                onClick={onMergeModalOpen}
              />
              <FormControlFormInput
                label="Ara"
                value={searchValue}
                onChange={handleSearchInputChange}
                width="150px"
                placeholder="Not ara"
              />
            </HStack>
          );
        }}
      />
    </>
  );
};
