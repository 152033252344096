import { AxiosInstance } from 'axios';

export interface ApiResponse<T> {
  data: T;
  isSuccess: boolean;
  message?: string;
  code: number;
}

export abstract class ApiBase {
  constructor(protected readonly apiClient: AxiosInstance) {}
}
