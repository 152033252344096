import { ChakraProvider, extendTheme, Drawer, ThemeConfig, Box, Text, Flex, Spinner } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';
import { Api } from 'api';
import {
  ArcElement,
  Legend,
  Chart as ChartJS,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
} from 'chart.js';
import { localStorageUserAuthInfoKey } from 'core';
import { hideLoading, setAuthUserInfo, setIsAuthInitDone, showLoading } from 'features';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';
import { RootState } from 'store';
import { AuthUserInfo } from 'types';
import './styles/main.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title);

const themeConfig: ThemeConfig = {
  // @ts-ignore
  initialColorMode: 'system',
  useSystemColorMode: true,
};

// @ts-ignore
Drawer.defaultProps = {
  // @ts-ignore
  ...(Drawer.defaultProps || {}),
  placement: 'left',
};

const customTheme = extendTheme({
  config: themeConfig,
  components: {
    Drawer: {
      variants: {
        permanent: {
          dialog: {
            transform: 'none !important',
            boxShadow: 'none !important',
            pointerEvents: 'auto',
            zIndex: cssVar('chakra-zIndices-base').reference,
            width: '280px !important',
          },
          dialogContainer: {
            pointerEvents: 'none',
            width: '280px !important',
            zIndex: 1,
          },
        },
      },
      defaultProps: {
        placement: 'left',
      },
    },
  },
});

export const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.core.isLoading);
  const isAuthInitDone = useSelector((state: RootState) => state.auth.isAuthInitDone);

  useEffect(() => {
    dispatch(showLoading());
    const authUserInfoStr = localStorage.getItem(localStorageUserAuthInfoKey);
    if (authUserInfoStr) {
      const authUserInfo: AuthUserInfo = JSON.parse(authUserInfoStr);
      if (authUserInfo.token) {
        dispatch(setAuthUserInfo(authUserInfo));
        Api.token = authUserInfo.token;
      }
    }
    dispatch(setIsAuthInitDone(true));
    dispatch(hideLoading());
  }, []);

  return (
    <ChakraProvider theme={customTheme}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          width="100vw"
          height="100vh"
          zIndex="99999"
          sx={{
            backgroundColor: 'rgba(0.2, 0.2, 0.2, 0.4)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
            <Spinner size="md" mr={2} /> <Text>Yükleniyor...</Text>
          </Flex>
        </Box>
      )}
      {isAuthInitDone && (
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      )}
    </ChakraProvider>
  );
};
