import { useBreakpointValue, useColorModeValue, useToast } from '@chakra-ui/react';
import { Api } from 'api';
import { AdminUserManagementUser } from 'apps/admin';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  CustomerQueryCacheKey,
  CustomerExpertiseFileType,
  CustomerTestResultFile,
  ExpertisePackage,
  AuthUserInfo,
  ExpertisePackageAbilityDefinitionType,
  ExpertisePointQueryCacheKey,
  ExpertiseQueryCacheKey,
  InhouseCode,
} from 'types';
import { getErrorMessage } from './utils';

export const useGetUploadedClientFile = (
  customerId: number | undefined,
  fileType: CustomerExpertiseFileType,
  configOptions: Omit<
    UseQueryOptions<
      CustomerTestResultFile,
      unknown,
      CustomerTestResultFile,
      (number | CustomerQueryCacheKey | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery(
    [CustomerQueryCacheKey.GetCustomerFile, fileType, customerId],
    async () => {
      if (customerId) {
        const response = await Api.Customer.getCustomerFile(customerId, fileType);
        return response.data;
      }

      throw new Error('Araç testi sonuçları çekilirken hata oluştu');
    },
    {
      ...configOptions,
      enabled: customerId != null,
      refetchOnWindowFocus: false,
    },
  );
};

export const useUploadClientFile = (
  customerId: number | undefined,
  fileType: CustomerExpertiseFileType,
  options?: Omit<UseMutationOptions<any, any, File, unknown>, 'mutationFn' | 'mutationKey'> | undefined,
) => {
  return useMutation<any, any, File>(async (file: File) => {
    if (customerId) {
      return Api.Customer.saveCustomerFile(customerId, fileType, file);
    }

    throw new Error('Bilinmeyen hata');
  }, options);
};

export const useBodyBackground = () => {
  return useColorModeValue('gray.50', 'gray.700');
};

export const useGetMainDrawerVariant = () => {
  return useBreakpointValue({ base: undefined, md: 'permanent' });
};

export const useIsBaseSize = () => {
  return useBreakpointValue({ base: true, md: false });
};

export const useMainLayoutTemplateColumns = () => {
  return useBreakpointValue({ base: '1fr', md: '280px 1fr' });
};

export const useMainLayoutTemplateRows = () => {
  return useBreakpointValue({ base: '56px 1fr 1fr', md: '1fr' });
};

export const useFetchExpertisePackages = (): UseQueryResult<ExpertisePackage[], unknown> => {
  const toast = useToast();
  return useQuery(
    CustomerQueryCacheKey.GetExpertisePackages,
    async () => {
      const response = await Api.Expertise.getExpertisePackages();
      const packagesResponse = response.data;
      return packagesResponse.expertisePackages;
    },
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        console.error(e);
        toast({
          title: 'Ekspertiz Paketleri Çekilirken Hata Oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
};

export const useAuthUser = (): AuthUserInfo | undefined => {
  const { authUserInfo } = useSelector((state: RootState) => ({
    authUserInfo: state.auth.authUserInfo,
  }));

  return authUserInfo;
};

export const useCheckAbilityCompleted = (
  customerId: number | undefined,
  abilityDefinitionId: ExpertisePackageAbilityDefinitionType,
  configOptions?: Omit<
    UseQueryOptions<boolean, unknown, boolean, (number | CustomerQueryCacheKey | undefined)[]>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<boolean, unknown> => {
  const toast = useToast();
  return useQuery(
    [CustomerQueryCacheKey.CheckAbilityCompleted, customerId, abilityDefinitionId],
    async () => {
      const response = await Api.Customer.checkAbilityCompleted(customerId, abilityDefinitionId);
      return response.data;
    },
    {
      ...configOptions,
      enabled: customerId != null,
      refetchOnWindowFocus: false,
      onError: (e) => {
        console.error(e);
        toast({
          title: 'Tamamlanan Testler Tespit Edilirken Hata Oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
};

export const useFetchAdminManagementUsers = (): UseQueryResult<AdminUserManagementUser[], unknown> => {
  const toast = useToast();
  return useQuery(
    ExpertisePointQueryCacheKey.GetUsers,
    async () => {
      const response = await Api.ExpertisePoint.getUsers();
      return response.data;
    },
    {
      onError(error: Error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcıları çekilirken hata oluştu'),
        });
      },
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
};

export const useFetchAdminInhouseCodes = (): UseQueryResult<InhouseCode[], unknown> => {
  const toast = useToast();
  return useQuery(
    ExpertiseQueryCacheKey.GetInhouseCodes,
    async () => {
      const response = await Api.Expertise.getInhouseCodes();
      return response.data?.codes || [];
    },
    {
      onError(error: Error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Kullanıcıları çekilirken hata oluştu'),
        });
      },
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
};

export const useFetchInhouseCodeExpertisePackages = (): UseQueryResult<ExpertisePackage[], unknown> => {
  const toast = useToast();
  return useQuery(
    ExpertiseQueryCacheKey.GetInhouseCodeExpertisePackages,
    async () => {
      const response = await Api.Expertise.getInhouseCodeExpertisePackages();
      const packagesResponse = response.data;
      return packagesResponse.packages;
    },
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        console.error(e);
        toast({
          title: 'Ekspertiz Paketleri Çekilirken Hata Oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
};
