import { Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, useToast } from '@chakra-ui/react';
import { Api } from 'api';
import {
  chasisNoRegex,
  FormControlFormChasisNoInput,
  FormControlFormSelect,
  GenericMessages,
  NoSpecialCharacterRegex,
} from 'core';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { IntegrationQueryCacheKey } from 'types';
import * as yup from 'yup';
import {
  AdminExpertisePointKmInquiriesHistoryTable,
  AdminExpertisePointTramerInquiriesHistoryTable,
  AdminPageTitle,
} from '../components';

const validationSchema = yup.object({
  chasisNo: yup
    .string()
    .matches(NoSpecialCharacterRegex, GenericMessages.NoSpecialCharater)
    .matches(chasisNoRegex, GenericMessages.InvalidChasisNo),
  requestType: yup.string().required('Request type is required'),
});

export const AdminInquiriesPage = () => {
  const toast = useToast();

  const { data: kmQueriesData } = useQuery(
    [IntegrationQueryCacheKey.getExternalKilometerQueries],
    async () => {
      const response = await Api.Integration.getExternalKilometerQueries();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: tramerQueriesData } = useQuery(
    [IntegrationQueryCacheKey.getExternalTramerQueries],
    async () => {
      const response = await Api.Integration.getExternalTramerQueries();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const getKilometerResultImageByChassisMutation = useMutation(
    async (chasisNo: string) => {
      const response = await Api.Integration.getKilometerResultImageByChassis(chasisNo);
      return response.data;
    },
    {
      onSuccess: ({ imageUrl }) => {
        if (imageUrl) {
          window.open(imageUrl, '_blank');
        } else {
          toast({
            title: 'Error occurred, image URL not found',
            status: 'error',
            isClosable: true,
          });
        }
      },
      onError: (err: any) => {
        console.error(err);
        toast({
          title: err?.response?.data?.message || 'Error occurred while getting kilometer result image by chassis',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const getTramerResultsByChassisMutation = useMutation(
    async (chassisNumber: string) => {
      const response = await Api.Integration.getTramerResultsByChassis(chassisNumber);
      return response.data;
    },
    {
      onSuccess: ({ tramerResult }) => {
        if (tramerResult) {
          window.open(tramerResult?.url, '_blank');
        } else {
          toast({
            title: 'Error occurred, Tramer result not found',
            status: 'error',
            isClosable: true,
          });
        }
      },
      onError: (err: any) => {
        console.error(err);
        toast({
          title: err?.response?.data?.message || 'Error occurred while getting Tramer results by chassis',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      chasisNo: '',
      requestType: 'KM',
    },
    validationSchema,
    onSubmit: (data: { chasisNo: string; requestType: string }) => {
      toast({
        isClosable: true,
        status: 'info',
        title: 'Bu işlem biraz zaman alabilir.Lütfen bekleyiniz.',
      });
      if (data.requestType === 'KM') {
        getKilometerResultImageByChassisMutation.mutate(data.chasisNo);
      } else if (data.requestType === 'Tramer') {
        getTramerResultsByChassisMutation.mutate(data.chasisNo);
      }
    },
  });

  return (
    <>
      <AdminPageTitle title="Sorgulamalar" />

      <Flex gap={12} direction="column">
        <Card width={['100%', '100%', '50%']}>
          <CardHeader>
            <Heading size="md">Sorgulama</Heading>
          </CardHeader>
          <CardBody>
            <form noValidate onSubmit={handleSubmit} id="adminKmResultForm">
              <FormControlFormSelect mb="10" name="requestType" value={values.requestType} onChange={handleChange}>
                <option value="KM">KM Sorgulama</option>
                <option value="Tramer">Tramer Sorgulama</option>
              </FormControlFormSelect>
              <FormControlFormChasisNoInput
                label="Şase No"
                name="chasisNo"
                onChange={handleChange}
                value={values.chasisNo}
                error={errors.chasisNo != null}
                helperText={errors.chasisNo}
              />
            </form>
          </CardBody>
          <CardFooter justifyContent="flex-end">
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={
                getKilometerResultImageByChassisMutation.isLoading || getTramerResultsByChassisMutation.isLoading
              }
              form="adminKmResultForm"
              isDisabled={!values.chasisNo}
            >
              Sorgula
            </Button>
          </CardFooter>
        </Card>
        {values.requestType === 'KM'
          ? kmQueriesData && <AdminExpertisePointKmInquiriesHistoryTable inquiriesData={kmQueriesData} />
          : tramerQueriesData && <AdminExpertisePointTramerInquiriesHistoryTable inquiriesData={tramerQueriesData} />}
      </Flex>
    </>
  );
};
