import { ExpertisePoint } from './expertise-point.types';

export const enum ProductTypeQueryCacheKey {
  GetTramerQuery = 'GetTramerQuery',
  GetKilometerQuery = 'GetKilometerQuery',
}

export const enum UtilQueryCacheKey {
  GetAnnouncements = 'GetAnnouncements',
  GetUnreadAnnouncemenets = 'GetUnreadAnnouncemenets',
  GetUnreadAnnouncementsCount = 'GetUnreadAnnouncementsCount',
  GetProducts = 'GetProducts',
}

export const enum ProductType {
  TramerQuery = 10,
  KilometerQuery = 20,
}

export enum ApplicationToggleType {
  KmQuery = 10,
  DamageQuery = 20,
}

export interface Announcement {
  id: number;
  title: string;
  description: string;
  isRead?: boolean;
  createdAt: Date;
  isAllSend?: boolean;
  expertisePoints?: ExpertisePoint[];
}

export interface AnnouncementResponse extends Omit<Announcement, 'createdAt'> {
  createdAt: string;
}

export interface SendAnnouncementRequest {
  title: string;
  description: string;
  toExpertisePointIds?: number[];
}

export const ApplicationToggleTypeLabel = new Map<number, string>([
  [ApplicationToggleType.KmQuery, 'Kilometre Sorgulama'],
  [ApplicationToggleType.DamageQuery, 'Hasar Sorgulama'],
]);
