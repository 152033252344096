import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Button,
  ModalFooter,
  Alert,
  AlertIcon,
  Center,
  VStack,
  Image,
} from '@chakra-ui/react';
import { Api } from 'api';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { IntegrationQueryCacheKey } from 'types';
import { LoadingText } from './LoadingText';

export interface KmResultProps extends Omit<ModalProps, 'children'> {
  customerId: number;
}

export const KmResults: React.FC<KmResultProps> = ({ customerId, ...modalProps }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { isLoading, data: kmResults } = useQuery(
    [IntegrationQueryCacheKey.GetKmResults, customerId],
    async () => {
      const { data } = await Api.Integration.getKilometerResult(customerId);
      return data;
    },
    {
      onError: (error: any) => {
        const {
          response: {
            data: { message },
          },
        } = error;
        setErrorMessage(message);
      },
      retry: 0,
      enabled: modalProps.isOpen,
      refetchOnWindowFocus: false,
    },
  );
  return (
    <Modal {...modalProps} isCentered size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Km Kaydı Sorgu Sonuçları</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && (
            <Center>
              <VStack>
                <Alert status="info" variant="solid" my="2rem">
                  <AlertIcon />
                  Bu işlemin sonuçlanması biraz zaman alabilir.
                </Alert>
                <LoadingText />
              </VStack>
            </Center>
          )}
          {errorMessage && (
            <Alert status="warning" variant="solid" mt="2rem">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          {kmResults && <Image src={kmResults.imageUrl} overflowY="scroll" objectFit="contain" />}
        </ModalBody>
        <ModalFooter>
          <Button onClick={modalProps.onClose}>Kapat</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
