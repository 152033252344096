import {
  Portal,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Center,
  Box,
  DrawerFooter,
  Text,
  useToast,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { Api } from 'api';
import { LoadingText } from 'components';
import { FormControlFormInput, getErrorMessage } from 'core';
import { hideLoading, showLoading } from 'features';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { ApiResponse, ExpertiseQueryCacheKey } from 'types';
import { SaveExpertiseReportResultRequest } from 'types/save-expertise.types';

const ExpertiseManuelTest = ({
  isOpen,
  onClose,
  chassisNumber,
  plate,
  refetchHasExpertiseResultFromMachine,
  customerId,
}: {
  chassisNumber: string | undefined;
  plate: string;
  isOpen: boolean;
  onClose: () => void;
  refetchHasExpertiseResultFromMachine: () => void;
  customerId: number;
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const formId = `expertiseManuelTestForm`;
  const manuelTestMutation = useMutation<ApiResponse<void>, any, any>(
    async (request: SaveExpertiseReportResultRequest) => {
      dispatch(showLoading());
      const response = await Api.Expertise.saveExpertiseReportResult(request);
      return response;
    },
    {
      onSuccess() {
        onClose();
        refetchHasExpertiseResultFromMachine();
        toast({
          isClosable: true,
          status: 'success',
          title: 'Manuel test verileri başarı ile kaydedildi',
        });
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError(error) {
        toast({
          isClosable: true,
          status: 'error',
          title: getErrorMessage(error, 'Manuel test verileri kayıt sırasında hata oluştu'),
        });
      },
    },
  );

  const { data: reportResult } = useQuery<SaveExpertiseReportResultRequest>(
    ExpertiseQueryCacheKey.GetExpertiseReportResult,
    async () => {
      const response = await Api.Expertise.getExpertiseReportResult(customerId);
      return response.data.reportResult;
    },
  );

  // eslint-disable-next-line no-console

  const calculateImbalance = (brake1: number, brake2: number) => {
    if (brake1 === 0 || brake2 === 0) return 0;
    const maxBrake = Math.max(brake1, brake2);
    const minBrake = Math.min(brake1, brake2);
    return Math.round(((maxBrake - minBrake) / maxBrake) * 100);
  };

  const { handleSubmit, values, errors, handleChange } = useFormik({
    initialValues: {
      power: reportResult?.result?.dynoTest?.power?.engine,
      frontLeftBrake: reportResult?.result?.brakeTest?.front?.left?.force,
      frontRightBrake: reportResult?.result?.brakeTest?.front?.right?.force,
      rearLeftBrake: reportResult?.result?.brakeTest?.rear?.left?.force,
      rearRightBrake: reportResult?.result?.brakeTest?.rear?.right?.force,
      leftHandBrake: reportResult?.result?.brakeTest?.park?.left?.force,
      rightHandBrake: reportResult?.result?.brakeTest?.park?.right?.force,
      suspensionFrontLeft: reportResult?.result?.suspensionTest?.front?.left?.grip,
      suspensionFrontRight: reportResult?.result?.suspensionTest?.front?.right?.grip,
      suspensionRearLeft: reportResult?.result?.suspensionTest?.rear?.left?.grip,
      suspensionRearRight: reportResult?.result?.suspensionTest?.rear?.right?.grip,
      slideSlipFront: reportResult?.result?.sideSlipTest?.front?.slip,
      slideSlipRear: reportResult?.result?.sideSlipTest?.rear?.slip,
    },
    enableReinitialize: true,
    onSubmit: (data: any) => {
      // eslint-disable-next-line no-use-before-define
      const parsedRequest = {
        'result': {
          'dynoTest': {
            'power': {
              'engine': data.power,
              'loss': 77,
              'wheel': 76,
              'powerUnit': 'kW',
              'performance': 78,
              'peakPowerRpm': 79,
              'peakPowerSpeed': 0,
              'performanceUnit': '%',
              'peakPowerRpmUnit': 'rpm',
              'peakPowerSpeedUnit': 'km/h',
            },
            'torque': {
              'loss': 0,
              'wheel': 0,
              'engine': 0,
              'torqueUnit': 'Nm',
              'peakTorqueRpm': 0,
              'peakTorqueSpeed': 0,
              'peakTorqueRpmUnit': 'rpm',
              'peakTorqueSpeedUnit': 'km/h',
            },
            'completed': data.power > 0,
          },
          'brakeTest': {
            'park': {
              'left': {
                'force': data.leftHandBrake,
                'forceUnit': 'daN',
                'efficiency': 30,
                'efficiencyUnit': '%',
              },
              'right': {
                'force': data.rightHandBrake,
                'forceUnit': 'daN',
                'efficiency': 30,
                'efficiencyUnit': '%',
              },
              'success':
                (data.leftHandBrake > 0 || data.rightHandBrake > 0) &&
                calculateImbalance(data.leftHandBrake, data.rightHandBrake) <= 30,
              'imbalance':
                data.leftHandBrake > 0 || data.rightHandBrake > 0
                  ? calculateImbalance(data.leftHandBrake, data.rightHandBrake)
                  : 0,
              'imbalanceUnit': '%',
            },
            'rear': {
              'left': {
                'force': data.rearLeftBrake,
                'forceUnit': 'daN',
                'efficiency': 30,
                'efficiencyUnit': '%',
              },
              'right': {
                'force': data.rearRightBrake,
                'forceUnit': 'daN',
                'efficiency': 30,
                'efficiencyUnit': '%',
              },
              'success':
                (data.rearLeftBrake > 0 || data.rearRightBrake > 0) &&
                calculateImbalance(data.rearLeftBrake, data.rearRightBrake) <= 30,
              'imbalance':
                data.rearLeftBrake > 0 || data.rearRightBrake > 0
                  ? calculateImbalance(data.rearLeftBrake, data.rearRightBrake)
                  : 0,
              'imbalanceUnit': '%',
            },
            'front': {
              'left': {
                'force': data.frontLeftBrake,
                'forceUnit': 'daN',
                'efficiency': 30,
                'efficiencyUnit': '%',
              },
              'right': {
                'force': data.frontRightBrake,
                'forceUnit': 'daN',
                'efficiency': 30,
                'efficiencyUnit': '%',
              },
              'success':
                (data.frontLeftBrake > 0 || data.frontRightBrake > 0) &&
                calculateImbalance(data.frontLeftBrake, data.frontRightBrake) <= 30,
              'imbalance':
                data.frontLeftBrake > 0 || data.frontRightBrake > 0
                  ? calculateImbalance(data.frontLeftBrake, data.frontRightBrake)
                  : 0,
              'imbalanceUnit': '%',
            },
            'completed': true,
          },
          'sideSlipTest': {
            'rear': {
              'slip': data.slideSlipRear,
              'success': Math.abs(data.slideSlipRear) <= 4,
              'slipUnit': 'M',
              'testSpeed': 7,
              'slipDirection': (() => {
                if (data.slideSlipRear < 0) return 'SOL';
                if (data.slideSlipRear > 0) return 'SAG';
                return '';
              })(),
              'testSpeedUnit': 'M',
            },
            'front': {
              'slip': data.slideSlipFront,
              'success': Math.abs(data.slideSlipFront) <= 4,
              'slipUnit': 'm/km',
              'testSpeed': 7,
              'slipDirection': (() => {
                const slipValue = Number(data.slideSlipFront);
                if (slipValue === 0) return '';
                return slipValue < 0 ? 'SOL' : 'SAG';
              })(),
              'testSpeedUnit': 'km/h',
            },
            'completed': true,
          },
          'suspensionTest': {
            'rear': {
              'left': {
                'grip': data.suspensionRearLeft,
                'success': data.suspensionRearLeft > 40 && data.suspensionRearLeft <= 100,
                'gripUnit': '%',
                'axisWeight': 305,
                'axisWeightUnit': 'kg',
              },
              'right': {
                'grip': data.suspensionRearRight,
                'success': data.suspensionRearRight > 40 && data.suspensionRearRight <= 100,
                'gripUnit': '%',
                'axisWeight': 274,
                'axisWeightUnit': 'kg',
              },
            },
            'front': {
              'left': {
                'grip': data.suspensionFrontLeft,
                'success': data.suspensionFrontLeft > 40 && data.suspensionFrontLeft <= 100,
                'gripUnit': '%',
                'axisWeight': 413,
                'axisWeightUnit': 'kg',
              },
              'right': {
                'grip': data.suspensionFrontRight,
                'success': data.suspensionFrontRight > 40 && data.suspensionFrontRight <= 100,
                'graphics': {
                  'interval': 50,
                  'intervalUnit': 'ms',
                },
                'gripUnit': '%',
                'axisWeight': 374,
                'axisWeightUnit': 'kg',
              },
            },
            'completed': true,
          },
        },
        'success': true,
        'vehicle': {
          'vin': chassisNumber,
          'plate': plate,
        },
        'errorMsg': '',
      };
      manuelTestMutation.mutate(parsedRequest);
    },
  });
  return (
    <Portal>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent overflow="auto">
          <form noValidate onSubmit={handleSubmit}>
            <DrawerHeader>Manuel Test Verileri</DrawerHeader>

            <DrawerBody>
              {false ? (
                <Center>
                  <LoadingText />
                </Center>
              ) : (
                <VStack gap={24}>
                  <form onSubmit={handleSubmit} noValidate id={formId}>
                    <Stack>
                      <Text fontWeight="bold" fontSize={32}>
                        Dinamometre
                      </Text>
                      <Box>
                        <FormControlFormInput
                          label="Ölçülen Güç"
                          placeholder="Ölçülen Güç"
                          name="power"
                          onChange={handleChange}
                          value={values.power}
                          error={errors.power != null}
                          helperText={errors.power as string}
                          type="number"
                        />
                      </Box>
                      <Text fontWeight="bold" fontSize={32}>
                        Frenleme
                      </Text>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <FormControlFormInput
                          label="Ön Sol Fren"
                          placeholder="Ön Sol Fren"
                          name="frontLeftBrake"
                          onChange={handleChange}
                          value={values.frontLeftBrake}
                          error={errors.frontLeftBrake != null}
                          helperText={errors.frontLeftBrake as string}
                          type="number"
                        />
                        <FormControlFormInput
                          label="Ön Sağ Fren"
                          placeholder="Ön Sağ Fren"
                          name="frontRightBrake"
                          onChange={handleChange}
                          value={values.frontRightBrake}
                          error={errors.frontRightBrake != null}
                          helperText={errors.frontRightBrake as string}
                          type="number"
                        />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <FormControlFormInput
                          label="Arka Sol Fren"
                          placeholder="Arka Sol Fren"
                          name="rearLeftBrake"
                          onChange={handleChange}
                          value={values.rearLeftBrake}
                          error={errors.rearLeftBrake != null}
                          helperText={errors.rearLeftBrake as string}
                          type="number"
                        />
                        <FormControlFormInput
                          label="Arka Sağ Fren"
                          placeholder="Arka Sağ Fren"
                          name="rearRightBrake"
                          onChange={handleChange}
                          value={values.rearRightBrake}
                          error={errors.rearRightBrake != null}
                          helperText={errors.rearRightBrake as string}
                          type="number"
                        />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <FormControlFormInput
                          label="Sol El Fren"
                          placeholder="Sol El Fren"
                          name="leftHandBrake"
                          onChange={handleChange}
                          value={values.leftHandBrake}
                          error={errors.leftHandBrake != null}
                          helperText={errors.leftHandBrake as string}
                          type="number"
                        />
                        <FormControlFormInput
                          label="Sağ El Fren"
                          placeholder="Sağ El Fren"
                          name="rightHandBrake"
                          onChange={handleChange}
                          value={values.rightHandBrake}
                          error={errors.rightHandBrake != null}
                          helperText={errors.rightHandBrake as string}
                          type="number"
                        />
                      </Box>
                      <Text fontWeight="bold" fontSize={32}>
                        Süspansiyon
                      </Text>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <FormControlFormInput
                          label="Ön Sol Süspansiyon"
                          placeholder="Ön Sol Süspansiyon"
                          name="suspensionFrontLeft"
                          onChange={handleChange}
                          value={values.suspensionFrontLeft}
                          error={errors.suspensionFrontLeft != null}
                          helperText={errors.suspensionFrontLeft as string}
                          type="number"
                        />
                        <FormControlFormInput
                          label="Ön Sağ Süspansiyon"
                          placeholder="Ön Sağ Süspansiyon"
                          name="suspensionFrontRight"
                          onChange={handleChange}
                          value={values.suspensionFrontRight}
                          error={errors.suspensionFrontRight != null}
                          helperText={errors.suspensionFrontRight as string}
                          type="number"
                        />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <FormControlFormInput
                          label="Arka Sol Süspansiyon"
                          placeholder="Arka Sol Süspansiyon"
                          name="suspensionRearLeft"
                          onChange={handleChange}
                          value={values.suspensionRearLeft}
                          error={errors.suspensionRearLeft != null}
                          helperText={errors.suspensionRearLeft as string}
                          type="number"
                        />
                        <FormControlFormInput
                          label="Arka Sağ Süspansiyon"
                          placeholder="Arka Sağ Süspansiyon"
                          name="suspensionRearRight"
                          onChange={handleChange}
                          value={values.suspensionRearRight}
                          error={errors.suspensionRearRight != null}
                          helperText={errors.suspensionRearRight as string}
                          type="number"
                        />
                      </Box>
                      <Text fontWeight="bold" fontSize={32}>
                        Kayma Testi
                      </Text>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <FormControlFormInput
                          label="Ön Kayma"
                          placeholder="Ön Kayma"
                          name="slideSlipFront"
                          onChange={handleChange}
                          value={values.slideSlipFront}
                          error={errors.slideSlipFront != null}
                          helperText={errors.slideSlipFront as string}
                          type="number"
                        />
                        <FormControlFormInput
                          label="Arka Kayma"
                          placeholder="Arka Kayma"
                          name="slideSlipRear"
                          onChange={handleChange}
                          value={values.slideSlipRear}
                          error={errors.slideSlipRear != null}
                          helperText={errors.slideSlipRear as string}
                          type="number"
                        />
                      </Box>
                    </Stack>
                  </form>
                </VStack>
              )}
            </DrawerBody>

            <DrawerFooter>
              <Button type="submit">Kaydet</Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
    </Portal>
  );
};

// eslint-disable-next-line import/no-default-export
export default ExpertiseManuelTest;
