import { Alert, AlertIcon, AlertTitle, Checkbox, Divider, useToast, VStack } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormInput, GenericMessages } from 'core';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { ExpertisePackageAbility, ExpertisePackageWithAbilities } from 'types';
import * as yup from 'yup';

type AbilityDefinitionType = {
  [key: number]: boolean;
};

const filterAbilityDefinitionsMap = (abilityDefinitionTypes: AbilityDefinitionType) =>
  Object.entries(abilityDefinitionTypes).filter((iter) => iter[1]);

const validationSchema = yup.object({
  name: yup.string().required(GenericMessages.CannotBeEmpty),
  price: yup.number().required(GenericMessages.CannotBeEmpty).min(0, GenericMessages.CantBeLessThanNumber(0)),
  abilityDefinitionTypes: yup
    .object()
    .test('abilityDefinitionTypes', 'En az bir paket özelliği seçilmiş olmalıdır', (value) => {
      return filterAbilityDefinitionsMap(value).length > 0;
    }),
});

type SavePackageWithAbilitiesFormData = {
  id?: number;
  name: string;
  price: string;
  abilityDefinitionTypes: AbilityDefinitionType;
  includeInRoyalty?: boolean;
  royaltyRate?: string | undefined;
  royaltyCheckboxChecked?: boolean;
};

export interface AdminPackageAbilitiesFormProps {
  formId: string;
  onSubmitStart: () => void;
  onSubmitComplete: (isSuccess: boolean) => void;
  abilities: ExpertisePackageAbility[];
  packageWithAbilities?: ExpertisePackageWithAbilities;
}

export const AdminPackageAbilitiesForm: React.FC<AdminPackageAbilitiesFormProps> = ({
  formId,
  onSubmitStart,
  onSubmitComplete,
  abilities,
  packageWithAbilities,
}) => {
  const toast = useToast();
  const initialAbilityDefinitionTypeTable = useMemo(() => {
    const freqMap = abilities.reduce((accum, next) => {
      const newAccum = { ...accum };
      newAccum[next.abilityDefinitionTypeId] = false;
      return accum;
    }, {} as any);

    if (packageWithAbilities) {
      packageWithAbilities.abilities.forEach((ability) => {
        freqMap[ability.abilityDefinitionTypeId] = true;
      });
    }

    return freqMap;
  }, [abilities]);

  const initialRoyaltyRateChecked = useMemo(() => {
    return packageWithAbilities?.expertisePackage.royaltyRate != null;
  }, [packageWithAbilities?.expertisePackage.royaltyRate]);

  const mutation = useMutation<void, void, SavePackageWithAbilitiesFormData>(
    async (formData: SavePackageWithAbilitiesFormData) => {
      onSubmitStart();
      await Api.Expertise.savePackageWithAbilities({
        id: packageWithAbilities?.expertisePackage.id || 0,
        name: formData.name,
        price: parseInt(formData.price, 10),
        includeInRoyalty: !formData.includeInRoyalty,
        abilityDefinitionTypes: filterAbilityDefinitionsMap(formData.abilityDefinitionTypes).map((iter) =>
          parseInt(iter[0], 10),
        ),
        royaltyRate:
          !formData.includeInRoyalty && formData.royaltyCheckboxChecked
            ? parseInt(formData.royaltyRate ?? '0', 10)
            : null,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: 'Paket başarı ile kayıt edildi',
          status: 'success',
          isClosable: true,
        });
        onSubmitComplete(true);
      },
      onError: () => {
        toast({
          title: 'Paket kayıt edilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
        onSubmitComplete(false);
      },
    },
  );
  const { values, errors, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: packageWithAbilities?.expertisePackage.name || '',
      price: packageWithAbilities?.expertisePackage.price || '',
      abilityDefinitionTypes: initialAbilityDefinitionTypeTable,
      includeInRoyalty: packageWithAbilities ? !packageWithAbilities.expertisePackage.includeInRoyalty : false,
      // info: ı dont get checked value from backend so i added a new field to check if it is checked or not
      royaltyCheckboxChecked: packageWithAbilities?.expertisePackage.includeInRoyalty && initialRoyaltyRateChecked,
      royaltyRate:
        packageWithAbilities?.expertisePackage.includeInRoyalty && initialRoyaltyRateChecked
          ? packageWithAbilities?.expertisePackage.royaltyRate ?? '0'
          : undefined,
    },
    onSubmit: (formData: SavePackageWithAbilitiesFormData) => {
      mutation.mutate(formData);
    },
    validationSchema,
    validateOnChange: false,
  });

  console.error(
    errors.abilityDefinitionTypes,
    errors.abilityDefinitionTypes && typeof errors.abilityDefinitionTypes === 'string',
  );

  const handleRoyaltyCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFieldValue('royaltyCheckboxChecked', isChecked);
    setFieldValue('royaltyRate', isChecked ? '0' : undefined);
  };

  return (
    <>
      <form id={formId} noValidate onSubmit={handleSubmit} />
      <VStack align="flex-start" gap={2}>
        <FormControlFormInput
          name="name"
          value={values.name}
          error={errors.name != null}
          helperText={errors.name}
          onChange={handleChange}
          label="Ad"
        />
        <FormControlFormInput
          name="price"
          value={values.price}
          error={errors.price != null}
          helperText={errors.price}
          onChange={handleChange}
          label="Fiyat"
          type="number"
        />
        {abilities.map((ability) => {
          return (
            <Checkbox
              key={`apaf.abilityDefinitionTypes.${ability.abilityDefinitionTypeId}`}
              colorScheme="blue"
              onChange={(event) => {
                setFieldValue(`abilityDefinitionTypes.${ability.abilityDefinitionTypeId}`, event.target.checked);
              }}
              isChecked={values.abilityDefinitionTypes[ability.abilityDefinitionTypeId]}
            >
              {ability.name}
            </Checkbox>
          );
        })}
        <Divider />

        <Checkbox
          colorScheme="blue"
          onChange={(event) => {
            setFieldValue('includeInRoyalty', event.target.checked);
          }}
          isChecked={values.includeInRoyalty}
        >
          Royalty hesaplanmasın
        </Checkbox>

        <Divider />

        {!values.includeInRoyalty && (
          <>
            <Checkbox
              colorScheme="blue"
              onChange={handleRoyaltyCheckboxChange}
              isChecked={values.royaltyCheckboxChecked}
            >
              Royalty Belirle
            </Checkbox>

            {values.royaltyCheckboxChecked && (
              <FormControlFormInput
                name="royaltyRate"
                value={values.royaltyRate}
                error={errors.royaltyRate != null}
                helperText={errors.royaltyRate}
                onChange={(e) => setFieldValue('royaltyRate', e.target.value)}
                label="Royalty Bedeli"
                type="number"
              />
            )}
          </>
        )}

        {errors.abilityDefinitionTypes && typeof errors.abilityDefinitionTypes === 'string' && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{errors.abilityDefinitionTypes}</AlertTitle>
          </Alert>
        )}
      </VStack>
    </>
  );
};
