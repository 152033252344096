import {
  TagProps,
  Tag,
  TagLabel,
  TagCloseButton,
  Avatar,
  Modal,
  ModalOverlay,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Image,
  Icon,
  VStack,
  Text,
  HStack,
} from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';
import { toTitleCase } from 'core';
import React, { useRef } from 'react';
import { MdCameraAlt, MdCancel } from 'react-icons/md';

type AutoCompletePictureNoteTagProps = {
  disabled?: boolean;
  label: string;
  onRemove?: () => void;
  image?: string | null;
  onImageChange: (file?: File) => void;
  onClearImage: () => void;
  isActionDisabled?: boolean;
} & TagProps;

const disabledStyles: TagProps = {
  cursor: 'text',
  userSelect: 'none',
  opacity: 0.4,
  _focus: { boxShadow: 'none' },
};

export const AutoCompletePictureNoteTag: React.FC<AutoCompletePictureNoteTagProps> = ({
  label,
  onRemove,
  disabled,
  image,
  onImageChange,
  onClearImage,
  isActionDisabled,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileChoseDialog = () => {
    if (!isActionDisabled && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onImageClick = () => {
    if (image) {
      onOpen();
    } else {
      openFileChoseDialog();
    }
  };

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      onImageChange(e.target.files[0]);
    }
  };

  const onImageCleanClicked = () => {
    onClearImage();
    onClose();
  };

  return (
    <>
      <input hidden type="file" accept="image/*" ref={fileInputRef} onChange={onFileChange} />
      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fotoğraflı Not</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Image src={image || undefined} boxSize="100%" maxWidth="500px" />
              <Text>{toTitleCase(label)}</Text>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            {!isActionDisabled && (
              <HStack>
                <Button leftIcon={<Icon as={MdCancel} />} onClick={onImageCleanClicked} colorScheme="red">
                  Temizle
                </Button>
                <Button leftIcon={<Icon as={MdCameraAlt} />} onClick={openFileChoseDialog}>
                  Tekrar Yükle
                </Button>
              </HStack>
            )}

            <Button onClick={onClose}>Kapat</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Tag borderRadius="md" fontWeight="normal" {...(disabled && disabledStyles)} {...rest}>
        <Avatar
          src={image || undefined}
          size="sm"
          ml={-1}
          mr={2}
          onClick={onImageClick}
          icon={!image ? <Icon as={MdCameraAlt} /> : undefined}
        />
        <TagLabel>{toTitleCase(label)}</TagLabel>
        {!isActionDisabled && (
          <TagCloseButton
            onClick={() => !disabled && runIfFn(onRemove)}
            cursor="pointer"
            {...(disabled && disabledStyles)}
          />
        )}
      </Tag>
    </>
  );
};
