import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { TotalBalanceSheetTransaction } from 'types';

export interface DealerBalanceTransactionHistoryDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  balanceHistory: TotalBalanceSheetTransaction[];
}

export const DealerBalanceTransactionHistoryDrawer: React.FC<DealerBalanceTransactionHistoryDrawerProps> = ({
  isOpen,
  onClose,
  balanceHistory,
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Bakiye Geçmişi</DrawerHeader>

        <DrawerBody>
          <TableContainer>
            <Table variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Tarih</Th>
                  <Th isNumeric>Miktar</Th>
                  <Th>Durum</Th>
                </Tr>
              </Thead>
              <Tbody>
                {balanceHistory.map((elem: TotalBalanceSheetTransaction) => (
                  <Tr key={`tbhs${elem.id}`}>
                    <Td>{elem.transactionDate?.toLocaleDateString() || '-'}</Td>
                    <Td isNumeric>₺{elem.amount}</Td>
                    <Td>{elem.isCompleted ? 'Tamamlandı' : 'Tamamlanmadı'}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Kapat
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
