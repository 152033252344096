/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  StackItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Api } from 'api';
import { DateRangePreSelector, ExpertisePointSelect, OccopayDrawer } from 'components';
import { FormControlFormInput, GenericMessages, OnlyNumberRegex } from 'core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  ExpertisePoint,
  ExpertisePointQueryCacheKey,
  PaymentTransationStartProcessData,
  TotalBalanceSheetData,
} from 'types';

import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { AdminExpertisePointBalanceHistoryTable, AdminPageTitle } from '../components';

const validationSchema = yup.object({
  amount: yup
    .string()
    .required(GenericMessages.CannotBeEmpty)
    .matches(OnlyNumberRegex, GenericMessages.OnlyNumber)
    .test(
      'less than 1000',
      GenericMessages.CantBeLessThanNumber(1000),
      (value) => !value || parseInt(value, 10) >= 1000,
    ),
});

export const AdminBalancePage = () => {
  const toast = useToast();
  const authUserInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const [expertisePointId, setExpertisePointId] = useState<number>();
  const [occopayPaymentData, setOccopayPaymentData] = useState<PaymentTransationStartProcessData>();
  const { isOpen: isOccopayDrawerOpen, onClose: onOccopayDrawerClose, onOpen: onOccopayDrawerOpen } = useDisclosure();
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // 7 gün öncesi
    new Date(),
  ]);

  const { data: transactionData, refetch: refetchBalances } = useQuery(
    [ExpertisePointQueryCacheKey.GetPreviousBalanceTransactions, expertisePointId, selectedDates[0], selectedDates[1]],
    async () => {
      const response = await Api.ExpertisePoint.getTransactions(
        expertisePointId,
        selectedDates[0].getTime(),
        selectedDates[1].getTime(),
      );
      const responseData = response.data;
      const convertedTransactionData: TotalBalanceSheetData = {
        totalBalance: responseData.totalBalance,
        transactions: responseData.transactions.map((transaction) => {
          return {
            id: transaction.id,
            amount: transaction.amount,
            isCompleted: transaction.isCompleted,
            transactionDate: transaction.transactionDate ? new Date(transaction.transactionDate) : undefined,
            expertisePointCityName: transaction.expertisePointCityName,
            expertisePointCountyName: transaction.expertisePointCountyName,
            expertisePointName: transaction.expertisePointName,
            transactionSource: transaction.transactionSource,
            balanceAfterTransaction: transaction.balanceAfterTransaction,
          };
        }),
      };
      return convertedTransactionData;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  const paymentStartMutation = useMutation<PaymentTransationStartProcessData, any, number>(
    async (amount: number) => {
      const response = await Api.ExpertisePoint.startProcess(amount);
      return { ...response.data, amount };
    },
    {
      onSuccess: (data: PaymentTransationStartProcessData) => {
        setOccopayPaymentData(data);
        onOccopayDrawerOpen();
        // eslint-disable-next-line no-use-before-define
        resetForm();
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Ödeme sistemi ile haberleşirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const { values, handleChange, handleSubmit, errors, resetForm } = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema,
    onSubmit: (data: { amount: string }) => {
      paymentStartMutation.mutate(parseInt(data.amount, 10));
    },
  });

  return (
    <>
      <OccopayDrawer paymentData={occopayPaymentData} isOpen={isOccopayDrawerOpen} onClose={onOccopayDrawerClose} />
      <AdminPageTitle title="Bakiye Geçmişi" />

      {!(authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin) && (
        <HStack align="center" gap={10}>
          <Card width={['100%', '100%', '25%']}>
            <CardHeader>
              <Heading size="md">Bakiye Yükle</Heading>
            </CardHeader>
            <CardBody>
              <form id="occopayAmountForm" onSubmit={handleSubmit}>
                <FormControlFormInput
                  label="Miktar"
                  type="number"
                  name="amount"
                  onChange={handleChange}
                  value={values.amount || ''}
                  error={errors.amount != null}
                  helperText={errors.amount}
                />
              </form>
            </CardBody>
            <CardFooter justifyContent="flex-end">
              <Button type="submit" form="occopayAmountForm" colorScheme="blue">
                Yükle
              </Button>
            </CardFooter>
          </Card>
        </HStack>
      )}

      <HStack align="center" gap={10}>
        <StackItem>
          <DateRangePreSelector
            onDateRangeSelected={(startDate: Date, endDate: Date) => {
              setSelectedDates([startDate, endDate]);
            }}
          />
        </StackItem>
        {(authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin) && (
          <StackItem w="300px">
            <ExpertisePointSelect
              enableAll
              onExpertisePointSelected={(selectedExpertisePoint) => {
                setExpertisePointId(selectedExpertisePoint?.id);
              }}
            />
          </StackItem>
        )}
      </HStack>

      <HStack align="center">
        <FormControl width={['100%', '100%', '25%']} marginBottom={55}>
          <FormLabel>Tarih Aralığı</FormLabel>
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            configs={{
              dateFormat: 'dd MMMM yyyy',
            }}
          />
        </FormControl>
      </HStack>

      {/* <Flex gap={12} direction="column" marginTop={55}> */}
      {transactionData?.transactions && (
        <AdminExpertisePointBalanceHistoryTable
          transactions={transactionData?.transactions}
          expertisePointId={expertisePointId}
          startDate={selectedDates[0].getTime()}
          endDate={selectedDates[1].getTime()}
        />
      )}
      {/* </Flex> */}
    </>
  );
};
