import { Button, ButtonProps, Menu, MenuButton, MenuItem, MenuList, Text, useToken, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ExpertiseBodyDamageType, ExpertiseBodyDamageTypeLabel, ExpertiseCarBodyPartName } from 'types';
import { ExpertiseBodyworkExpertiseStates } from '../models';

export interface ExpertiseBodyworkExpertiseStateSelectorProps extends Omit<ButtonProps, 'children'> {
  expertiseKeyName: ExpertiseCarBodyPartName;
  displayId: number;
  onBodyworkExpertiseChanged: (
    expertiseKeyName: ExpertiseCarBodyPartName,
    expertiseBodyworkExpertiseState: ExpertiseBodyDamageType,
  ) => void;
  value: ExpertiseBodyDamageType;
  label: string;
  isDisabled?: boolean;
}
export const ExpertiseBodyworkExpertiseStateSelector: React.FC<ExpertiseBodyworkExpertiseStateSelectorProps> = ({
  value,
  onBodyworkExpertiseChanged,
  expertiseKeyName,
  displayId,
  label,
  isDisabled,
  ...buttonProps
}) => {
  const [
    originalColor,
    paintedColor,
    changedColor,
    halfPaintedColor,
    localPaintedColor,
    removedColor,
    cutColor,
    processedColor,
    foilCoatedColor,
    damagedColor,
    sunroofColor,
    plasticColor,
  ] = useToken(
    // the key within the theme, in this case `theme.colors`
    'colors',
    // the subkey(s), resolving to `theme.colors.red.100`
    [
      'gray.900',
      'red.400',
      'green.300',
      'orange.400',
      'orange.900',
      'cyan.400',
      'purple.400',
      'pink.400',
      'yellow.400',
      'red.600',
      'yellow.600',
      'teal.400',
    ],
    // a single fallback or fallback array matching the length of the previous arg
  );

  const lookupTable = useMemo(
    () => ({
      [ExpertiseBodyDamageType.Original]: {
        bg: originalColor,
        label: ExpertiseBodyworkExpertiseStates.Original,
      },
      [ExpertiseBodyDamageType.Painted]: {
        bg: paintedColor,
        label: ExpertiseBodyworkExpertiseStates.Painted,
      },
      [ExpertiseBodyDamageType.Changed]: {
        bg: changedColor,
        label: ExpertiseBodyworkExpertiseStates.Changed,
      },
      [ExpertiseBodyDamageType.HalfPainted]: {
        bg: halfPaintedColor,
        label: ExpertiseBodyworkExpertiseStates.HalfPainted,
      },
      [ExpertiseBodyDamageType.LocallyPainted]: {
        bg: localPaintedColor,
        label: ExpertiseBodyworkExpertiseStates.LocallyPainted,
      },
      [ExpertiseBodyDamageType.Removed]: {
        bg: removedColor,
        label: ExpertiseBodyworkExpertiseStates.Removed,
      },
      [ExpertiseBodyDamageType.Cutting]: {
        bg: cutColor,
        label: ExpertiseBodyworkExpertiseStates.Cutting,
      },
      [ExpertiseBodyDamageType.Processed]: {
        bg: processedColor,
        label: ExpertiseBodyworkExpertiseStates.Processed,
      },
      [ExpertiseBodyDamageType.FoilCoated]: {
        bg: foilCoatedColor,
        label: ExpertiseBodyworkExpertiseStates.FoilCoated,
      },
      [ExpertiseBodyDamageType.Damaged]: {
        bg: damagedColor,
        label: ExpertiseBodyworkExpertiseStates.Damaged,
      },
      [ExpertiseBodyDamageType.Sunroof]: {
        bg: sunroofColor,
        label: ExpertiseBodyworkExpertiseStates.Sunroof,
      },
      [ExpertiseBodyDamageType.Plastic]: {
        bg: plasticColor,
        label: ExpertiseBodyworkExpertiseStates.Plastic,
      },
    }),
    [],
  );

  const onStateChange = (expertiseBodyworkExpertiseStates: ExpertiseBodyDamageType) => () => {
    onBodyworkExpertiseChanged(expertiseKeyName, expertiseBodyworkExpertiseStates);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        width={8}
        height={8}
        borderRadius="50%"
        variant="solid"
        {...buttonProps}
        bg={lookupTable[value]?.bg}
        _hover={{
          bg: lookupTable[value]?.bg,
        }}
        _active={{
          bg: lookupTable[value]?.bg,
        }}
      >
        <VStack spacing={0} width="100%" height="100%" justify="center">
          <Text fontSize="sm" color="white">
            {value}
          </Text>
        </VStack>
      </MenuButton>
      <MenuList>
        <MenuItem disabled isDisabled>
          {label}
        </MenuItem>
        {!isDisabled && (
          <>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Original)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Original)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Painted)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Painted)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Changed)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Changed)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.HalfPainted)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.HalfPainted)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.LocallyPainted)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.LocallyPainted)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Removed)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Removed)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Cutting)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Cutting)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Processed)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Processed)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.FoilCoated)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.FoilCoated)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Plastic)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Plastic)}
            </MenuItem>
            <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Damaged)}>
              {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Damaged)}
            </MenuItem>
            {/* Show sunroof option only on tavan of car */}
            {displayId === ExpertiseCarBodyPartName.Tavan && (
              <MenuItem onClick={onStateChange(ExpertiseBodyDamageType.Sunroof)}>
                {ExpertiseBodyDamageTypeLabel.get(ExpertiseBodyDamageType.Sunroof)}
              </MenuItem>
            )}
          </>
        )}
      </MenuList>
    </Menu>
  );
};

ExpertiseBodyworkExpertiseStateSelector.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  position: 'absolute',
  // eslint-disable-next-line react/default-props-match-prop-types
  bg: 'gray.900',
};
