import axios, { AxiosInstance } from 'axios';
import { AuthApi } from './auth.api';
import { CustomerApi } from './customer.api';
import { ExpertisePointApi } from './expertise-point.api';
import { ExpertiseApi } from './expertise.api';
import { IntegrationApi } from './integration.api';
import { LocationApi } from './location.api';
import { OcrApi } from './ocr.api';
import { ReportingApi } from './reporting.api';
import { UserApi } from './user.api';
import { UtilApi } from './util.api';
import { VehicleApi } from './vehicle.api';

interface ApiFuncs {
  Auth: AuthApi;
  Customer: CustomerApi;
  Vehicle: VehicleApi;
  Location: LocationApi;
  Expertise: ExpertiseApi;
  Integration: IntegrationApi;
  Ocr: OcrApi;
  ExpertisePoint: ExpertisePointApi;
  Util: UtilApi;
  Reporting: ReportingApi;
  User: UserApi;
}

export class BackendApi {
  private _token?: string;

  private axiosInstace: AxiosInstance;

  private apiFuncs: ApiFuncs;

  // eslint-disable-next-line no-use-before-define
  private static _instance: BackendApi;

  private constructor() {
    this.axiosInstace = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 60000,
    });
    this.apiFuncs = this.generateApiFuncs();
  }

  public get token(): string | undefined {
    return this._token;
  }

  public set token(newToken: string | undefined) {
    this._token = newToken;
    if (newToken) {
      this.axiosInstace = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 60000,
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
      });
      this.apiFuncs = this.generateApiFuncs();
    }
  }

  private generateApiFuncs(): ApiFuncs {
    return {
      Auth: new AuthApi(this.axiosInstace),
      Customer: new CustomerApi(this.axiosInstace),
      Vehicle: new VehicleApi(this.axiosInstace),
      Location: new LocationApi(this.axiosInstace),
      Expertise: new ExpertiseApi(this.axiosInstace),
      Integration: new IntegrationApi(this.axiosInstace),
      Ocr: new OcrApi(this.axiosInstace),
      ExpertisePoint: new ExpertisePointApi(this.axiosInstace),
      Util: new UtilApi(this.axiosInstace),
      Reporting: new ReportingApi(this.axiosInstace),
      User: new UserApi(this.axiosInstace),
    };
  }

  public static get Instance(): BackendApi {
    if (!this._instance) {
      this._instance = new BackendApi();
    }
    return this._instance;
  }

  // #region Api Etkilesim fonksiyonlari
  public get Auth(): AuthApi {
    return this.apiFuncs.Auth;
  }

  public get Customer(): CustomerApi {
    return this.apiFuncs.Customer;
  }

  public get Vehicle(): VehicleApi {
    return this.apiFuncs.Vehicle;
  }

  public get Location(): LocationApi {
    return this.apiFuncs.Location;
  }

  public get Expertise(): ExpertiseApi {
    return this.apiFuncs.Expertise;
  }

  public get Integration(): IntegrationApi {
    return this.apiFuncs.Integration;
  }

  public get Ocr(): OcrApi {
    return this.apiFuncs.Ocr;
  }

  public get ExpertisePoint(): ExpertisePointApi {
    return this.apiFuncs.ExpertisePoint;
  }

  public get Util(): UtilApi {
    return this.apiFuncs.Util;
  }

  public get Reporting(): ReportingApi {
    return this.apiFuncs.Reporting;
  }

  public get User(): UserApi {
    return this.apiFuncs.User;
  }
  // #endregion
}

export const Api = BackendApi.Instance;
