import { Box, Button, Center, HStack, VStack, useColorModeValue } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormInput, updateLocalStorageAuthInfo } from 'core';
import { setAuthUserInfo } from 'features';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { AuthUserInfo, LoginInfo } from 'types';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const mutation = useMutation<LoginInfo, any, { email: string; password: string }>(
    async ({ email, password }) => {
      const response = await Api.Auth.login(email, password);
      return response.data;
    },
    {
      onSuccess: (loginInfo) => {
        const authUserInfo: AuthUserInfo = { ...loginInfo.user, token: loginInfo.token };
        Api.token = loginInfo.token;
        dispatch(setAuthUserInfo(authUserInfo));
        updateLocalStorageAuthInfo(authUserInfo);
      },
    },
  );

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (formValues) => {
      mutation.mutateAsync(formValues);
    },
  });
  return (
    <Center height="100vh">
      <Box boxShadow="lg" p="8" rounded="md" bg={useColorModeValue('white', 'gray.900')}>
        <form noValidate onSubmit={handleSubmit}>
          <VStack spacing={10} align="stretch">
            <FormControlFormInput
              label="Kullanıcı Adı"
              placeholder="Kullanıcı Adı"
              onChange={handleChange}
              name="email"
              value={values.email}
              helperText={errors.email}
              error={errors.email != null}
            />
            <FormControlFormInput
              label="Parola"
              placeholder="Parola"
              type="password"
              onChange={handleChange}
              name="password"
              value={values.password}
              helperText={errors.password}
              error={errors.password != null}
            />
            <HStack justify="flex-end">
              <Button colorScheme="blue" isLoading={mutation.isLoading} type="submit">
                Giris Yap
              </Button>
            </HStack>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};
