import { useToast } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { UtilQueryCacheKey } from 'types';

export type ProductSelectProps = FormControlFormSelectProps;
export const ProductSelect: React.FC<ProductSelectProps> = ({ ...formSelectProps }) => {
  const toast = useToast();
  const query = useQuery(
    UtilQueryCacheKey.GetProducts,
    async () => {
      const response = await Api.Util.getProducts();
      return response.data;
    },
    {
      onError: () => {
        toast({
          status: 'error',
          title: 'Ürünler çekilirken hata oluştu',
          isClosable: true,
        });
      },
      refetchOnWindowFocus: false,
    },
  );
  return (
    <FormControlFormSelect label="Ürün Seç" placeholder="Ürün Seç" {...formSelectProps}>
      {query.data?.map((product) => (
        <option value={product.id} key={`ps${product.name}`}>
          {product.name}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
