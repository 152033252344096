import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React from 'react';
import { useQuery } from 'react-query';
import { VehicleQueryCacheKeys } from 'types';

export type VehicleFuelTypeSelectProps = FormControlFormSelectProps;
export const VehicleFuelTypeSelect: React.FC<VehicleFuelTypeSelectProps> = ({ ...formSelectProps }) => {
  const query = useQuery(
    VehicleQueryCacheKeys.GetVehicleFuelTypes,
    async () => {
      const response = await Api.Vehicle.getVehicleFuelTypes();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <FormControlFormSelect label="Yakit Turu" placeholder="Yakit Turu" {...formSelectProps}>
      {query.data?.map((fuelType) => (
        <option value={fuelType.key} key={`vft${fuelType.value}`}>
          {fuelType.value}
        </option>
      ))}
    </FormControlFormSelect>
  );
};
