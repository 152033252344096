import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from 'components';
import React from 'react';
import { TotalBalanceSheetTransaction } from 'types';

export interface AdminExpertisePointBalanceHistoryTableProps {
  transactions: TotalBalanceSheetTransaction[];
}

export const AdminExpertisePointBalanceHistoryTable: React.FC<AdminExpertisePointBalanceHistoryTableProps> = ({
  transactions,
}) => {
  const columnHelper = createColumnHelper<TotalBalanceSheetTransaction>();

  const columns = [
    // columnHelper.accessor('id', {
    //   cell: (info) => `₺${info.getValue()}`,
    //   header: 'Miktar',
    //   meta: {
    //     isNumeric: true,
    //   },
    // }),
    columnHelper.accessor('transactionDate', {
      cell: (info) => info.getValue()?.toLocaleDateString() || '-',
      header: 'Tarih',
    }),
    columnHelper.accessor('amount', {
      cell: (info) => `₺${info.getValue()}`,
      header: 'Miktar',
      meta: {
        isNumeric: true,
      } as any,
    }),
    columnHelper.accessor('isCompleted', {
      cell: (info) => (info.getValue() ? 'Tamamlandı' : 'Tamamlanmadı'),
      header: 'Durum',
    }),
  ];

  return (
    <DataTable
      data={transactions}
      columns={columns}
      usePagination
      tableProps={{
        variant: 'striped',
        colorScheme: 'teal',
      }}
      title="Bakiye Geçmişi"
    />
  );
};
