import {
  Center,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
  Button,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  IconButton,
  ModalOverlay,
  useBoolean,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Checkbox,
  HStack,
} from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable, LoadingIndicator } from 'components';
import { useMutation, useQuery } from 'react-query';
import { ExpertisePackageAbilityDefinitionType, ExpertisePackageWithAbilities, ExpertiseQueryCacheKey } from 'types';
import React, { useMemo, useState } from 'react';
import { AddIcon, CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { AdminPackageAbilitiesForm, AdminPageTitle } from '../components';

type ExpertisePackagesWithAbilitiesDatatableType = {
  id: number;
  name: string;
  price: number;
  paint: boolean;
  micron: boolean;
  sasi: boolean;
  body: boolean;
  mechanic: boolean;
  km: boolean;
  damage: boolean;
  reportEkspertise: boolean;
  reportBreakdown: boolean;
  reportKm: boolean;
  reportConta: boolean;
  multiplePhoto: boolean;
  roadAssistance: boolean;
  secondHandGuarantee: boolean;
  isActive: boolean;
};
export const AdminPackageManagementPage = () => {
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
  const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure();
  const {
    isOpen: isDeleteConfirmDialogOpen,
    onOpen: onDeleteConfirmDialogOpen,
    onClose: onDeleteConfirmDialogClose,
  } = useDisclosure();
  const deleteDialogCancelRef = React.useRef(null);

  const [focusedPackage, setFocusedPackage] = useState<ExpertisePackageWithAbilities>();
  const query = useQuery(
    ExpertiseQueryCacheKey.GetExpertisePackagesWithAbilities,
    async () => {
      const response = await Api.Expertise.getExpertisePackagesWithAbilities();
      const packages = response.data;
      const datatable = packages.map((expertisePackage) => {
        const abilittySet = new Set(expertisePackage.abilities.map((ability) => ability.abilityDefinitionTypeId));
        return {
          id: expertisePackage.expertisePackage.id,
          name: expertisePackage.expertisePackage.name,
          price: parseInt(expertisePackage.expertisePackage.price, 10),
          paint: abilittySet.has(ExpertisePackageAbilityDefinitionType.BoyaDegisenFormu),
          micron: abilittySet.has(ExpertisePackageAbilityDefinitionType.MikronKaydiFormu),
          sasi: abilittySet.has(ExpertisePackageAbilityDefinitionType.SasiKontrol),
          body: abilittySet.has(ExpertisePackageAbilityDefinitionType.KaportaKontrolNotlari),
          mechanic: abilittySet.has(ExpertisePackageAbilityDefinitionType.MotorMekanikKontrolNotlari),
          km: abilittySet.has(ExpertisePackageAbilityDefinitionType.KMKaydiSorgulama),
          damage: abilittySet.has(ExpertisePackageAbilityDefinitionType.HasarKaydiSorgulama),
          reportEkspertise: abilittySet.has(ExpertisePackageAbilityDefinitionType.EkspertizCihaziTestSonuclari),
          reportBreakdown: abilittySet.has(ExpertisePackageAbilityDefinitionType.ArizaTespitSonuclari),
          reportKm: abilittySet.has(ExpertisePackageAbilityDefinitionType.KMTespitSonuclari),
          reportConta: abilittySet.has(ExpertisePackageAbilityDefinitionType.ContaKacakTespitSonuclari),
          multiplePhoto: abilittySet.has(ExpertisePackageAbilityDefinitionType.AracFotografiCoklu),
          roadAssistance: abilittySet.has(ExpertisePackageAbilityDefinitionType.YolYardimSertifikasi),
          secondHandGuarantee: abilittySet.has(ExpertisePackageAbilityDefinitionType.AracKorumaSertifikasi),
          isActive: expertisePackage.expertisePackage.active,
        };
      }) as ExpertisePackagesWithAbilitiesDatatableType[];
      return {
        datatable,
        packagesWithAbilities: response.data,
      };
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  const [showDeactivated, { toggle: toggleShowDeativated }] = useBoolean(true);
  const filteredData = useMemo(() => {
    if (showDeactivated) {
      return query.data;
    }

    return {
      packagesWithAbilities: query.data?.packagesWithAbilities,
      datatable: query.data?.datatable ? query.data?.datatable.filter((elem) => elem.isActive) : [],
    };
  }, [query.data, showDeactivated]);

  const deleteMutation = useMutation<void, void, number>(
    async (packageId: number) => {
      await Api.Expertise.deletePackage(packageId);
    },
    {
      onSuccess: () => {
        query.refetch();
      },
    },
  );

  const columnHelper = createColumnHelper<ExpertisePackagesWithAbilitiesDatatableType>();

  const columnHeaderBaseMeta = {
    style: {
      textAlign: 'center',
    } as React.CSSProperties,
  } as any;
  const columnDef: ColumnDef<ExpertisePackagesWithAbilitiesDatatableType>[] = [
    {
      header: ' ',
      columns: [
        columnHelper.accessor('name', {
          header: 'Paket',
        }),
      ],
    },
    {
      header: 'Formlar',
      meta: columnHeaderBaseMeta,
      columns: [
        columnHelper.accessor('paint', {
          header: 'Boya',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('micron', {
          header: 'Mikron',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('sasi', {
          header: 'Şasi',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
      ],
    },
    {
      header: 'Notlar',
      meta: columnHeaderBaseMeta,
      columns: [
        columnHelper.accessor('body', {
          header: 'Kaporta',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('mechanic', {
          header: 'Mekanik',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
      ],
    },
    {
      header: 'Sorgu',
      meta: columnHeaderBaseMeta,
      columns: [
        columnHelper.accessor('km', {
          header: 'KM',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('damage', {
          header: 'Hasar',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
      ],
    },
    {
      header: 'Sonuçlar',
      meta: columnHeaderBaseMeta,
      columns: [
        columnHelper.accessor('reportEkspertise', {
          header: 'Ekspertiz',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('reportBreakdown', {
          header: 'Arıza',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('reportKm', {
          header: 'KM',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('reportConta', {
          header: 'Conta',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
      ],
    },
    {
      header: 'Sertifikalar',
      meta: columnHeaderBaseMeta,
      columns: [
        columnHelper.accessor('roadAssistance', {
          header: 'Yol Yardım',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
        columnHelper.accessor('secondHandGuarantee', {
          header: 'İkinci El Garanti',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
      ],
    },
    {
      header: 'Fotoğraf',
      meta: columnHeaderBaseMeta,
      columns: [
        columnHelper.accessor('multiplePhoto', {
          header: 'Çoklu',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
      ],
    },
    {
      header: ' ',
      columns: [
        columnHelper.accessor('price', {
          header: 'Fiyat',
          meta: { ...columnHeaderBaseMeta, isNumeric: true },
          cell: (info) => {
            return `₺${info.getValue()}`;
          },
        }),
        columnHelper.accessor('isActive', {
          header: 'Aktif Mi?',
          meta: columnHeaderBaseMeta,
          cell: (info) => {
            return <Center>{info.getValue() ? <CheckIcon color="green" /> : <CloseIcon color="red" />}</Center>;
          },
        }),
      ],
    },
    {
      header: 'Aksiyonlar',
      cell: (cellProps) => {
        const { row } = cellProps;
        return (
          <ButtonGroup gap={2}>
            <IconButton
              icon={<EditIcon />}
              aria-label="Duzenle"
              colorScheme="blue"
              onClick={() => {
                if (query.data) {
                  const elem = query.data.packagesWithAbilities.find(
                    (pkg) => pkg.expertisePackage.id === row.original.id,
                  );
                  if (elem) {
                    setFocusedPackage(elem);
                    onEditModalOpen();
                  }
                }
              }}
            />
            <IconButton
              icon={<DeleteIcon />}
              aria-label="Sil"
              colorScheme="red"
              onClick={() => {
                if (query.data) {
                  const elem = query.data.packagesWithAbilities.find(
                    (pkg) => pkg.expertisePackage.id === row.original.id,
                  );
                  if (elem) {
                    setFocusedPackage(elem);
                    onDeleteConfirmDialogOpen();
                  }
                }
              }}
            />
          </ButtonGroup>
        );
      },
    },
  ];

  const abilitiesQuery = useQuery(
    ExpertiseQueryCacheKey.GetAllAbilities,
    async () => {
      const response = await Api.Expertise.getAllAbilities();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const [isLoading, { on: startLoading, off: stopLoading }] = useBoolean();

  return (
    <>
      {abilitiesQuery.data && (
        <>
          <Modal isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
            <ModalOverlay />

            <ModalContent>
              <ModalHeader>Paket Oluştur</ModalHeader>

              <ModalBody>
                <AdminPackageAbilitiesForm
                  abilities={abilitiesQuery.data}
                  formId="createPackageForm"
                  onSubmitComplete={(isSuccess: boolean) => {
                    if (isSuccess) {
                      onCreateModalClose();
                      query.refetch();
                    }
                    stopLoading();
                  }}
                  onSubmitStart={startLoading}
                />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button onClick={onCreateModalClose} disabled={isLoading}>
                    İptal
                  </Button>
                  <Button colorScheme="green" type="submit" form="createPackageForm" isLoading={isLoading}>
                    Kaydet
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
            <ModalOverlay />

            <ModalContent>
              <ModalHeader>Paket Düzenle</ModalHeader>

              <ModalBody>
                <AdminPackageAbilitiesForm
                  packageWithAbilities={focusedPackage}
                  abilities={abilitiesQuery.data}
                  formId="editPackageForm"
                  onSubmitComplete={(isSuccess: boolean) => {
                    if (isSuccess) {
                      onEditModalClose();
                      query.refetch();
                    }
                    stopLoading();
                  }}
                  onSubmitStart={startLoading}
                />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button onClick={onEditModalClose} disabled={isLoading}>
                    İptal
                  </Button>
                  <Button colorScheme="green" type="submit" form="editPackageForm" isLoading={isLoading}>
                    Kaydet
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <AlertDialog
            isOpen={isDeleteConfirmDialogOpen}
            onClose={onDeleteConfirmDialogClose}
            leastDestructiveRef={deleteDialogCancelRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Onay
                </AlertDialogHeader>

                <AlertDialogBody>Silmek istediğinize emin misiniz?</AlertDialogBody>

                <AlertDialogFooter>
                  <Button
                    onClick={onDeleteConfirmDialogClose}
                    ref={deleteDialogCancelRef}
                    disabled={deleteMutation.isLoading}
                  >
                    İptal
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      if (focusedPackage) {
                        deleteMutation.mutate(focusedPackage.expertisePackage.id);
                      }
                      onDeleteConfirmDialogClose();
                    }}
                    ml={3}
                    isLoading={deleteMutation.isLoading}
                  >
                    Sil
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}

      <AdminPageTitle title="Paket Yönetimi" />
      {query.isLoading ? (
        <LoadingIndicator />
      ) : (
        <DataTable
          data={filteredData?.datatable || []}
          columns={columnDef}
          usePagination
          actions={
            <HStack>
              <Checkbox
                title=""
                checked={showDeactivated}
                onChange={() => {
                  toggleShowDeativated();
                }}
              >
                Aktif Olmayanları Gizle
              </Checkbox>
              <Button colorScheme="green" onClick={onCreateModalOpen} leftIcon={<AddIcon />}>
                Ekle
              </Button>
            </HStack>
          }
        />
      )}
    </>
  );
};
