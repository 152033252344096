import { Button, ButtonProps, Text, useToken, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';

export interface ExpertiseBodyworkExpertiseMicronSelectorProps
  extends Omit<Omit<Omit<ButtonProps, 'bg'>, 'onClick'>, 'children'> {
  partNo: string;
  value?: number;
  isFocused?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>, partNo: string) => void;
}
export const ExpertiseBodyworkExpertiseMicronSelector: React.FC<ExpertiseBodyworkExpertiseMicronSelectorProps> = ({
  partNo,
  value,
  onClick,
  isFocused,
  ...buttonProps
}) => {
  const [inactive, selected, done] = useToken(
    // the key within the theme, in this case `theme.colors`
    'colors',
    // the subkey(s), resolving to `theme.colors.red.100`
    ['gray.900', 'blue.600', 'green.900'],
    // a single fallback or fallback array matching the length of the previous arg
  );

  const bg = useMemo(() => {
    if (isFocused) {
      return selected;
    }
    if (value != null) {
      return done;
    }

    return inactive;
  }, [value, isFocused]);

  return (
    <Button
      bg={bg}
      _active={{
        bg: selected,
      }}
      _hover={{
        bg: selected,
      }}
      _focus={{
        bg,
      }}
      width={9}
      height={9}
      borderRadius="50%"
      onClick={(event: React.MouseEvent<HTMLElement>) => onClick(event, partNo)}
      {...buttonProps}
    >
      <VStack spacing={0} width="100%" height="100%" justify="center">
        {value ? (
          <>
            <Text fontSize="sm" color="white">
              {value}
            </Text>
            <Text fontSize="10px" color="white">
              {partNo}
            </Text>
          </>
        ) : (
          <Text fontSize="sm" color="white">
            {partNo}
          </Text>
        )}
      </VStack>
    </Button>
  );
};

ExpertiseBodyworkExpertiseMicronSelector.defaultProps = {
  isFocused: false,

  // eslint-disable-next-line react/default-props-match-prop-types
  position: 'absolute',
};
