import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalFooter,
  HStack,
  Button,
  Icon,
  useDisclosure,
  Image,
  Box,
  IconButton,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { MdCancel, MdCameraAlt } from 'react-icons/md';

export interface VehicleImageProps {
  isActionsDisabled?: boolean;
  photoUrl: string;
  onDeleteImage: () => void;
  onImageChange: (image: File) => void;
}
export const VehicleImage: React.FC<VehicleImageProps> = ({
  isActionsDisabled,
  photoUrl,
  onDeleteImage,
  onImageChange,
}) => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      onImageChange(e.target.files[0]);
    }
  };

  return (
    <>
      <input hidden type="file" accept="image/*" ref={fileInputRef} onChange={onFileChange} />
      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fotoğraflı Not</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Image src={photoUrl} boxSize="100%" maxWidth="500px" />
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            {!isActionsDisabled && (
              <HStack>
                <Button leftIcon={<Icon as={MdCancel} />} onClick={onDeleteImage} colorScheme="red">
                  Sil
                </Button>
                <Button
                  leftIcon={<Icon as={MdCameraAlt} />}
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  Tekrar Yükle
                </Button>
              </HStack>
            )}

            <Button onClick={onClose}>Kapat</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box position="relative">
        <Image width="128px" height="128px" src={photoUrl} onClick={onOpen} />
        {!isActionsDisabled && (
          <IconButton
            icon={<Icon as={MdCancel} />}
            colorScheme="red"
            aria-label="Sil"
            position="absolute"
            top="0"
            right="0"
            onClick={onDeleteImage}
          />
        )}
      </Box>
    </>
  );
};
