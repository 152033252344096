/* eslint-disable @typescript-eslint/no-unused-vars */
import { AddIcon, ArrowDownIcon, ArrowUpIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
  SimpleGrid,
  Wrap,
  WrapItem,
  Divider,
  Heading,
} from '@chakra-ui/react';
import { createColumnHelper, CellContext, ColumnDef } from '@tanstack/react-table';
import { Api } from 'api';
import { DataTable, ExpertisePointSelect } from 'components';
import { FormControlFormInput, FormControlFormSelect, GenericMessages, useFetchExpertisePackages } from 'core';
import { FormikErrors, useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  ExpertisePackage,
  ExpertisePackageGroup,
  ExpertisePackagePriceListItem,
  ExpertisePoint,
  ExpertisePointQueryCacheKey,
  ExternalPackageGroupSourceType,
  SaveExpertisePackageRequest,
} from 'types';
import * as yup from 'yup';

const validationSchema = yup.object({
  priceListName: yup.string().required(GenericMessages.CannotBeEmpty),
  priceCampaingOverrides: yup
    .array(
      yup.object({
        price: yup
          .number()
          .required(GenericMessages.CannotBeEmpty)
          .min(1, () => GenericMessages.CantBeLessThanNumber(1)),
        quantity: yup.number().min(1, () => GenericMessages.CantBeLessThanNumber(1)),
      }),
    )
    .min(1, 'En az 1 tane paket eklemeniz gerekli'),
  selectedExpertisePoints: yup
    .array()
    .min(1, 'En az bir bayi eklemek zorundasınız')
    .required('En az bir bayi eklemek zorundasınız'),
  discountAvailable: yup.boolean(),
  maxDiscountRate: yup.string().when('discountAvailable', {
    is: true,
    then: yup.string().required(GenericMessages.CannotBeEmpty),
  }),
});

export interface AdminPriceListFormProps {
  formId: string;
  onSubmitStart: () => void;
  onSubmitComplete: () => void;
  group?: ExpertisePackageGroup;
}

export const AdminPriceListForm: React.FC<AdminPriceListFormProps> = ({
  formId,
  onSubmitComplete,
  onSubmitStart,
  group,
}) => {
  const toast = useToast();
  const [isChecked, setIsChecked] = useState(false);

  const { data: allExpertisePoints } = useQuery(
    ExpertisePointQueryCacheKey.GetAllExpertisePoints,
    async () => {
      const response = await Api.ExpertisePoint.getAllExpertisePoints();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const deleteMutate = useMutation<any, any, number>(
    (id: number) => {
      return Api.Expertise.deletePackageGroupItem(id);
    },
    {
      onSuccess: () => {
        toast({
          title: 'Kampanya paketi başarı ile silindi',
          isClosable: false,
          status: 'success',
        });
      },
      onError: () => {
        toast({
          title: 'Kampanya paketi silinirken hata oluştu',
          isClosable: false,
          status: 'error',
        });
      },
    },
  );
  const mutate = useMutation<any, any, SaveExpertisePackageRequest>(
    (request: SaveExpertisePackageRequest) => {
      return Api.Expertise.savePackageGroup(request);
    },
    {
      onSuccess: () => {
        toast({
          title: 'Kampanya başarı ile eklendi',
          isClosable: false,
          status: 'success',
        });
      },
      onSettled: () => {
        onSubmitComplete();
      },
      onError: () => {
        toast({
          title: 'Kampanya eklenirken hata oluştu',
          isClosable: false,
          status: 'error',
        });
      },
    },
  );

  const { values, errors, handleChange, setFieldError, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      selectedCampaing: 0,
      selectedExpertisePoint: null,
      selectedExpertisePoints: (group?.expertisePoints || []) as ExpertisePoint[],
      priceListName: group?.name || '',
      isOccoCar: group?.externalPackageGroupSource === ExternalPackageGroupSourceType.OccoCar,
      isOccoCoupon: group?.externalPackageGroupSource === ExternalPackageGroupSourceType.OccoCoupon,
      discountAvailable: group?.discountAvailable || false,
      maxDiscountRate: group?.maxDiscountRate || '',
      priceCampaingOverrides: (group?.items || []).map((item) => ({
        active: item.active,
        price: item.price,
        displayOrder: 0,
        durationInMinute: 0,
        name: item.expertisePackageName,
        id: item.expertisePackageId,
        itemId: item.id,
        quantity: item.quantity || 1,
      })) as ExpertisePackagePriceListItem[],
    },
    onSubmit: (formData) => {
      onSubmitStart();

      let externalPackageGroupSourceResult: ExternalPackageGroupSourceType | undefined;
      if (formData.isOccoCar) {
        externalPackageGroupSourceResult = ExternalPackageGroupSourceType.OccoCar;
      } else if (formData.isOccoCoupon) {
        externalPackageGroupSourceResult = ExternalPackageGroupSourceType.OccoCoupon;
      } else {
        externalPackageGroupSourceResult = undefined;
      }

      mutate.mutate({
        name: formData.priceListName,
        id: group?.id,
        packages: formData.priceCampaingOverrides.map((priceCampaingOverride) => ({
          expertisePackageId: priceCampaingOverride.id,
          price: parseInt(priceCampaingOverride.price, 10),
          quantity: formData.isOccoCar || formData.isOccoCoupon ? priceCampaingOverride.quantity : 1,
        })),
        expertisePoints: formData.selectedExpertisePoints.map((expertisePoint) => expertisePoint.id),
        externalPackageGroupSource: externalPackageGroupSourceResult,
        discountAvailable: formData.discountAvailable,
        maxDiscountRate: formData.discountAvailable ? parseInt(formData.maxDiscountRate, 10) : undefined,
      });
    },
    validationSchema,
    validateOnChange: false,
  });

  const onExpertisePointAddClicked = () => {
    const expertisePoint = values.selectedExpertisePoint as ExpertisePoint | null;
    if (expertisePoint) {
      if (values.selectedExpertisePoints.findIndex((elem) => elem.id === expertisePoint.id) === -1) {
        setFieldError('selectedExpertisePoint', undefined);
        setFieldValue('selectedExpertisePoints', [...values.selectedExpertisePoints, values.selectedExpertisePoint]);
      } else {
        setFieldError('selectedExpertisePoint', 'Ayni Bayi Eklenemez');
      }
    } else {
      setFieldError('selectedExpertisePoint', 'Bayi Seçmeniz Gerekiyor');
    }
  };

  const onDeleteExpertisePoint = (index: number) => () => {
    const newExpertisePointValues = [...values.selectedExpertisePoints];
    newExpertisePointValues.splice(index, 1);
    setFieldValue('selectedExpertisePoints', newExpertisePointValues);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked && allExpertisePoints) {
      const mergeAllExpertisePointsArray = [
        ...values.selectedExpertisePoints,
        ...allExpertisePoints.filter(
          (expertisePoint) =>
            !values.selectedExpertisePoints.some(
              (selectedExpertisePoint) => selectedExpertisePoint.id === expertisePoint.id,
            ),
        ),
      ];
      setFieldValue('selectedExpertisePoints', mergeAllExpertisePointsArray);
    } else {
      setFieldValue('selectedExpertisePoints', group?.expertisePoints || []);
    }
  };

  const columnHelper = createColumnHelper<ExpertisePackage>();
  const expertisePackagesQuery = useFetchExpertisePackages();

  // Su an icin daah fazla editlenebilir hucereye ihtiyac yok
  // fakat ileride olursa daha generic bir yapi olusturulmali
  // ornegin: https://github.com/TanStack/table/blob/5145a632c944d135863d8a2f14a160a2f9395f61/examples/editable-data/src/App.js
  const priceInputCell = (info: CellContext<ExpertisePackage, string>) => {
    const { id, price } = info.row.original;
    const { index } = info.row;
    const [rawValue, setRawValue] = useState(price?.toString() || '');
    const [isEditing, setIsEditing] = useState(false);

    // Format number as Turkish currency with TL suffix
    const formatAsTurkishCurrency = (value: number): string => {
      const formattedNumber = new Intl.NumberFormat('tr-TR', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
      return `${formattedNumber} TL`;
    };

    // Parse string to number, handling Turkish number format
    const parseNumber = (value: string): number => {
      const normalizedValue = value.replace(/[^\d,]/g, '').replace(',', '.');
      return parseFloat(normalizedValue);
    };

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value.replace(' TL', '');
      setRawValue(inputValue);
    };

    const getDisplayValue = () => {
      if (isEditing) {
        return rawValue;
      }
      if (rawValue === '' || Number.isNaN(parseFloat(rawValue))) {
        return '';
      }
      return formatAsTurkishCurrency(parseFloat(rawValue));
    };

    return (
      <FormControlFormInput
        type="text"
        style={{ minWidth: '120px', textAlign: 'left' }}
        error={
          errors.priceCampaingOverrides != null &&
          (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).price != null
        }
        helperText={
          errors.priceCampaingOverrides != null
            ? (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).price
            : undefined
        }
        key={`aplfpui${id}`}
        value={getDisplayValue()}
        onChange={handlePriceChange}
        onFocus={() => setIsEditing(true)}
        onBlur={() => {
          setIsEditing(false);
          if (rawValue === '') {
            setFieldValue(`priceCampaingOverrides.${index}.price`, '');
            return;
          }

          // Remove all non-numeric characters except comma
          const cleanValue = rawValue.replace(/[^\d,]/g, '');

          // Only process if it's a valid number format
          if (/^\d*,?\d{0,2}$/.test(cleanValue)) {
            const numericValue = parseNumber(cleanValue);
            if (!Number.isNaN(numericValue)) {
              setFieldValue(`priceCampaingOverrides.${index}.price`, numericValue);
              setRawValue(numericValue.toString());
            }
          } else {
            // If invalid, reset to empty
            setRawValue('');
            setFieldValue(`priceCampaingOverrides.${index}.price`, '');
          }
        }}
      />
    );
  };

  type ExpertisePackageQuantityCellProps = CellContext<ExpertisePackage, number>;

  const quantityInputCell: React.FC<ExpertisePackageQuantityCellProps> = (info) => {
    const { id, quantity } = info.row.original;
    const { index } = info.row;
    const [quantityCellData, setQuantityCellData] = useState<number>(quantity || 1);

    const handleBlur = () => {
      const value = quantityCellData;
      if (!Number.isNaN(value)) {
        setFieldValue(`priceCampaingOverrides.${index}.quantity`, value);
      }
    };

    return (
      <FormControlFormInput
        type="number"
        min="1"
        step="1"
        error={
          errors.priceCampaingOverrides != null &&
          (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).quantity != null
        }
        helperText={
          errors.priceCampaingOverrides != null
            ? (errors.priceCampaingOverrides[index] as FormikErrors<ExpertisePackage>).quantity
            : undefined
        }
        key={`aplfpui${id}`}
        value={quantityCellData}
        onChange={(event) => setQuantityCellData(Number(event.target.value))}
        onBlur={handleBlur}
        isDisabled={!values.isOccoCar && !values.isOccoCoupon}
      />
    );
  };

  const { isOpen: isDeleteDialogOpen, onClose: onDeleteDialogClose, onOpen: onDeleteDialogOpen } = useDisclosure();
  const [focusedDeletePackageGroupItem, setFocusedDeletePackageGroupItem] = useState<ExpertisePackagePriceListItem>();
  const rowActionCell = (info: CellContext<ExpertisePackagePriceListItem, number>) => {
    const moveItem = (from: number, to: number) => {
      const newList = [...values.priceCampaingOverrides];
      // remove `from` item and store it
      const tmp = newList.splice(from, 1)[0];
      // insert stored item into position `to`
      newList.splice(to, 0, tmp);
      setFieldValue('priceCampaingOverrides', newList);
    };
    return (
      <Flex gap={{ base: 1, sm: 2 }} justify="center">
        <Tooltip label="Sil">
          <IconButton
            aria-label="Sil"
            icon={<DeleteIcon />}
            onClick={() => {
              const deletedPriceInfo = values.priceCampaingOverrides[info.row.index];
              if (deletedPriceInfo.itemId) {
                setFocusedDeletePackageGroupItem(deletedPriceInfo);
                onDeleteDialogOpen();
              } else {
                const newData = [...values.priceCampaingOverrides];
                newData.splice(info.row.index, 1);
                setFieldValue('priceCampaingOverrides', newData);
              }
            }}
            colorScheme="red"
          />
        </Tooltip>
        <IconButton
          icon={<ArrowDownIcon />}
          aria-label="Assagi"
          onClick={() => {
            moveItem(info.row.index, info.row.index + 1);
          }}
          disabled={info.row.index === values.priceCampaingOverrides.length - 1}
        />
        <IconButton
          icon={<ArrowUpIcon />}
          aria-label="Yukari"
          disabled={info.row.index === 0}
          onClick={() => {
            moveItem(info.row.index, info.row.index - 1);
          }}
        />
      </Flex>
    );
  };

  const columns: ColumnDef<ExpertisePackagePriceListItem, any>[] = [
    columnHelper.accessor('name', {
      header: 'Ad',
    }),
    columnHelper.accessor('price', {
      header: 'Fiyat',
      cell: priceInputCell,
    }),
    columnHelper.accessor('quantity', {
      header: 'Adet',
      cell: quantityInputCell,
    }),
    {
      header: 'Aksiyonlar',
      cell: rowActionCell,
    },
  ];

  const cancelRef = useRef(null);

  return (
    <>
      <AlertDialog isOpen={isDeleteDialogOpen} leastDestructiveRef={cancelRef} onClose={onDeleteDialogClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Sil
            </AlertDialogHeader>

            <AlertDialogBody>Silmek istediğinize emin misiniz?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteDialogClose} disabled={deleteMutate.isLoading}>
                İptal
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (focusedDeletePackageGroupItem && focusedDeletePackageGroupItem.itemId) {
                    try {
                      await deleteMutate.mutateAsync(focusedDeletePackageGroupItem.itemId);
                      const index = values.priceCampaingOverrides.findIndex(
                        (elem) => elem.itemId === focusedDeletePackageGroupItem.itemId,
                      );
                      if (index !== -1) {
                        const newData = [...values.priceCampaingOverrides];
                        newData.splice(index, 1);
                        setFieldValue('priceCampaingOverrides', newData);
                        onDeleteDialogClose();
                      }
                    } catch {
                      // Do nothing
                    }
                  }
                }}
                ml={3}
                disabled={deleteMutate.isLoading}
                isLoading={deleteMutate.isLoading}
              >
                Sil
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <form id={formId} noValidate onSubmit={handleSubmit} />
      <Grid
        templateColumns={{ base: 'repeat(4, 1fr)' }}
        gap={{ base: 2, md: 4 }}
        w="full"
        maxW={{ base: '100%', md: 'container.xl' }}
      >
        {/* Form Alanı */}
        <GridItem colSpan={4}>
          <Grid templateColumns="repeat(4, 1fr)" gap={2}>
            {/* Ad Input */}
            <GridItem colSpan={4}>
              <FormControlFormInput
                label="Ad"
                name="priceListName"
                value={values.priceListName}
                error={errors.priceListName != null}
                helperText={errors.priceListName}
                onChange={handleChange}
                width="full"
                size="sm"
              />
            </GridItem>

            {/* Paket Seçimi */}
            <GridItem colSpan={4}>
              <FormControlFormSelect
                name="selectedCampaing"
                label="Paket"
                value={values.selectedCampaing}
                error={errors.selectedCampaing != null}
                helperText={errors.selectedCampaing}
                onChange={handleChange}
                placeholder="Paket Seç"
                width="full"
                size="sm"
                mb={2}
              >
                {expertisePackagesQuery?.data?.map((campaing) => (
                  <option value={campaing.id} key={`aplcs${campaing.id}`}>
                    {campaing.name}
                  </option>
                ))}
              </FormControlFormSelect>
            </GridItem>

            {/* Paket Ekle Butonu */}
            <GridItem colSpan={4}>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="green"
                width="full"
                size="sm"
                onClick={() => {
                  if (expertisePackagesQuery.data && values.selectedCampaing) {
                    // eslint-disable-next-line
                    const campaing = expertisePackagesQuery.data.find((elem) => elem.id == values.selectedCampaing);

                    const existingCampaing = values.priceCampaingOverrides.find(
                      // eslint-disable-next-line
                      (elem) => elem.id == values.selectedCampaing,
                    );

                    if (existingCampaing) {
                      setFieldError('selectedCampaing', 'Aynı paket bir daha eklenemez');
                      return;
                    }
                    if (campaing) {
                      const overrides = [...values.priceCampaingOverrides];
                      overrides.push(campaing);
                      setFieldValue('priceCampaingOverrides', overrides);
                    }
                  } else {
                    setFieldError('selectedCampaing', 'Paket Seçmeniz Gerekli');
                  }
                }}
                whiteSpace="normal"
                height="auto"
                py={2}
              >
                Paket Ekle
              </Button>
            </GridItem>

            {/* İndirim Seçenekleri */}
            <GridItem colSpan={4}>
              <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                <GridItem colSpan={4}>
                  <Checkbox
                    borderColor="gray.300"
                    size="sm"
                    colorScheme="green"
                    name="discountAvailable"
                    onChange={(event) => {
                      setFieldValue(event.target.name, event.target.checked);
                    }}
                    isChecked={values.discountAvailable}
                    isDisabled={values.isOccoCar || values.isOccoCoupon}
                  >
                    İndirim uygulanabilsin mi?
                  </Checkbox>
                </GridItem>

                {values.discountAvailable && (!values.isOccoCar || !values.isOccoCoupon) && (
                  <GridItem colSpan={4}>
                    <FormControlFormInput
                      name="maxDiscountRate"
                      value={values.maxDiscountRate}
                      error={errors.maxDiscountRate != null}
                      helperText={errors.maxDiscountRate}
                      onChange={handleChange}
                      type="number"
                      size="sm"
                      placeholder="Maksimum İndirim Oranı(%)"
                      width="full"
                    />
                  </GridItem>
                )}

                {/* Koçan ve Occo Car Seçenekleri */}
                <GridItem colSpan={2}>
                  <Checkbox
                    borderColor="gray.300"
                    size="sm"
                    colorScheme="green"
                    name="isOccoCoupon"
                    onChange={(event) => {
                      setFieldValue(event.target.name, event.target.checked);
                      if (!event.target.checked) {
                        setFieldValue('maxDiscountRate', '');
                        setFieldValue('discountAvailable', false);
                        setFieldValue('isOccoCar', false);
                      }
                    }}
                    isChecked={values.isOccoCoupon}
                    isDisabled={values.isOccoCar}
                  >
                    Koçan
                  </Checkbox>
                </GridItem>
                <GridItem colSpan={2}>
                  <Checkbox
                    borderColor="gray.300"
                    size="sm"
                    colorScheme="green"
                    name="isOccoCar"
                    onChange={(event) => {
                      setFieldValue(event.target.name, event.target.checked);
                      if (!event.target.checked) {
                        setFieldValue('maxDiscountRate', '');
                        setFieldValue('discountAvailable', false);
                        setFieldValue('isOccoCoupon', false);
                      }
                    }}
                    isChecked={values.isOccoCar}
                    isDisabled={values.isOccoCoupon}
                  >
                    Occo Car
                  </Checkbox>
                </GridItem>
              </Grid>
            </GridItem>

            {/* Bayi Yönetimi Başlığı */}
            <GridItem colSpan={4}>
              <HStack spacing={{ base: 1, sm: 2, md: 4 }} align="center" width="full">
                <Divider borderWidth="1px" borderColor="gray.400" />
                <Heading size="sm" whiteSpace="nowrap" color="gray.600" fontSize={{ base: 'xs', md: 'sm' }}>
                  Bayi Yönetimi
                </Heading>
                <Divider borderWidth="1px" borderColor="gray.400" />
              </HStack>
            </GridItem>

            {/* Bayi Seçimi */}
            <GridItem colSpan={4}>
              <ExpertisePointSelect
                onExpertisePointSelected={(expertisePoint?: ExpertisePoint) => {
                  setFieldValue('selectedExpertisePoint', expertisePoint);
                }}
                error={errors.selectedExpertisePoint != null}
                helperText={errors.selectedExpertisePoint != null ? (errors.selectedExpertisePoint as string) : ''}
                filterActive
              />
            </GridItem>

            {/* Bayi Ekle ve Hepsi */}
            <GridItem colSpan={2}>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="green"
                size="sm"
                onClick={onExpertisePointAddClicked}
                width="full"
              >
                Bayi Ekle
              </Button>
            </GridItem>
            <GridItem colSpan={2}>
              <Checkbox
                borderColor="gray.300"
                colorScheme="green"
                name="selectAllPoints"
                isChecked={isChecked}
                onChange={handleCheckboxChange}
                width="full"
              >
                <Text>Hepsi</Text>
              </Checkbox>
            </GridItem>
          </Grid>
        </GridItem>

        {/* Erişebilen Bayiler */}
        <GridItem colSpan={4}>
          <FormLabel>Erişebilen Bayiler</FormLabel>
          {values.selectedExpertisePoints.length ? (
            <Box maxW="full" borderWidth="1px" borderRadius="lg" p={2}>
              <Wrap spacing={2}>
                {values.selectedExpertisePoints.map((point, index) => (
                  <WrapItem key={`ausdt${point.id}`}>
                    <ButtonGroup size="sm" isAttached>
                      <Button>{point.name}</Button>
                      <IconButton onClick={onDeleteExpertisePoint(index)} aria-label="Bayi Sil" icon={<CloseIcon />} />
                    </ButtonGroup>
                  </WrapItem>
                ))}
              </Wrap>
            </Box>
          ) : (
            <Text fontSize="xs">Bayi seçiniz.</Text>
          )}
        </GridItem>

        {/* Paket Listesi */}
        <GridItem colSpan={4}>
          <HStack spacing={{ base: 1, sm: 2, md: 4 }} align="center" width="full" marginY={{ base: 2, md: 4 }}>
            <Divider borderWidth="1px" borderColor="gray.400" />
            <Heading size="sm" whiteSpace="nowrap" color="gray.600" fontSize={{ base: 'xs', md: 'sm' }}>
              Paket Listesi
            </Heading>
            <Divider borderWidth="1px" borderColor="gray.400" />
          </HStack>

          <Box
            width="full"
            overflowX="auto"
            sx={{
              '&::-webkit-scrollbar': {
                height: { base: '3px', md: '6px' },
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '3px',
                backgroundColor: 'gray.300',
              },
            }}
          >
            <DataTable data={values.priceCampaingOverrides} columns={columns} usePagination={false} />
          </Box>
          {errors.priceCampaingOverrides && typeof errors.priceCampaingOverrides === 'string' && (
            <Alert status="error" mt="2">
              <AlertIcon />
              <AlertTitle>{errors.priceCampaingOverrides}</AlertTitle>
            </Alert>
          )}
          {errors.selectedExpertisePoints && typeof errors.selectedExpertisePoints === 'string' && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>{errors.selectedExpertisePoints}</AlertTitle>
            </Alert>
          )}
        </GridItem>
      </Grid>
    </>
  );
};
