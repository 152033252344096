import { useToast } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormSelect, FormControlFormSelectProps } from 'core';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { LocationQueryCacheKeys } from 'types';

export type CountySelectProps = FormControlFormSelectProps & { cityId?: number };

export const CountySelect: React.FC<CountySelectProps> = ({ cityId, ...formSelectProps }) => {
  const toast = useToast();

  const { refetch, data } = useQuery(
    [LocationQueryCacheKeys.GetCounties, cityId],
    async () => {
      if (cityId) {
        const response = await Api.Location.getCounties(cityId);
        return response.data;
      }
      return [];
    },
    {
      initialData: [],
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        toast({
          title: 'İlçeler çekilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
  useEffect(() => {
    if (cityId) {
      refetch();
    }
  }, [cityId]);
  return (
    <FormControlFormSelect
      label="İlçe Seç"
      placeholder="İlçe Seç"
      {...formSelectProps}
      isDisabled={!cityId || !data || data.length === 0 || formSelectProps.isDisabled}
    >
      {data &&
        data.map((county) => (
          <option value={county.id} key={`couns${county.id}`}>
            {county.name}
          </option>
        ))}
    </FormControlFormSelect>
  );
};
