import { AxiosError } from 'axios';
import {
  AuthUserInfo,
  Customer,
  CustomerAbilityDefinition,
  CustomerAbilityDefinitionTable,
  CustomerResponse,
  ExpertiseCarBodyTypes,
  ExpertisePackageAbilityDefinitionType,
} from 'types';
import { localStorageUserAuthInfoKey } from './consts';

export const updateLocalStorageAuthInfo = (authUserInfo: AuthUserInfo) => {
  localStorage.setItem(localStorageUserAuthInfoKey, JSON.stringify(authUserInfo));
};

export const getExpertiseBackroundImageUrl = (expertiseCarBodyTypes: ExpertiseCarBodyTypes) => {
  const baseUrl = '/images/arac_modeller';
  let image = 'sedan.svg';
  switch (expertiseCarBodyTypes) {
    case ExpertiseCarBodyTypes.CanliPanelvan:
      image = 'Camli_Van.svg';
      break;
    case ExpertiseCarBodyTypes.Hatchback2:
      image = 'Hatchback_2_Kapi.svg';
      break;
    case ExpertiseCarBodyTypes.Hatchback5:
      image = 'Hatchback_4_Kapi.svg';
      break;
    case ExpertiseCarBodyTypes.Minibus:
      image = 'minibus.svg';
      break;
    case ExpertiseCarBodyTypes.TekSurguPanelvan:
      image = 'Panelvan_Tek_Surgu.svg';
      break;
    case ExpertiseCarBodyTypes.Panelvan:
      image = 'Panelvan.svg';
      break;
    case ExpertiseCarBodyTypes.Pickup:
      image = 'Pickup_4_Kapi.svg';
      break;
    case ExpertiseCarBodyTypes.Pickup2:
      image = 'Pickup_2_Kapi.svg';
      break;
    case ExpertiseCarBodyTypes.SUV:
      image = 'suv.svg';
      break;
    case ExpertiseCarBodyTypes.Station:
      image = 'Station_Wagon.svg';
      break;
    case ExpertiseCarBodyTypes.Sedan:
    default:
      image = 'sedan.svg';
      break;
  }

  return `${baseUrl}/${image}`;
};

export const vehicleBodyTypeIdToString = (vehicleTypeId: number): ExpertiseCarBodyTypes => {
  switch (vehicleTypeId) {
    case 2:
      return ExpertiseCarBodyTypes.Hatchback5;
    case 3:
      return ExpertiseCarBodyTypes.Hatchback2;
    case 4:
      return ExpertiseCarBodyTypes.Pickup;
    case 5:
      return ExpertiseCarBodyTypes.Pickup2;
    case 6:
      return ExpertiseCarBodyTypes.Minibus;
    case 7:
      return ExpertiseCarBodyTypes.Panelvan;
    case 8:
      return ExpertiseCarBodyTypes.CanliPanelvan;
    case 9:
      return ExpertiseCarBodyTypes.TekSurguPanelvan;
    case 1:
    default:
      return ExpertiseCarBodyTypes.Sedan;
  }
};

export const createEmptyCustomerAbilityTable = () => {
  return Object.values(ExpertisePackageAbilityDefinitionType)
    .filter((v) => !Number.isNaN(Number(v)))
    .reduce((accum: any, next: string | ExpertisePackageAbilityDefinitionType) => {
      // eslint-disable-next-line no-param-reassign
      accum[next] = false;
      return accum;
    }, {});
};
export const createCustomerAbilityTable = (abilities: CustomerAbilityDefinition[]): CustomerAbilityDefinitionTable => {
  const initialValues = createEmptyCustomerAbilityTable();
  return abilities.reduce((accum: any, next: CustomerAbilityDefinition) => {
    // eslint-disable-next-line no-param-reassign
    accum[next.abilityDefinitionType] = next.hasAbility;
    return accum;
  }, initialValues);
};

export const yupNameFieldCheck = (name: string | undefined): boolean => {
  if (!name) {
    return false;
  }
  const splitSentence = name.split(' ');
  if (splitSentence.some(({ length }) => length <= 1)) {
    return false;
  }
  return true;
};

export const yupNameFieldWhiteSpaceCheck = (name: string | undefined): boolean => {
  if (!name) {
    return false;
  }
  if (name.includes('  ')) {
    return false;
  }
  const splitSentence = name.split(' ');
  if (splitSentence[0] === '' || splitSentence[splitSentence.length - 1] === '') {
    return false;
  }
  return true;
};

export const yupGovIdFirstCharCheck = (name: string | undefined): boolean => {
  if (name?.startsWith('0')) {
    return false;
  }
  return true;
};

export const customerResponseToCustomer = (customerResponse: CustomerResponse): Customer => {
  const { customer, hasKvkkApproval, canContinue, displayPlate } = customerResponse;
  customer.hasKvkkApproval = hasKvkkApproval;
  customer.canContinue = canContinue;
  customer.displayPlate = displayPlate;
  return customer;
};

export const getRandomColorForChartPie = (dataSize: number) => {
  // generates random colours and puts them in string
  const colors = [];
  for (let i = 0; i < dataSize; i += 1) {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let x = 0; x < 6; x += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    colors.push(color);
  }
  return colors;
};

export const getErrorMessage = (error: Error, defaultError: string): string => {
  if (error instanceof AxiosError) {
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.message) {
          return error.response.data.message;
        }
      }
    }
  }

  return defaultError;
};

export const toTitleCase = (text: string): string => {
  const titleCase = text
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  return titleCase;
};

export const downloadBlobAsFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  window.URL.revokeObjectURL(url);
};
