import { ApiBase, ApiResponse, City, County } from 'types';

const BASE_URL = 'location';

export class LocationApi extends ApiBase {
  public async getCities(): Promise<ApiResponse<City[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-cities`);

    return response.data;
  }

  public async getCounties(cityId: number): Promise<ApiResponse<County[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-counties/${cityId}`);

    return response.data;
  }
}
