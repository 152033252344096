export const DEFAULT_FORM_SIMPLE_GRID_LAYOUT = [1, null, 2, 3, 3, 6];
export const FORM_SELECT_SIMPLE_GRID_LAYOUT = [1, null, 1, 2, 2, 4];

export const GenericMessages = {
  CannotBeEmpty: 'Bu alan boş olamaz',
  InvalidEmail: 'Lütfen emailiniz doğru formatta olduğundan emin olunuz',
  InvalidChasisNo: 'Lütfen şase numarasının doğru formatta olduğundan emin olunuz',
  PhoneLenghtProblem: 'Telefon numarası 10 haneli olmalıdır',
  CantBeLessThan: (min: number) => `En az ${min} karakterli olmak zorundadır`,
  CantBeMoreThan: (max: number) => `En fazla ${max} karakterli olmak zorundadır`,
  CantBeLessThanNumber: (min: number) => `En az ${min} olabilir`,
  CantBeMoreThanNumber: (max: number) => `En fazla ${max} olabilir`,
  GetIdLength: 'Lütfen T.C. Kimlik numaranızı kontrol ediniz.',
  NoSpecialCharater: 'Özel karakter kullanılamaz',
  OnlyNumber: 'Bu alana sadece sayı girilerbilir',
  OnlyLetter: 'Bu alana sadece harf girilebilir',
  InvalidGovId: 'T.C. Kimlik No 11 haneli olmalıdır',
  NameMinTwoLetters: 'En az iki harften oluşan kelimeler kullanılmalıdır',
  CannotStartWithZero: 'Bu alan sıfır ile başlamamalıdır.',
  NameWhiteSpaceBetweenWords: 'Sadece kelimeler arasında bir boşluk karakteri kullanılmalıdır.',
};

export const chasisNoRegex: RegExp = /\b[A-Z0-9]{17}\b/gm;
export const NoSpecialCharacterRegex = /^[a-zA-Z0-9\s]+$/;
export const OnlyNumberRegex = /^[0-9]*$/;
export const OnlyLetterRegex = /^[\p{L}\s]+$/gu;
export const govIdCharacterLengthRegex = /^((?!(0))[0-9]{11})$/g;
export const localStorageUserAuthInfoKey = 'authUserInfo';
