import { ExpertisePoint } from './expertise-point.types';
import { ExpertiseCarBodyPartName, ExpertiseCarBodyTypes, ExpertiseChassisCarBodyPartName } from './vehicle.types';

// #region Begin Enums
export const enum ExpertiseQueryCacheKey {
  GetDamageNotes = 'GetDamageNotes',
  GetExpertisePackagesWithAbilities = 'GetExpertisePackagesWithAbilities',
  GetAllAbilities = 'GetAllAbilities',
  GetAllPackageGroups = 'GetAllPackageGroups',
  GetInhouseCodeExpertisePackages = 'GetInhouseCodeExpertisePackages',
  GetInhouseCodes = 'GetInhouseCodes',
  GetExpertiseReportResult = 'GetExpertiseReportResult',
}

export enum ExpertiseBodyDamageType {
  Original = 'O',
  Painted = 'B',
  LocallyPainted = 'LB',
  Changed = 'D',
  HalfPainted = 'YB',
  Cutting = 'K',
  Removed = 'ST',
  Processed = 'IG',
  FoilCoated = 'FK',
  Damaged = 'H',
  Sunroof = 'SR',
}

export enum ExpertiseChassisDamageType {
  Original = 'O',
  Changed = 'D',
  Cutting = 'K',
  Processed = 'IG',
  Damaged = 'H',
  Painted = 'B',
  Meek = 'MK',
}

export enum ExpertiseBodyDamagePartType {
  Expertise = 1,
  Micron = 2,
  Chassis = 3,
  MotorControl = 4,
  // MechanicControl = 5,
  InternalControl = 6,
  ExternalControl = 7,
  Undercarriage = 8,
}

export const ExpertiseBodyDamageTypeLabel = new Map<string, string>([
  [ExpertiseBodyDamageType.Original, 'Orijinal'],
  [ExpertiseBodyDamageType.Painted, 'Boyalı'],
  [ExpertiseBodyDamageType.LocallyPainted, 'Lokal Boyalı'],
  [ExpertiseBodyDamageType.Changed, 'Değişmiş'],
  [ExpertiseBodyDamageType.HalfPainted, 'Yarı Boyalı'],
  [ExpertiseBodyDamageType.Cutting, 'Kesme'],
  [ExpertiseBodyDamageType.Removed, 'Sök Tak'],
  [ExpertiseBodyDamageType.Processed, 'İşlem Görmüş'],
  [ExpertiseBodyDamageType.FoilCoated, 'Folyo Kaplı'],
  [ExpertiseBodyDamageType.Damaged, 'Hasarlı'],
  [ExpertiseBodyDamageType.Sunroof, 'Cam Tavan'],
]);

export const ExpertiseChassisDamageTypeLabel = new Map<string, string>([
  [ExpertiseChassisDamageType.Original, 'Orijinal'],
  [ExpertiseChassisDamageType.Changed, 'Değişmiş'],
  [ExpertiseChassisDamageType.Cutting, 'Kesme'],
  [ExpertiseChassisDamageType.Processed, 'İşlem Görmüş'],
  [ExpertiseChassisDamageType.Damaged, 'Hasarlı'],
  [ExpertiseChassisDamageType.Painted, 'Boyalı'],
  [ExpertiseChassisDamageType.Meek, 'Ezik'],
]);

export const enum ExpertiseDamageNoteType {
  Bodywork = 1,
  Mechanic = 2,
}

export const enum ExternalPackageGroupSourceType {
  OccoCar = 10,
  OccoCoupon = 20,
}

export const enum ExternalPackageCodeStatus {
  NotUsed = 100,
  Used = 200,
  Expired = 1000,
}

export const ExternalPackageCodeStatusLabel = new Map<number, string>([
  [ExternalPackageCodeStatus.NotUsed, 'Kullanılmadı'],
  [ExternalPackageCodeStatus.Used, 'Kullanıldı'],
  [ExternalPackageCodeStatus.Expired, 'Süresi Geçti'],
]);

// #endregion

// #region Begin Types

export interface ExpertisePackageItem {
  expertisePackageId: number;
  description?: any;
  active: boolean;
  displayOrder: number;
  name: string;
  id: number;
  expertisePackage?: any;
}

export interface ExpertisePackage {
  description?: any;
  active: boolean;
  displayOrder: number;
  durationInMinute: number;
  price: string;
  name: string;
  id: number;
  items: ExpertisePackageItem[];
  quantity: number;
  includeInRoyalty?: boolean;
  royaltyRate?: string | null;
}

export interface ExpertisePackagePriceListItem {
  description?: any;
  active: boolean;
  displayOrder: number;
  durationInMinute: number;
  price: string;
  name: string;
  id: number;
  items: ExpertisePackageItem[];
  itemId?: number;
  quantity: number;
}

export interface ExpertisePackageAbility {
  abilityDefinitionTypeId: number;
  name: string;
}
export interface ExpertisePackagesResponse {
  expertisePackages: ExpertisePackage[];
}

export interface InhouseCoudeExpertisePackagesResponse {
  packages: ExpertisePackage[];
}

export interface ExpertisePackageWithAbilities {
  expertisePackage: ExpertisePackage;
  abilities: ExpertisePackageAbility[];
}

export type ExpertisePackageWithAbilitiesResponse = ExpertisePackageWithAbilities;

export interface ExpertiseTemplate {
  active: boolean;
  expertiseBodyDamagePartTypeId: number;
  vehicleTypeId: number;
  displayId: ExpertiseCarBodyPartName | ExpertiseChassisCarBodyPartName;
  name: string;
  id: number;
  vehicleType?: {
    id: number;
    name: ExpertiseCarBodyTypes;
  };
}

export interface BodyPartExpertiseQuestionTemplate {
  possibleAnswers: { key: string; value: string }[];
  active: boolean;
  expertiseBodyDamagePartTypeId: number;
  displayOrder: number;
  question: string;
  id: number;
}

export interface ExpertiseTemplateResponse {
  expertiseBodyDamageParts: ExpertiseTemplate[];
  expertiseBodyQuestions: BodyPartExpertiseQuestionTemplate[];
}

export interface ExpertiseBodyQuestionResponse {
  expertiseBodyQuestions: BodyPartExpertiseQuestionTemplate[];
}

export interface SaveExpertiseBodyPartsRequest {
  customerId: number;
  expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType;
  bodyDamageParts: {
    bodyDamageValue: string;
    expertiseBodyDamagePartId: number;
  }[];
  bodyDamageAnswers: { answer: string; expertiseBodyQuestionId: number }[];
}

export interface SaveExpertiseBodyDamageAnswersRequest {
  customerId: number;
  bodyDamageAnswers: { answer: string; expertiseBodyQuestionId: number }[];
}

export interface BodyPartExpertiseInfo {
  expertiseBodyDamagePartId: number;
  customerId: number;
  expertiseBodyDamageValue: ExpertiseBodyDamageType;
  id: number;
  expertiseBodyDamagePart: ExpertiseTemplate;
}

export interface BodyPartAnswerInfo {
  expertiseBodyDamagePartTypeId: number;
  expertiseBodyQuestionId: number;
  customerId: number;
  answer: string;
  id: number;
}
export interface BodyPartExpertiseInfoResponse {
  expertiseBodyDamages: BodyPartExpertiseInfo[];
  expertiseBodyAnswers: BodyPartAnswerInfo[];
}

export interface SaveDamageNoteRequest {
  id?: number;
  customerId: number;
  note: string;
  noteType: ExpertiseDamageNoteType;
  file?: File;
  damageNoteDictId?: number;
}

export interface DamageNote {
  id: number;
  customerId: number;
  note: string;
  noteType: ExpertiseDamageNoteType;
  photoUrl: string;
}

export interface SaveDamageNoteResponse {
  id: number;
  customerId: number;
  note: string;
  noteType: ExpertiseDamageNoteType;
  photoUrl: string;
}

export interface OcrImageResponse {
  key: string;
  value: string;
}

export interface DamageNoteSocket {
  notes: DamageNote[];
  type: string;
}

export interface SavePackageWithAbilitiesRequest {
  id?: number;
  name: string;
  price: number;
  includeInRoyalty?: boolean;
  abilityDefinitionTypes: number[];
  royaltyRate?: number | null;
}
export interface ExpertisePackageGroupItemResponse {
  id: number;
  expertisePackageGroupId: number;
  expertisePackageId: number;
  expertisePackageName: string;
  active: boolean;
  price: string;
  quantity?: string;
}

export interface ExpertisePackageGroup {
  id: number;
  name: string;
  active: boolean;
  items: ExpertisePackageGroupItemResponse[];
  expertisePoints?: ExpertisePoint[];
  externalPackageGroupSource?: number;
  discountAvailable?: boolean;
  maxDiscountRate?: string;
}

export interface ExpertisePackageGroupResponse {
  expertisePackageGroup: ExpertisePackageGroup;
}

export interface SaveExpertisePackageRequestItem {
  expertisePackageId: number;

  price: number;
}
export interface SaveExpertisePackageRequest {
  id?: number;
  name: string;
  packages: SaveExpertisePackageRequestItem[];
  expertisePoints: number[];
  externalPackageGroupSource?: number;
  discountAvailable?: boolean;
  maxDiscountRate?: number;
}

export interface ExpertiseReportFileInfo {
  fileType: string;
  url: string;
}

export interface CheckOldBodyDamagesResponse {
  hasInconsistency: boolean;
  oldReportUrl?: string;
}

export interface ExpertiseDamageNoteDictItem {
  id: number;
  note: string;
  noteType: number;
}

export interface SearchExpertiseDamageNoteDictItem {
  id: number;
  note: string;
}

export interface SaveExpertiseNoteDictItemRequest {
  id?: number;
  note: string;
  noteType: ExpertiseDamageNoteType;
  mergeDamageNoteDictIds?: number[];
}

export type InhouseCode = {
  id: number;
  code: string;
  endDate: string;
  expertisePointName?: string;
  expertisePackageName?: string;
  price: string;
  statusStr: string;
};
export interface InhouseCodes {
  codes: InhouseCode[];
  externalPackageGroupSource: ExternalPackageGroupSourceType;
}
export interface CreateInhouseCodeRequest {
  expertisePackageGroupItemId: number;
  externalPackageGroupSource: ExternalPackageGroupSourceType;
  price: number;
  quantity: number;
  expertisePointId: number;
}

// #endregion
