import { ApiBase, ApiResponse, OcrImageResponse, OcrLicencePhotoRequest } from 'types';

const BASE_URL = 'ocr';

type ChasisAndKilometerResponse = string[];

export class OcrApi extends ApiBase {
  public async ocrLicencePhoto(ocrLicencePhotoRequest: OcrLicencePhotoRequest): Promise<OcrImageResponse[]> {
    const formData = new FormData();
    if (ocrLicencePhotoRequest.files) {
      formData.append('files', ocrLicencePhotoRequest.files);
    }

    const response = await this.apiClient.post(`${BASE_URL}/ocr-license-image`, formData);
    return response.data.data;
  }

  public async chasisNoAndKilometerCheckByOcr(customerId: number): Promise<ApiResponse<ChasisAndKilometerResponse>> {
    const response = await this.apiClient.get<ApiResponse<ChasisAndKilometerResponse>>(
      `customer/chasis-no-and-kilometer-check-ocr/${customerId}`,
    );
    return response.data;
  }
}
