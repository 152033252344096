import { CircularProgress, Flex } from '@chakra-ui/react';

export const LoadingIndicator = () => {
  return (
    <Flex align="center" justify="center">
      <CircularProgress isIndeterminate color="green.300" size="32px" />
      Yukleniyor...
    </Flex>
  );
};
