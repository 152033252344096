import { Flex, Heading, SimpleGrid, Spacer, Button, Box, useToast, VStack, Checkbox, Badge } from '@chakra-ui/react';
import { Api } from 'api';
import { CitySelect, CountySelect, FormControlFormPhoneKvKKInput } from 'components';
import {
  DEFAULT_FORM_SIMPLE_GRID_LAYOUT,
  FormControlFormGovIdInput,
  FormControlFormInput,
  FormControlFormNameInput,
  FormControlFormPhoneInput,
  FORM_SELECT_SIMPLE_GRID_LAYOUT,
  GenericMessages,
  govIdCharacterLengthRegex,
  OnlyLetterRegex,
  yupGovIdFirstCharCheck,
  yupNameFieldCheck,
  yupNameFieldWhiteSpaceCheck,
  createCustomerSocket,
} from 'core';
import { refetchCustomer, setCustomerFormDirty } from 'features';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Customer, CustomerResponse } from 'types';
import * as yup from 'yup';
import { Socket } from 'socket.io-client';

type FormData = {
  clientInfo: {
    name: string;
    firmName?: string;
    mersisNo?: string;
    authorizationNo?: string;
    phone: string;
    city?: string;
    county?: string;
    govId: string;
    email: string;
    address?: string;
    isIndividual: boolean;
  };
  carOwnerInfo: {
    name?: string;
    phone?: string;
  };
  // expertisePackage?: string;
  // expertisePackageGroup?: string;
};

const formValidation = yup.object({
  clientInfo: yup.object({
    name: yup
      .string()
      .required(GenericMessages.CannotBeEmpty)
      .matches(OnlyLetterRegex, GenericMessages.OnlyLetter)
      .test('checkNameWhiteSpace', GenericMessages.NameWhiteSpaceBetweenWords, yupNameFieldWhiteSpaceCheck)
      .test('checkNameWords', GenericMessages.NameMinTwoLetters, yupNameFieldCheck),
    firmName: yup.string(),
    mersisNo: yup.string().when('isIndividual', {
      is: false, // When isIndividual is false
      then: yup.string().required(GenericMessages.CannotBeEmpty),
    }),
    authorizationNo: yup.string().when('isIndividual', {
      is: false, // When isIndividual is false
      then: yup.string().required(GenericMessages.CannotBeEmpty),
    }),
    phone: yup.string().required(GenericMessages.CannotBeEmpty),
    city: yup.number().required(GenericMessages.CannotBeEmpty),
    county: yup.number().required(GenericMessages.CannotBeEmpty),
    govId: yup
      .string()
      .required(GenericMessages.CannotBeEmpty)
      .when('clientInfo.isIndividual', {
        is: true,
        then: yup
          .string()
          .required(GenericMessages.CannotBeEmpty)
          .test('checkGovIdFirstChar', GenericMessages.CannotStartWithZero, yupGovIdFirstCharCheck)
          .matches(govIdCharacterLengthRegex, GenericMessages.InvalidGovId),
      }),
    email: yup.string().nullable().email(GenericMessages.InvalidEmail),
    address: yup.string().nullable(),
    isIndividual: yup.bool(),
  }),
  carOwnerInfo: yup.object({
    name: yup.string().when('.', {
      is: (val: string) => val?.length > 0,
      then: yup
        .string()
        .matches(OnlyLetterRegex, GenericMessages.OnlyLetter)
        .test('checkCarOwnerNameWhiteSpace', GenericMessages.NameWhiteSpaceBetweenWords, yupNameFieldWhiteSpaceCheck)
        .test('checkCarOwnerNameWords', GenericMessages.NameMinTwoLetters, yupNameFieldCheck),
    }),
    phone: yup.string(),
  }),
  // expertisePackage: yup.number().required(GenericMessages.CannotBeEmpty),
  // expertisePackageGroup: yup.number().required(GenericMessages.CannotBeEmpty),
});

export const ExpertiseClientInformationPage = () => {
  const customer = useSelector((state: RootState) => state.customer.focusedCustomer);
  const canCustomerContinue = useSelector((state: RootState) => state.customer.canCustomerContinue);
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const [hasKvkkApproval, setHasKvkkApproval] = useState(customer?.hasKvkkApproval || false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSocket, setCustomerSocket] = useState<Socket | null>(null);

  useEffect(() => {
    setHasKvkkApproval(customer?.hasKvkkApproval || false);
  }, [customer?.hasKvkkApproval]);

  // const mutate = useMutation<string, any, FormData>(
  //   async (formData: FormData) => {
  //     await Api.Customer.saveCustomerInfo({
  //       id: customer!.id,
  //       name: formData.clientInfo.name,
  //       phoneNumber: formData.clientInfo.phone,
  //       countyId: formData.clientInfo.province,
  //       cityId: formData.clientInfo.district,
  //       governmentId: formData.clientInfo.govId,
  //       isIndividual: true,
  //       carLicencePhoneNumber: formData.carOwnerInfo.phone,
  //       carLicenceName: formData.carOwnerInfo.name,
  //       expertisePackageId: formData.expertisePackage,
  //     });
  //     return '';
  //   },
  //   {
  //     onSuccess: {
  //       // Do Nothing
  //     },
  //   },
  // );§

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mutation = useMutation<void, any, FormData>(
    async (formData: FormData) => {
      await Api.Customer.saveCustomerInfo({
        id: customer!.id,
        name: formData.clientInfo.name,
        firmName: formData.clientInfo.firmName!,
        mersisNo: formData.clientInfo.mersisNo!,
        authorizationNo: formData.clientInfo.authorizationNo!,
        phoneNumber: formData.clientInfo.phone,
        countyId: parseInt(formData.clientInfo.county!, 10),
        cityId: parseInt(formData.clientInfo.city!, 10),
        governmentId: formData.clientInfo.govId,
        isIndividual: formData.clientInfo.isIndividual,
        carLicencePhoneNumber: formData.carOwnerInfo.phone,
        carLicenceName: formData.carOwnerInfo.name,
        // expertisePackageId: parseInt(formData.expertisePackage!, 10),
        // expertisePackageGroupId: parseInt(formData.expertisePackageGroup!, 10),
        email: formData.clientInfo.email,
        address: formData.clientInfo.address ? formData.clientInfo.address : '',
      });
    },
    {
      onSuccess: () => {
        dispatch(setCustomerFormDirty(false));
        dispatch(refetchCustomer(customer!.id));
        toast({
          title: 'Müşteri bilgileri başarılı bir şekilde kayıt edildi',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error) => {
        if (!customer?.hasKvkkApproval) {
          setHasKvkkApproval(false);
        }
        toast({
          title: error?.response?.data?.message || 'Müşteri bilgileri kayıt edilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: formValidation,
    initialValues: {
      clientInfo: {
        name: customer?.name || '',
        firmName: customer?.firmName || '',
        mersisNo: customer?.mersisNo || '',
        authorizationNo: customer?.authorizationNo || '',
        phone: customer?.phoneNumber || '',
        county: customer?.countyId ? customer?.countyId.toString() : '',
        city: customer?.cityId ? customer?.cityId.toString() : '',
        govId: customer?.governmentId || '',
        email: customer?.email || '',
        address: customer?.address || '',
        isIndividual: customer!.isIndividual,
      },
      carOwnerInfo: {
        name: customer?.carLicenceName || '',
        phone: customer?.carLicencePhoneNumber || '',
      },
      // expertisePackage: customer?.expertisePackageId ? customer?.expertisePackageId.toString() : '',
      // expertisePackageGroup: customer?.expertisePackageGroupId ? customer?.expertisePackageGroupId.toString() : '',
    },
    onSubmit: (data: FormData) => {
      mutation.mutateAsync(data);
    },
  });

  const { handleChange, values, errors, dirty, setFieldValue, resetForm } = formik;

  useEffect(() => {
    dispatch(setCustomerFormDirty(dirty));
  }, [dirty]);

  useEffect(() => {
    if (customer) {
      const newCustomerSocket = createCustomerSocket(customer.id.toString());
      setCustomerSocket(newCustomerSocket);

      newCustomerSocket.on('customerChanged', (data: CustomerResponse) => {
        if (data.customer?.id === customer?.id) {
          dispatch(refetchCustomer(data.customer?.id));
        }
      });

      return () => {
        newCustomerSocket.disconnect();
      };
    }
    return undefined;
  }, [customer, dispatch]);

  const onKvKKConfirmation = (kvKKCustomerResponse?: Customer) => {
    if (kvKKCustomerResponse) {
      const { name, governmentId, countyId, cityId, email, address, firmName } = kvKKCustomerResponse;
      setFieldValue('clientInfo.name', name);
      setFieldValue('clientInfo.govId', governmentId);
      setFieldValue('clientInfo.county', countyId);
      setFieldValue('clientInfo.city', cityId);
      setFieldValue('clientInfo.email', email || '');
      setFieldValue('clientInfo.firmName', firmName);
      setFieldValue('clientInfo.address', address || '');
      setHasKvkkApproval(true);
    } else {
      setHasKvkkApproval(true);
    }
  };

  useEffect(() => {
    if (!customer?.countyId) {
      setFieldValue('clientInfo.county', '');
    }

    if (!customer?.cityId) {
      setFieldValue('clientInfo.city', '');
    }

    if (!customer?.expertisePackageId) {
      setFieldValue('expertisePackage', '');
    }

    resetForm({ values });
  }, [customer?.id]);

  if (!customer) {
    return null;
  }

  return (
    <Flex align="stretch" direction="column" basis="100%" gap="2">
      <Heading as="h4" size="md">
        Müşteri Bilgileri
      </Heading>
      <Spacer />
      {/* <Box>
        <Button>Occo Kartı Tara</Button>
      </Box>
      <Spacer /> */}
      <form onSubmit={formik.handleSubmit} noValidate>
        <Flex align="stretch" direction="column" basis="100%" gap="2">
          <SimpleGrid spacing="30px" columns={DEFAULT_FORM_SIMPLE_GRID_LAYOUT}>
            <VStack align="self-start">
              <FormControlFormNameInput
                label="Ad"
                name="clientInfo.name"
                onChange={handleChange}
                value={values.clientInfo.name}
                error={errors.clientInfo?.name != null}
                helperText={errors.clientInfo?.name}
                isDisabled={!canCustomerContinue}
              />
              <Checkbox
                borderColor="blue.200"
                colorScheme="blue"
                name="clientInfo.isIndividual"
                onChange={(event) => {
                  setFieldValue(event.target.name, !event.target.checked);
                }}
                isChecked={!values.clientInfo.isIndividual}
                isDisabled={!canCustomerContinue}
              >
                Kurumsal
              </Checkbox>
            </VStack>
            {!values.clientInfo.isIndividual && (
              <FormControlFormInput
                label="Firma Adı"
                name="clientInfo.firmName"
                onChange={handleChange}
                value={values.clientInfo.firmName}
                error={errors.clientInfo?.firmName != null}
                helperText={errors.clientInfo?.firmName}
                isDisabled={!canCustomerContinue}
              />
            )}
            {!values.clientInfo.isIndividual && (
              <FormControlFormInput
                label="Mersis No"
                name="clientInfo.mersisNo"
                onChange={handleChange}
                value={values.clientInfo.mersisNo}
                error={errors.clientInfo?.mersisNo != null}
                helperText={errors.clientInfo?.mersisNo}
                isDisabled={!canCustomerContinue}
              />
            )}
            {!values.clientInfo.isIndividual && (
              <FormControlFormInput
                label="Yetki Belge Numarası"
                name="clientInfo.authorizationNo"
                onChange={handleChange}
                value={values.clientInfo.authorizationNo}
                error={errors.clientInfo?.authorizationNo != null}
                helperText={errors.clientInfo?.authorizationNo}
                isDisabled={!canCustomerContinue}
              />
            )}
            <FormControlFormPhoneKvKKInput
              label="Telefon"
              name="clientInfo.phone"
              onChange={handleChange}
              value={values.clientInfo.phone}
              error={errors.clientInfo?.phone != null}
              helperText={errors.clientInfo?.phone}
              type="text"
              isDisabled={!canCustomerContinue}
              onKvKKConfirmation={onKvKKConfirmation}
              showKvKKButton={!hasKvkkApproval && !!values.clientInfo.name && !!values.clientInfo.phone}
              customerName={values.clientInfo.name}
            />
            <CitySelect
              name="clientInfo.city"
              onChange={handleChange}
              value={values.clientInfo.city}
              error={errors.clientInfo?.city != null}
              helperText={errors.clientInfo?.city}
              isDisabled={!canCustomerContinue}
            />
            <CountySelect
              cityId={values.clientInfo?.city ? parseInt(values.clientInfo?.city, 10) : undefined}
              name="clientInfo.county"
              onChange={handleChange}
              value={values.clientInfo.county}
              error={errors.clientInfo?.county != null}
              helperText={errors.clientInfo?.county}
              isDisabled={!canCustomerContinue}
            />
            <FormControlFormGovIdInput
              label={values.clientInfo.isIndividual ? 'Tc Kimlik ' : 'Vergi Numarası'}
              name="clientInfo.govId"
              value={values.clientInfo.govId}
              error={errors.clientInfo?.govId != null}
              helperText={errors.clientInfo?.govId}
              onChange={handleChange}
              type="text"
              isDisabled={!canCustomerContinue}
              isIndividual={values.clientInfo.isIndividual}
            />
            <FormControlFormInput
              label="E-Posta Adresi"
              name="clientInfo.email"
              onChange={handleChange}
              value={values.clientInfo.email}
              error={errors.clientInfo?.email != null}
              helperText={errors.clientInfo?.email}
              type="email"
              isDisabled={!canCustomerContinue}
            />
            <FormControlFormInput
              label="Adres"
              name="clientInfo.address"
              onChange={handleChange}
              value={values.clientInfo.address}
              error={errors.clientInfo?.address != null}
              helperText={errors.clientInfo?.address}
              type="text"
              isDisabled={!canCustomerContinue}
            />
          </SimpleGrid>
          <Spacer />
          <Heading as="h4" size="md">
            Araç Sahibi Bilgileri (Opsiyonel)
          </Heading>
          <Spacer />
          <SimpleGrid spacing="30px" columns={DEFAULT_FORM_SIMPLE_GRID_LAYOUT}>
            <FormControlFormNameInput
              label="Ad"
              name="carOwnerInfo.name"
              value={values.carOwnerInfo.name}
              error={errors.carOwnerInfo?.name != null}
              helperText={errors.carOwnerInfo?.name}
              isDisabled={!canCustomerContinue}
              onChange={handleChange}
            />
            <FormControlFormPhoneInput
              label="Telefon"
              name="carOwnerInfo.phone"
              onChange={handleChange}
              value={values.carOwnerInfo.phone}
              error={errors.carOwnerInfo?.phone != null}
              helperText={errors.carOwnerInfo?.phone}
              isDisabled={!canCustomerContinue}
              type="text"
            />
          </SimpleGrid>
          <Spacer />
          <Heading as="h4" size="md">
            Ekspertiz Hizmet Paketi
          </Heading>
          <Spacer />
          <SimpleGrid spacing="30px" columns={FORM_SELECT_SIMPLE_GRID_LAYOUT}>
            <Badge>{customer.expertisePackage?.name}</Badge>
            {/* <ExpertisePackageGroupSelect
              name="expertisePackageGroup"
              onChange={handleChange}
              value={values.expertisePackageGroup}
              error={errors.expertisePackageGroup != null}
              helperText={errors.expertisePackageGroup}
              isDisabled={!canCustomerContinue}
            />
            <ExpertisePackageSelect
              name="expertisePackage"
              onChange={handleChange}
              value={values.expertisePackage}
              error={errors.expertisePackage != null}
              helperText={errors.expertisePackage}
              isDisabled={!canCustomerContinue || !values.expertisePackageGroup}
              expertisePackageGroupId={values.expertisePackageGroup}
            /> */}
          </SimpleGrid>
          {canCustomerContinue && (
            <Box width="100%" display="flex" justifyContent="flex-end">
              <Button type="submit" isLoading={mutation.isLoading}>
                Kaydet
              </Button>
            </Box>
          )}
        </Flex>
      </form>
    </Flex>
  );
};
