import { AxiosResponse } from 'axios';
import { Announcement, AnnouncementResponse, ApiBase, ApiResponse, ProductType, SendAnnouncementRequest } from 'types';

const BASE_URL = 'util';

export class UtilApi extends ApiBase {
  private static convertAnnouncementsResponse(announcementsResponse: AnnouncementResponse[]): Announcement[] {
    return announcementsResponse.map((elem) => ({
      id: elem.id,
      title: elem.title,
      description: elem.description,
      isRead: elem.isRead,
      createdAt: new Date(elem.createdAt),
      expertisePoints: elem.expertisePoints,
      isAllSend: elem.isAllSend,
    }));
  }

  public async getProductPriceByType(productType: ProductType): Promise<ApiResponse<string>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-product-price-by-type/${productType}`);
    return response.data;
  }

  public async sendAnnouncement(sendAnnouncementRequest: SendAnnouncementRequest): Promise<ApiResponse<void>> {
    const response = await this.apiClient.post(`${BASE_URL}/send-announcement`, sendAnnouncementRequest);
    return response.data;
  }

  public async getAllAnnouncements(): Promise<ApiResponse<Announcement[]>> {
    const response: AxiosResponse<ApiResponse<AnnouncementResponse[]>> = await this.apiClient.get(
      `${BASE_URL}/all-announcements`,
    );

    const announcements: Announcement[] = UtilApi.convertAnnouncementsResponse(response.data.data);

    return {
      isSuccess: true,
      data: announcements,
      code: 200,
    };
  }

  public async markAnnouncementAsRead(announcementId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.get(`${BASE_URL}/mark-as-read-announcement/${announcementId}`);
    return response.data;
  }

  public async getUserAnnouncements(): Promise<ApiResponse<Announcement[]>> {
    const response: AxiosResponse<ApiResponse<AnnouncementResponse[]>> = await this.apiClient.get(
      `${BASE_URL}/current-announcements`,
    );
    const announcements: Announcement[] = UtilApi.convertAnnouncementsResponse(response.data.data);
    return {
      isSuccess: true,
      data: announcements,
      code: 200,
    };
  }

  public async deleteAnnouncement(announcementId: number): Promise<ApiResponse<void>> {
    const response = await this.apiClient.delete(`${BASE_URL}/delete-announcement/${announcementId}`);
    return response.data;
  }

  public async getUnreadAnnouncemenets(): Promise<ApiResponse<Announcement[]>> {
    const response: AxiosResponse<ApiResponse<AnnouncementResponse[]>> = await this.apiClient.get(
      `${BASE_URL}/current-unread-announcements`,
    );
    const announcements: Announcement[] = UtilApi.convertAnnouncementsResponse(response.data.data);
    return {
      isSuccess: true,
      data: announcements,
      code: 200,
    };
  }

  public async getToggleEnabled(toggleType: number): Promise<
    ApiResponse<{
      isEnabled: boolean;
    }>
  > {
    const response = await this.apiClient.get(`${BASE_URL}/get-toggle-enabled/${toggleType}`);
    return response?.data;
  }

  public async getProducts(): Promise<ApiResponse<any[]>> {
    const response = await this.apiClient.get(`${BASE_URL}/get-products`);
    return response.data;
  }
}
