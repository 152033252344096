import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { PaymentTransationStartProcessData } from 'types';

export interface OccopayDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  paymentData?: PaymentTransationStartProcessData;
}

export const OccopayDrawer: React.FC<OccopayDrawerProps> = ({ isOpen, onClose, paymentData }) => {
  if (!paymentData) {
    return null;
  }
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Occopay</DrawerHeader>

        <DrawerBody>
          <iframe
            width="100%"
            height="800"
            scrolling="yes"
            title="occopay"
            src={`${process.env.REACT_APP_OCCOPAY_URL}/iframeAuth?token=${paymentData.token}&paymentTransactionId=${paymentData.paymentTransactionId}&paymentAmount=${paymentData.amount}&isPackagePayment=true`}
          />
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Kapat
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
