import { Api } from 'api';
import { ChartData } from 'chart.js';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { Chart } from 'react-chartjs-2';
import { useMutation, useQuery } from 'react-query';
import {
  AdminQueriesReportGraphData,
  ApiResponse,
  ExpertisePoint,
  QueriesReportAdminResponse,
  QueriesReportResponse,
  ReportingQueryCacheKey,
} from 'types';
import {
  Button,
  Center,
  Icon,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useToast,
} from '@chakra-ui/react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'features';
import { downloadBlobAsFile } from 'core';
import { DownloadIcon } from '@chakra-ui/icons';

export interface AdminReportQueriesProps {
  beginDate?: Date;
  endDate?: Date;
  expertisePoint?: ExpertisePoint;
}

type QueryReportChartData = ChartData<'line' | 'bar', number[], string>;
type QueryReportData = {
  graphData: QueryReportChartData;
  isEmpty: boolean;
  totalQueriesCount?: number;
  totalQueriesAmount?: number;
  totalTramerQueriesCount?: number;
  totalTramerQueriesAmount?: number;
  totalKilometerQueriesCount?: number;
  totalKilometerQueriesAmount?: number;
};
export const AdminReportQueries: React.FC<AdminReportQueriesProps> = ({ beginDate, endDate, expertisePoint }) => {
  const authUserInfo = useSelector((state: RootState) => state.auth.authUserInfo);
  const toast = useToast();
  const dispatch = useDispatch();

  const { data: reportData, isLoading } = useQuery(
    [ReportingQueryCacheKey.GetQueriesReport, beginDate?.getTime(), endDate?.getTime(), expertisePoint?.id],
    async (): Promise<QueryReportData | null> => {
      if (beginDate && endDate) {
        const response: ApiResponse<QueriesReportAdminResponse | QueriesReportResponse> =
          authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin
            ? await Api.Reporting.getQueriesReportAdmin(beginDate.getTime(), endDate.getTime(), expertisePoint?.id)
            : await Api.Reporting.getQueriesReport(beginDate.getTime(), endDate.getTime());
        const queryData: QueriesReportAdminResponse | QueriesReportResponse = response.data;
        const graphData: AdminQueriesReportGraphData = {
          dateLabels: [],
          tramerReportBarData: [],
          kmReportBarData: [],
          totalCountReportLineData: [],
        };
        for (let i = 0; i < queryData.kilometerQueries.length; i += 1) {
          const km = queryData.kilometerQueries[i];
          const tramer = queryData.tramerQueries[i];
          const kmsCount = parseInt(km.count, 10);
          const tramersCount = parseInt(tramer.count, 10);
          const total = kmsCount + tramersCount;
          if (total !== 0) {
            const date = new Date(km.date);
            graphData.dateLabels.push(
              `${date.getDate()} ${date.toLocaleString(window.navigator.language, { month: 'long' })}`,
            );
            graphData.kmReportBarData.push(kmsCount);
            graphData.tramerReportBarData.push(tramersCount);
            graphData.totalCountReportLineData.push(total);
          }
        }
        return {
          isEmpty: graphData.dateLabels.length === 0,
          graphData: {
            labels: graphData.dateLabels,
            datasets: [
              {
                type: 'line' as const,
                label: 'Toplam',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: graphData.totalCountReportLineData,
                yAxisID: 'totalYAxis',
                xAxisID: 'xAxis',
              },
              {
                type: 'bar' as const,
                label: 'Hasar Sorgu',
                backgroundColor: 'rgb(75, 192, 192)',
                data: graphData.tramerReportBarData,
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'yAxis',
                xAxisID: 'xAxis',
              },
              {
                type: 'bar' as const,
                label: 'KM Sorgu',
                backgroundColor: 'rgb(53, 162, 235)',
                data: graphData.kmReportBarData,
                borderColor: 'white',
                borderWidth: 2,
                yAxisID: 'yAxis',
                xAxisID: 'xAxis',
              },
            ],
          },
          totalQueriesAmount: 'totalQueriesAmount' in queryData ? queryData.totalQueriesAmount : undefined,
          totalQueriesCount: 'totalQueriesCount' in queryData ? queryData.totalQueriesCount : undefined,
          totalTramerQueriesCount:
            'totalTramerQueriesCount' in queryData ? queryData.totalTramerQueriesCount : undefined,
          totalTramerQueriesAmount:
            'totalTramerQueriesAmount' in queryData ? queryData.totalTramerQueriesAmount : undefined,
          totalKilometerQueriesCount:
            'totalKilometerQueriesCount' in queryData ? queryData.totalKilometerQueriesCount : undefined,
          totalKilometerQueriesAmount:
            'totalKilometerQueriesAmount' in queryData ? queryData.totalKilometerQueriesAmount : undefined,
        };
      }
      return null;
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!(beginDate && endDate),
    },
  );

  const getExportQueriesReport = useMutation<any, any>(
    async () => {
      dispatch(showLoading());
      if (beginDate && endDate) {
        const response =
          authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin
            ? await Api.Reporting.exportQueriesReportAdmin(beginDate.getTime(), endDate.getTime(), expertisePoint?.id)
            : await Api.Reporting.exportQueriesReport(beginDate.getTime(), endDate.getTime());

        return response;
      }
      return null;
    },
    {
      onSuccess(data) {
        if (data) {
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          const fileName = `Sorgulamalar${formattedDate}.xlsx`;
          downloadBlobAsFile(data, fileName);
        } else {
          toast({
            status: 'info',
            title: 'Rapor Bulunamadı',
            isClosable: true,
          });
        }
      },
      onSettled() {
        dispatch(hideLoading());
      },
      onError() {
        toast({
          status: 'error',
          title: 'Rapor İndirilirken Hata Oluştu',
          isClosable: true,
        });
      },
    },
  );

  if (!reportData || isLoading) {
    return null;
  }

  if (reportData.isEmpty) {
    return (
      <Center>
        <Text fontSize="2xl" mt={2} mb={6}>
          Seçtiğiniz Tarih Aralığında Veri Bulunmuyor!
        </Text>
      </Center>
    );
  }

  return (
    <>
      <Chart
        type="bar"
        data={reportData.graphData}
        options={{
          responsive: true,
          scales: {
            xAxis: {
              stacked: true,
              axis: 'x',
            },
            yAxis: {
              stacked: true,
              position: 'left',
            },
            totalYAxis: {
              stacked: true,
              ticks: {
                precision: 0,
              },
              beginAtZero: true,
              position: 'right',
            },
          },
        }}
      />
      {(authUserInfo?.isSuperAdmin || authUserInfo?.isAdmin) &&
        beginDate &&
        endDate &&
        reportData &&
        reportData.totalQueriesCount &&
        reportData.totalQueriesCount && (
          <>
            <StatGroup p={4}>
              <Stat>
                <StatLabel>KM Sorgulama</StatLabel>
                <StatNumber>{reportData.totalKilometerQueriesCount}</StatNumber>
                <StatHelpText>
                  {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Tramer Sorgulama</StatLabel>
                <StatNumber>{reportData.totalTramerQueriesCount}</StatNumber>
                <StatHelpText>
                  {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Toplam Sorgu</StatLabel>
                <StatNumber>{reportData.totalQueriesCount}</StatNumber>
                <StatHelpText>
                  {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                </StatHelpText>
              </Stat>
            </StatGroup>
            <StatGroup p={4}>
              <Stat>
                <StatLabel>KM Sorgulama Tutarı</StatLabel>
                <StatNumber>{reportData.totalKilometerQueriesAmount} ₺</StatNumber>
                <StatHelpText>
                  {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Tramer Sorgulama Tutarı</StatLabel>
                <StatNumber>{reportData.totalTramerQueriesAmount} ₺</StatNumber>
                <StatHelpText>
                  {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                </StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Toplam Sorgu Tutarı</StatLabel>
                <StatNumber>{reportData.totalQueriesAmount} ₺</StatNumber>
                <StatHelpText>
                  {beginDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                </StatHelpText>
              </Stat>
            </StatGroup>
          </>
        )}
      <Button variant="solid" leftIcon={<Icon as={DownloadIcon} />} onClick={() => getExportQueriesReport.mutate()}>
        Excel İndir
      </Button>
    </>
  );
};
