import {
  Alert,
  AlertIcon,
  Center,
  FormControl,
  FormLabel,
  HStack,
  StackItem,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Api } from 'api';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { DataTable, DateRangePreSelector, LoadingText } from 'components';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Customer, ExpertisePointQueryCacheKey, UtilQueryCacheKey } from 'types';
import { AdminPageTitle } from '../components';

export const AdminPanelPage = () => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
  const [datePickerDates, setDatePickerDates] = useState<Date[]>([new Date(), new Date()]);

  const { data, isLoading } = useQuery(
    [ExpertisePointQueryCacheKey.GetPanelInfo, selectedDates[0], selectedDates[1]],
    async () => {
      if (selectedDates) {
        const response = await Api.ExpertisePoint.getExpertisePointPanel(
          selectedDates[0].getTime(),
          selectedDates[1].getTime(),
        );
        return response.data;
      }
      return null;
    },
    {
      enabled: selectedDates.length === 2,
    },
  );

  const { data: unreadNotificationsData, isLoading: unreadNotificationsDataLoading } = useQuery(
    UtilQueryCacheKey.GetUnreadAnnouncemenets,
    async () => {
      const response = await Api.Util.getUnreadAnnouncemenets();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const dateRangeText = useMemo(() => {
    if (selectedDates.length === 2) {
      return `${selectedDates[1].toLocaleDateString()} - ${selectedDates[1].toLocaleDateString()}`;
    }
    return ``;
  }, [selectedDates]);

  const columnHelper = createColumnHelper<Customer>();

  const columnDef: ColumnDef<Customer, any>[] = [
    columnHelper.accessor('plate', {
      header: 'Plaka',
    }),
    {
      header: 'Marka',
      cell: (cellProps: CellContext<Customer, any>) => {
        const { row } = cellProps;
        const { original } = row;
        return original.customerVehicle?.brand?.name;
      },
    },
    {
      header: 'Model',
      cell: (cellProps: CellContext<Customer, any>) => {
        const { row } = cellProps;
        const { original } = row;
        return original.customerVehicle?.model?.name;
      },
    },
    {
      header: 'Yıl',
      cell: (cellProps: CellContext<Customer, any>) => {
        const { row } = cellProps;
        const { original } = row;
        return original.customerVehicle?.modelYear;
      },
    },
    {
      header: 'Ad',
      cell: (cellProps: CellContext<Customer, any>) => {
        const { row } = cellProps;
        const { original } = row;
        return original.name;
      },
    },
    {
      header: 'Telefon',
      cell: (cellProps: CellContext<Customer, any>) => {
        const { row } = cellProps;
        const { original } = row;
        return original.phoneNumber;
      },
    },
  ];

  return (
    <VStack align="flex-start">
      <AdminPageTitle title="Pano" />
      {!unreadNotificationsDataLoading && unreadNotificationsData?.length !== 0 && (
        <StackItem alignSelf="stretch" mb={2}>
          <Alert status="warning">
            <AlertIcon />
            Okunmamış {unreadNotificationsData?.length} bildirimiz var
          </Alert>
        </StackItem>
      )}

      <HStack gap={10} align="center">
        <StackItem>
          <DateRangePreSelector
            onDateRangeSelected={(startDate: Date, endDate: Date) => {
              setSelectedDates([startDate, endDate]);
            }}
          />
        </StackItem>
        <StackItem>
          <FormControl minWidth="220px">
            <FormLabel>Tarih Aralığı</FormLabel>

            <RangeDatepicker
              selectedDates={datePickerDates}
              onDateChange={(dates: Date[]) => {
                if (dates.length === 2) {
                  setSelectedDates(dates);
                }
                setDatePickerDates(dates);
              }}
              configs={{
                dateFormat: 'dd/MM/yyyy',
              }}
            />
          </FormControl>
        </StackItem>
      </HStack>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!data || isLoading ? (
        <LoadingText />
      ) : data.customers.length === 0 ? (
        <Center alignSelf="stretch">
          <Text fontSize="2xl">Seçilen tarih aralığında gösterilebilecek veri yok</Text>
        </Center>
      ) : (
        <>
          <StackItem>
            <StatGroup p={4} gap={10}>
              <Stat>
                <StatLabel>Ciro</StatLabel>
                <StatNumber>{data.totalSalesAmount} ₺</StatNumber>
                <StatHelpText>{dateRangeText}</StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Müşteri</StatLabel>
                <StatNumber>{data.customersCount}</StatNumber>
                <StatHelpText>{dateRangeText}</StatHelpText>
              </Stat>
            </StatGroup>
          </StackItem>
          <StackItem alignSelf="stretch">
            <DataTable columns={columnDef} data={data.customers} title={`${dateRangeText} Araçlar`} />
          </StackItem>
        </>
      )}
    </VStack>
  );
};
