import { UnorderedList, ListItem, useToast, Text, HStack, Radio } from '@chakra-ui/react';
import { Api } from 'api';
import { FormControlFormInput, FormControlFormRadioGroup, getErrorMessage } from 'core';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { ExpertiseDamageNoteDictItem, ExpertiseDamageNoteType } from 'types';

export interface AdminSaveDamageDictionaryItemFormProps {
  selectedNotes: ExpertiseDamageNoteDictItem[];
  formId: string;
  focusedNote?: ExpertiseDamageNoteDictItem;
  onSubmitStart?: () => void;
  onSubmitComplete?: (savedDamageNoteDictItem?: ExpertiseDamageNoteDictItem) => void;
  noteType: ExpertiseDamageNoteType;
}
type SaveExpertiseNoteDictItemMutationData = {
  note: string;
  mergeDamageNoteDictIds?: number[];
  noteType?: ExpertiseDamageNoteType;
};

export const AdminSaveDamageDictionaryItemForm: React.FC<AdminSaveDamageDictionaryItemFormProps> = ({
  selectedNotes,
  formId,
  focusedNote,
  onSubmitStart,
  onSubmitComplete,
  noteType,
}) => {
  const toast = useToast();
  const saveNoteMutation = useMutation<any, any, SaveExpertiseNoteDictItemMutationData>(
    async (data: SaveExpertiseNoteDictItemMutationData) => {
      const response = await Api.Expertise.saveExpertiseNoteDictItem({
        id: focusedNote?.id,
        note: data.note,
        mergeDamageNoteDictIds: data.mergeDamageNoteDictIds,
        noteType: Number(data.noteType),
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast({
          title: 'Not Başarılı Bir Şekilde Kayıt Edildi',
          status: 'success',
          isClosable: true,
        });
        if (onSubmitComplete) {
          onSubmitComplete(data);
        }
      },
      onError: (err) => {
        toast({
          title: getErrorMessage(err, 'Not Kayıt Edilirken Hata Oluştu'),
          status: 'error',
          isClosable: true,
        });
        if (onSubmitComplete) {
          onSubmitComplete();
        }
      },
    },
  );

  const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
    initialValues: {
      note: focusedNote?.note || '',
      noteType: (noteType || ExpertiseDamageNoteType.Mechanic).toString(),
    },
    onSubmit: (data) => {
      if (onSubmitStart) {
        onSubmitStart();
      }
      saveNoteMutation.mutateAsync({
        note: data.note,
        mergeDamageNoteDictIds:
          selectedNotes.length > 0 ? selectedNotes.map((selectedNote) => selectedNote.id) : undefined,
        noteType: Number(data.noteType),
      });
    },
  });

  return (
    <>
      {selectedNotes.length > 0 && (
        <>
          <Text>Birleştirilecek Notlar </Text>
          <UnorderedList mb={2} mt={1}>
            {selectedNotes.map((selectedNote) => (
              <ListItem key={`${selectedNote.note}${selectedNote.id}`}>{selectedNote.note}</ListItem>
            ))}
          </UnorderedList>
        </>
      )}
      <form onSubmit={handleSubmit} noValidate id={formId}>
        {focusedNote && (
          <FormControlFormRadioGroup
            key="noteType"
            label="Not Türü"
            onChange={(value) => {
              setFieldValue('noteType', value);
            }}
            value={values.noteType}
          >
            <HStack spacing="24px">
              <Radio value={ExpertiseDamageNoteType.Mechanic.toString()}>Mekanik </Radio>
              <Radio value={ExpertiseDamageNoteType.Bodywork.toString()}>Kaporta</Radio>
            </HStack>
          </FormControlFormRadioGroup>
        )}
        <FormControlFormInput
          name="note"
          label="Not"
          placeholder="Not"
          onChange={handleChange}
          value={values.note}
          error={errors.note != null}
          helperText={errors.note}
        />
      </form>
    </>
  );
};
